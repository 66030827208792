import React, { useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import List from "./List"
import { Helmet } from "react-helmet"
import AddEditModel from "../Add/AddEditModel"
function Category() {
  const BreadcrumbDetails = {
    breadcrumbItem: {
      label: "List",
      link: "/categories/list",
    },
    paths: [
      {
        label: "Setting",
        link: "/categories/list",
      },
      {
        label: "Categories",
        link: "/categories/list",
      },
    ],
  }
  const [load, setLoad] = useState(false)
  const [openAddEditModal, setOpenAppEditModal] = useState(false)
  const [id, setId] = useState(null)
  const onCategoryAdd = ID => {
    if (ID) {
      setId(ID)
    }
    setOpenAppEditModal(true)
  }
  const [activeTab, setActiveTab] = useState("other")
  const toggleTab = tab => {
    if (activeTab != tab) {
      setActiveTab(tab)
    }
  }
  return (
    <React.Fragment>
      <Helmet>
        <title>Category List | Discover Equestrian</title>
      </Helmet>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs breadcrumbDetails={BreadcrumbDetails} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                    <NavItem>
                      <NavLink
                        className={activeTab == "other" ? "active" : ""}
                        onClick={() => {
                          toggleTab("other")
                        }}
                      >
                        {" "}
                        Businesses & Services
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={activeTab == "blog" ? "active" : ""}
                        onClick={() => {
                          toggleTab("blog")
                        }}
                      >
                        {" "}
                        News & Blogs
                      </NavLink>
                    </NavItem>
                  </ul>
                  <TabContent activeTab={activeTab} className="p-3">
                    <TabPane tabId={activeTab} id="all-order">
                      <List
                        categoryType={activeTab}
                        setLoad={setLoad}
                        load={load}
                        onCategoryAdd={onCategoryAdd}
                      />
                      <AddEditModel
                        categoryType={activeTab}
                        openModal={openAddEditModal}
                        setOpenModal={setOpenAppEditModal}
                        setLoadList={setLoad}
                        load={load}
                        id={id}
                        setId={setId}
                      />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Category
