import React, { useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Input,
  FormGroup,
  Label,
  Button,
  Form,
  FormFeedback,
} from "reactstrap"

// Import Editor
import QuillEditor from "components/Common/ReactQuill/QuillEditor"
import "react-datepicker/dist/react-datepicker.css"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useCategoryListQuery } from "redux/category/api"
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js"
import { showError, showToaster } from "helpers"
import draftToHtml from "draftjs-to-html"
import { TagsInput } from "react-tag-input-component"
import {
  useAddBlogMutation,
  useGetBlogMutation,
  useUpdateBlogMutation,
} from "redux/newsBlogs/api"
import ImageCroperModel from "components/Common/ImageCroper/ImageCroperModel"
import { replaceQuillClasses } from "helpers/util"
import config from "config"
import LazyImageWithFallback from "components/Common/LazyImageWithFallback"
import LoadingSpinner from "components/Common/LoadingSpinner"

function BlogAdd() {
  const navigate = useNavigate()
  const [isCropModalOpen, setIsCropModalOpen] = useState(false)
  const [selectedFiles, setselectedFiles] = useState([])
  const [addBlog, addBlogAPI] = useAddBlogMutation()
  const [selectedTag, setSelectedTag] = useState([])
  // For Edit
  const { id } = useParams()
  const { pathname } = useLocation()
  const IsEdit = pathname?.includes("edit")
  const [getData, getDataAPI] = useGetBlogMutation()
  const [updateBlog, setUpdateBlogAPI] = useUpdateBlogMutation()
  const [editInfo, setEditInfo] = useState([])
  // category option data list
  const [categoryOption, setcategoryOption] = useState([])
  const categoryData = useCategoryListQuery("blog")
  const BreadcrumbDetails = {
    breadcrumbItem: {
      label: !IsEdit
        ? "Add News & Blogs"
        : "Edit News & Blogs" || editInfo?.title,
      link: !IsEdit ? "news&blogs/add" : `news&blogs/edit/${id}`,
    },
    paths: IsEdit
      ? [
          {
            label: "News & Blogs",
            link: "news&blogs/list",
          },
          // {
          //   label: "Edit",
          //   link: "blogs/list",
          // },
        ]
      : [
          {
            label: "News & Blogs",
            link: "news&blogs/add",
          },
        ],
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getData(id).unwrap()
        setEditInfo(result?.data)
        setSelectedTag(result?.data?.keywords || [])
        if (result?.data?.mediaType == "image") {
          setselectedFiles([result?.data?.image])
        }
      } catch (error) {
        showError(error)
      }
    }
    if (id) {
      fetchData()
    } else {
      setSelectedTag([])
    }
  }, [id])

  useEffect(() => {
    if (categoryData.isSuccess) {
      setcategoryOption(categoryData?.data?.data?.categories)
    }
    if (categoryData.isError) {
      showError(categoryData.error)
    }
    return () => {
      setcategoryOption([])
    }
  }, [categoryData])
  const validationSchema = Yup.object().shape({
    title: Yup.string().trim().required("Title is required"),
    videoLink: Yup.string().when("mediaType", {
      is: mediaType => mediaType != "image",
      then: () =>
        Yup.string()
          .matches(/^\S*$/, "Whitespace is not allowed")
          .url("Invalid video link")
          .matches(
            /^(https?:\/\/)?(www\.)?(youtube\.com|vimeo\.com)\/.+$/,
            "Invalid video link",
          )
          .required("Video Link is required"),
      otherwise: () => Yup.string(),
    }),
    mediaType: Yup.string().required("Media Type is required"),
    categoryId: Yup.string().required("Category selection is required"),
    description: Yup.string().required("Description is required"),
    image: Yup.mixed().when("mediaType", {
      is: mediaType => mediaType == "image",
      then: () =>
        Yup.mixed()
          .required("Image is required")
          .test(
            "fileType",
            "Select Valid Image  (only jpg, jpeg or png)",
            value => {
              // Check Image type
              if (value && value.type) {
                if (!config.IMAGE_UPLOAD_TYPE.includes(value.type)) {
                  handleFileInputClick()
                  formik.values.image = null
                }
                return config.IMAGE_UPLOAD_TYPE.includes(value.type)
              }
              // If value or size is not available, skip the validation
              return true
            },
          )
          .test(
            "fileSize",
            "File size should be less than or equal to 5MB",
            value => {
              // Check if value exists and has a size property
              if (value && value?.size) {
                if (!(value.size <= config.IMAGE_MAXSIZE)) {
                  handleFileInputClick()
                  formik.values.image = null
                }
                return value.size <= config.IMAGE_MAXSIZE // 5MB limit
              }
              // If value or size is not available, skip the validation
              return true
            },
          ),
      otherwise: () => Yup.mixed().notRequired(),
    }),
  })
  const editInitialValues = {
    title: editInfo?.title || "",
    videoLink: editInfo?.videoLink || "",
    mediaType: editInfo?.mediaType || "videoLink",
    categoryId: editInfo?.category?._id || "",
    keywords: editInfo?.keywords || [],
    image: editInfo?.image?.url || null,
    description: editInfo?.description || "",
    meta: {
      title: editInfo?.meta?.title || "",
      tags: editInfo?.meta?.tags || [],
      description: editInfo?.meta?.description || "",
    },
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: IsEdit
      ? editInitialValues
      : {
          title: "",
          videoLink: "",
          mediaType: "videoLink",
          categoryId: "",
          keywords: [],
          image: null,
          description: "",
          meta: {
            title: "",
            tags: [],
            description: "",
          },
        },
    validationSchema,
    onSubmit: async values => {
      const { image, meta, ...UpdateValue } = values
      let newValue = {
        ...UpdateValue,
        description: replaceQuillClasses(`${values?.description}`),
        "meta[title]": meta?.title,
        "meta[tags]": meta?.tags,
        "meta[description]": meta?.description,
      }
      if (selectedFiles[0]?.file) {
        newValue = { ...newValue, image: selectedFiles[0]?.file }
      }
      if (!IsEdit) {
        try {
          const result = await addBlog(newValue).unwrap()
          navigate("/news&blogs/list")
          showToaster("success", `${result.message}`, "Success")
        } catch (error) {
          showError(error)
        }
      } else {
        try {
          const result = await updateBlog({
            payload: newValue,
            id: id,
          }).unwrap()
          navigate("/news&blogs/list")
          showToaster("success", `${result.message}`, "Success")
        } catch (error) {
          showError(error)
        }
      }
    },
  })

  // croper model manage
  const handleOpenCropModal = () => {
    setIsCropModalOpen(true)
  }

  const handleCloseCropModal = (Ufile = selectedFiles) => {
    setIsCropModalOpen(false)
    if (Ufile && Ufile?.length > 0) {
      setselectedFiles([...Ufile])
    } else {
      formik.setFieldValue("image", [])
      handleFileInputClick()
    }
  }
  const fileInputRef = useRef(null)
  // Function to open the file input dialog when the "Change Avatar" button is clicked
  const handleFileInputClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = null
    }
  }

  useEffect(() => {
    if (!formik.values.image) {
      if (IsEdit) {
        formik.setFieldValue("image", editInfo?.image?.url)
        setselectedFiles([editInfo?.image])
      }
      setselectedFiles([])
      return
    }

    if (
      formik.values.image &&
      formik.values.image?.size <= config.IMAGE_MAXSIZE &&
      config.IMAGE_UPLOAD_TYPE.includes(formik.values.image.type)
    ) {
      setselectedFiles([formik.values.image])
      handleOpenCropModal()
    }
  }, [formik.values.image])
  useEffect(() => {
    formik.setFieldValue("keywords", [...selectedTag])
  }, [selectedTag])
  const handleInputChange = (fieldName, e) => {
    const sanitizedValue = e.target.value.replace(/\s+/g, " ")
    formik.handleChange(e)
    formik.setFieldValue(fieldName, sanitizedValue)
  }
  return (
    <React.Fragment>
      <Helmet>
        <title>
          {" "}
          {!IsEdit ? "Add Blog" : "Edit Blog"} | Discover Equestrian
        </title>
      </Helmet>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbDetails={BreadcrumbDetails} />

          {getDataAPI?.isLoading || getDataAPI?.isFetching ? (
            <Col lg="12" className="w-100 d-flex justify-content-center">
              {" "}
              <LoadingSpinner />{" "}
            </Col>
          ) : (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">
                      {/* {IsEdit && editInfo?.title} */}
                      {/* {!IsEdit ? "Add Blog" : "Edit Blog"} */}
                    </CardTitle>
                    <Form
                      className="outer-repeater"
                      onSubmit={e => {
                        e.preventDefault()
                        formik.handleSubmit()
                        // return
                      }}
                    >
                      <div data-repeater-list="outer-group" className="outer">
                        <div data-repeater-item className="outer">
                          <FormGroup className="mb-4" row>
                            <Label
                              htmlFor="title"
                              className="col-form-label col-lg-2"
                            >
                              Title
                            </Label>
                            <Col lg="10">
                              <Input
                                id="title"
                                name="title"
                                type="text"
                                className="form-control"
                                placeholder="Enter Title"
                                value={formik.values.title || ""}
                                onChange={e => {
                                  handleInputChange("title", e)
                                }}
                                onBlur={formik.handleBlur}
                                invalid={
                                  !!(
                                    formik.touched.title && formik.errors.title
                                  )
                                }
                              />
                              {formik.touched.title && formik.errors.title && (
                                <FormFeedback
                                  className="fs-6 mt-0"
                                  type="invalid"
                                >
                                  {formik.errors.title}
                                </FormFeedback>
                              )}
                            </Col>
                          </FormGroup>
                          <FormGroup className="mb-4" row>
                            <Label
                              htmlFor="taskname"
                              className="col-form-label col-lg-2"
                            >
                              Media Type
                            </Label>
                            <Col lg="10">
                              <Row className="justify-content-left">
                                <Col>
                                  <div>
                                    <input
                                      type="radio"
                                      id="videoLink"
                                      name="mediaType"
                                      className="btn-check"
                                      value={"videoLink"}
                                      disabled={IsEdit}
                                      checked={
                                        formik.values.mediaType === "videoLink"
                                      }
                                      onChange={() => {
                                        setselectedFiles([])
                                        formik.setFieldValue("image", null)
                                        formik.touched.image = false
                                        formik.setFieldValue(
                                          "mediaType",
                                          "videoLink",
                                        )
                                      }}
                                    />
                                    <label
                                      className="btn btn-outline-primary"
                                      htmlFor="videoLink"
                                    >
                                      <span>Video</span>{" "}
                                      <i className="bx bx-video text-18"></i>
                                    </label>
                                    <input
                                      type="radio"
                                      id="image"
                                      name="mediaType"
                                      disabled={IsEdit}
                                      className="btn-check"
                                      value={"image"}
                                      checked={
                                        formik.values.mediaType === "image"
                                      }
                                      onChange={() => {
                                        formik.setFieldValue("videoLink", "")
                                        formik.touched.videoLink = false
                                        formik.setFieldValue(
                                          "mediaType",
                                          "image",
                                        )
                                      }}
                                    />
                                    <label
                                      className="btn btn-outline-primary mx-2"
                                      htmlFor="image"
                                    >
                                      <span>Image</span>{" "}
                                      <i className="bx bx-image-alt"></i>
                                    </label>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </FormGroup>
                          <FormGroup className="mb-4" row>
                            <Label
                              htmlFor="categoryId"
                              className="col-form-label col-lg-2"
                            >
                              Category
                            </Label>
                            <Col lg="10">
                              <Input
                                type="select"
                                name="categoryId"
                                className="form-control"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.categoryId || ""}
                                invalid={
                                  !!(
                                    formik.touched.categoryId &&
                                    formik.errors.categoryId
                                  )
                                }
                              >
                                <option value="">Select Category</option>
                                {categoryOption?.map((val, index) => {
                                  return (
                                    <option key={index} value={`${val?.id}`}>
                                      {val?.name}
                                    </option>
                                  )
                                })}
                              </Input>
                              {formik.touched.categoryId &&
                                formik.errors.categoryId && (
                                  <FormFeedback
                                    className="fs-6 mt-0"
                                    type="invalid"
                                  >
                                    {formik.errors.categoryId}
                                  </FormFeedback>
                                )}
                            </Col>
                          </FormGroup>

                          {formik?.values?.mediaType == "image" ? (
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="formFile"
                                className="col-form-label col-lg-2"
                              >
                                Image
                              </Label>
                              <Col lg="5">
                                <Input
                                  innerRef={fileInputRef}
                                  className="form-control"
                                  type="file"
                                  name="image"
                                  accept="image/*"
                                  onBlur={formik.handleBlur}
                                  invalid={
                                    !!(
                                      formik.touched.image &&
                                      formik.errors.image
                                    )
                                  }
                                  onChange={event => {
                                    formik.setFieldValue(
                                      "image",
                                      event.currentTarget.files[0],
                                    )
                                  }}
                                  id="formFile"
                                />
                                {formik.touched.image &&
                                  formik.errors.image && (
                                    <div
                                      className="fs-6 mt-0 text-danger"
                                      type="invalid"
                                    >
                                      {formik.errors.image}
                                    </div>
                                  )}
                              </Col>
                              <Col lg="5">
                                {(IsEdit || !!selectedFiles[0]?.preview) && (
                                  <LazyImageWithFallback
                                    src={
                                      selectedFiles[0]?.preview ||
                                      editInfo?.image?.url
                                    }
                                    alt="Selected Image"
                                    className="rounded"
                                    style={{ height: "50px", width: "auto" }}
                                  />
                                )}
                              </Col>
                            </FormGroup>
                          ) : (
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="videoLink"
                                className="col-form-label col-lg-2"
                              >
                                Video Link
                              </Label>
                              <Col lg="10">
                                <Input
                                  id="videoLink"
                                  name="videoLink"
                                  type="text"
                                  onChange={e => {
                                    handleInputChange("videoLink", e)
                                  }}
                                  className="form-control"
                                  placeholder="Enter Video Link"
                                  value={formik.values.videoLink || ""}
                                  onBlur={formik.handleBlur}
                                  invalid={
                                    !!(
                                      formik.touched.videoLink &&
                                      formik.errors.videoLink
                                    )
                                  }
                                />
                                {formik.touched.videoLink &&
                                  formik.errors.videoLink && (
                                    <FormFeedback
                                      className="fs-6 mt-0"
                                      type="invalid"
                                    >
                                      {formik.errors.videoLink}
                                    </FormFeedback>
                                  )}
                              </Col>
                            </FormGroup>
                          )}
                          <FormGroup className="mb-4" row>
                            <Label
                              htmlFor="tag"
                              className="col-form-label col-lg-2 pt-3"
                            >
                              Tags
                            </Label>
                            <Col lg="10">
                              <em className="text-info">
                                Press enter to add multiple Tags
                              </em>
                              <TagsInput
                                value={selectedTag}
                                onChange={setSelectedTag}
                                name="tag"
                                placeHolder="Enter Tag"
                              />
                              {/* <em>press enter to add new tag</em> */}
                            </Col>
                          </FormGroup>
                          <FormGroup className="mb-4" row>
                            <Label className="col-form-label col-lg-2">
                              Description
                            </Label>
                            <Col lg="10">
                              <QuillEditor
                                setFieldTouched={() =>
                                  formik.setFieldTouched("description", true)
                                }
                                data={formik.values.description}
                                handleChange={description =>
                                  formik.setFieldValue(
                                    "description",
                                    description,
                                  )
                                }
                              />
                              {formik.errors.description &&
                                formik.touched.description && (
                                  <div className="text-danger">
                                    {formik.errors.description}
                                  </div>
                                )}
                            </Col>
                          </FormGroup>
                          <div className="pt-5 ">
                            <h3>Meta</h3>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="MetaTitle"
                                className="col-form-label col-lg-2"
                              >
                                Title
                              </Label>
                              <Col lg="10">
                                <Input
                                  id="metaTitle"
                                  name="meta.title"
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Meta Title"
                                  value={formik.values?.meta?.title || ""}
                                  onChange={e => {
                                    handleInputChange("meta.title", e)
                                  }}
                                  onBlur={formik.handleBlur}
                                  invalid={
                                    !!(
                                      formik.touched?.meta?.title &&
                                      formik?.errors?.meta?.title
                                    )
                                  }
                                />
                                {formik.touched?.meta?.title &&
                                  formik?.errors?.meta?.title && (
                                    <FormFeedback
                                      className="fs-6 mt-0"
                                      type="invalid"
                                    >
                                      {formik?.errors?.meta?.title}
                                    </FormFeedback>
                                  )}
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="meta.tags"
                                className="col-form-label col-lg-2 pt-3"
                              >
                                Tag
                              </Label>
                              <Col lg="10">
                                <em className="text-info">
                                  Press enter to add multiple Meta Tags
                                </em>
                                <TagsInput
                                  value={formik.values?.meta?.tags}
                                  onChange={value =>
                                    formik.setFieldValue("meta.tags", value)
                                  }
                                  name="meta.tags"
                                  placeHolder="Enter Meta Tag"
                                />
                                {/* <em>press enter to add new tag</em> */}
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="metaDescription"
                                className="col-form-label col-lg-2"
                              >
                                Description
                              </Label>
                              <Col lg="10">
                                <Input
                                  id="metaDescription"
                                  name="meta.description"
                                  type="textarea"
                                  className="form-control"
                                  placeholder="Enter Meta description"
                                  value={
                                    formik?.values?.meta?.description || ""
                                  }
                                  onChange={e => {
                                    handleInputChange("meta.description", e)
                                  }}
                                  onBlur={formik.handleBlur}
                                  invalid={
                                    !!(
                                      formik.touched?.meta?.description &&
                                      formik.errors?.meta?.description
                                    )
                                  }
                                />
                                {formik.touched?.meta?.description &&
                                  formik.errors?.meta?.description && (
                                    <FormFeedback
                                      className="fs-6 mt-0"
                                      type="invalid"
                                    >
                                      {formik.errors?.meta?.description}
                                    </FormFeedback>
                                  )}
                              </Col>
                            </FormGroup>
                          </div>
                        </div>
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          {IsEdit && (
                            <Link
                              to={"/news&blogs/list"}
                              type="button"
                              className="btn btn-danger me-1"
                            >
                              <i className="bx bx-left-arrow-alt font-size-16 align-middle me-2"></i>{" "}
                              Back
                            </Link>
                          )}
                          <button
                            type="submit"
                            disabled={formik.isSubmitting}
                            className={`btn btn-success w-md ${
                              formik.isSubmitting
                                ? "cursor-wait"
                                : "cursor-pointer"
                            }`}
                          >
                            {formik.isSubmitting ? (
                              <div className="d-flex justify-content-center align-items-center">
                                <div
                                  className="spinner-border spinner-border-sm me-1"
                                  role="status"
                                >
                                  <span className="sr-only">Adding...</span>
                                </div>
                                <span>Adding...</span>
                              </div>
                            ) : !IsEdit ? (
                              <>
                                <i className="bx bx-plus font-size-16 align-middle me-2"></i>{" "}
                                Add
                              </>
                            ) : (
                              <>
                                <i className="bx bx-up-arrow-alt font-size-16 align-middle me-2"></i>{" "}
                                Update
                              </>
                            )}
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          {isCropModalOpen && (
            <ImageCroperModel
              imageArry={selectedFiles}
              onCancel={handleCloseCropModal}
              setFile={setselectedFiles}
              isOpen={isCropModalOpen}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BlogAdd
