import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Label,
  Form,
  FormGroup,
  Input,
  FormFeedback,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Helmet } from "react-helmet"
// formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import Dropzone from "react-dropzone"
import { showError, showToaster } from "helpers"
import {
  useAddPropertyMutation,
  useGetpropertyDataMutation,
  useUpdatePropertyMutation,
} from "redux/property/api"
import FormikInput from "./FormikInput"
import { usePrefixListQuery } from "redux/prefix/api"
import ImageCroperModel from "components/Common/ImageCroper/ImageCroperModel"
import config from "config"
import { videoLinkRegex } from "helpers/regex"
import QuillEditor from "components/Common/ReactQuill/QuillEditor"
import { replaceQuillClasses } from "helpers/util"
import LazyImageWithFallback from "components/Common/LazyImageWithFallback"
import { DragImage } from "components/Common/DragImage/DragDiv"
import LoadingSpinner from "components/Common/LoadingSpinner"
const AddProperty = () => {
  const [addProperty, addPropertyAPI] = useAddPropertyMutation()
  const [updateProperty, updatePropertyAPI] = useUpdatePropertyMutation()
  const navigate = useNavigate()

  // for edit
  const { pathname } = useLocation()
  const IsEdit = pathname?.includes("edit")
  const { id } = useParams()
  const [editData, setEditData] = useState({})
  const [deletephoto, setdeletphoto] = useState([])
  const [getData, getDataAPI] = useGetpropertyDataMutation()
  const [mapModalShow, setMapModalShow] = useState(false)
  // malti image upload
  const [file, setFile] = useState([])
  const [uploadImage, setUploadImage] = useState([])
  const [isCropModalOpen, setIsCropModalOpen] = useState(false)
  const [userOption, setUserOption] = useState([])
  const [prefixOption, setPrefixOption] = useState([])
  const prefixData = usePrefixListQuery()
  // Fetch edit property data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getData(id).unwrap()
        setEditData(result?.data)
        setUploadImage(result?.data?.images || [])
        setUserOption([
          {
            value: result?.data?.user?._id,
            label: result?.data?.user?.name,
            email: result?.data?.user?.email,
          },
        ])
      } catch (error) {
        showError(error)
      }
    }

    if (id) {
      fetchData()
    } else {
      setEditData([])
      setUploadImage([])
      setUserOption([])
    }
  }, [id, pathname])

  useEffect(() => {
    if (prefixData.isSuccess) {
      setPrefixOption(prefixData?.data?.data?.prefixes)
    }
    if (prefixData.isError) {
      showError(prefixData.error)
    }
    return () => {
      setPrefixOption([])
    }
  }, [prefixData])
  const BreadcrumbDetails = {
    breadcrumbItem: {
      label: !IsEdit ? "Add Property" : "Edit Property" || editData?.title,
      link: !IsEdit
        ? "properties/add"
        : pathname.includes("user")
          ? `user/property/edit/${id}`
          : `properties/edit/${id}`,
    },
    paths: IsEdit
      ? pathname.includes("user")
        ? [
            {
              label: "User",
              link: `user/property/edit/${id}`,
            },
            {
              label: "Property",
              link: `user/property/edit/${id}`,
            },
          ]
        : [
            {
              label: "Properties",
              link: "properties/list",
            },
          ]
      : [
          {
            label: "Properties",
            link: "properties/add",
          },
        ],
  }
  const [imageSequence, setImageSequence] = useState([])
  const initialFormValues = {
    user: editData?.user?._id || "",
    type: editData?.type || "",
    price: editData?.price || "",
    title: editData?.title || "",
    address: editData?.address || "",
    beds: editData?.beds == "" ? "0" : editData?.bathrooms,
    bathrooms: editData?.bathrooms == "" ? "0" : editData?.bathrooms,
    area: editData?.area || "",
    videoLink: editData?.videoLink || "",
    prefixId: editData?.prefixId || "",
    stable: editData?.stable == true ? "yes" : "no" || "no",
    outBuilding: editData?.outBuilding == true ? "yes" : "no" || "no",
    manage: editData?.manage == true ? "yes" : "no" || "no",
    annex: editData?.annex == true ? "yes" : "no" || "no",
    autoUpdate: editData?.autoUpdate == true ? "yes" : "no" || "no",
    description: editData?.description || "",
    long: editData?.location?.coordinates[0] || "",
    lat: editData?.location?.coordinates[1] || "",
    image: editData?.images ? editData?.images : null,
    county: editData?.addressMeta?.county
      ? editData?.addressMeta?.county
      : null,
    town: editData?.addressMeta?.town ? editData?.addressMeta?.town : "",
  }
  const validationSchema = Yup.object({
    user: Yup.string().required("User selection is required"),
    type: Yup.string().required("Property type selection is required"),
    price: Yup.number()
      .moreThan(0, "Please enter valid price")
      .max(100000000000, "Price must not exceed € 100,000,000,000")
      .integer("Please enter valid price")
      .required("Price is required"),
    title: Yup.string()
      .trim()
      .required("Property Title is required")
      .max(100, "Title should be less than or equal to  100 characters"),
    beds: Yup.number()
      .notRequired("Please enter the number of beds")
      .integer("Please enter integer value")
      .moreThan(-1, "Please enter valid Beds counts")
      .max(99, "Beds counts should be less than or equal to 99"),
    bathrooms: Yup.number()
      .moreThan(-1, "Please enter valid Bathrooms counts")
      .integer("Please enter integer value")
      .notRequired("Please enter the number of bathrooms")
      .max(99, "Bathrooms counts should be less than or equal to 99"),
    area: Yup.number()
      .moreThan(0, "Please enter valid value of Area's")
      .max(1000000000000000, "Area must not exceed 1000000000000000 Acres")
      .required("Area is required"),
    videoLink: Yup.string()
      .trim()
      .matches(/^\S*$/, "Whitespace is not allowed")
      .url("Please enter a valid URL")
      .matches(
        /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
        "Invalid video link",
      )
      .notRequired("Video URL is required"),
    prefixId: Yup.string().required("Prefix selection is required"),
    stable: Yup.string(),
    address: Yup.string()
      .trim()
      .required("Address is required")
      .test("not-same-as-old", "Enter valid Address", function (value) {
        return typeof this.parent.lat == "number"
      }),
    description: Yup.string().trim().required("Description is required"),
    outBuilding: Yup.string(),
    manege: Yup.string(),
    annex: Yup.string(),
    autoUpdate: Yup.string(),
    image: Yup.mixed()
      .required("Images is required")
      .test("fileType", "Select Valid Image (only jpg, jpeg or png)", value => {
        // Check Image type
        if (value && value[0]?.type) {
          if (
            !!value.filter(val => !config.IMAGE_UPLOAD_TYPE.includes(val.type))
              .length
          ) {
            showToaster(
              "error",
              "Select Valid Image (only jpg, jpeg or png)",
              "Wrong",
            )
          }
          return !!!value.filter(
            val => !config.IMAGE_UPLOAD_TYPE.includes(val.type),
          ).length
        }
        return true
      })
      .test(
        "uploadImage",
        "File size should be less than or equal to 10MB",
        value => {
          if (value && value[0]?.size) {
            if (
              !!value.filter(val => val.size >= config.PROPERTY_IMAGE_MAXSIZE)
                .length
            ) {
              showToaster(
                "error",
                "File size should be less than or equal to 10MB",
                "Wrong",
              )
            }
            return !!!value.filter(
              val => val.size >= config.PROPERTY_IMAGE_MAXSIZE,
            ).length
          }
          return true
        },
      ),
  })
  // delete Image remove in array and and add deleteArray delete image key
  const handleDeleteImage = (index, IsEdit = false) => {
    const updatedFiles = [...uploadImage]
    updatedFiles.splice(index, 1)
    setUploadImage(updatedFiles)
    if (updatedFiles?.length) {
      validation.setFieldValue("image", updatedFiles)
    } else {
      validation.setFieldValue("image", null)
    }
    if (IsEdit) {
      setdeletphoto([...deletephoto, IsEdit])
    }
  }
  const [submit, setSubmit] = useState(false)
  // formik
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: initialFormValues,
    validationSchema: submit
      ? validationSchema
      : Yup.object({
          user: Yup.string().required("User selection is required"),
          title: Yup.string()
            .trim()
            .required("Property Title is required")
            .max(100, "Title should be less than or equal to  100 characters"),
        }),
    onSubmit: async values => {
      let newvalue = {
        user: values.user,
        type: values.type,
        title: values.title,
        price: values.price,
        location: {
          coordinates: [values?.long, values?.lat],
        },
        beds: values.beds || 0,
        bathrooms: values.bathrooms || 0,
        area: values.area,
        prefixId: values.prefixId,
        videoLink: values.videoLink || "",
        description: replaceQuillClasses(values.description),
        address: values.address,
        stable: values.stable == "no" ? false : true,
        outBuilding: values.outBuilding == "no" ? false : true,
        manage: values.manage == "no" ? false : true,
        annex: values.annex == "no" ? false : true,
        autoUpdate: values.autoUpdate == "no" ? false : true,
        addressMeta: {
          county: values.county ? values.county : "",
          town: values.town ? values.town : "",
        },
      }
      if (!submit) {
        newvalue = {
          ...newvalue,
          draft: true,
        }
      } else if (editData?.status == "draft") {
        newvalue = {
          ...newvalue,
          draft: false,
        }
      }
      if (!IsEdit) {
        try {
          const result = await addProperty({
            value: newvalue,
            images: uploadImage,
          }).unwrap()
          navigate("/properties/list")
          showToaster("success", `${result.message}`, "Success")
        } catch (error) {
          showError(error)
        }
      } else {
        try {
          const Sequence = uploadImage.map(value =>
            value?.key ? value?.key : value?.file?.name,
          )
          const result = await updateProperty({
            value: newvalue,
            images: uploadImage,
            deleteImg: deletephoto,
            imageMap: Sequence,
            id: id,
          }).unwrap()
          navigate(-1)
          showToaster("success", `${result.message}`, "Success")
        } catch (error) {
          showError(error)
        }
      }
    },
  })
  const handleOpenCropModal = () => {
    setIsCropModalOpen(true)
  }

  const handleCloseCropModal = (Ufile = file) => {
    setIsCropModalOpen(false)
    if (Ufile.length <= 0) {
      validation.setFieldValue("image", null)
    }
    if (Ufile.length > 0) {
      setUploadImage([...uploadImage, ...Ufile])
    }
  }

  useEffect(() => {
    if (
      validation.values.image?.length &&
      !!!validation.values?.image[0]?.url &&
      !!!validation.values.image.filter(
        val => val?.size >= config.PROPERTY_IMAGE_MAXSIZE,
      ).length &&
      !!!validation.values.image.filter(
        val => !config.IMAGE_UPLOAD_TYPE.includes(val.type),
      ).length
    ) {
      setFile([...validation.values.image])
      handleOpenCropModal()
    } else {
      validation.touched.image = true
      if (uploadImage.length > 0) {
        validation.setFieldValue("image", uploadImage)
      }
    }
  }, [validation.values.image])
  const handleInputChange = (fieldName, e) => {
    const sanitizedValue = e.target.value.replace(/\s+/g, " ")
    validation.handleChange(e)
    validation.setFieldValue(fieldName, sanitizedValue)
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>
          {!IsEdit ? "Add Property" : "Edit Property"} | Discover Equestrian
        </title>
      </Helmet>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbDetails={BreadcrumbDetails} />

          {(getDataAPI?.isLoading || getDataAPI?.isFetching) && (
            <Col lg="12" className="w-100 d-flex justify-content-center">
              {" "}
              <LoadingSpinner />{" "}
            </Col>
          )}

          {!getDataAPI?.isFetching && (
            <Row>
              <Col lg="12">
                <Form
                  className="form-horizontal"
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                    // return
                  }}
                >
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4">
                        {/* {!IsEdit ? "Add Property" : "Update Property"} */}
                      </CardTitle>
                      {!getDataAPI?.isLoading && !getDataAPI?.isFetching && (
                        <Row>
                          <FormikInput
                            IsEdit={IsEdit}
                            validation={validation}
                            prefixOption={prefixOption}
                            userOption={userOption}
                            setMapModalShow={setMapModalShow}
                            mapModalShow={mapModalShow}
                            editData={editData}
                            handleInputChange={handleInputChange}
                          />
                          <FormGroup className="mb-4" row>
                            <Label className="col-form-label col-lg-2">
                              Description
                            </Label>
                            <Col lg="12">
                              <QuillEditor
                                hideIcon={{ image: true }}
                                setFieldTouched={() =>
                                  validation.setFieldTouched(
                                    "description",
                                    true,
                                  )
                                }
                                data={validation.values.description}
                                handleChange={description =>
                                  validation.setFieldValue(
                                    "description",
                                    description,
                                  )
                                }
                              />
                              {validation.errors.description &&
                                validation.touched.description && (
                                  <div className="text-danger">
                                    {validation.errors.description}
                                  </div>
                                )}
                            </Col>
                          </FormGroup>
                        </Row>
                      )}
                      <Row className="mb-4">
                        {!getDataAPI?.isLoading && !getDataAPI?.isFetching && (
                          <Label className="col-form-label col-lg-2">
                            Select Images
                          </Label>
                        )}
                        {/* <Form> */}
                        <Row>
                          {!getDataAPI?.isLoading &&
                            !getDataAPI?.isFetching && (
                              <Col lg="4" md="6">
                                <Dropzone
                                  onDrop={acceptedFiles => {
                                    validation.setFieldValue(
                                      "image",
                                      acceptedFiles,
                                    )
                                  }}
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <div className="dropzone">
                                      <div
                                        className="dz-message needsclick"
                                        {...getRootProps()}
                                      >
                                        <input
                                          {...getInputProps()}
                                          onBlur={validation.handleBlur}
                                          accept="image/*"
                                        />
                                        <div className="dz-message needsclick">
                                          <div className="mb-3">
                                            <i className="display-4 text-muted bx bxs-cloud-upload" />
                                          </div>
                                          <h4>
                                            Drop images here or click to upload.
                                          </h4>
                                          <h6 className="text-danger">
                                            Maximum image size allowed is 10MB.{" "}
                                          </h6>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Dropzone>
                                {validation.touched.image &&
                                  validation.errors.image && (
                                    <div
                                      className="fs-6 mt-0 text-danger"
                                      type="invalid"
                                    >
                                      {validation.errors.image}
                                    </div>
                                  )}
                              </Col>
                            )}
                          {!getDataAPI?.isFetching && uploadImage && (
                            <Col lg="8" md="6">
                              {uploadImage && (
                                <DragImage
                                  Images={uploadImage}
                                  setImages={setUploadImage}
                                  imageSequence={imageSequence}
                                  setImageSequence={setImageSequence}
                                  handleDeleteImage={handleDeleteImage}
                                />
                              )}
                            </Col>
                          )}

                          {!getDataAPI?.isLoading &&
                            !getDataAPI?.isFetching &&
                            isCropModalOpen && (
                              <ImageCroperModel
                                imageArry={file}
                                onCancel={handleCloseCropModal}
                                setFile={setFile}
                                isOpen={isCropModalOpen}
                                propertyImageCrop={true}
                              />
                            )}
                        </Row>
                      </Row>
                      {!getDataAPI?.isLoading && !getDataAPI?.isFetching && (
                        <Row className="mb-3">
                          <Col className="text-end">
                            {IsEdit && (
                              <Link
                                onClick={() => navigate(-1)}
                                type="button"
                                className="btn btn-danger me-1"
                              >
                                <i className="bx bx-left-arrow-alt font-size-16 align-middle me-2"></i>{" "}
                                Back
                              </Link>
                            )}
                            {(!IsEdit || editData?.status == "draft") && (
                              <button
                                type="submit"
                                disabled={
                                  validation.isSubmitting ||
                                  (IsEdit &&
                                    validation.initialValues ==
                                      validation.values &&
                                    JSON.stringify(uploadImage) ===
                                      JSON.stringify(editData?.images))
                                }
                                className={`btn btn-dark w-md  me-2 ${
                                  validation.isSubmitting
                                    ? "cursor-wait"
                                    : "cursor-pointer"
                                }`}
                                onClick={() => setSubmit(false)} // Save as Draft button
                              >
                                <i className="mdi mdi-content-save-edit font-size-14 align-middle me-2"></i>{" "}
                                Save as Draft
                              </button>
                            )}
                            <button
                              type="submit"
                              disabled={
                                validation.isSubmitting ||
                                (IsEdit &&
                                  validation.initialValues ==
                                    validation.values &&
                                  JSON.stringify(uploadImage) ===
                                    JSON.stringify(editData?.images))
                              }
                              onClick={() => setSubmit(true)} // Save as Draft button
                              className={`btn btn-success w-md ${
                                validation.isSubmitting
                                  ? "cursor-wait"
                                  : "cursor-pointer"
                              }`}
                            >
                              {validation.isSubmitting && submit ? (
                                <div className="d-flex justify-content-center align-items-center">
                                  <div
                                    className="spinner-border spinner-border-sm me-1"
                                    role="status"
                                  >
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                  <span>Loading...</span>
                                </div>
                              ) : !IsEdit || editData?.status == "draft" ? (
                                <>
                                  <i className="bx bx-plus font-size-16 align-middle me-2"></i>{" "}
                                  Add
                                </>
                              ) : (
                                <>
                                  <i className="bx bx-up-arrow-alt font-size-16 align-middle me-2"></i>{" "}
                                  Update
                                </>
                              )}
                            </button>
                          </Col>
                        </Row>
                      )}
                    </CardBody>
                  </Card>
                </Form>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddProperty
