import React, { useEffect, useMemo, useState } from "react"

//Import Breadcrumb
import CustomTable from "components/Common/Table/CustomeTable"
import { useDispatch, useSelector } from "react-redux"
import { authentiCateUserPermission, showError } from "helpers"
import { Link } from "react-router-dom"
import { UncontrolledTooltip } from "reactstrap"
import {
  selectCurrentUser,
  selectPermissions,
} from "redux/authentication/slice"
import { useCrmListQuery } from "redux/user/CRM/api"
import {
  selectCrmList,
  selectCrmPageInfo,
  setCrmList,
} from "redux/user/CRM/slice"
import { formatDate } from "helpers/data-formatters"
import ViewModel from "./CrmViewModel"

function CrmList({ userId = null }) {
  const [openModal, setOpenModal] = useState(false)
  const [id, setId] = useState(null)
  const openViewModel = Id => {
    if (Id) {
      setId(Id)
    }
    setOpenModal(true)
  }
  const data = useSelector(selectCrmList)
  const pageInfo = useSelector(selectCrmPageInfo)
  const [state, setState] = useState({
    search: undefined,
    type: null,
    limit: 10,
    page: 1,
    status: null,
  })
  const dispatch = useDispatch()
  const user = useSelector(selectCurrentUser)
  const userPermissions = useSelector(selectPermissions)

  const columns = useMemo(() => [
    {
      Header: "Id",
      accessor: "_id",
      // filter: false,
      Cell: cellProps => {
        return <div> {cellProps.row.original._id} </div>
      },
    },
    {
      Header: "CRM  ",
      accessor: "crm",
      filter: false,
      filterable: false,
      disableFilters: true,
      disableSortBy: true,
      maxSize: 300,
      Cell: cellProps => {
        return <div> {cellProps.value} </div>
      },
    },
    {
      Header: "Created At",
      accessor: "createdAt",
      filterable: false,
      disableFilters: true,
      disableSortBy: true,
      Cell: cellProps => {
        return <div> {formatDate(cellProps.value)} </div>
      },
    },
    {
      Header: "Action",
      accessor: "action",
      disableFilters: true,
      disableSortBy: true,
      Cell: cellProps => {
        return (
          <>
            <div className="d-flex gap-2">
              <Link
                to={`#`}
                onClick={() => openViewModel(cellProps.row.original._id)}
                className="text-info"
              >
                <i
                  className="mdi mdi-eye font-size-18"
                  id={`view${cellProps.row.original._id}`}
                />
                <UncontrolledTooltip
                  placement="top"
                  target={`view${cellProps.row.original._id}`}
                >
                  View
                </UncontrolledTooltip>
              </Link>
            </div>
          </>
        )
      },
    },
  ])

  const list = useCrmListQuery({ state: state, userId: userId })

  if (list.isSuccess) {
    dispatch(setCrmList(list.data.data))
  }

  if (list.isError) {
    showError(list.error)
  }

  useEffect(() => {
    list.refetch()

    return () => {
      dispatch(
        setCrmList({
          list: [],
          pageInfo: { limit: 10, page: 1, totalPages: 1, totalResults: 0 },
        }),
      )
    }
  }, [state])
  return (
    <React.Fragment>
      <CustomTable
        data={data}
        list={list}
        totalRecords={pageInfo?.totalResults}
        stateAPI={state}
        setStateAPI={setState}
        columns={columns}
        // userId={userId}
      />
      {openModal && (
        <ViewModel
          openModal={openModal}
          setOpenModal={setOpenModal}
          id={id}
          setId={setId}
        />
      )}
    </React.Fragment>
  )
}
export default CrmList
