import React from "react"
import ReactQuill from "react-quill"
import QuillToolbar, { formats, modules } from "./EditorToolbar"
import "./index.css"

const QuillEditor = ({
  data,
  handleChange,
  setFieldTouched,
  hideIcon = {},
}) => {
  return (
    <div className="text-editor">
      <QuillToolbar {...hideIcon} />
      <ReactQuill
        style={{ borderRadius: "50px" }}
        theme="snow"
        id="body"
        onBlur={el => {
          setFieldTouched()
        }}
        value={data}
        onChange={values => {
          if ("<p><br></p>" == values) {
            handleChange("")
            return
          }
          handleChange(values)
          return
        }}
        placeholder={"Enter Description Here"}
        modules={modules}
        formats={formats}
      />
    </div>
  )
}

export default QuillEditor
