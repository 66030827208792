import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import {
  setCredentials,
  logOut,
  setUserProfile,
  serverError,
} from "./authentication/slice"
import config from "../config"

import { getAuthToken, setAuthToken } from "helpers/util"

const baseQuery = fetchBaseQuery({
  baseUrl: config.API_URL,
  // credentials: "include",
  withCredentials: true,
  credentials: "same-origin",
  prepareHeaders: (headers, { getState, endpoint }) => {
    const token = getState().auth.token
    if (token && endpoint !== "refresh") {
      headers.set("authorization", `Bearer ${token}`)
    }
    headers.set("role", `admin`)
    // Check if the request body is FormData
    if (endpoint.body instanceof FormData) {
      // Set the Content-Type header to multipart/form-data
      headers.set("Content-Type", "multipart/form-data")
    }
    return headers
  },
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)
  // if ([403].includes(result?.error?.data?.code)) {
  //   return
  // }
  if (
    [401].includes(result?.error?.data?.code) &&
    !extraOptions?.customRefresh &&
    !!getAuthToken()
  ) {
    // Send refresh token to get a new access token
    const refreshToken = getAuthToken() || ""
    // Implement your logic to send a request to the token refresh endpoint
    const refreshResult = await fetch(`${config.API_URL}/auth/refresh-tokens`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        role: "admin",
      },
      body: JSON.stringify({ refreshToken: refreshToken }),
    })
    const data = await refreshResult.json()
    if (data.type == "success") {
      setAuthToken(data?.data?.refresh?.token)
      api.dispatch(setCredentials(data?.data?.access))
      const userResult = await fetch(`${config.API_URL}/users`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${data?.data?.access?.token}`,
          role: "admin",
        },
      })
      const userData = await userResult.json()
      if (userData.type == "success") {
        api.dispatch(setUserProfile(userData.data))
        result = await baseQuery(args, api, extraOptions)
      } else {
        api.dispatch(logOut())
        return
      }
    } else {
      api.dispatch(logOut())
      return
    }
  } else if (
    result?.error?.status == "FETCH_ERROR" ||
    result?.error?.code == 500
  ) {
    api.dispatch(serverError(true))
  }
  return result
}

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: builder => ({}),
})
