import LazyImageWithFallback from "components/Common/LazyImageWithFallback"
import {
  formatBigNumber,
  formatDate,
  wordTitleCase,
} from "helpers/data-formatters"
import { getAvatarValue } from "helpers/util"
import { capitalize } from "lodash"
import React from "react"
import { Link } from "react-router-dom"
import { Badge, UncontrolledTooltip } from "reactstrap"
// import { wordTitleCase } from '../../helpers/data-formatters';

const TitleText = cell => {
  return wordTitleCase(cell.value)
}
const DateFormate = cell => {
  return formatDate(cell.value) || "-"
}
const Name = cell => {
  const role = cell?.row?.original
  return (
    <>
      <Link id={`review${cell.row.original.id}`} to={`/user/${role?.id}`}>
        <div style={{ fontWeight: "500" }}>{wordTitleCase(cell.value)}</div>
      </Link>
      <div className="">{wordTitleCase(role.role)}</div>
    </>
  )
}

const ProfileImage = cell => {
  const role = cell?.row?.original
  const avatar = getAvatarValue(cell?.row?.original?.name)
  return (
    <Link
      className="d-inline-block"
      id={`review${cell.row.original.id}`}
      to={`/user/${role?.id}`}
    >
      {cell?.value ? (
        <LazyImageWithFallback
          src={cell?.value}
          alt={""}
          className="p-0 position-relative avatar-sm rounded-circle img-thumbnail"
        />
      ) : (
        <div
          className="d-flex align-items-center justify-content-center rounded-circle  shadow-2xl position-relative avatar-sm"
          style={{
            backgroundColor: `${avatar?.backgroundColor}`,
            color: `${avatar?.fontColor}`,
          }}
        >
          <h4 className="display-61 font-weight-bold   mb-0">
            {avatar && avatar?.character}
          </h4>
        </div>
      )}
    </Link>
  )
}
const NumberFormate = cell => {
  return formatBigNumber(cell.value) || ""
}

const TypeStatus = cell => {
  return cell?.row?.original?.isDeleted ? (
    <div className="d-flex gap-2 align-items-center">
      <Badge className={"font-size-12 badge-soft-danger"}>Deleted</Badge>
      {cell?.row?.original?.deleted && (
        <>
          <i
            className="mdi  mdi-information-outline text-danger font-size-18"
            id={`edit${cell.row.original._id}`}
          />
          <UncontrolledTooltip
            placement="top"
            target={`edit${cell.row.original._id}`}
          >
            {cell.row.original?.deleted?.deletedBy && (
              <>
                Deleted By {cell.row.original?.deleted?.deletedBy}
                <br />
              </>
            )}
            Deleted At {formatDate(cell.row.original?.deleted?.deletedAt)}
          </UncontrolledTooltip>
        </>
      )}
    </div>
  ) : cell?.row?.original?.isBanned?.status ? (
    <Badge className={"font-size-12 badge-soft-dark"}>Banned</Badge>
  ) : (
    <Badge className={"font-size-12 badge-soft-success"}>Active</Badge>
  )
}

export { TitleText, Name, ProfileImage, NumberFormate, TypeStatus, DateFormate }
