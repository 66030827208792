import React, { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { capitalize, map } from "lodash"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Table,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
//Import mini card widgets
import MiniCards from "./mini-card"

//Import Images
import profile1 from "assets/images/profile-img.png"
import { useGetUserMutation } from "redux/user/api"
import { authentiCateUserPermission, showError } from "helpers"
import BusinessesServiceList from "pages/Advert/List/List"
import PropertyList from "pages/Property/List/List"
import AdvertsList from "pages/BannerAds/List/List"
import { useSelector } from "react-redux"
import { selectAdvTotalRecord } from "redux/advert/slice"
import { selectPropertyTotalRecord } from "redux/property/slice"
import {
  selectCurrentUser,
  selectPermissions,
} from "redux/authentication/slice"
import EditUserModel from "./EditUserModel"
import { Helmet } from "react-helmet"
import "./MapOption.scss"
import LazyImageWithFallback from "components/Common/LazyImageWithFallback"
import { selectBannerAdsTotalRecord } from "redux/bannerAds/slice"
import { getAvatarValue } from "helpers/util"
import CrmList from "./CRM/CrmList"
import LoadingSpinner from "components/Common/LoadingSpinner"
function ViewUser() {
  const { id } = useParams()
  const [getData, getDataAPI] = useGetUserMutation()
  const [userData, setUserData] = useState([])
  const totalBusinessesService = useSelector(selectAdvTotalRecord)
  const totalProperty = useSelector(selectPropertyTotalRecord)
  const totalAdvert = useSelector(selectBannerAdsTotalRecord)
  const user = useSelector(selectCurrentUser)
  const userPermission = useSelector(selectPermissions)
  const [load, setLoad] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getData(id).unwrap()
        setUserData(result?.data)
      } catch (error) {
        navigate("/user/list")
        showError(error)
      }
    }
    if (id) {
      fetchData()
    }
  }, [id, load])

  useEffect(() => {
    setMiniCards([
      {
        title: "Properties",
        iconClass: "bx bx-building-house",
        text: totalProperty?.totalResults || 0,
      },
      {
        title: "Businesses & Services",
        iconClass: "bx bx-briefcase-alt",
        text: totalBusinessesService?.totalResults || 0,
      },
      {
        title: "Adverts",
        iconClass: "mdi mdi-google-ads",
        text: totalAdvert?.totalResults || 0,
      },
      // { title: "Total Spent Amount", iconClass: "bx bx-pound", text: "0" },
    ])
    if (
      !authentiCateUserPermission(
        ["listProperty"],
        userPermission,
        user?.role,
      ) &&
      authentiCateUserPermission(["listAdvert"], userPermission, user?.role)
    ) {
      setActiveTab("businesses&services")
    }
    if (
      !authentiCateUserPermission(
        ["listProperty"],
        userPermission,
        user?.role,
      ) &&
      !authentiCateUserPermission(["listAdvert"], userPermission, user?.role) &&
      authentiCateUserPermission(["listBanner"], userPermission, user?.role)
    ) {
      setActiveTab("adverts")
    }
    if (
      !authentiCateUserPermission(
        ["listProperty"],
        userPermission,
        user?.role,
      ) &&
      !authentiCateUserPermission(["listAdvert"], userPermission, user?.role) &&
      !authentiCateUserPermission(["listBanner"], userPermission, user?.role) &&
      authentiCateUserPermission(["CRMlist"], userPermission, user?.role)
    ) {
      setActiveTab("crm")
    }
  }, [
    totalBusinessesService?.totalResults,
    totalProperty?.totalResults,
    userPermission,
  ])
  const [miniCards, setMiniCards] = useState([])
  const BreadcrumbDetails = {
    breadcrumbItem: {
      label: `${userData.name}`,
      link: "user/list/",
    },
    paths: [
      {
        label: "Users",
        link: "user/list",
      },
      {
        label: "List",
        link: "user/list",
      },
    ],
  }
  const [activeTab, setActiveTab] = useState("Properties")
  const toggleTab = tab => {
    if (activeTab != tab) {
      setActiveTab(tab)
    }
  }
  const [openPasswordModel, setOpenPasswordModel] = useState(false)
  const handleCloseModal = () => {
    setOpenPasswordModel(false)
  }
  const handleOpenModal = () => {
    setOpenPasswordModel(true)
  }
  const avatar = userData?.name && getAvatarValue(userData?.name)
  return (
    <React.Fragment>
      <Helmet>
        <title>User Detail | Discover Equestrian</title>
      </Helmet>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {getDataAPI?.isLoading || getDataAPI?.isFetching ? (
            <Col lg="12" className="w-100 d-flex justify-content-center">
              {" "}
              <LoadingSpinner />{" "}
            </Col>
          ) : (
            <>
              <Breadcrumbs breadcrumbDetails={BreadcrumbDetails} />
              <Row>
                <Col xl="4">
                  <Card className="overflow-hidden">
                    <div className="bg-dark bg-soft">
                      <Row>
                        <Col xs="7">
                          <div className="text- p-3">
                            <h5 className="text-primary">Welcome !</h5>
                          </div>
                        </Col>
                        <Col xs="5" className="align-self-end">
                          <img src={profile1} alt="" className="img-fluid" />
                        </Col>
                      </Row>
                    </div>
                    <CardBody className="pt-0">
                      <Row>
                        <Col sm="4">
                          <div className="profile-user-wid mb-4 ">
                            <div className="d-flex align-items-center profile-imgtxt">
                              <div className="profileimage-wrapper">
                                {userData?.profileImage ? (
                                  <LazyImageWithFallback
                                    className="w-25"
                                    src={`${userData?.profileImage}?v=${Math.floor(
                                      Math.random() * 90 + 10,
                                    )}`}
                                    alt="profile"
                                  />
                                ) : (
                                  <div
                                    className="d-flex align-items-center justify-content-center rounded-circle  shadow-2xl position-relative avatar-lg"
                                    style={{
                                      backgroundColor: `${avatar?.backgroundColor}`,
                                      color: `${avatar?.fontColor}`,
                                    }}
                                  >
                                    <h1 className="display-6 font-weight-bold   mb-0">
                                      {avatar && avatar?.character}
                                    </h1>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <h5 className="font-size-15 text-truncate">
                            {userData.name}
                          </h5>
                          <p className="text-muted mb-0 text-truncate">
                            {userData.profileHeading}
                          </p>
                        </Col>

                        <Col sm={8}>
                          <div className="pt-4">
                            <div className="mt-4">
                              {authentiCateUserPermission(
                                ["updateUser"],
                                userPermission,
                                user?.role,
                              ) && (
                                <Link
                                  to="#"
                                  className="btn btn-primary  btn-sm"
                                  onClick={() => handleOpenModal()}
                                >
                                  Edit Profile{" "}
                                  <i className="mdi mdi-arrow-right ms-1" />
                                </Link>
                              )}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <EditUserModel
                    load={load}
                    setLoad={setLoad}
                    onCancel={handleCloseModal}
                    isOpen={openPasswordModel}
                    userdata={userData}
                  />
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4">
                        Personal Information
                      </CardTitle>
                      <p
                        className="text-muted mb-4 overflow-auto scroller-thin"
                        style={{ maxHeight: "500px" }}
                      >
                        {userData.aboutMe}
                      </p>
                      <div className="table-responsive">
                        <Table className="table-wrap mb-0">
                          <tbody>
                            <tr>
                              <th scope="row">Date of Birth :</th>
                              <td>{userData.dob}</td>
                            </tr>
                            <tr>
                              <th scope="row">Mobile :</th>
                              <td>{userData.contact}</td>
                            </tr>
                            <tr>
                              <th scope="row">E-mail :</th>
                              <td>{userData.email}</td>
                            </tr>
                            <tr>
                              <th scope="row">Address :</th>
                              <td>{userData.address}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col xl="8">
                  <Row>
                    {map(miniCards, (card, key) => (
                      <MiniCards
                        title={card.title}
                        text={card.text}
                        iconClass={card.iconClass}
                        key={"_card_" + key}
                      />
                    ))}
                  </Row>
                  {userData.id &&
                    authentiCateUserPermission(
                      ["listProperty", "listAdvert"],
                      userPermission,
                      user?.role,
                    ) && (
                      <>
                        <Row>
                          <Col lg="12">
                            <Card>
                              <CardBody>
                                <ul
                                  className="nav nav-tabs nav-tabs-custom"
                                  role="tablist"
                                >
                                  {authentiCateUserPermission(
                                    ["listProperty"],
                                    userPermission,
                                    user?.role,
                                  ) && (
                                    <NavItem>
                                      <NavLink
                                        className={
                                          activeTab == "Properties"
                                            ? "active"
                                            : ""
                                        }
                                        onClick={() => {
                                          toggleTab("Properties")
                                        }}
                                      >
                                        {" "}
                                        Properties
                                      </NavLink>
                                    </NavItem>
                                  )}
                                  {authentiCateUserPermission(
                                    ["listAdvert"],
                                    userPermission,
                                    user?.role,
                                  ) && (
                                    <NavItem>
                                      <NavLink
                                        className={
                                          activeTab == "businesses&services"
                                            ? "active"
                                            : ""
                                        }
                                        onClick={() => {
                                          toggleTab("businesses&services")
                                        }}
                                      >
                                        {" "}
                                        Businesses & Services
                                      </NavLink>
                                    </NavItem>
                                  )}
                                  {authentiCateUserPermission(
                                    ["listBanner"],
                                    userPermission,
                                    user?.role,
                                  ) && (
                                    <NavItem>
                                      <NavLink
                                        className={
                                          activeTab == "adverts" ? "active" : ""
                                        }
                                        onClick={() => {
                                          toggleTab("adverts")
                                        }}
                                      >
                                        {" "}
                                        Adverts
                                      </NavLink>
                                    </NavItem>
                                  )}
                                  {authentiCateUserPermission(
                                    ["CRMList"],
                                    userPermission,
                                    user?.role,
                                  ) && (
                                    <NavItem>
                                      <NavLink
                                        className={
                                          activeTab == "crm" ? "active" : ""
                                        }
                                        onClick={() => {
                                          toggleTab("crm")
                                        }}
                                      >
                                        {" "}
                                        CRM
                                      </NavLink>
                                    </NavItem>
                                  )}
                                </ul>
                                <TabContent
                                  activeTab={activeTab}
                                  className="p-3"
                                >
                                  {authentiCateUserPermission(
                                    ["listProperty"],
                                    userPermission,
                                    user?.role,
                                  ) && (
                                    <TabPane
                                      tabId={"Properties"}
                                      id="all-order1"
                                    >
                                      <CardTitle className="mb-4">
                                        Properties
                                      </CardTitle>
                                      {<PropertyList userId={userData.id} />}
                                    </TabPane>
                                  )}
                                  {authentiCateUserPermission(
                                    ["listAdvert"],
                                    userPermission,
                                    user?.role,
                                  ) && (
                                    <TabPane
                                      tabId={"businesses&services"}
                                      id="all-order2"
                                    >
                                      <CardTitle className="mb-4">
                                        Businesses & Services
                                      </CardTitle>
                                      {
                                        <BusinessesServiceList
                                          userId={userData.id}
                                        />
                                      }
                                    </TabPane>
                                  )}
                                  {authentiCateUserPermission(
                                    ["listBanner"],
                                    userPermission,
                                    user?.role,
                                  ) && (
                                    <TabPane tabId={"adverts"} id="all-order1">
                                      <CardTitle className="mb-4">
                                        Adverts
                                      </CardTitle>
                                      {<AdvertsList userId={userData.id} />}
                                    </TabPane>
                                  )}
                                  {authentiCateUserPermission(
                                    ["CRMList"],
                                    userPermission,
                                    user?.role,
                                  ) && (
                                    <TabPane tabId={"crm"} id="crm">
                                      <CardTitle className="mb-4">
                                        CRM
                                      </CardTitle>
                                      {<CrmList userId={userData.id} />}
                                    </TabPane>
                                  )}
                                </TabContent>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </>
                    )}
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ViewUser
