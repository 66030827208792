import CustomTable2 from "components/Common/Table/CustomeTable2"
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { TitleText } from "./ColumnFormatters"
import { useDeletePrefixMutation, usePrefixListQuery } from "redux/prefix/api"
import {
  selectPrefixList,
  selectPrefixPageInfo,
  setPrefixList,
} from "redux/prefix/slice"
import { Link } from "react-router-dom"
import { UncontrolledTooltip } from "reactstrap"
import {
  SweetAlert,
  authentiCateUserPermission,
  confirmAlert,
  showError,
  showToaster,
} from "helpers"
import {
  selectCurrentUser,
  selectPermissions,
} from "redux/authentication/slice"

function List({ load, setLoad, onAddRole }) {
  const data = useSelector(selectPrefixList)
  const pageInfo = useSelector(selectPrefixPageInfo)
  const [state, setState] = useState({
    search: undefined,
    type: null,
    limit: 10,
    page: 1,
    status: null,
  })
  const dispatch = useDispatch()
  const [deletePrefix, deletePrefixAPI] = useDeletePrefixMutation()
  const user = useSelector(selectCurrentUser)
  const userPermissions = useSelector(selectPermissions)
  const onDelete = async id => {
    try {
      const confirmationMessage = `You want to delete this Prefix?`
      const confirmation = await confirmAlert(
        confirmationMessage,
        "warning",
        "Yes, delete it!",
      )
      if (confirmation.isConfirmed) {
        const result = await deletePrefix(id).unwrap()
        setLoad(load => !load)
        SweetAlert(`${result.message}`, "success")
      }
    } catch (error) {
      showError(error)
    }
  }

  const columns = useMemo(() => [
    {
      Header: "Id",
      accessor: "id",
      // filter: false,
      Cell: cellProps => {
        return <div> {cellProps.row.original.id} </div>
      },
    },
    {
      Header: "Name",
      accessor: "name",
      filter: false,
      filterable: false,
      disableFilters: true,
      disableSortBy: true,
      Cell: cellProps => {
        return <TitleText {...cellProps} />
      },
    },
    {
      Header: "Action",
      accessor: "action",
      disableFilters: true,
      disableSortBy: true,
      Cell: cellProps => {
        return (
          <>
            <div className="d-flex gap-2">
              {authentiCateUserPermission(
                ["updatePrefix"],
                userPermissions,
                user?.role,
              ) && (
                <Link
                  to={"#"}
                  onClick={() => onAddRole(cellProps.row.original.id)}
                  className="text-success"
                >
                  <i
                    className="mdi mdi-lead-pencil font-size-18"
                    id={`edit${cellProps.row.original.id}`}
                  />
                  <UncontrolledTooltip
                    placement="top"
                    target={`edit${cellProps.row.original.id}`}
                  >
                    Edit
                  </UncontrolledTooltip>
                </Link>
              )}
              {authentiCateUserPermission(
                ["deletePrefix"],
                userPermissions,
                user?.role,
              ) && (
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    const user = cellProps.row.original
                    onDelete(user.id)
                  }}
                >
                  {" "}
                  <i
                    className="mdi mdi-delete font-size-18"
                    id={`delete${cellProps.row.original.id}`}
                  />
                  <UncontrolledTooltip
                    placement="top"
                    target={`delete${cellProps.row.original.id}`}
                  >
                    Delete
                  </UncontrolledTooltip>
                </Link>
              )}
            </div>
          </>
        )
      },
    },
  ])
  const list = usePrefixListQuery(state)
  useEffect(() => {
    if (list.isSuccess) {
      dispatch(setPrefixList(list.data.data))
    }

    if (list.isError) {
      showError(list.error)
    }
  }, [list])
  useEffect(() => {
    list.refetch()

    return () => {
      dispatch(
        setPrefixList({
          list: [],
          pageInfo: { limit: 10, page: 1, totalPages: 1, totalResults: 0 },
        }),
      )
    }
  }, [state, load])
  var Table2Prop = authentiCateUserPermission(
    ["createPrefix"],
    userPermissions,
    user?.role,
  )
    ? {
        data: data,
        list: list,
        totalRecords: pageInfo?.totalResults,
        stateAPI: state,
        setStateAPI: setState,
        columns: columns,
        createButton: {
          visible: true,
          lable: "Create Prefix",
          onClick: onAddRole,
        },
      }
    : {
        data: data,
        list: list,
        totalRecords: pageInfo?.totalResults,
        stateAPI: state,
        setStateAPI: setState,
        columns: columns,
      }
  return (
    <React.Fragment>
      <CustomTable2
        {...Table2Prop}
        // data={data}
        // list={list}
        // totalRecords={pageInfo?.totalResults}
        // stateAPI={pageInfo}
        // setStateAPI={setState}
        // columns={columns}
        // createButton={{
        //   visible: true,
        //   lable: "Create Prefix",
        //   onClick: onAddRole,
        // }}
      />
    </React.Fragment>
  )
}

export default List
