import React, { useRef, useState } from "react"
import {
  Autocomplete,
  StandaloneSearchBox,
  useJsApiLoader,
  useLoadScript,
} from "@react-google-maps/api"
import { Button, FormFeedback, Input } from "reactstrap"
import config from "../../../config"

const GoogleAddressField = ({
  isPropertyLocation = false,
  formik,
  ...props
}) => {
  const [searchResult, setSearchResult] = useState("Result: none")

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    libraries: config.MAP_LIBRARIES,
    googleMapsApiKey: config.GOOGLE_MAP_KEY,
  })

  function onLoad(autocomplete) {
    setSearchResult(autocomplete)
  }

  function onPlaceChanged() {
    if (searchResult?.getPlace) {
      const place = searchResult?.getPlace()
      if (!place?.geometry) {
        formik.setFieldValue("address", "")
        formik.setFieldValue("lat", "")
        formik.setFieldValue("long", "")
      } else {
        formik.touched.address = false
        formik.setFieldValue("address", place?.formatted_address)
        formik.setFieldValue("lat", place.geometry.location.lat())
        formik.setFieldValue("long", place.geometry.location.lng())
        if (isPropertyLocation) {
          // Find administrative_area_level_2
          const administrativeAreaLevel2 = place?.address_components.find(
            component =>
              component.types.includes("administrative_area_level_2"),
          )
          formik.setFieldValue("county", administrativeAreaLevel2?.long_name)
          // Find postal_town
          const postalTown = place?.address_components.find(component =>
            component.types.includes("postal_town"),
          )
          formik.setFieldValue("town", postalTown?.long_name)
        }
      }
    }
  }
  return (
    <>
      {isLoaded ? (
        // Render the Google Maps StandaloneSearchBox
        <>
          <Autocomplete
            onPlaceChanged={onPlaceChanged}
            onLoad={onLoad}
            restrictions={{ country: "uk" }}
            value={formik.values.address}
            options={{
              strictBounds: true,
              fields: [
                "address_components",
                "formatted_address",
                "geometry",
                "icon",
                "name",
              ],
            }}
          >
            <Input
              type="text"
              onBlur={formik.handleBlur}
              value={formik.values.address}
              onChange={e => {
                const sanitizedValue = e.target.value.replace(/\s+/g, " ")
                formik.setFieldValue("lat", null)
                formik.handleChange(e)
                formik.setFieldValue("address", sanitizedValue)
              }}
              {...props}
            />
          </Autocomplete>
          {formik.values.address && !props?.disabled && (
            <Button
              style={{
                position: "absolute",
                top: "50%",
                right: "10px",
                transform: "translateY(-50%)",
                background: "transparent",
                border: "none",
              }}
              className="text-black"
              onClick={() => {
                formik.setFieldValue("address", "")
                formik.setFieldValue("lat", "")
                formik.setFieldValue("long", "")
              }}
            >
              {"X"}
            </Button>
          )}
        </>
      ) : (
        <Input type="text" {...props} />
      )}
      {formik.errors.address && formik.touched.address && (
        <div className="text-danger fs-6 mt-0">{formik.errors.address}</div>
      )}
    </>
  )
}

export default GoogleAddressField
