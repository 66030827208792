module.exports = {
  API_URL: process.env.REACT_APP_API_URL,
  GOOGLE_MAP_KEY: process.env.REACT_APP_GOOGLE_MAP_KEY,
  TOASTR_DEFAULT_OPTIONS: {
    closeButton: true,
    closeMethod: "fadeOut",
    closeEasing: "swing",
    showMethod: "fadeIn",
    showEasing: "swing",
    closeDuration: 300,
    hideDuration: 300,
    newestOnTop: true,
    progressBar: false,
    rtl: false,
    timeOut: 2000,
    positionClass: "toast-top-right mt-4",
    tapToDismiss: true,
    preventDuplicates: true,
    toastClass: "opacity-100 mt-4",
  },
  userRoles: {
    superadmin: {
      key: "superadmin",
      level: 0,
    },
    admin: {
      key: "admin",
      level: 1,
    },
  },
  // Image render ratios
  IMAGE_RATIO: {
    banner: 108 / 25,
    home: {
      section1: 16 / 9,
      section2: 16 / 9,
      section3: 16 / 9,
      section4: 16 / 9,
      section5: 16 / 9,
    },
    "about-us": {
      section1: 1 / 1,
      section2: 1 / 1,
      section3: {
        image: 1 / 1,
        image1: 1 / 1,
        image2: 648 / 335,
        image3: 648 / 335,
      },
    },
    riding: {
      section1: 629 / 364,
      section2: 629 / 364,
      section3: 162 / 55,
    },
  },
  DESCRIPTION_LENGTH: {
    property: 2000,
    advert: 1000,
  },
  MAP_LIBRARIES: ["places", "maps", "visualization"],
  IMAGE_UPLOAD_TYPE: ["image/jpeg", "image/jpg", "image/png"],
  IMAGE_MAXSIZE: 5 * 1024 * 1024,
  PROPERTY_IMAGE_MAXSIZE: 10 * 1024 * 1024,
  REVIEW_PROPERTY_TYPE: {
    underReview: { color: "info", name: "Under Review" },
    approved: { color: "success", name: "Approved" },
    rejected: { color: "danger", name: "Rejected" },
  },
  CHARGES_LABEL: {
    advert: { color: "info", name: "Business & service" },
    property: { color: "info", name: "Property" },
    banner: { color: "info", name: "Advert" },
  },
  REPORT_TYPE: {
    accident: { color: "danger", name: "Accident" },
    blockage: { color: "draft", name: "Blockage" },
    "near-miss": { color: "warning", name: "Near Miss" },
  },
  DASHBOARD_CARD: {
    banusers: {
      title: `Ban Users`,
      iconClass: "fas fa-user-times text-danger",
    },
    totalusers: { title: `Total Users`, iconClass: "fas fa-users" },
    activeusers: {
      title: `Active Users`,
      iconClass: "fas fa-user-check text-success",
    },
    preApprovedusers: {
      title: `Pre-Approved Users`,
      iconClass: "fas fa-user-shield",
    },
    draftproperties: {
      title: `Draft Properties`,
      iconClass: "bx bx-building-house text-dark",
    },
    archiveproperties: {
      title: `Archive Properties`,
      iconClass: "bx bx-building-house text-info",
    },
    totalproperties: {
      title: `Total Properties`,
      iconClass: "bx bx-building-house",
    },
    liveproperties: {
      title: `Live Properties`,
      iconClass: "bx bx-building-house text-success",
    },
    draftadverts: {
      title: ` Draft Businesses & Services      `,
      iconClass: "bx bx-briefcase-alt  text-dark",
    },
    totalTransaction: {
      title: `Total Transaction`,
      iconClass: "bx bx-pound text-success",
    },
    totaladverts: {
      title: `Total Businesses & Services`,
      iconClass: "bx bx-briefcase-alt",
    },
    liveadverts: {
      title: `Live Businesses & Services`,
      iconClass: "bx bx-briefcase-alt text-success",
    },
    totalbanners: {
      title: `Total Adverts`,
      iconClass: "mdi mdi-google-ads",
    },
    livebanners: {
      title: `Live Adverts`,
      iconClass: "mdi mdi-google-ads text-success",
    },
    draftbanners: {
      title: `Draft Adverts`,
      iconClass: "mdi mdi-google-ads text-dark",
    },
  },
}
