import { createSlice } from "@reduxjs/toolkit"

const crmSlice = createSlice({
  name: "crm",
  initialState: {
    list: [],
    pageInfo: { limit: 10, page: 1, totalPages: 1, totalResults: 0 },
  },
  reducers: {
    setCrmList: (state, action) => {
      const { crm, pageInfo } = action.payload
      state.list = crm
      state.pageInfo = pageInfo
      return
    },
  },
})

export const { setCrmList } = crmSlice.actions

export default crmSlice.reducer

export const selectCrmList = state => state.crm?.list
export const selectCrmPageInfo = state => state.crm?.pageInfo
