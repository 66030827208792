import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Routes, Route, useLocation, useNavigate } from "react-router-dom"
import { layoutTypes } from "./constants/layout"
// Import Routes all
import { authProtectedRoutes, globalRoutes, publicRoutes } from "./routes"

// Import all middleware
import Authmiddleware from "./routes/RequireAuth"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
// import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"
import RedirectToDashboard from "routes/RedirectToDashboard"
import RequireAuth from "./routes/RequireAuth"
import FullPageLoader from "components/Common/FullPageLoader"
import {
  logOut,
  selectCurrentToken,
  selectLogout,
  selectServerError,
  serverError,
  setCredentials,
  setUserProfile,
} from "redux/authentication/slice"
// import { useLogoutMutation, useRefreshMutation } from "redux/authentication/api"
import { getAuthToken, removeAuthToken, setAuthToken } from "helpers/util"
import {
  useLogoutMutation,
  useRefreshMutation,
  useUserProfileMutation,
} from "redux/authentication/api"

const getLayout = layoutType => {
  return VerticalLayout
}

const App = () => {
  const [loading, setLoading] = useState(true)
  const { layoutType } = useSelector(state => state.Layout)

  const locationState = useLocation()
  const isLogout = useSelector(selectLogout)
  const isServerError = useSelector(selectServerError)
  const [refresh, refreshAPI] = useRefreshMutation()
  const [logoutMutation, logoutAPI] = useLogoutMutation()
  const [userProfile, userProfileAPI] = useUserProfileMutation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const refreshToken = {
    refreshToken: `${getAuthToken()}` ?? "",
  }
  useEffect(() => {
    ;(async () => {
      if (!!getAuthToken()) {
        try {
          const result = await refresh(refreshToken).unwrap()
          if (result.type == "success") {
            setAuthToken(result?.data?.refresh?.token)
            dispatch(setCredentials(result?.data?.access))
            const userResult = await userProfile().unwrap()
            if (userResult.type == "success") {
              dispatch(setUserProfile(userResult.data))
            }
          }
          setLoading(false)
        } catch (error) {
          setLoading(false)
          if (error.status == "FETCH_ERROR") {
            dispatch(serverError(true))
            return
          }
          dispatch(logOut())
        }
      } else {
        setLoading(false)
      }
    })()
  }, [])
  useEffect(() => {
    if (isServerError) {
      navigate("/server-error")
    }
  }, [isServerError])
  useEffect(() => {
    if (
      isLogout &&
      locationState.pathname != "/login" &&
      !locationState.pathname.startsWith("/reset-password") &&
      locationState.pathname != "/forgot-password"
    ) {
      ;(async () => {
        try {
          setLoading(true)
          const result = await logoutMutation(refreshToken).unwrap()
          removeAuthToken()
          setLoading(false)
        } catch (error) {
          setLoading(false)
        }
      })()
    }
  }, [isLogout, locationState])

  const Layout = getLayout(layoutType)

  return (
    <React.Fragment>
      {!loading ? (
        <Routes>
          <Route element={<RedirectToDashboard />}>
            {publicRoutes.map((route, idx) => (
              <Route
                path={route.path}
                element={<NonAuthLayout>{route.component}</NonAuthLayout>}
                key={idx}
                exact={true}
              />
            ))}
          </Route>

          {authProtectedRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <RequireAuth userRole={route.userRole}>
                  <Layout>{route.component}</Layout>
                </RequireAuth>
              }
              key={idx}
              exact={true}
            />
          ))}

          {globalRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={<NonAuthLayout>{route.component}</NonAuthLayout>}
              key={idx}
              exact={true}
            />
          ))}
        </Routes>
      ) : (
        <FullPageLoader />
      )}
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

export default App
