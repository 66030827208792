import React, { useEffect, useMemo, useState } from "react"

//Import Breadcrumb
import CustomTable from "components/Common/Table/CustomeTable"
import { useDispatch, useSelector } from "react-redux"
import { showError } from "helpers"
import { Card, CardBody, UncontrolledTooltip } from "reactstrap"
import { Adderss, ContactsDetails, Date, TitleText } from "./ColumnFormatters"
import {
  selectRfaList,
  selectRfaTotalRecord,
  setRfaList,
} from "redux/rfa/slice"
import { useRfaListQuery } from "redux/rfa/api"
import { Link } from "react-router-dom"
import ViewModel from "./ViewModel"
function List() {
  const data = useSelector(selectRfaList)
  const pageInfo = useSelector(selectRfaTotalRecord)
  const [state, setState] = useState({
    search: undefined,
    type: null,
    limit: 10,
    page: 1,
    status: null,
  })
  const dispatch = useDispatch()
  const [modelData, setModelDate] = useState({})
  const [openModal, setOpenModal] = useState(false)
  const onOpenModel = data => {
    setModelDate(data)
    setOpenModal(true)
  }
  const toggle = () => {
    if (openModal) {
      setOpenModal(openModal => !openModal)
      setModelDate([])
    }
  }
  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        // filter: false,
        Cell: cellProps => {
          return <div> {cellProps.row.original.id} </div>
        },
      },
      {
        Header: "User",
        accessor: "user.name",
        // filter: false,
        filterable: false,
        disableFilters: true,
        disableSortBy: true,
        maxSize: 300,
        Cell: cellProps => {
          return <TitleText {...cellProps} />
        },
      },
      {
        Header: "Date",
        accessor: "createdAt",
        filterable: false,
        disableFilters: true,
        disableSortBy: true,
        maxSize: 200,
        Cell: cellProps => {
          return <Date {...cellProps} />
        },
      },
      {
        Header: "Location",
        accessor: "location",
        // filter: false,
        filterable: false,
        disableFilters: true,
        disableSortBy: true,
        // maxSize: 500,
        Cell: cellProps => {
          return <Adderss {...cellProps} />
        },
      },
      {
        Header: "Location",
        accessor: "location.name",
        // filter: false,
        filterable: false,
        disableFilters: true,
        disableSortBy: true,
        // maxSize: 500,
        Cell: cellProps => {
          return <div> {cellProps.value} </div>
        },
      },
      {
        Header: "Contact Details",
        accessor: "contactsDetails",
        // filter: false,
        filterable: false,
        disableFilters: true,
        disableSortBy: true,
        maxSize: 300,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-2">
              <Link
                // to={`/#`}
                className="text-dark"
                onClick={() => {
                  onOpenModel(cellProps.row.original.contactsDetails)
                }}
              >
                <i
                  className="mdi mdi-eye text-info font-size-18"
                  id={`View${cellProps.row.original._id}`}
                />
                <UncontrolledTooltip
                  placement="top"
                  target={`View${cellProps.row.original._id}`}
                >
                  Contact Details
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    [data],
  )

  const list = useRfaListQuery({ state: state })
  if (list.isSuccess) {
    dispatch(setRfaList(list.data.data))
  }

  if (list.isError) {
    showError(list.error)
  }

  useEffect(() => {
    list.refetch()

    return () => {
      dispatch(
        setRfaList({
          list: [],
          pageInfo: { limit: 10, page: 1, totalPages: 1, totalResults: 0 },
        }),
      )
    }
  }, [state])
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CustomTable
            data={data}
            list={list}
            totalRecords={pageInfo?.totalResults}
            stateAPI={state}
            setStateAPI={setState}
            columns={columns}
            hideColumns={["location.name"]}
          />
        </CardBody>
      </Card>
      {openModal && (
        <ViewModel openModal={openModal} toggle={toggle} data={modelData} />
      )}
    </React.Fragment>
  )
}
export default List
