import React from "react"
import { Navigate } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import config from "../config"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

// Pages
import PagesMaintenance from "../pages/Utility/pages-maintenance"
import PagesComingsoon from "../pages/Utility/pages-comingsoon"
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"
import ResetPassword from "pages/Authentication/ResetPassword"
import Profile from "pages/Authentication/Profile/Index"
import PropertyList from "pages/Property/List/Index"
import AddProperty from "pages/Property/Add/Index"
import Home from "pages/CMS/Home/Index"
import AboutUs from "pages/CMS/AboutUs/Index"
import PropertySetting from "pages/CMS/Properties/Index"
import Riding from "pages/CMS/Riding/Index"
import Shopping from "pages/CMS/Shopping/Index"
import BusinessServices from "pages/CMS/BusinessServices/Index"
import Faq from "pages/CMS/FAQ/Index"
import NewsBlogs from "pages/CMS/NewsBlogs/Index"
import AdvList from "pages/Advert/List/Index"
import AdvAdd from "pages/Advert/Add/Index"
import UserList from "pages/User/List/Index"
import UserAdd from "pages/User/Add/Index"
import RoleList from "pages/Role/List/Index"
import RoleAdd from "pages/Role/Add/Index"
import Prefix from "pages/Prefix/List/Index"
import Category from "pages/Category/List/Index"
import ViewUser from "pages/User/View/Index"
import BlogsList from "pages/NewsBlogs/List/Index"
import BlogAdd from "pages/NewsBlogs/Add/Index"
import AdvertReview from "pages/Review/Advert/Index"
import PropertyReview from "pages/Review/Property/Index"
import BlogDetails from "pages/NewsBlogs/View/BlogDetails"
import WebSettings from "pages/CMS/WebSettings/Index"
import PropertyDetails from "pages/Property/View/PropertyDetails"
import Charges from "pages/Charges/Index"
import PropertyPayment from "pages/Payment/Property/Index"
import AdvertPayment from "pages/Payment/Advert/Index"
import ContactsList from "pages/Contacts/List/Index"
import RFAList from "pages/RFA/List/Index"
import ReportList from "pages/Report/List/Index"
import BannerAdsList from "pages/BannerAds/List/Index"
import BannerAdsAdd from "pages/BannerAds/Add/Index"
import BannerAdsPayment from "pages/Payment/BannerAds/Index"
import ReportingLocationList from "pages/ReportingLocation/List/Index"
// Define routes for authenticated users
const authProtectedRoutes = [
  {
    path: "/dashboard",
    component: <Dashboard />,
    userRole: [true],
  },
  // Admin Profile
  {
    path: "/profile",
    component: <Profile />,
    userRole: [true],
  },
  // properties
  {
    path: "/properties/list",
    component: <PropertyList />,
    userRole: [
      "listProperty",
      "createProperty",
      "archiveProperty",
      "updatePropertyStatus",
    ],
  },
  {
    path: "/properties/Add",
    component: <AddProperty />,
    userRole: ["createProperty"],
  },
  {
    path: "/properties/edit/:id",
    component: <AddProperty />,
    userRole: ["updateProperty"],
  },
  {
    path: "/user/property/edit/:id",
    component: <AddProperty />,
    userRole: ["updateProperty"],
  },
  {
    path: "/properties/:id",
    component: <PropertyDetails />,
    userRole: ["listProperty"],
  },
  {
    path: "/user/property/:id",
    component: <PropertyDetails />,
    userRole: ["listProperty"],
  },

  // CMS Settings
  {
    path: "/pages/settings",
    component: <WebSettings />,
    userRole: ["updateCMS"],
  },
  {
    path: "/pages/home",
    component: <Home />,
    userRole: ["updateCMS"],
  },
  {
    path: "/pages/about-us",
    component: <AboutUs />,
    userRole: ["updateCMS"],
  },
  {
    path: "/pages/properties",
    component: <PropertySetting />,
    userRole: ["updateCMS"],
  },
  {
    path: "/pages/riding",
    component: <Riding />,
    userRole: ["updateCMS"],
  },
  {
    path: "/pages/shopping",
    component: <Shopping />,
    userRole: ["updateCMS"],
  },
  {
    path: "/pages/business-service",
    component: <BusinessServices />,
    userRole: ["updateCMS"],
  },
  {
    path: "/pages/faq",
    component: <Faq />,
    userRole: ["updateCMS"],
  },
  {
    path: "/pages/news-blogs",
    component: <NewsBlogs />,
    userRole: ["updateCMS"],
  },
  //users
  {
    path: "/user/list",
    component: <UserList />,
    userRole: ["updateUser", "deleteUser", "banUser", "listUser"],
  },
  {
    path: "/user/:id",
    component: <ViewUser />,
    userRole: ["getUser"],
  },
  {
    path: "/user/Add",
    component: <UserAdd />,
    userRole: ["createUser"],
  },
  {
    path: "/user/edit/:id",
    component: <UserAdd />,
    userRole: ["updateUser"],
  },
  //Settige
  {
    path: "/role/list",
    component: <RoleList />,
    userRole: ["admin"],
  },
  {
    path: "/prefix/list",
    component: <Prefix />,
    userRole: ["listPrefix"],
  },
  {
    path: "/categories/list",
    component: <Category />,
    userRole: ["listCategory"],
  },
  {
    path: "/setting/charges",
    component: <Charges />,
    userRole: ["manageSettings"],
  },
  // {
  //   path: "/role/Add",
  //   component: <RoleAdd />,
  //   userRole:[],
  // },
  // {
  //   path: "/role/edit/:id",
  //   component: <RoleAdd />,
  //   userRole:[],
  // },

  // businesses & services
  {
    path: "/businesses&services/list",
    component: <AdvList />,
    userRole: ["deleteAdvert", "listAdvert"],
  },
  {
    path: "/businesses&services/add",
    component: <AdvAdd />,
    userRole: ["createAdvert"],
  },
  {
    path: "/user/businesses&services/edit/:id",
    component: <AdvAdd />,
    userRole: ["updateAdvert"],
  },
  {
    path: "/businesses&services/edit/:id",
    component: <AdvAdd />,
    userRole: ["updateAdvert"],
  },

  // Adverts
  {
    path: "/adverts/list",
    component: <BannerAdsList />,
    userRole: ["createBanner", "deleteBanner", "listBanner", "updateBanner"],
  },
  {
    path: "/adverts/add",
    component: <BannerAdsAdd />,
    userRole: ["createBanner"],
  },
  {
    path: "/user/adverts/edit/:id",
    component: <BannerAdsAdd />,
    userRole: ["updateBanner"],
  },
  {
    path: "/adverts/edit/:id",
    component: <BannerAdsAdd />,
    userRole: ["updateBanner"],
  },

  // News & Blogs
  {
    path: "/news&blogs/list",
    component: <BlogsList />,
    userRole: ["listBlog"],
  },
  {
    path: "/news&blogs/Add",
    component: <BlogAdd />,
    userRole: ["createBlog"],
  },
  {
    path: "/news&blogs/edit/:id",
    component: <BlogAdd />,
    userRole: ["updateBlog"],
  },
  {
    path: "/news&blogs/:id",
    component: <BlogDetails />,
    userRole: ["listBlog"],
  },

  // Review
  {
    path: "/review/adverts/list",
    component: <AdvertReview />,
    userRole: ["review"],
  },
  {
    path: "/review-properties/list",
    component: <PropertyReview />,
    userRole: ["review"],
  },
  {
    path: "/review-properties/:id",
    component: <PropertyDetails />,
    userRole: ["review"],
  },
  // payment
  {
    path: "/payment/properties/list",
    component: <PropertyPayment />,
    userRole: ["paymentList"],
  },
  {
    path: "/payment/businesses&services/list",
    component: <AdvertPayment />,
    userRole: ["paymentList"],
  },
  {
    path: "/payment/adverts/list",
    component: <BannerAdsPayment />,
    userRole: ["paymentList"],
  },
  // conatct us
  {
    path: "/contacts/list",
    component: <ContactsList />,
    userRole: ["contacts"],
  },
  {
    path: "/rfa/list",
    component: <RFAList />,
    userRole: ["RFA"],
  },
  {
    path: "/report/list",
    component: <ReportList />,
    userRole: ["report"],
  },
  {
    path: "/reporting-location/list",
    component: <ReportingLocationList />,
    userRole: ["reportingLocation"],
  },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
]

// Define public routes
const publicRoutes = [
  { path: "/reset-password", component: <ResetPassword /> },
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/pages-maintenance", component: <PagesMaintenance /> },
  { path: "/pages-comingsoon", component: <PagesComingsoon /> },
  { path: "*", component: <Pages404 /> }, // Handle all other routes as 404
  { path: "/server-error", component: <Pages500 /> },
]

// Define Global routes
const globalRoutes = [
  {
    path: "/health-check",
    component: <p>{JSON.stringify({ isHealthy: true })}</p>,
  },
]

export { authProtectedRoutes, publicRoutes, globalRoutes }
