import { createSlice } from "@reduxjs/toolkit"
const bannerAdsSlice = createSlice({
  name: "bannerAds",
  initialState: {
    list: [],
    pageInfo: { limit: 10, page: 1, totalPages: 1, totalResults: 0 },
  },
  reducers: {
    setBannerAdsList: (state, action) => {
      const { banners, pageInfo } = action.payload
      state.list = banners
      state.pageInfo = pageInfo
      return
    },
  },
})

export const { setBannerAdsList } = bannerAdsSlice.actions

export default bannerAdsSlice.reducer

export const selectBannerAdsList = state => state.bannerAds?.list
export const selectBannerAdsTotalRecord = state => state.bannerAds?.pageInfo
