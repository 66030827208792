import { apiSlice } from "../api"

export const cmsApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getPagesData: builder.query({
      query: payload => {
        return {
          url: `/pages/${payload}`,
          method: "Get",
        }
      },
    }),
    updatePage: builder.mutation({
      query: ({ pageName, data, section, fileField }) => {
        var formData = new FormData()
        // dynamic function for fromData
        if (data) {
          // const formData = new FormData();
          Object.keys(data).forEach(key => {
            if (key === "images") {
              if (data?.images?.length > 0) {
                for (const file of data?.images) {
                  if (file) {
                    formData.append("images", file?.file ? file?.file : file)
                  }
                }
              }
            } else if (key === "deleteImages") {
              if (data?.deleteImages?.length == 1) {
                formData.append("deleteImages[0]", data?.deleteImages[0])
              } else {
                for (const file of data?.deleteImages) {
                  if (file) {
                    formData.append("deleteImages", file)
                  }
                }
              }
            } else if (key === "meta") {
              Object.keys(data[key]).forEach((metaKey, index) => {
                if (!Array.isArray(data[key][metaKey])) {
                  formData.append(`${[key]}[${metaKey}]`, data[key][metaKey])
                } else if (Array.isArray(data[key][metaKey])) {
                  data[key][metaKey].forEach((valueArray, index) => {
                    formData.append(
                      `${[key]}[${metaKey}][${index}]`,
                      valueArray,
                    )
                  })
                }
              })
            } else {
              formData.append(key, data[key])
            }
          })
        }
        return {
          url: fileField
            ? `/pages/${pageName}/${section}?fileFieldName=${fileField}`
            : `/pages/${pageName}${section && "/" + section}`,
          method: "PUT",
          body: formData,
        }
      },
    }),
    deleteFaq: builder.mutation({
      query: ({ pageName, id }) => {
        return {
          url: `/pages/${pageName}/${id}`,
          method: "DELETE",
        }
      },
    }),
    reorderFaq: builder.mutation({
      query: ({ pathname, changeRow }) => {
        return {
          url: `${pathname}/reorder`,
          method: "PUT",
          body: { order: { ...changeRow } },
        }
      },
    }),
  }),
})
export const {
  useGetPagesDataQuery,
  useUpdatePageMutation,
  useDeleteFaqMutation,
  useReorderFaqMutation,
} = cmsApiSlice
