import { apiSlice } from "../api"

export const reportingListApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    reportingList: builder.query({
      query: props => {
        let url = `/reports`
        if (props) {
          const {
            page = 1,
            limit = 10,
            status = "",
            type = "",
            search = "",
          } = props?.state
          url = `/reporting-locations?${type ? "type=" + type + "&" : ""}${
            search ? "search=" + search + "&" : ""
          }page=${page}&limit=${limit}`
        }
        return {
          url: `${url} `,
          method: "GET",
        }
      },
    }),
    getReportingLocation: builder.mutation({
      query: props => {
        return {
          url: `/reporting-locations/${props}`,
          method: "GET",
        }
      },
    }),
    addReportingLocation: builder.mutation({
      query: payload => {
        return {
          url: "/reporting-locations",
          method: "POST",
          body: { ...payload },
        }
      },
    }),
    updateReportingLocation: builder.mutation({
      query: payload => {
        return {
          url: `/reporting-locations/${payload?.id}`,
          method: "PATCH",
          body: { ...payload?.value },
        }
      },
    }),
    deleteReportingLocation: builder.mutation({
      query: props => {
        return {
          url: `/reporting-locations/${props}`,
          method: "DELETE",
        }
      },
    }),
  }),
})
export const {
  useReportingListQuery,
  useUpdateReportingLocationMutation,
  useGetReportingLocationMutation,
  useAddReportingLocationMutation,
  useDeleteReportingLocationMutation,
} = reportingListApiSlice
