import { useFormik } from "formik"
import React, { useState } from "react"
import Dropzone from "react-dropzone"
import * as Yup from "yup"
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap"
import { useGetPagesDataQuery, useUpdatePageMutation } from "redux/cms/api"
import { showError, showToaster } from "helpers"
export const AppSetting = ({ activeTab }) => {
  const { data, isLoading, isSuccess, refetch } = useGetPagesDataQuery("app")
  const [updatePage] = useUpdatePageMutation()
  const [btnDisable, setBtnDisable] = useState(false)
  const validation = useFormik({
    // enableReinitialize: use this flag when initial values need to be changed
    enableReinitialize: true,
    initialValues: {
      ios: data?.data?.ios ? data?.data?.ios : "",
      android: data?.data?.android ? data?.data?.android : "",
    },
    validationSchema: Yup.object({
      ios: Yup.string()
        .matches(
          /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
          "Enter a valid link",
        )
        .required("IOS App Link is required"),
      android: Yup.string()
        .matches(
          /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
          "Enter a valid link",
        )
        .required("Android App Link is required"),
    }),
    onSubmit: async values => {
      try {
        let apiObj = {
          pageName: "app",
          data: values,
          section: activeTab,
        }
        setBtnDisable(true)
        const result = await updatePage(apiObj).unwrap()
        refetch()
        showToaster("success", `${result.message}`, "Success")
        setBtnDisable(false)
      } catch (error) {
        showError(error)
      }
    },
  })
  {
    /* CMS App Form */
  }
  return (
    isSuccess && (
      <Form
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
        }}
      >
        <FormGroup className="mb-4" row>
          <Label htmlFor="link" md="2" className="col-form-label">
            IOS App Link
          </Label>
          <Col md="10">
            <Input
              type="text"
              className="form-control"
              id="ios"
              placeholder="Enter IOS Link"
              name="ios"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values?.ios || ""}
              invalid={!!(validation.touched.ios && validation.errors.ios)}
            />
            {validation.touched.ios && validation.errors.ios && (
              <FormFeedback className="fs-6 mt-0" type="invalid">
                {validation.errors.ios}
              </FormFeedback>
            )}
          </Col>
        </FormGroup>
        <FormGroup className="mb-4" row>
          <Label htmlFor="link" md="2" className="col-form-label">
            Android App Link
          </Label>
          <Col md="10">
            <Input
              type="text"
              className="form-control"
              id="android"
              placeholder="Enter Android Link"
              name="android"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values?.android || ""}
              invalid={
                !!(validation.touched.android && validation.errors.android)
              }
            />
            {validation.touched.android && validation.errors.android && (
              <FormFeedback className="fs-6 mt-0" type="invalid">
                {validation.errors.android}
              </FormFeedback>
            )}
          </Col>
        </FormGroup>
        <Row className="mt-4">
          <Col sm="12">
            <div className="text-sm-end">
              <button
                type="submit"
                className="btn btn-success me-2"
                disabled={btnDisable}
              >
                {btnDisable ? (
                  <i className="bx bx-loader font-size-16 label-icon align-middle me-2">
                    {" "}
                    Loading
                  </i>
                ) : (
                  <i className="bx bx-check-double font-size-16 label-icon me-2 align-middle">
                    {" "}
                    Submit
                  </i>
                )}
              </button>
              <button
                className="btn btn-danger"
                type="button"
                onClick={() => validation.resetForm()}
              >
                <i className="bx bx-block font-size-16 label-icon me-2 align-middle" />{" "}
                Cancel{" "}
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    )
  )
}
