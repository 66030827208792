import PropTypes from "prop-types"
import React from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap"

import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// import images
import profile from "../../assets/images/profile-img.png"
import { useForgotPasswordMutation } from "redux/authentication/api"
import { EMAIL_REGEX } from "helpers/regex"
import { Helmet } from "react-helmet"
import { showError, showToaster } from "helpers"

// Define the ForgetPasswordPage component
const ForgetPasswordPage = props => {
  const navigate = useNavigate()
  const [forgetPassword, forgetPasswordAPI] = useForgotPasswordMutation()

  // Form validation using Formik and Yup
  const validation = useFormik({
    // enableReinitialize: use this flag when initial values need to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(/^\S*$/, "Whitespace is not allowed")
        .required("Please enter your email")
        .email("Please enter a valid email")
        .matches(EMAIL_REGEX, "Please enter a valid email"),
    }),
    onSubmit: async values => {
      try {
        // Call the forgetPassword function to initiate password reset
        const result = await forgetPassword(values).unwrap()
        showToaster("success", `${result.message}`, "Success")
        navigate("/login", { replace: true })
      } catch (error) {
        showError(error)
      }
    },
  })

  return (
    <React.Fragment>
      {/* Helmet component for setting page title */}
      <Helmet>
        <title>Forget Password | Discover Equestrian</title>
      </Helmet>

      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-dark">Forget Password</h5>
                        <p className="text-dark">
                          Enter your email address below to reset the password
                        </p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback className="fs-6 mt-0" type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          <button
                            type="submit"
                            disabled={validation.isSubmitting}
                            className={`btn btn-primary w-md ${
                              validation.isSubmitting
                                ? "cursor-wait"
                                : "cursor-pointer"
                            }`}
                          >
                            {validation.isSubmitting ? (
                              <div className="d-flex justify-content-center align-items-center">
                                <div
                                  className="spinner-border spinner-border-sm me-1"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                                <span>sending...</span>
                              </div>
                            ) : (
                              "Send Mail"
                            )}
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
                <p>© {new Date().getFullYear()} Discover Equestrian </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// PropTypes for the component
ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
}

// Export the component with withRouter HOC (Higher Order Component)
export default withRouter(ForgetPasswordPage)
