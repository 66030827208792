import React, { useState } from "react"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import List from "./List"
import { Helmet } from "react-helmet"
import AddModel from "../Add/AddModel"
function RoleList() {
  const BreadcrumbDetails = {
    breadcrumbItem: {
      label: "List",
      link: "/role/list",
    },
    paths: [
      {
        label: "Setting",
        link: "/role/list",
      },
      {
        label: "Roles",
        link: "/role/list",
      },
    ],
  }
  const [openAddEditModal, setOpenAppEditModal] = useState()
  const [load, setLoad] = useState(false)
  const onAddRole = ID => {
    if (ID) {
      setId(ID)
    }
    setOpenAppEditModal(true)
  }
  const [id, setId] = useState(null)
  return (
    <React.Fragment>
      <Helmet>
        <title>Role List | Discover Equestrian</title>
      </Helmet>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs breadcrumbDetails={BreadcrumbDetails} />
          <Row>
            <List setLoad={setLoad} load={load} onAddRole={onAddRole} />
          </Row>
          <AddModel
            openModal={openAddEditModal}
            setOpenModal={setOpenAppEditModal}
            setLoadList={setLoad}
            load={load}
            id={id}
            setId={setId}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default RoleList
