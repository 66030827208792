import config from "config"
import { formatDate, wordTitleCase } from "helpers/data-formatters"
import React from "react"
import { Link } from "react-router-dom"
import { Badge, UncontrolledTooltip } from "reactstrap"
const TitleText = cell => {
  return wordTitleCase(cell.value)
}
const Date = cell => {
  return formatDate(cell.value)
}
const Adderss = cell => {
  return (
    <>
      <div>
        <Link
          rel="noopener"
          id={`location${cell.row.original._id}`}
          target="_blank"
          to={`https://maps.google.com/?q=${cell?.value?.coordinates[1]},${cell?.value?.coordinates[0]}`}
        >
          <i className="bx bx-map align-middle text-dark me-1"></i>
          {cell?.value?.name}
        </Link>
      </div>
      <UncontrolledTooltip
        placement="top"
        target={`location${cell.row.original._id}`}
      >
        View Location
      </UncontrolledTooltip>
    </>
  )
}
const Type = cell => {
  return (
    <Badge
      className={`font-size-12 badge-soft-${
        config.REPORT_TYPE[cell.value]?.color
      }`}
    >
      {config.REPORT_TYPE[cell.value]?.name}
    </Badge>
  )
}
export { TitleText, Date, Adderss, Type }
