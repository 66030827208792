import { useRolesListQuery } from "redux/role/api"
import {
  useRemoveProfileImgMutation,
  useUpdateUserMutation,
  useUpdateUserProfileImgMutation,
} from "redux/user/api"
import React, { useEffect, useRef, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  FormGroup,
  Label,
  Button,
  Form,
  FormFeedback,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
  DropdownMenu,
  DropdownToggle,
  Dropdown,
} from "reactstrap"
import { useNavigate } from "react-router-dom"
import { useFormik } from "formik"
import * as Yup from "yup"

import { confirmAlert, showError, showToaster } from "helpers"
import GoogleAddressField from "components/Common/Location/GoogleLocation"
import { EMAIL_REGEX } from "helpers/regex"
import { getAvatarValue } from "helpers/util"
import moment from "moment"
import config from "config"
import LazyImageWithFallback from "components/Common/LazyImageWithFallback"
import ImageCroperModel from "components/Common/ImageCroper/ImageCroperModel"

function EditUserModel({ isOpen, onCancel, userdata, load, setLoad }) {
  const navigate = useNavigate()
  const [updateUser, UpdateUserAPI] = useUpdateUserMutation()
  const [removeProfile, removeProfileAPI] = useRemoveProfileImgMutation()
  const [menu, setMenu] = useState(false)
  const [isCropModalOpen, setIsCropModalOpen] = useState(false)
  const [selectedFiles, setselectedFiles] = useState([])
  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required("Name is required"),
    subRole: Yup.string(),
    email: Yup.string()
      .matches(/^\S*$/, "Whitespace is not allowed")
      .required("Please enter your email")
      .email("Please enter a valid email")
      .matches(EMAIL_REGEX, "Please enter a valid email"),
    dob: Yup.date()
      .max(
        new Date(new Date().setFullYear(new Date().getFullYear() - 15)),
        "Must be at least 15 years old",
      )
      .required("Date of Birth is required"),
    address: Yup.string()
      .trim()
      .required("Address is required")
      .test("not-same-as-old", "Enter valid Address", function (value) {
        return typeof this.parent.lat == "number"
      }),
    contact: Yup.string()
      .max(11, "Phone Number should be from 10 to 11 digits")
      .min(10, "Phone Number should be from 10 to 11 digits")
      .required("Phone Number is required"),
  })
  const editInitialValues = {
    name: userdata?.name,
    subRole: userdata?.subRole || "",
    email: userdata?.email,
    dob: moment(userdata?.dob, "DD/MM/YYYY").format("YYYY-MM-DD"),
    address: userdata?.address,
    long: userdata?.location?.coordinates[0],
    lat: userdata?.location?.coordinates[1],
    contact: userdata?.contact,
    isPreApproved: userdata?.isPreApproved,
    paymentExempt: userdata?.paymentExempt,
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: editInitialValues,
    validationSchema,
    onSubmit: async values => {
      let newValue = {
        name: values.name,
        subRole: values.subRole,
        email: values.email,
        dob: moment(new Date(values.dob)).format("DD/MM/YYYY"),
        address: values.address,
        location: {
          coordinates: [values?.long, values?.lat],
        },
        contact: `${values.contact}`,
        isPreApproved: values.isPreApproved,
        paymentExempt: values?.paymentExempt,
      }

      try {
        const result = await updateUser({
          value: newValue,
          id: userdata?.id,
        }).unwrap()
        onCancel()
        setLoad(load => !load)
        showToaster("success", `${result.message}`, "Success")
      } catch (error) {
        showError(error)
      }
    },
  })
  // userList Option
  const [rolesOption, setRolesOption] = useState([])
  const rolesData = useRolesListQuery()
  useEffect(() => {
    if (rolesData.isSuccess) {
      setRolesOption(rolesData?.data?.data?.roles)
    }
    if (rolesData.isError) {
      showError(rolesData.error)
    }
    return () => {
      setRolesOption([])
    }
  }, [rolesData])
  const fileInputRef = useRef(null)
  const [updateProfileImg, updateProfileImgAPI] =
    useUpdateUserProfileImgMutation()
  // Function to open the file input dialog when the "Change Avatar" button is clicked
  const handleFileInputClick = () => {
    fileInputRef.current.click()
  }
  // Function to handle file selection
  const handleFileChange = async event => {
    // Get the selected file(s) from the input element
    const files = event.target.files
    if (files.length === 0) {
      // No file selected, handle this case as needed
      return
    }
    if (!config.IMAGE_UPLOAD_TYPE.includes(files[0].type)) {
      showToaster(
        "error",
        `Select Valid Image (only jpg, jpeg or png)`,
        "Error",
      )
      return
    }
    if (files[0].size > config.IMAGE_MAXSIZE) {
      showToaster(
        "error",
        `File size should be less than or equal to 5MB`,
        "Error",
      )
      return
    }
    setselectedFiles([files[0]])
    handleOpenCropModal()
    // try {
    //   // Create a new FormData object and append the selected file(s)
    //   const result = await updateProfileImg({
    //     payload: files[0],
    //     id: userdata?.id,
    //   }).unwrap()
    //   setLoad(load => !load)
    //   showToaster("success", `${result.message}`, "Success")
    // } catch (error) {
    //   // Handle any unexpected errors
    //   showError(error)
    // }
  }
  const handelRemoveProfile = async () => {
    try {
      // Create a new FormData object and append the selected file(s)
      const result = await removeProfile({
        id: userdata?.id,
      }).unwrap()
      setLoad(load => !load)
      showToaster("success", `${result.message}`, "Success")
    } catch (error) {
      // Handle any unexpected errors
      showError(error)
    }
  }

  const avatar = userdata?.name && getAvatarValue(userdata?.name)
  const handleOpenCropModal = () => {
    setIsCropModalOpen(true)
  }
  const handleCloseCropModal = async cropedFiles => {
    setIsCropModalOpen(false)
    if (cropedFiles && cropedFiles[0]) {
      try {
        // Create a new FormData object and append the selected file(s)
        const result = await updateProfileImg({
          payload: cropedFiles[0]?.file,
          id: userdata?.id,
        }).unwrap()
        setLoad(load => !load)
        showToaster("success", `${result.message}`, "Success")
      } catch (error) {
        // Handle any unexpected errors
        showError(error)
      }
    }
  }
  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        toggle={onCancel}
        centered
        backdrop={"static"}
        size="lg"
      >
        <ModalHeader toggle={onCancel}>Update Profile</ModalHeader>

        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <Form
                  className="outer-repeater"
                  onSubmit={e => {
                    e.preventDefault()
                    formik.handleSubmit()
                    // return
                  }}
                >
                  <ModalBody style={{ width: "100%" }}>
                    <Row className="d-flex justify-content-center align-item-center pb-5 pt-3">
                      <Col sm="4">
                        <div className="mx-xxl-3 position-relative">
                          {!updateProfileImgAPI.isLoading ? (
                            <div className="d-flex align-items-center profile-imgtxt">
                              <div className="profileimage-wrapper">
                                {userdata?.profileImage ? (
                                  <LazyImageWithFallback
                                    className="w-25"
                                    src={`${userdata?.profileImage}?v=${Math.floor(
                                      Math.random() * 90 + 10,
                                    )}`}
                                    alt="profile"
                                  />
                                ) : (
                                  <div
                                    className="d-flex align-items-center justify-content-center rounded-circle  shadow-2xl position-relative avatar-lg"
                                    style={{
                                      backgroundColor: `${avatar?.backgroundColor}`,
                                      color: `${avatar?.fontColor}`,
                                    }}
                                  >
                                    <h1 className="display-6 font-weight-bold   mb-0">
                                      {avatar && avatar?.character}
                                    </h1>
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : (
                            <div className="rounded-circle bg-primary shadow-2xl position-relative avatar-xl">
                              <CardBody className="d-flex align-items-center justify-content-center">
                                <h1 className="display-4 font-weight-bold text-white">
                                  <i className="bx bxs-user font-size-48 align-middle"></i>
                                </h1>
                              </CardBody>
                            </div>
                          )}
                          <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                            accept="image/*"
                          />
                          <Dropdown
                            isOpen={menu}
                            toggle={() => {
                              if (
                                !(
                                  updateProfileImgAPI?.isLoading ||
                                  removeProfileAPI?.isLoading
                                )
                              ) {
                                setMenu(!menu)
                              }
                            }}
                            className="d-inline-block rounded-circle position-absolute bottom-0 start-0"
                          >
                            <DropdownToggle
                              className="btn header-item d-flex align-items-center"
                              id="page-header-user-dropdown"
                              tag="button"
                              type={"button"}
                            >
                              <button
                                disabled={
                                  updateProfileImgAPI?.isLoading ||
                                  removeProfileAPI?.isLoading
                                }
                                type="button"
                                className="btn btn-sm bg-white border rounded-circle position-absolute bottom-0 start-0"
                              >
                                <i className="mdi mdi-pencil font-size-16 align-middle"></i>
                              </button>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-start">
                              <button
                                type="button"
                                disabled={
                                  updateProfileImgAPI?.isLoading ||
                                  removeProfileAPI?.isLoading
                                }
                                onClick={e => {
                                  setMenu(!menu)
                                  handleFileInputClick(e)
                                }}
                                className="dropdown-item"
                              >
                                <i className="bx bx-upload font-size-18 align-middle me-1"></i>
                                <span>Upload Image</span>
                              </button>
                              {userdata?.profileImage && (
                                <>
                                  <div className="dropdown-divider" />
                                  <button
                                    onClick={() => {
                                      handelRemoveProfile()
                                      setMenu(!menu)
                                    }}
                                    type="button"
                                    className="dropdown-item"
                                  >
                                    <i className="mdi mdi-delete font-size-18 align-middle me-1 text-danger" />
                                    Remove Image
                                  </button>
                                </>
                              )}
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                      </Col>
                    </Row>
                    <div className="profile-user-wid mb-4">
                      <div data-repeater-item className="outer">
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="name"
                            className="col-form-label col-lg-2"
                          >
                            Name
                          </Label>
                          <Col lg="10">
                            <Input
                              id="name"
                              name="name"
                              type="text"
                              className="form-control"
                              placeholder="Enter User Name"
                              value={formik.values.name || ""}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              invalid={
                                !!(formik.touched.name && formik.errors.name)
                              }
                            />
                            {formik.touched.name && formik.errors.name && (
                              <FormFeedback
                                className="fs-6 mt-0"
                                type="invalid"
                              >
                                {formik.errors.name}
                              </FormFeedback>
                            )}
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="subRole"
                            className="col-form-label col-lg-2"
                          >
                            Role
                          </Label>
                          <Col lg="10">
                            <Input
                              type="select"
                              name="subRole"
                              className="form-control"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.subRole || ""}
                              invalid={
                                !!(
                                  formik.touched.subRole &&
                                  formik.errors.subRole
                                )
                              }
                            >
                              <option value="">Select a Role</option>
                              {rolesOption?.map((val, index) => {
                                return (
                                  <option key={index} value={`${val?.id}`}>
                                    {val?.name}
                                  </option>
                                )
                              })}
                            </Input>
                            {formik.touched.subRole &&
                              formik.errors.subRole && (
                                <FormFeedback
                                  className="fs-6 mt-0"
                                  type="invalid"
                                >
                                  {formik.errors.subRole}
                                </FormFeedback>
                              )}
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="email"
                            className="col-form-label col-lg-2"
                          >
                            Email
                          </Label>
                          <Col lg="10">
                            <Input
                              disabled={true}
                              id="email"
                              name="email"
                              type="text"
                              className="form-control"
                              value={formik.values.email || ""}
                            />
                            {formik.touched.email && formik.errors.email && (
                              <FormFeedback
                                className="fs-6 mt-0"
                                type="invalid"
                              >
                                {formik.errors.email}
                              </FormFeedback>
                            )}
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="dob"
                            className="col-form-label col-lg-2"
                          >
                            Date Of Birth
                          </Label>
                          <Col lg="10">
                            {/* <Flatpickr
                                                            id="dob"
                                                            name="dob"
                                                            className="form-control d-block"
                                                            placeholder="Select time"
                                                            options={{
                                                                ltInput: true,
                                                                altFormat: "F j, Y",
                                                                dateFormat: "Y-m-d"
                                                            }}
                                                            value={new Date(formik.values.dob)}
                                                            // value={selectedRange}
                                                            // onOpen={handelOpen}
                                                            // onChange={handleDateChange}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            invalid={
                                                                !!(formik.touched.dob && formik.errors.dob)
                                                            }
                                                        /> */}
                            <Input
                              id="dob"
                              name="dob"
                              type="date"
                              className="form-control"
                              placeholder="Enter Date of birth..."
                              value={formik.values.dob || ""}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              invalid={
                                !!(formik.touched.dob && formik.errors.dob)
                              }
                            />
                            {formik.touched.dob && formik.errors.dob && (
                              <FormFeedback
                                className="fs-6 mt-0"
                                type="invalid"
                              >
                                {formik.errors.dob}
                              </FormFeedback>
                            )}
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="contact"
                            className="col-form-label col-lg-2"
                          >
                            Phone Number
                          </Label>
                          <Col lg="10">
                            <Input
                              id="contact"
                              name="contact"
                              value={formik.values.contact || ""}
                              type={"number"}
                              placeholder="Enter Phone Number"
                              autoComplete="false"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              invalid={
                                !!(
                                  formik.touched.contact &&
                                  formik.errors.contact
                                )
                              }
                            />
                            {formik.touched.contact &&
                              formik.errors.contact && (
                                <FormFeedback
                                  className="fs-6 mt-0"
                                  type="invalid"
                                >
                                  {formik.errors.contact}
                                </FormFeedback>
                              )}
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="address"
                            className="col-form-label col-lg-2"
                          >
                            Address
                          </Label>
                          <Col lg="10">
                            <GoogleAddressField
                              formik={formik}
                              id="address"
                              name="address"
                              type="text"
                              className="form-control"
                              placeholder="Find Your Address"
                              invalid={
                                !!(
                                  formik.touched.address &&
                                  formik.errors.address
                                )
                              }
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4 d-flex">
                          <Label
                            htmlFor="isPreApproved"
                            className="col-form-label"
                          >
                            Pre-Approved
                          </Label>
                          <div className="form-check form-switch pt-2 ms-5">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="isPreApproved"
                              style={{ transform: "scale(1.5)" }}
                              name={`isPreApproved`}
                              checked={formik.values.isPreApproved}
                              onChange={async e => {
                                const confirmationMessage = !e.target.checked
                                  ? `You want to make ${userdata?.name} to non pre-approved user?`
                                  : `You want to make ${userdata?.name} to pre-approved user?`
                                const confirmation = await confirmAlert(
                                  confirmationMessage,
                                  "warning",
                                  "Yes, do it!",
                                )
                                if (confirmation.isConfirmed) {
                                  const result = await updateUser({
                                    value: {
                                      isPreApproved: !e.target.checked,
                                      paymentExempt:
                                        formik?.values?.paymentExempt,
                                    },
                                    id: userdata?.id,
                                  }).unwrap()
                                  showToaster(
                                    "success",
                                    `${result.message}`,
                                    "Success",
                                  )
                                  formik.setFieldValue(
                                    `isPreApproved`,
                                    !e.target.checked,
                                  )
                                }
                              }}
                            />
                          </div>
                        </FormGroup>
                        <Alert
                          className="border border-2 pt-2 m-1 info-card-detail"
                          color="info"
                        >
                          <Col md={12} className="mb-2">
                            <div className="mb-2 d-flex align-items-center">
                              <i className="mdi mdi-information-outline text-info font-size-20 me-2" />
                              <h6 className="mb-0">For Pre-Approved Users:</h6>
                            </div>
                            <div className="ps-4">
                              <ul>
                                <li>
                                  Properties with "Payments Remaining" will be
                                  moved to "Under Review".
                                </li>
                                <li>
                                  Invoices will be generated for these
                                  properties after 30 days.
                                </li>
                              </ul>
                            </div>
                          </Col>
                          <Col md={12} className="mb-2">
                            <div className="ps-4 ms-1">
                              <h6 className="mb-2">
                                For Non Pre-Approved Users:
                              </h6>
                              <ul>
                                <li>
                                  Properties in "Under Review" with pending
                                  payments will be moved to "Payments
                                  Remaining".
                                </li>
                              </ul>
                            </div>
                          </Col>
                        </Alert>
                        <FormGroup className="mb-4 d-flex">
                          <Label
                            htmlFor="paymentExempt"
                            className="col-form-label"
                          >
                            Payment Exempt
                          </Label>
                          <div className="form-check form-switch pt-2 ms-4">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="paymentExempt"
                              style={{ transform: "scale(1.5)" }}
                              name={`paymentExempt
                                `}
                              checked={formik.values.paymentExempt}
                              onChange={async e => {
                                const confirmationMessage = !e.target.checked
                                  ? `You want to make ${userdata?.name} to non payment exempt user?`
                                  : `You want to make ${userdata?.name} to payment exempt user?`
                                const confirmation = await confirmAlert(
                                  confirmationMessage,
                                  "warning",
                                  "Yes, do it!",
                                )
                                if (confirmation.isConfirmed) {
                                  const result = await updateUser({
                                    value: {
                                      paymentExempt: !e.target.checked,
                                      isPreApproved:
                                        formik?.values?.isPreApproved,
                                    },
                                    id: userdata?.id,
                                  }).unwrap()
                                  showToaster(
                                    "success",
                                    `${result.message}`,
                                    "Success",
                                  )
                                  formik.setFieldValue(
                                    `paymentExempt`,
                                    !e.target.checked,
                                  )
                                }
                              }}
                            />
                          </div>
                        </FormGroup>
                        <Alert
                          color={"info"}
                          className="border border-2 pt-2 m-1 info-card-detail"
                        >
                          <Col md={12} className="mb-2">
                            <div className="mb-2 d-flex align-items-center">
                              <i className="mdi mdi-information-outline text-info font-size-20 me-2" />
                              <h6 className="mb-0">
                                For Payment Exempt Users:
                              </h6>
                            </div>

                            <div className="ps-4">
                              <ul>
                                <li>
                                  Properties with "Payments Remaining" will be
                                  moved to "Under Review".
                                </li>
                                <li>
                                  Zero Amount Invoices will be generated for
                                  these properties after 30 days.
                                </li>
                              </ul>
                            </div>
                          </Col>
                          <Col md={12} className="mb-2">
                            <div className="ps-4 ms-1">
                              <h6 className="mb-2">
                                For Non Payment Exempt Users:
                              </h6>
                              <ul>
                                <li>
                                  Properties in "Under Review" with pending
                                  payments will be moved to "Payments
                                  Remaining".
                                </li>
                              </ul>
                            </div>
                          </Col>
                        </Alert>
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => {
                        onCancel()
                      }}
                    >
                      <i className="bx bx-left-arrow-alt font-size-16 align-middle me-2"></i>{" "}
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      disabled={
                        formik.isSubmitting ||
                        (formik?.initialValues?.name == formik?.values?.name &&
                          formik?.initialValues?.subRole ==
                            formik?.values?.subRole &&
                          formik?.initialValues?.email ==
                            formik?.values?.email &&
                          formik?.initialValues?.dob == formik?.values?.dob &&
                          formik?.initialValues?.address ==
                            formik?.values?.address &&
                          formik?.initialValues?.contact ==
                            formik?.values?.contact)
                      }
                      className={`btn btn-success w-md ${
                        formik.isSubmitting ? "cursor-wait" : "cursor-pointer"
                      }`}
                    >
                      {formik.isSubmitting ? (
                        <div className="d-flex justify-content-center align-items-center">
                          <div
                            className="spinner-border spinner-border-sm me-1"
                            role="status"
                          >
                            <span className="sr-only">Adding...</span>
                          </div>
                          <span>Adding...</span>
                        </div>
                      ) : (
                        <>
                          <i className="bx bx-up-arrow-alt font-size-16 align-middle me-2"></i>{" "}
                          Update
                        </>
                      )}
                    </Button>
                  </ModalFooter>
                </Form>
              </CardBody>
            </Card>
          </Col>
          {isCropModalOpen && (
            <ImageCroperModel
              imageArry={selectedFiles}
              onCancel={handleCloseCropModal}
              setFile={setselectedFiles}
              isOpen={isCropModalOpen}
            />
          )}
        </Row>
      </Modal>
    </React.Fragment>
  )
}

export default EditUserModel
