import config from "../config"
import Swal from "sweetalert2"
import toastr from "toastr"
import { useSelector } from "react-redux"
import {
  selectCurrentToken,
  selectCurrentUser,
} from "redux/authentication/slice"
import { jwtDecode } from "jwt-decode"

export const showToaster = (type = "info", message, title = "", options) => {
  const opts = Object.assign({}, config.TOASTR_DEFAULT_OPTIONS, options)
  toastr.options = opts
  toastr[type](message, title)
}

export const showError = error => {
  console.log(error)
  let message =
    error.data && error.data.message
      ? error.data.message
      : "Something went wrong !"
  if (
    error.status == 400 &&
    error?.data?.validationErrors &&
    Array.isArray(error.data.validationErrors) &&
    error.data.validationErrors.length
  ) {
    message = error.data.validationErrors[0].msg || "Something went wrong"
  }
  showToaster("error", message, "Error")
}
export const authentiCateUserPermission = (
  requiredUserPermission = [],
  userPermision = [],
  user,
) => {
  let result = false
  if (user == "admin") {
    return true
  }
  if (userPermision) {
    const allValue = requiredUserPermission.map(val => {
      return userPermision.includes(val) || val == true || user == "admin"
    })
    if (allValue.includes(true)) {
      result = true
    }
  }
  return result
}
export const confirmAlert = (
  message = `You won't be able to revert/edit this, Please do confirm details first.`,
  icon = "warning",
  btnText = "Yes, do it!",
) => {
  return Swal.fire({
    title: "Are you sure?",
    text: message,
    icon: icon,
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: btnText,
  })
}
export const SweetAlert = (message = `Completed`, icon = "success") => {
  return Swal.fire({
    position: "center",
    icon: icon,
    title: message,
    showConfirmButton: true,
  })
}
