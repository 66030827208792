import React, { useEffect, useRef, useState } from "react"
import "./index.css"

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap"

// Redux
import { useDispatch, useSelector } from "react-redux"
import withRouter from "components/Common/withRouter"

// Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// import avatar from "../../assets/images/users/avatar-1.jpg"
import { selectCurrentUser, setUserProfile } from "redux/authentication/slice"

import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap"
import { Helmet } from "react-helmet"

import ChangePassword from "./ChangePassword"
import AccountDetails from "./AccountDetails"
import {
  useUpdateProfileImgMutation,
  useUserProfileMutation,
} from "redux/authentication/api"
import { showError, showToaster } from "helpers"
import config from "config"
import LazyImageWithFallback from "components/Common/LazyImageWithFallback"
import { getAvatarValue, showFirst10Words } from "helpers/util"
import { useRemoveProfileImgMutation } from "redux/user/api"
import ImageCroperModel from "components/Common/ImageCroper/ImageCroperModel"
const Profile = () => {
  const [activeTab, setActiveTab] = useState("tab1")
  const user = useSelector(selectCurrentUser)
  const [updateProfileImg, updateProfileImgAPI] = useUpdateProfileImgMutation()
  const [userProfile, userProfileAPI] = useUserProfileMutation()
  const [removeProfile, removeProfileAPI] = useRemoveProfileImgMutation()
  const [menu, setMenu] = useState(false)
  const dispatch = useDispatch()
  const [load, setLoad] = useState(false)
  const [isCropModalOpen, setIsCropModalOpen] = useState(false)
  const [selectedFiles, setselectedFiles] = useState([])
  // Function to toggle between tabs
  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }
  useEffect(() => {
    const call = async () => {
      try {
        const userResult = await userProfile().unwrap()
        dispatch(setUserProfile(userResult.data))
      } catch (error) {
        // Handle any unexpected errors
        showError(error)
      }
    }
    call()
  }, [load])
  const fileInputRef = useRef(null)

  // Function to open the file input dialog when the "Change Avatar" button is clicked
  const handleFileInputClick = () => {
    fileInputRef.current.click()
  }

  // Function to handle file selection
  const handleFileChange = async event => {
    // Get the selected file(s) from the input element
    const files = event.target.files
    if (files.length === 0) {
      // No file selected, handle this case as needed
      return
    }
    if (!config.IMAGE_UPLOAD_TYPE.includes(files[0].type)) {
      showToaster(
        "error",
        `Select Valid Image (only jpg, jpeg or png)`,
        "Error",
      )
      return
    }
    if (files[0].size > config.IMAGE_MAXSIZE) {
      showToaster(
        "error",
        `File size should be less than or equal to 5MB`,
        "Error",
      )
      return
    }
    setselectedFiles([files[0]])
    handleOpenCropModal()
    // try {
    //   // Create a new FormData object and append the selected file(s)
    //   const formData = new FormData()
    //   formData.append("profileImage", files[0], files[0].name)
    //   formData.append("Content-Type", files[0].type)
    //   // Call the mutation to update the profile image
    //   const result = await updateProfileImg(formData).unwrap()
    //   setLoad(load => !load)
    //   showToaster("success", `${result.message}`, "Success")
    // } catch (error) {
    //   // Handle any unexpected errors
    //   showError(error)
    // }
  }
  const BreadcrumbDetails = {
    breadcrumbItem: {
      label: "Profile",
      link: "/profile",
    },
    paths: [
      {
        label: "Admin",
        link: "/profile",
      },
    ],
  }
  const handelRemoveProfile = async () => {
    try {
      // Create a new FormData object and append the selected file(s)
      const result = await removeProfile({
        id: user?.id,
      }).unwrap()
      setLoad(load => !load)
      showToaster("success", `${result.message}`, "Success")
    } catch (error) {
      // Handle any unexpected errors
      showError(error)
    }
  }
  const avatar = user?.id && getAvatarValue(user?.name)
  const handleOpenCropModal = () => {
    setIsCropModalOpen(true)
  }
  const handleCloseCropModal = async cropedFiles => {
    setIsCropModalOpen(false)
    if (cropedFiles && cropedFiles[0]) {
      try {
        const formData = new FormData()
        formData.append(
          "profileImage",
          cropedFiles[0]?.file,
          cropedFiles[0]?.file?.name,
        )
        formData.append("Content-Type", cropedFiles[0]?.file?.type)
        // Call the mutation to update the profile image
        const result = await updateProfileImg(formData).unwrap()
        setLoad(load => !load)
        showToaster("success", `${result.message}`, "Success")
      } catch (error) {
        showError(error)
      }
    }
  }
  return (
    <React.Fragment>
      {/* Helmet component for setting page title */}
      <Helmet>
        <title>Profile | Discover Equestrian</title>
      </Helmet>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs breadcrumbDetails={BreadcrumbDetails} />

          <Row>
            <Col
              xl="2"
              lg="3"
              className="full-viewport-height full-viewport-height-lg"
            >
              <Card style={{ height: "100%" }}>
                <CardBody className="shadow-sm">
                  <div className="d-flex flex-column ">
                    <div className="mx-xxl-3 position-relative">
                      {!updateProfileImgAPI.isLoading ? (
                        <div className="d-flex align-items-center profile-imgtxt">
                          <div className="profileimage-wrapper">
                            {user?.profileImage ? (
                              <LazyImageWithFallback
                                className="w-25"
                                src={`${user?.profileImage}?v=${Math.floor(
                                  Math.random() * 90 + 10,
                                )}`}
                                alt="profile"
                              />
                            ) : (
                              <div
                                className="d-flex align-items-center justify-content-center rounded-circle  shadow-2xl position-relative avatar-lg"
                                style={{
                                  backgroundColor: `${avatar?.backgroundColor}`,
                                  color: `${avatar?.fontColor}`,
                                }}
                              >
                                <h1 className="display-6 font-weight-bold   mb-0">
                                  {avatar && avatar?.character}
                                </h1>
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="rounded-circle bg-primary shadow-2xl position-relative avatar-xl">
                          <CardBody className="d-flex align-items-center justify-content-center">
                            <h1 className="display-4 font-weight-bold text-white">
                              <i className="bx bxs-user font-size-48 align-middle"></i>
                            </h1>
                          </CardBody>
                        </div>
                      )}
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                        accept="image/*"
                      />
                      <Dropdown
                        isOpen={menu}
                        toggle={() => {
                          if (
                            !(
                              updateProfileImgAPI?.isLoading ||
                              removeProfileAPI?.isLoading
                            )
                          ) {
                            setMenu(!menu)
                          }
                        }}
                        className="d-inline-block rounded-circle position-absolute bottom-0 start-0"
                      >
                        <DropdownToggle
                          className="btn header-item d-flex align-items-center"
                          id="page-header-user-dropdown"
                          tag="button"
                          type={"button"}
                        >
                          <button
                            disabled={
                              updateProfileImgAPI?.isLoading ||
                              removeProfileAPI?.isLoading
                            }
                            type="button"
                            className="btn btn-sm bg-white border rounded-circle position-absolute bottom-0 start-0"
                          >
                            <i className="mdi mdi-pencil font-size-20 align-middle"></i>
                          </button>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-start">
                          <button
                            type="button"
                            disabled={
                              updateProfileImgAPI?.isLoading ||
                              removeProfileAPI?.isLoading
                            }
                            onClick={e => {
                              setMenu(!menu)
                              handleFileInputClick(e)
                            }}
                            className="dropdown-item"
                          >
                            <i className="bx bx-upload font-size-18 align-middle me-1"></i>
                            <span>Upload Image</span>
                          </button>
                          {user?.profileImage && (
                            <>
                              <div className="dropdown-divider" />
                              <button
                                onClick={() => {
                                  handelRemoveProfile()
                                  setMenu(!menu)
                                }}
                                type="button"
                                className="dropdown-item"
                              >
                                <i className="mdi mdi-delete font-size-18 align-middle me-1 text-danger" />
                                Remove Image
                              </button>
                            </>
                          )}
                        </DropdownMenu>
                      </Dropdown>
                      {/* <button
                        className="btn btn-sm bg-white border rounded-circle position-absolute bottom-0 start-0"
                        onClick={handleFileInputClick}
                      >
                        <i className="bx bxs-camera font-size-24 align-middle"></i>
                      </button> */}
                    </div>

                    <div className="mt-2 flex-grow-1 mx-xxl-3 align-self-start ">
                      <div className="text-muted">
                        <h5>{showFirst10Words(user?.name)}</h5>
                      </div>
                    </div>
                  </div>
                  <Nav tabs className="pt-3">
                    <div
                      className="d-flex  flex-lg-row flex-lg-column gap-1"
                      style={{ width: "100%" }}
                    >
                      <NavItem>
                        <NavLink
                          className={
                            activeTab === "tab1"
                              ? "active bg-primary text-white"
                              : ""
                          }
                          onClick={() => toggleTab("tab1")}
                        >
                          Account Details
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={
                            activeTab === "tab2"
                              ? "active bg-primary text-white"
                              : ""
                          }
                          onClick={() => toggleTab("tab2")}
                        >
                          Change Password
                        </NavLink>
                      </NavItem>
                    </div>
                  </Nav>
                </CardBody>
              </Card>
            </Col>
            <Col xl="10" lg="9">
              <TabContent activeTab={activeTab}>
                <TabPane tabId="tab1">
                  <AccountDetails
                    load={load}
                    setLoad={setLoad}
                    activeTab={activeTab}
                  />
                </TabPane>
                <TabPane tabId="tab2">
                  <ChangePassword activeTab={activeTab} />
                </TabPane>
              </TabContent>
            </Col>
          </Row>
          {isCropModalOpen && (
            <ImageCroperModel
              imageArry={selectedFiles}
              onCancel={handleCloseCropModal}
              setFile={setselectedFiles}
              isOpen={isCropModalOpen}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Profile)
