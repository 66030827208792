import { apiSlice } from "../api"

export const propetyApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    propertyList: builder.query({
      query: props => {
        let url = `/property`
        if (props) {
          const {
            page = 1,
            limit = 10,
            type = "",
            search = "",
            status = "",
          } = props?.state
          const userID = props?.userId
          url = `/property/all?${
            status ? "type=" + status + "&" : ""
          }page=${page}&limit=${limit}${
            search != "" ? "&search=" + search : ""
          }`
          if (userID) {
            url = `/property/user/${userID}?${
              status ? "type=" + status + "&" : ""
            }page=${page}&limit=${limit}${
              search != "" ? "&search=" + search : ""
            }`
          }
        }
        return {
          url: `${url} `,
          method: "GET",
        }
      },
    }),
    addProperty: builder.mutation({
      query: payload => {
        const {
          location,
          stable,
          outBuilding,
          manage,
          annex,
          autoUpdate,
          addressMeta,
          draft,
        } = payload.value
        var data = new FormData()
        Object.keys(payload.value).map(key => {
          if (
            payload.value[key] &&
            [
              "user",
              "type",
              "title",
              "price",
              "address",
              "beds",
              "bathrooms",
              "area",
              "prefixId",
              "videoLink",
              "description",
            ].includes(key)
          ) {
            data.append(key, payload.value[key])
          }
        })
        data.append("stable", stable)
        data.append("outBuilding", outBuilding)
        data.append("manage", manage)
        data.append("annex", annex)
        data.append("autoUpdate", autoUpdate)
        if (draft == true || draft == false) {
          data.append("draft", draft)
        }
        if (location["coordinates"][0]) {
          data.append("location[coordinates][0]", location["coordinates"][0])
          data.append("location[coordinates][1]", location["coordinates"][1])
        }
        if (addressMeta["county"]) {
          data.append("addressMeta[county]", addressMeta["county"])
        }
        if (addressMeta["town"]) {
          data.append("addressMeta[town]", addressMeta["town"])
        }
        if (payload.images.length > 0) {
          for (const file of payload.images) {
            if (file?.file) {
              data.append("images", file.file)
            }
          }
        }
        return {
          url: `/Property`,
          method: "POST",
          body: data,
        }
      },
    }),
    getpropertyData: builder.mutation({
      query: props => {
        return {
          url: `/property/${props}`,
          method: "GET",
        }
      },
    }),
    archiveProperty: builder.mutation({
      query: props => {
        const { isArchived, id } = props
        return {
          url: `/property/${id}/` + isArchived,
          method: "PATCH",
        }
      },
    }),
    liveProperty: builder.mutation({
      query: props => {
        const { isLive, id } = props
        return {
          url: `/property/${id}/` + isLive,
          method: "PUT",
        }
      },
    }),
    updateProperty: builder.mutation({
      query: payload => {
        var data = new FormData()
        const {
          location,
          stable,
          outBuilding,
          manage,
          annex,
          autoUpdate,
          addressMeta,
          draft,
        } = payload.value
        Object.keys(payload.value).map(key => {
          if (
            [
              "user",
              "type",
              "title",
              "price",
              "address",
              "beds",
              "bathrooms",
              "area",
              "prefixId",
              "videoLink",
              "description",
            ].includes(key)
          ) {
            data.append(key, payload.value[key])
          }
        })
        data.append("stable", stable)
        data.append("outBuilding", outBuilding)
        data.append("manage", manage)
        data.append("annex", annex)
        data.append("autoUpdate", autoUpdate)
        if (draft == true || draft == false) {
          data.append("draft", draft)
        }
        if (location["coordinates"][0]) {
          data.append("location[coordinates][0]", location["coordinates"][0])
          data.append("location[coordinates][1]", location["coordinates"][1])
        } else {
          if (location["coordinates"][0]) {
            data.append("location[coordinates][0]", 0)
            data.append("location[coordinates][1]", 0)
          }
        }
        if (addressMeta["county"]) {
          data.append("addressMeta[county]", addressMeta["county"])
        }
        if (addressMeta["town"]) {
          data.append("addressMeta[town]", addressMeta["town"])
        }

        if (payload.images.length > 0) {
          for (const file of payload.images) {
            if (file?.file) {
              data.append("images", file.file)
            }
          }
        }

        if (payload.imageMap.length > 0) {
          if (payload.imageMap.length == 1) {
            data.append("imageMap[0]", payload.imageMap[0])
          } else {
            for (const file of payload?.imageMap) {
              if (file) {
                data.append("imageMap", file)
              }
            }
          }
        }
        if (payload.deleteImg.length > 0) {
          if (payload.deleteImg.length == 1) {
            data.append("deleteImages[0]", payload.deleteImg[0])
          } else {
            for (const file of payload.deleteImg) {
              if (file) {
                data.append("deleteImages", file)
              }
            }
          }
        }
        return {
          url: `/property/${payload.id}`,
          method: "PATCH",
          body: data,
        }
      },
    }),
    deleteProperty: builder.mutation({
      query: props => {
        const { id, userId } = props
        return {
          url: `/property/${id}`,
          method: "DELETE",
          body: { user: userId },
        }
      },
    }),
  }),
})

export const {
  usePropertyListQuery,
  useAddPropertyMutation,
  useGetpropertyDataMutation,
  useArchivePropertyMutation,
  useLivePropertyMutation,
  useUpdatePropertyMutation,
  useDeletePropertyMutation,
} = propetyApiSlice
