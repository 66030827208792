import React, { useState } from "react"

import {
  Container,
  Row,
  Col,
  Table,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  Form,
  FormGroup,
  Label,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "reactstrap"
import Select from "react-select"
import { Link } from "react-router-dom"

import classnames from "classnames"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { LoginSetting } from "./LoginSetting"
import { SignupSetting } from "./SignUpSetting"
import { AppSetting } from "./AppSetting"
import { FooterTabs } from "./Footer/Tab"
import { AdvertSetting } from "./Footer/AdvertiseWithUs"
import { Helmet } from "react-helmet"

const WebSettings = () => {
  const BreadcrumbDetails = {
    breadcrumbItem: {
      label: "Web Settings",
      link: "/pages/settings",
    },
    paths: [
      {
        label: "Pages",
        link: "/pages/settings",
      },
    ],
  }
  let tabs = ["Login", "Sign-up", "App", "Footer", "Advertise With Us"]
  const [activeTab, setactiveTab] = useState(tabs[0]) //login, sign-up, app, footer
  const [selectedGroup, setselectedGroup] = useState(null)

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup)
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Web Setting | Discover Equestrian </title>
      </Helmet>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs breadcrumbDetails={BreadcrumbDetails} />

          <div className="checkout-tabs">
            <Row>
              <Col xxl="1" lg="2" md="2" sm="3">
                <Nav className="flex-column" pills>
                  {tabs?.map((tabName, index) => (
                    <NavItem key={index}>
                      <NavLink
                        className={classnames({
                          active: activeTab === tabName,
                        })}
                        onClick={() => {
                          setactiveTab(tabName)
                        }}
                      >
                        {tabName === "Login" && (
                          <i className="bx bx-user-check d-block check-nav-icon mt-1 mb-1" />
                        )}
                        {tabName === "Sign-up" && (
                          <i className="bx bx-user-plus d-block check-nav-icon mt-1 mb-1" />
                        )}
                        {tabName === "App" && (
                          <i className="bx bx-mobile d-block check-nav-icon mt-1 mb-1" />
                        )}
                        {tabName === "Footer" && (
                          <i className="bx bxs-file d-block check-nav-icon mt-1 mb-1" />
                        )}
                        {tabName === "Advertise With Us" && (
                          <i className="bx bxs-check-shield d-block check-nav-icon mt-1 mb-1" />
                        )}
                        <p className="fw-bold mb-1">{tabName}</p>
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
              </Col>
              <Col xxl="11" lg="10" md="10" sm="9">
                <Card>
                  <CardBody>
                    <TabContent activeTab={activeTab}>
                      {tabs?.map((tabName, index) => (
                        <TabPane key={index} tabId={tabName}>
                          <div>
                            <CardTitle>{tabName}</CardTitle>
                            <p className="card-title-desc">
                              Fill all the below mentioned information
                            </p>
                            {tabName === "Login" && (
                              <LoginSetting activeTab={"banner"} />
                            )}
                            {tabName === "Sign-up" && (
                              <SignupSetting activeTab={"banner"} />
                            )}
                            {tabName === "App" && <AppSetting activeTab={""} />}
                            {tabName === "Footer" && <FooterTabs />}
                            {tabName === "Advertise With Us" && (
                              <AdvertSetting />
                            )}
                          </div>
                        </TabPane>
                      ))}
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default WebSettings
