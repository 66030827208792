import React, { useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Input,
  FormGroup,
  Label,
  Button,
  Form,
  FormFeedback,
  Alert,
  DropdownToggle,
  Dropdown,
  DropdownMenu,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { useFormik } from "formik"
import * as Yup from "yup"

import { confirmAlert, showError, showToaster } from "helpers"
import GoogleAddressField from "components/Common/Location/GoogleLocation"
import {
  useAddUserMutation,
  useGetUserMutation,
  useRemoveProfileImgMutation,
  useUpdateUserMutation,
  useUpdateUserProfileImgMutation,
} from "redux/user/api"
import { EMAIL_REGEX } from "helpers/regex"
import { useRolesListQuery } from "redux/role/api"
import moment from "moment"
import LoadingSpinner from "components/Common/LoadingSpinner"
import LazyImageWithFallback from "components/Common/LazyImageWithFallback"
import { getAvatarValue } from "helpers/util"
import config from "config"
import ImageCroperModel from "components/Common/ImageCroper/ImageCroperModel"
function UserAdd() {
  const navigate = useNavigate()
  const [addUser, addUserAPI] = useAddUserMutation()
  // edit Edit
  const { pathname } = useLocation()
  const IsEdit = pathname?.includes("edit")
  const [editInfo, setEditInfo] = useState([])
  const { id } = useParams()
  const [getData, getDataAPI] = useGetUserMutation()
  const [updateUser, UpdateUserAPI] = useUpdateUserMutation()
  const [passwordShow, setPasswordShow] = useState(false)
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false)
  const [updateProfileImg, updateProfileImgAPI] =
    useUpdateUserProfileImgMutation()
  const [removeProfile, removeProfileAPI] = useRemoveProfileImgMutation()
  // userList Option
  const [rolesOption, setRolesOption] = useState([])
  const rolesData = useRolesListQuery()
  const [menu, setMenu] = useState(false)
  const [load, setLoad] = useState(true)
  const [isCropModalOpen, setIsCropModalOpen] = useState(false)
  const [selectedFiles, setselectedFiles] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getData(id).unwrap()
        setEditInfo(result?.data)
      } catch (error) {
        navigate("/user/list")
        showError(error)
      }
    }
    if (id) {
      rolesData.refetch()
      fetchData()
    } else {
      setEditInfo([])
    }
  }, [id, load])

  useEffect(() => {
    if (rolesData.isSuccess) {
      setRolesOption(rolesData?.data?.data?.roles)
    }
    if (rolesData.isError) {
      showError(rolesData.error)
    }
    return () => {
      setRolesOption([])
    }
  }, [rolesData])
  const BreadcrumbDetails = {
    breadcrumbItem: {
      label: !IsEdit ? "Add User" : "Edit User" || editInfo?.name,
      link: !IsEdit ? "user/add" : `user/edit/${id}`,
    },
    paths: IsEdit
      ? [
          {
            label: "Users",
            link: "user/list",
          },
          // {
          //   label: "Edit",
          //   link: "user/list",
          // },
        ]
      : [
          {
            label: "Users",
            link: "user/add",
          },
        ],
  }
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required("Name is required")
      .max(50, "Name should be less than or equal to 50 characters."),
    subRole: Yup.string(),
    email: Yup.string()
      .matches(/^\S*$/, "Whitespace is not allowed")
      .required("Please enter your email")
      .email("Please enter a valid email")
      .matches(EMAIL_REGEX, "Please enter a valid email"),
    dob: Yup.date()
      .min(
        new Date(new Date().setFullYear(new Date().getFullYear() - 100)),
        "Must be at most 100 years old",
      )
      .max(
        new Date(new Date().setFullYear(new Date().getFullYear())),
        "Enter valid Date of Birth",
      )
      .required("Date of Birth is required"),
    address: Yup.string()
      .trim()
      .required("Address is required")
      .test("not-same-as-old", "Enter valid Address", function (value) {
        return typeof this.parent.lat == "number"
      }),
    contact: Yup.string()
      .max(11, "Phone Number should be from 10 to 11 digits")
      .min(10, "Phone Number should be from 10 to 11 digits")
      .required("Phone Number is required"),
    password: IsEdit
      ? Yup.string().notRequired()
      : Yup.string()
          .required("Please enter password")
          .matches(
            /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
            "Password should contain minimum 8 characters including 1 number, 1 alphabet, and 1 special character",
          )
          .min(
            8,
            "Password should contain minimum 8 characters including 1 number, 1 alphabet, and 1 special character",
          )
          .max(50, "Password should be less than or equal to 50 characters"),

    confirmPassword: IsEdit
      ? Yup.string().notRequired()
      : Yup.string()
          .oneOf(
            [Yup.ref("password"), null],
            "Password and Confirm Password should be same",
          )
          .required("Please confirm  password"),
  })

  const editInitialValues = {
    name: editInfo?.name,
    subRole: editInfo?.subRole || "",
    email: editInfo?.email,
    dob: editInfo
      ? moment(editInfo.dob, "DD/MM/YYYY").format("YYYY-MM-DD")
      : "",
    address: editInfo?.address,
    long: editInfo?.location?.coordinates[0],
    lat: editInfo?.location?.coordinates[1],
    contact: editInfo?.contact,
    isPreApproved: editInfo?.isPreApproved,
    paymentExempt: editInfo?.paymentExempt,
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: IsEdit
      ? editInitialValues
      : {
          name: "",
          subRole: "",
          email: "",
          password: "",
          confirmPassword: "",
          dob: "",
          address: "",
          contact: "",
          isPreApproved: false,
          paymentExempt: false,
        },
    validationSchema,
    onSubmit: async values => {
      let newValue = {
        name: values.name,
        subRole: values.subRole,
        email: values.email,
        password: values.password,
        dob: moment(new Date(values.dob)).format("DD/MM/YYYY"),
        address: values.address,
        location: {
          coordinates: [values?.long, values?.lat],
        },
        contact: `${values.contact}`,
        isPreApproved: values.isPreApproved,
        paymentExempt: values.paymentExempt,
      }
      if (!IsEdit) {
        try {
          const result = await addUser(newValue).unwrap()
          navigate("/user/list")
          showToaster("success", `${result.message}`, "Success")
        } catch (error) {
          showError(error)
        }
      } else {
        try {
          const result = await updateUser({
            value: newValue,
            id: id,
          }).unwrap()
          navigate("/user/list")
          showToaster("success", `${result.message}`, "Success")
        } catch (error) {
          showError(error)
        }
      }
    },
  })
  const avatar = editInfo?.name && getAvatarValue(editInfo?.name)
  const fileInputRef = useRef(null)
  const handleFileInputClick = () => {
    fileInputRef.current.click()
  }
  // Function to handle file selection
  const handleFileChange = async event => {
    const files = event.target.files
    if (files.length === 0) {
      return
    }
    if (!config.IMAGE_UPLOAD_TYPE.includes(files[0].type)) {
      showToaster(
        "error",
        `Select Valid Image (only jpg, jpeg or png)`,
        "Error",
      )
      return
    }
    if (files[0].size > config.IMAGE_MAXSIZE) {
      showToaster(
        "error",
        `File size should be less than or equal to 5MB`,
        "Error",
      )
      return
    }
    setselectedFiles([files[0]])
    handleOpenCropModal()
  }

  const handelRemoveProfile = async () => {
    try {
      const result = await removeProfile({
        id: editInfo?.id,
      }).unwrap()
      setLoad(load => !load)
      showToaster("success", `${result.message}`, "Success")
    } catch (error) {
      showError(error)
    }
  }
  const handleOpenCropModal = () => {
    setIsCropModalOpen(true)
  }
  const handleCloseCropModal = async cropedFiles => {
    setIsCropModalOpen(false)
    if (cropedFiles && cropedFiles[0]) {
      try {
        const result = await updateProfileImg({
          payload: cropedFiles[0]?.file,
          id: editInfo?.id,
        }).unwrap()
        setLoad(load => !load)
        showToaster("success", `${result.message}`, "Success")
      } catch (error) {
        showError(error)
      }
    }
  }
  return (
    <React.Fragment>
      <Helmet>
        <title>
          {!IsEdit ? "Add User" : "Edit User"} | Discover Equestrian
        </title>
      </Helmet>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbDetails={BreadcrumbDetails} />

          {getDataAPI?.isLoading || getDataAPI?.isFetching ? (
            <Col lg="12" className="w-100 d-flex justify-content-center">
              {" "}
              <LoadingSpinner />{" "}
            </Col>
          ) : (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4"></CardTitle>
                    {IsEdit && (
                      <Row className="d-flex justify-content-start align-item-center pb-5 pt-3">
                        <Col sm="4">
                          <div className="mx-xxl-3 position-relative">
                            {!getDataAPI.isLoading && IsEdit ? (
                              <div className="d-flex align-items-center profile-imgtxt">
                                <div className="profileimage-wrapper">
                                  {editInfo?.profileImage ? (
                                    <LazyImageWithFallback
                                      className="w-25"
                                      src={`${editInfo?.profileImage}?v=${Math.floor(
                                        Math.random() * 90 + 10,
                                      )}`}
                                      alt="profile"
                                    />
                                  ) : (
                                    <div
                                      className="d-flex align-items-center justify-content-center rounded-circle  shadow-2xl position-relative avatar-lg"
                                      style={{
                                        backgroundColor: `${avatar?.backgroundColor}`,
                                        color: `${avatar?.fontColor}`,
                                      }}
                                    >
                                      <h1 className="display-6 font-weight-bold   mb-0">
                                        {avatar && avatar?.character}
                                      </h1>
                                    </div>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div className="rounded-circle bg-primary shadow-2xl position-relative avatar-xl">
                                <CardBody className="d-flex align-items-center justify-content-center">
                                  <h1 className="display-4 font-weight-bold text-white">
                                    <i className="bx bxs-user font-size-48 align-middle"></i>
                                  </h1>
                                </CardBody>
                              </div>
                            )}
                            <input
                              type="file"
                              ref={fileInputRef}
                              style={{ display: "none" }}
                              onChange={e => {
                                handleFileChange(e)
                              }}
                              accept="image/*"
                            />
                            <Dropdown
                              isOpen={menu}
                              toggle={() => {
                                if (
                                  !(
                                    updateProfileImgAPI?.isLoading ||
                                    removeProfileAPI?.isLoading
                                  )
                                ) {
                                  setMenu(!menu)
                                }
                              }}
                              className="d-inline-block rounded-circle position-absolute bottom-0 start-0"
                            >
                              <DropdownToggle
                                className="btn header-item d-flex align-items-center"
                                id="page-header-user-dropdown"
                                tag="button"
                              >
                                <button
                                  disabled={
                                    updateProfileImgAPI?.isLoading ||
                                    removeProfileAPI?.isLoading
                                  }
                                  type="button"
                                  className="btn btn-sm bg-white border rounded-circle position-absolute bottom-0 start-0"
                                >
                                  <i className="mdi mdi-pencil font-size-14 align-middle"></i>
                                </button>
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-start">
                                <button
                                  type="button"
                                  disabled={
                                    updateProfileImgAPI?.isLoading ||
                                    removeProfileAPI?.isLoading
                                  }
                                  onClick={e => {
                                    setMenu(!menu)
                                    handleFileInputClick(e)
                                  }}
                                  className="dropdown-item"
                                >
                                  <i className="bx bx-upload font-size-18 align-middle me-1"></i>
                                  <span>Upload Image</span>
                                </button>
                                {editInfo?.profileImage && (
                                  <>
                                    <div className="dropdown-divider" />
                                    <button
                                      onClick={() => {
                                        handelRemoveProfile()
                                        setMenu(!menu)
                                      }}
                                      type="button"
                                      className="dropdown-item"
                                    >
                                      <i className="mdi mdi-delete font-size-18 align-middle me-1 text-danger" />
                                      Remove Image
                                    </button>
                                  </>
                                )}
                              </DropdownMenu>
                            </Dropdown>
                          </div>
                        </Col>
                        <div className="pt-3 text-info">
                          <i className="mdi mdi-information-outline font-size-14 align-middle pe-1" />
                          Profile image should not be more than 5 mb.
                        </div>
                      </Row>
                    )}
                    <Form
                      className="outer-repeater"
                      onSubmit={e => {
                        e.preventDefault()
                        formik.handleSubmit()
                        // return
                      }}
                    >
                      <div data-repeater-list="outer-group" className="outer">
                        <div data-repeater-item className="outer">
                          <FormGroup className="mb-4" row>
                            <Label
                              htmlFor="name"
                              className="col-form-label col-lg-2"
                            >
                              Name
                            </Label>
                            <Col lg="10">
                              <Input
                                id="name"
                                name="name"
                                type="text"
                                className="form-control"
                                placeholder="Enter User Name"
                                value={formik.values.name || ""}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                invalid={
                                  !!(formik.touched.name && formik.errors.name)
                                }
                              />
                              {formik.touched.name && formik.errors.name && (
                                <FormFeedback
                                  className="fs-6 mt-0"
                                  type="invalid"
                                >
                                  {formik.errors.name}
                                </FormFeedback>
                              )}
                            </Col>
                          </FormGroup>
                          <FormGroup className="mb-4" row>
                            <Label
                              htmlFor="subRole"
                              className="col-form-label col-lg-2"
                            >
                              Role
                            </Label>
                            <Col lg="10">
                              <Input
                                type="select"
                                name="subRole"
                                className="form-control"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.subRole || ""}
                                invalid={
                                  !!(
                                    formik.touched.subRole &&
                                    formik.errors.subRole
                                  )
                                }
                              >
                                <option value="">Select Role</option>
                                {rolesOption?.map((val, index) => {
                                  return (
                                    <option key={index} value={`${val?.id}`}>
                                      {val?.name}
                                    </option>
                                  )
                                })}
                              </Input>
                              {formik.touched.subRole &&
                                formik.errors.subRole && (
                                  <FormFeedback
                                    className="fs-6 mt-0"
                                    type="invalid"
                                  >
                                    {formik.errors.subRole}
                                  </FormFeedback>
                                )}
                            </Col>
                          </FormGroup>
                          <FormGroup className="mb-4" row>
                            <Label
                              htmlFor="email"
                              className="col-form-label col-lg-2"
                            >
                              Email
                            </Label>
                            <Col lg="10">
                              <Input
                                disabled={IsEdit}
                                id="email"
                                name="email"
                                type="text"
                                className="form-control"
                                placeholder="Enter Email"
                                value={formik.values.email || ""}
                                onChange={e => {
                                  if (!IsEdit) {
                                    formik.handleChange(e)
                                  }
                                }}
                                onBlur={formik.handleBlur}
                                invalid={
                                  !!(
                                    formik.touched.email && formik.errors.email
                                  )
                                }
                              />
                              {formik.touched.email && formik.errors.email && (
                                <FormFeedback
                                  className="fs-6 mt-0"
                                  type="invalid"
                                >
                                  {formik.errors.email}
                                </FormFeedback>
                              )}
                            </Col>
                          </FormGroup>
                          <FormGroup className="mb-4" row>
                            <Label
                              htmlFor="dob"
                              className="col-form-label col-lg-2"
                            >
                              Date Of Birth
                            </Label>
                            <Col lg="10">
                              {/* <Flatpickr
                                                            id="dob"
                                                            name="dob"
                                                            className="form-control d-block"
                                                            placeholder="Select time"
                                                            options={{
                                                                ltInput: true,
                                                                altFormat: "F j, Y",
                                                                dateFormat: "Y-m-d"
                                                            }}
                                                            value={new Date(formik.values.dob)}
                                                            // value={selectedRange}
                                                            // onOpen={handelOpen}
                                                            // onChange={handleDateChange}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            invalid={
                                                                !!(formik.touched.dob && formik.errors.dob)
                                                            }
                                                        /> */}
                              <Input
                                id="dob"
                                name="dob"
                                type="date"
                                className="form-control"
                                placeholder="Enter Date of birth"
                                value={formik.values.dob || ""}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                invalid={
                                  !!(formik.touched.dob && formik.errors.dob)
                                }
                              />
                              {formik.touched.dob && formik.errors.dob && (
                                <FormFeedback
                                  className="fs-6 mt-0"
                                  type="invalid"
                                >
                                  {formik.errors.dob}
                                </FormFeedback>
                              )}
                            </Col>
                          </FormGroup>
                          <FormGroup className="mb-4" row>
                            <Label
                              htmlFor="contact"
                              className="col-form-label col-lg-2"
                            >
                              Phone Number
                            </Label>
                            <Col lg="10">
                              <Input
                                id="contact"
                                name="contact"
                                value={formik.values.contact || ""}
                                type={"text"}
                                placeholder="Enter Phone Number"
                                autoComplete="false"
                                onChange={e => {
                                  if (!isNaN(e.target.value)) {
                                    formik.handleChange(e)
                                  }
                                }}
                                onBlur={formik.handleBlur}
                                invalid={
                                  !!(
                                    formik.touched.contact &&
                                    formik.errors.contact
                                  )
                                }
                              />
                              {formik.touched.contact &&
                                formik.errors.contact && (
                                  <FormFeedback
                                    className="fs-6 mt-0"
                                    type="invalid"
                                  >
                                    {formik.errors.contact}
                                  </FormFeedback>
                                )}
                            </Col>
                          </FormGroup>
                          <FormGroup className="mb-4" row>
                            <Label
                              htmlFor="address"
                              className="col-form-label col-lg-2"
                            >
                              Address
                            </Label>
                            <Col lg="10">
                              <GoogleAddressField
                                formik={formik}
                                id="address"
                                name="address"
                                type="text"
                                className="form-control"
                                placeholder="Find Your Address"
                                invalid={
                                  !!(
                                    formik.touched.address &&
                                    formik.errors.address
                                  )
                                }
                              />
                            </Col>
                          </FormGroup>
                          {!IsEdit && (
                            <>
                              <FormGroup className="mb-4" row>
                                <Label
                                  htmlFor="password"
                                  className="col-form-label col-lg-2"
                                >
                                  Password
                                </Label>
                                <Col lg="10">
                                  <div className="input-group auth-pass-inputgroup">
                                    <Input
                                      id="password"
                                      name="password"
                                      value={formik.values.password || ""}
                                      type={passwordShow ? "text" : "password"}
                                      placeholder="Enter Password"
                                      autoComplete="false"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      invalid={
                                        !!(
                                          formik.touched.password &&
                                          formik.errors.password
                                        )
                                      }
                                    />
                                    <button
                                      onClick={() =>
                                        setPasswordShow(!passwordShow)
                                      }
                                      className="btn btn-light"
                                      type="button"
                                      id="password-password"
                                    >
                                      <i
                                        className={`mdi ${
                                          !passwordShow
                                            ? "mdi-eye-outline"
                                            : "mdi-eye-off-outline"
                                        }`}
                                      ></i>
                                    </button>
                                    {formik.touched.password &&
                                      formik.errors.password && (
                                        <FormFeedback
                                          className="fs-6 mt-0"
                                          type="invalid"
                                        >
                                          {formik.errors.password}
                                        </FormFeedback>
                                      )}
                                  </div>
                                </Col>
                              </FormGroup>
                              <FormGroup className="mb-4" row>
                                <Label
                                  htmlFor="confirmPassword"
                                  className="col-form-label col-lg-2"
                                >
                                  Confirm Password
                                </Label>
                                <Col lg="10">
                                  <div className="input-group auth-pass-inputgroup">
                                    <Input
                                      id="confirmPassword"
                                      name="confirmPassword"
                                      value={
                                        formik.values.confirmPassword || ""
                                      }
                                      type={
                                        confirmPasswordShow
                                          ? "text"
                                          : "password"
                                      }
                                      placeholder="Enter Confirm Password"
                                      autoComplete="false"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      invalid={
                                        !!(
                                          formik.touched.confirmPassword &&
                                          formik.errors.confirmPassword
                                        )
                                      }
                                    />
                                    <button
                                      onClick={() =>
                                        setConfirmPasswordShow(
                                          !confirmPasswordShow,
                                        )
                                      }
                                      className="btn btn-light"
                                      type="button"
                                      id="password-confirmPassword"
                                    >
                                      <i
                                        className={`mdi ${
                                          !confirmPasswordShow
                                            ? "mdi-eye-outline"
                                            : "mdi-eye-off-outline"
                                        }`}
                                      ></i>
                                    </button>
                                    {formik.touched.confirmPassword &&
                                      formik.errors.confirmPassword && (
                                        <FormFeedback
                                          className="fs-6 mt-0"
                                          type="invalid"
                                        >
                                          {formik.errors.confirmPassword}
                                        </FormFeedback>
                                      )}
                                  </div>
                                </Col>
                              </FormGroup>
                            </>
                          )}

                          <FormGroup className="mb-4" row>
                            <Label
                              htmlFor="isPreApproved"
                              className="col-form-label col-lg-2"
                            >
                              Pre-Approved
                            </Label>
                            <Col lg="1">
                              <div className="form-check form-switch pt-2 ms-2">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="isPreApproved"
                                  style={{ transform: "scale(1.5)" }}
                                  name={`isPreApproved`}
                                  checked={formik.values.isPreApproved}
                                  onChange={async e => {
                                    if (!IsEdit) {
                                      formik.setFieldValue(
                                        `isPreApproved`,
                                        e.target.checked,
                                      )
                                    } else {
                                      const confirmationMessage = !e.target
                                        .checked
                                        ? `You want to make ${editInfo?.name} to non pre-approved user?`
                                        : `You want to make ${editInfo?.name} to pre-approved user?`
                                      const confirmation = await confirmAlert(
                                        confirmationMessage,
                                        "warning",
                                        "Yes, do it!",
                                      )
                                      if (confirmation.isConfirmed) {
                                        const result = await updateUser({
                                          value: {
                                            isPreApproved: !e.target.checked,
                                            paymentExempt:
                                              formik.values.paymentExempt,
                                          },
                                          id: id,
                                        }).unwrap()
                                        showToaster(
                                          "success",
                                          `${result.message}`,
                                          "Success",
                                        )
                                        formik.setFieldValue(
                                          `isPreApproved`,
                                          !e.target.checked,
                                        )
                                      }
                                    }
                                  }}
                                />
                              </div>
                            </Col>
                          </FormGroup>
                          <Alert
                            className="border border-2 pt-2 m-1 info-card-detail "
                            color="info"
                          >
                            <Col md={12} className="mb-2">
                              <div className="mb-2 d-flex align-items-center">
                                <i className="mdi mdi-information-outline text-info font-size-20 me-2" />
                                <h6 className="mb-0">
                                  For Pre-Approved Users:
                                </h6>
                              </div>
                              <div className="ps-4">
                                <ul>
                                  <li>
                                    Properties with "Payments Remaining" will be
                                    moved to "Under Review".
                                  </li>
                                  <li>
                                    Invoices will be generated for these
                                    properties after 30 days.
                                  </li>
                                </ul>
                              </div>
                            </Col>
                            <Col md={12} className="mb-2">
                              <div className="ps-4 ms-1">
                                <h6 className="mb-2">
                                  For Non Pre-Approved Users:
                                </h6>
                                <ul>
                                  <li>
                                    Properties in "Under Review" with pending
                                    payments will be moved to "Payments
                                    Remaining".
                                  </li>
                                </ul>
                              </div>
                            </Col>
                          </Alert>

                          <FormGroup className="mb-4" row>
                            <Label
                              htmlFor="paymentExempt"
                              className="col-form-label col-lg-2"
                            >
                              Payment Exempt
                            </Label>
                            <Col lg="1">
                              <div className="form-check form-switch pt-2 ms-2">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="paymentExempt"
                                  style={{ transform: "scale(1.5)" }}
                                  name={`paymentExempt
                                `}
                                  checked={formik.values.paymentExempt}
                                  onChange={async e => {
                                    if (!IsEdit) {
                                      formik.setFieldValue(
                                        `paymentExempt`,
                                        e.target.checked,
                                      )
                                    } else {
                                      const confirmationMessage = !e.target
                                        .checked
                                        ? `You want to make ${editInfo?.name} to non payment exempt user?`
                                        : `You want to make ${editInfo?.name} to payment exempt user?`
                                      const confirmation = await confirmAlert(
                                        confirmationMessage,
                                        "warning",
                                        "Yes, do it!",
                                      )
                                      if (confirmation.isConfirmed) {
                                        const result = await updateUser({
                                          value: {
                                            paymentExempt: !e.target.checked,
                                            isPreApproved:
                                              formik?.values?.isPreApproved,
                                          },
                                          id: id,
                                        }).unwrap()
                                        showToaster(
                                          "success",
                                          `${result.message}`,
                                          "Success",
                                        )

                                        formik.setFieldValue(
                                          `paymentExempt`,
                                          !e.target.checked,
                                        )
                                      }
                                    }
                                  }}
                                />
                              </div>
                            </Col>
                          </FormGroup>
                          <Alert
                            color={"info"}
                            className="border border-2 pt-2 m-1 info-card-detail"
                          >
                            <Col md={12} className="mb-2">
                              <div className="mb-2 d-flex align-items-center">
                                <i className="mdi mdi-information-outline text-info font-size-20 me-2" />
                                <h6 className="mb-0">
                                  For Payment Exempt Users:
                                </h6>
                              </div>

                              <div className="ps-4">
                                <ul>
                                  <li>
                                    Properties with "Payments Remaining" will be
                                    moved to "Under Review".
                                  </li>
                                  <li>
                                    Zero Amount Invoices will be generated for
                                    these properties after 30 days.
                                  </li>
                                </ul>
                              </div>
                            </Col>
                            <Col md={12} className="mb-2">
                              <div className="ps-4 ms-1">
                                <h6 className="mb-2">
                                  For Non Payment Exempt Users:
                                </h6>
                                <ul>
                                  <li>
                                    Properties in "Under Review" with pending
                                    payments will be moved to "Payments
                                    Remaining".
                                  </li>
                                </ul>
                              </div>
                            </Col>
                          </Alert>
                        </div>
                      </div>
                      <Row className="mb-3 mt-3">
                        <Col className="text-end">
                          {IsEdit && (
                            <Link
                              to={"/user/list"}
                              type="button"
                              className="btn btn-danger me-1"
                            >
                              <i className="bx bx-left-arrow-alt font-size-16 align-middle me-2"></i>{" "}
                              Back
                            </Link>
                          )}
                          <button
                            type="submit"
                            disabled={formik.isSubmitting}
                            className={`btn btn-success w-md ${
                              formik.isSubmitting
                                ? "cursor-wait"
                                : "cursor-pointer"
                            }`}
                          >
                            {formik.isSubmitting ? (
                              <div className="d-flex justify-content-center align-items-center">
                                <div
                                  className="spinner-border spinner-border-sm me-1"
                                  role="status"
                                >
                                  <span className="sr-only">Adding...</span>
                                </div>
                                <span>Adding...</span>
                              </div>
                            ) : !IsEdit ? (
                              <>
                                <i className="bx bx-plus font-size-16 align-middle me-2"></i>{" "}
                                Add
                              </>
                            ) : (
                              <>
                                <i className="bx bx-up-arrow-alt font-size-16 align-middle me-2"></i>{" "}
                                Update
                              </>
                            )}
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          {isCropModalOpen && (
            <ImageCroperModel
              imageArry={selectedFiles}
              onCancel={handleCloseCropModal}
              setFile={setselectedFiles}
              isOpen={isCropModalOpen}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UserAdd
