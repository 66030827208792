import React from "react"
import { formatDate, wordTitleCase } from "helpers/data-formatters"
import {
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"

function ViewModel({ openModal, toggle, data = {} }) {
  return (
    <React.Fragment>
      <Modal
        size="lg"
        isOpen={openModal}
        toggle={toggle}
        backdrop={"static"}
        centered={true}
      >
        <ModalHeader className="text-dark" toggle={toggle} tag="h4">
          {"Contact Details"}
        </ModalHeader>
        <ModalBody style={{ maxHeight: "70vh", overflow: "auto" }}>
          <Row>
            {data?.map((value, index) => {
              return (
                <Col key={index} sm="6" md="6" lg="6" xl="6" xxl="6">
                  <Card className="mini-stats-wid bg-light ">
                    <CardBody>
                      <div className="d-flex flex-wrap">
                        <div className="me-3">
                          <p className="fw-medium mb-2 text-blacka">
                            {" "}
                            {wordTitleCase(value.name)}
                          </p>
                          <h5 className="mb-0 text-blacka"> {value.number} </h5>
                        </div>

                        <div className="avatar-sm ms-auto">
                          <div className="avatar-title bg-primary rounded-circle text-light">
                            <i
                              className={
                                "font-size-24 bx bx-phone-call bx bxs-contact " +
                                value?.iconClass
                              }
                            ></i>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )
            })}
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default ViewModel
