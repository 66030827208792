import config from "config"
import { formatBigNumber, wordTitleCase } from "helpers/data-formatters"
import { showFirst20Words } from "helpers/util"
import React from "react"
import { Badge } from "reactstrap"

const TitleText = cell => {
  return showFirst20Words(cell.value)
}

const NumberFormate = cell => {
  return formatBigNumber(cell.value) || ""
}

const LiveStatus = cell => {
  return !cell.row.original.isArchived ? (
    <Badge
      className={"font-size-12 badge-soft-" + (cell.value ? "success" : "dark")}
    >
      {cell.value ? "Live" : "Draft"}
    </Badge>
  ) : (
    <Badge className={"font-size-12 badge-soft-" + "info"}>Archived</Badge>
  )
}

export { TitleText, NumberFormate, LiveStatus }
