import React, { useState, useEffect } from "react"
import placeholderImg from "../../assets/images/image-placeholder.jpg"
import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"

export const LazyImageWithFallback = ({ className, src, alt, ...props }) => {
  // State to manage the image source and error status
  const [imageSrc, setImageSrc] = useState(src)
  const [imageError, setImageError] = useState(false)

  // Reset image source and error status when 'src' prop changes
  useEffect(() => {
    setImageSrc(src)
    setImageError(false)
  }, [src])

  // Function to handle image load errors and replace with a placeholder
  const handleImageError = () => {
    if (!imageError) {
      setImageSrc(placeholderImg)
      setImageError(true)
    }
  }

  return (
    <LazyLoadImage
      className={className}
      // Display the placeholder image if there is an image error
      src={imageError ? placeholderImg : imageSrc}
      // Attach the error handler to replace images with errors
      onError={handleImageError}
      // Set the image opacity to 1 if 'imageSrc' is available
      style={{ opacity: imageSrc ? 1 : 0 }}
      {...props}
      effect="blur"
      alt={alt}
    />
  )
}
export default LazyImageWithFallback
