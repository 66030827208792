import React, { useEffect, useState } from "react"

import {
  Container,
  Row,
  Col,
  Table,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  Form,
  FormGroup,
  Label,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "reactstrap"

import classnames from "classnames"
import { CmsBanner } from "./Banner"
import { convertToTitleCase, getObjectKeys } from "helpers/util"
import { CmsSection } from "./Section"
import LoadingSpinner from "components/Common/LoadingSpinner"
import { FaqForm } from "../FAQ/Form"
import { FaqTable } from "../FAQ/FaqTable"
import FaqModal from "../FAQ/Modal"
import { useFormik } from "formik"
import { useGetPagesDataQuery } from "redux/cms/api"
import { useLocation } from "react-router-dom"
import { SectionTabs } from "../Home/SectionsTab"
import { wordTitleCase } from "helpers/data-formatters"

// pass array for managing tabs

const CmsTab = ({ data, isLoading, isSuccess, tab, refetch }) => {
  const [activeTab, setactiveTab] = useState("banner")
  const [activeField, setActiveField] = useState("")
  const [tabList, setTabList] = useState([])
  const [faqId, setFaqId] = useState("") //state for managing add and edit modal by faqId
  const [modal_center, setmodal_center] = useState(false)
  const location = useLocation()
  // const { data, isLoading, isSuccess, refetch } = useGetPagesDataQuery(
  //   `faq/${faqId}`,
  // )
  useEffect(() => {
    if (data) {
      setTabList(getObjectKeys(data?.data))
    }
  }, [data])

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_center(id) {
    setmodal_center(!modal_center)
    if (id) {
      setFaqId(id) // only for edit faq data
    } else {
      setFaqId("")
    }
    removeBodyCss()
  }

  return (
    <div className="checkout-tabs">
      {isSuccess && (
        <>
          {activeTab === "faq" && (
            <FaqModal
              modal_center={modal_center}
              tog_center={tog_center}
              setmodal_center={setmodal_center}
              faqId={faqId}
              activeTab={"faqSection"}
              listRefetch={refetch}
            />
          )}
          <Row>
            <Col xxl="1" lg="2" md="2" sm="3">
              <Nav className="flex-column" pills>
                {tabList?.length > 0 && location?.pathname === "/pages/home" ? (
                  <NavItem key={"home"}>
                    <NavLink
                      className={classnames({
                        active: activeTab === "banner",
                      })}
                      onClick={() => {
                        setactiveTab("banner")
                      }}
                    >
                      <i className="bx bx-photo-album d-block check-nav-icon mt-1 mb-1" />
                      <p className="fw-bold mb-1">
                        {convertToTitleCase("banner")}
                      </p>
                    </NavLink>
                    <NavLink
                      className={classnames({
                        active: activeTab === "sections",
                      })}
                      onClick={() => {
                        setactiveTab("sections")
                      }}
                    >
                      <i className="bx bx-detail d-block check-nav-icon mt-1 mb-1" />
                      <p className="fw-bold mb-1">
                        {convertToTitleCase("section")}
                      </p>
                    </NavLink>
                  </NavItem>
                ) : (
                  tabList?.map(tabItem => (
                    <NavItem key={tabItem}>
                      <NavLink
                        className={classnames({
                          active: activeTab === tabItem,
                        })}
                        onClick={() => {
                          setactiveTab(tabItem)
                        }}
                      >
                        {tabItem === "banner" ? (
                          <i className="bx bx-photo-album d-block check-nav-icon mt-1 mb-1" />
                        ) : (
                          <i className="bx bx-detail d-block check-nav-icon mt-1 mb-1" />
                        )}
                        <p className="fw-bold mb-1">
                          {convertToTitleCase(tabItem)}
                        </p>
                      </NavLink>
                    </NavItem>
                  ))
                )}
              </Nav>
            </Col>
            <Col xxl="11" lg="10" md="10" sm="9">
              <Card>
                <CardBody>
                  <TabContent activeTab={activeTab}>
                    <TabPane
                      tabId={activeTab}
                      id="v-pills-confir"
                      role="tabpanel"
                    >
                      <div>
                        {activeTab !== "faq" && (
                          <>
                            <CardTitle>
                              {tab}: {wordTitleCase(activeTab)}
                            </CardTitle>
                            <p className="card-title-desc">
                              Fill all the below mentioned information
                            </p>
                          </>
                        )}
                        {/* Add children */}
                        {activeTab === "banner" && (
                          //Banner Form
                          <CmsBanner
                            data={data?.data}
                            activeTab={activeTab}
                            refetch={refetch}
                          />
                        )}
                        {
                          tabList.includes(activeTab) &&
                            activeTab !== "banner" &&
                            activeTab !== "faq" && (
                              //   section forms with prop[section 1 or 2]
                              <CmsSection
                                data={data?.data}
                                activeTab={activeTab}
                                refetch={refetch}
                                activeField={activeField}
                                setActiveField={setActiveField}
                              />
                            )
                          // // faq form
                          // <>{activeTab}</>
                        }
                        {activeTab === "faq" && (
                          <FaqTable
                            data={data?.data}
                            isLoading={isLoading}
                            isSuccess={isSuccess}
                            refetch={refetch}
                            tog_center={tog_center}
                            modal_center={modal_center}
                          />
                        )}
                        {activeTab === "sections" && (
                          //Home section Form
                          <SectionTabs
                            tabList={tabList}
                            data={data?.data}
                            activeTab={activeTab}
                            refetch={refetch}
                          />
                        )}
                      </div>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )}
      {isLoading && (
        <Row>
          <Col lg="5" sm="2"></Col>
          <Col lg="4" sm="2">
            {<LoadingSpinner />}
          </Col>
        </Row>
      )}
    </div>
  )
}

export default CmsTab
