import PropTypes from "prop-types"
import React, { useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap"

import { useLocation, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// import images
import resetPasswordpng from "../../assets/images/ResetPassword.png"
import { Helmet } from "react-helmet"
import { showError, showToaster } from "helpers"
import { useResetPasswordMutation } from "redux/authentication/api"

// Define the ResetPassword component
const ResetPassword = props => {
  // Get the URL search parameters
  const { search } = useLocation()
  const navigate = useNavigate()
  const [resetPassword, resetPasswordAPI] = useResetPasswordMutation()
  useEffect(() => {
    if (!search) {
      navigate("/login", { replace: true })
    }
  }, [])
  // Form validation using Formik and Yup
  const validation = useFormik({
    // enableReinitialize: use this flag when initial values need to be changed
    enableReinitialize: true,
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Please enter password")
        .matches(
          /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
          "Password should contain minimum 8 characters including 1 number, 1 alphabet, and 1 special character",
        )
        .min(
          8,
          "Password should contain minimum 8 characters including 1 number, 1 alphabet, and 1 special character",
        )
        .max(50, "Password should be less than or equal to 50 characters"),
      confirmPassword: Yup.string()
        .oneOf(
          [Yup.ref("password"), null],
          "New Password and Confirm New Password should be same",
        )
        .required("Please confirm new password"),
    }),
    onSubmit: async values => {
      const { password, ...newvalue } = values
      const value = [{ password: password }, search]
      try {
        // Call the resetPassword function to initiate password reset
        const result = await resetPassword(value).unwrap()
        showToaster("success", `${result.message}`, "Success")
        navigate("/login", { replace: true })
      } catch (error) {
        showError(error)
      }
    },
  })

  return (
    <React.Fragment>
      {/* Helmet component for setting page title */}
      <Helmet>
        <title>Reset Password | Discover Equestrian</title>
      </Helmet>

      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-dark">Reset Password</h5>
                        <p className="text-dark">
                          Enter new password below to reset the password
                        </p>
                      </div>
                    </Col>
                    <Col className="col-3  align-self-center">
                      <img
                        src={resetPasswordpng}
                        alt=""
                        className="img-fluid"
                      />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">New Password</Label>
                        <Input
                          name="password"
                          className="form-control"
                          placeholder="Enter new password"
                          type="password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            !!(
                              validation.touched.password &&
                              validation.errors.password
                            )
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback className="fs-6 mt-0" type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Confirm Password</Label>
                        <Input
                          name="confirmPassword"
                          className="form-control"
                          placeholder="Confirm new password"
                          type="password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.confirmPassword || ""}
                          invalid={
                            !!(
                              validation.touched.confirmPassword &&
                              validation.errors.confirmPassword
                            )
                          }
                        />
                        {validation.touched.confirmPassword &&
                        validation.errors.confirmPassword ? (
                          <FormFeedback className="fs-6 mt-0" type="invalid">
                            {validation.errors.confirmPassword}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          <button
                            type="submit"
                            disabled={validation.isSubmitting}
                            className={`btn btn-primary w-md ${
                              validation.isSubmitting
                                ? "cursor-wait"
                                : "cursor-pointer"
                            }`}
                          >
                            {validation.isSubmitting ? (
                              <div className="d-flex justify-content-center align-items-center">
                                <div
                                  className="spinner-border spinner-border-sm me-1"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                                <span>Sending...</span>
                              </div>
                            ) : (
                              "Reset Password"
                            )}
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>© {new Date().getFullYear()} Discover Equestrian </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// PropTypes for the component
ResetPassword.propTypes = {
  history: PropTypes.object,
}

// Export the component with withRouter HOC (Higher Order Component)
export default withRouter(ResetPassword)
