import { apiSlice } from "../api"

export const blogsApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    blogsList: builder.query({
      query: props => {
        let url = `/blog/all`
        if (props) {
          const { page = 1, limit = 10, type = "", search = "" } = props
          url = `/blog/all?${type ? "type=" + type + "&" : ""}${
            search ? "search=" + search + "&" : ""
          }page=${page}&limit=${limit}`
        }
        return {
          url: `${url} `,
          method: "GET",
        }
      },
    }),
    addBlog: builder.mutation({
      query: payload => {
        const keys = Object.keys(payload)
        var data = new FormData()
        if (keys.length > 0) {
          for (const key of keys) {
            if (payload[key] !== "" && !Array.isArray(payload[key])) {
              data.append(`${key}`, payload[key])
            } else if (Array.isArray(payload[key])) {
              payload[key].forEach((value, index) => {
                data.append(`${[key]}[${index}]`, value)
              })
            }
          }
        }
        return {
          url: "/blog",
          method: "POST",
          body: data,
        }
      },
    }),
    getBlog: builder.mutation({
      query: props => {
        return {
          url: `/blog/${props}`,
          method: "GET",
        }
      },
    }),
    updateBlog: builder.mutation({
      query: ({ payload, id }) => {
        const keys = Object.keys(payload)
        var data = new FormData()
        if (keys.length > 0) {
          for (const key of keys) {
            if (payload[key] !== "" && !Array.isArray(payload[key])) {
              data.append(`${key}`, payload[key])
            } else if (Array.isArray(payload[key])) {
              payload[key].forEach((value, index) => {
                data.append(`${[key]}[${index}]`, value)
              })
            }
          }
        }
        return {
          url: `/blog/${id}`,
          method: "PATCH",
          body: data,
        }
      },
    }),
    deleteBlog: builder.mutation({
      query: props => {
        return {
          url: `/blog/${props}`,
          method: "DELETE",
        }
      },
    }),
  }),
})
export const {
  useBlogsListQuery,
  useAddBlogMutation,
  useGetBlogMutation,
  useUpdateBlogMutation,
  useDeleteBlogMutation,
} = blogsApiSlice
