import { useFormik } from "formik"
import React, { useState } from "react"
import Dropzone from "react-dropzone"
import * as Yup from "yup"
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap"
import { asset, getImageRatio, getPath, isvalidFileSize } from "helpers/util"
import LazyImageWithFallback from "components/Common/LazyImageWithFallback"
import { createSectionInitValue, createValidationSchema } from "./formik"
import { useLocation } from "react-router-dom"
import { useUpdatePageMutation } from "redux/cms/api"
import { showError, showToaster } from "helpers"
import ImageCroperModel from "components/Common/ImageCroper/ImageCroperModel"
import config from "config"
import { TagsInput } from "react-tag-input-component"
export const CmsBanner = ({ data, activeTab, refetch }) => {
  const { pathname } = useLocation()
  const [updatePage] = useUpdatePageMutation()
  const [btnDisable, setBtnDisable] = useState(false)
  const [file, setFile] = useState([])
  const [isCropModalOpen, setIsCropModalOpen] = useState(false)
  const [uploadImage, setUploadImage] = useState([])
  const validation = useFormik({
    // enableReinitialize: use this flag when initial values need to be changed
    enableReinitialize: true,
    initialValues: createSectionInitValue(data, activeTab, pathname),
    validationSchema: createValidationSchema(Yup, activeTab, pathname),
    onSubmit: async values => {
      try {
        let apiObj = {
          pageName: getPath(pathname),
          data: values?.banner,
          section: activeTab,
        }
        if (uploadImage[0]?.file) {
          apiObj = {
            ...apiObj,
            data: { ...values?.banner, image: uploadImage[0]?.file },
            fileField: "image",
          }
        }
        setBtnDisable(true)
        const result = await updatePage(apiObj).unwrap()
        refetch()
        showToaster("success", `${result.message}`, "Success")
        setBtnDisable(false)
      } catch (error) {
        showError(error)
      }
    },
  })
  // Image Upload Process
  const handleOpenCropModal = () => {
    setIsCropModalOpen(true)
  }

  const handleCloseCropModal = (Ufile = file) => {
    setIsCropModalOpen(false)
    if (Ufile.length > 0) {
      setUploadImage([...Ufile])
    }
  }
  const handleSetSelectFiles = files => {
    if (files.length == 1) {
      if (
        !!!files.filter(val => !config.IMAGE_UPLOAD_TYPE.includes(val.type))
          .length
      ) {
        if (isvalidFileSize(files)) {
          setFile([...files])
          handleOpenCropModal()
        } else {
          showToaster(
            "error",
            "File size should be less than or equal to 5MB",
            "Error",
          )
        }
      } else {
        showToaster(
          "error",
          "Select Valid Image(only jpg, jpeg or png)",
          "Error",
        )
      }
    } else {
      showToaster("error", "Select only one file", "Error")
    }
  }
  {
    /* CMS Banner Form */
  }
  return (
    <Form
      onSubmit={e => {
        e.preventDefault()
        validation.handleSubmit()
        // return
      }}
    >
      {data?.banner?.image && (
        <FormGroup className="mb-4" row>
          <Label htmlFor="image" md="2" className="col-form-label">
            Banner Image
          </Label>
          {pathname !== "/pages/home" && (
            <Col md="10">
              <Dropzone
                name="banner.image"
                onDrop={acceptedFiles => {
                  handleSetSelectFiles(acceptedFiles)
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone">
                    <div className="dz-message needsclick" {...getRootProps()}>
                      <input {...getInputProps()} accept="image/*" />
                      <div className="dz-message needsclick">
                        <div className="mb-3">
                          <i className="display-4 text-muted bx bxs-cloud-upload" />
                        </div>
                        <h4>Drop files here or click to upload.</h4>
                        <h6 className="text-danger">
                          Maximum image size allowed is 5MB.{" "}
                        </h6>
                      </div>
                    </div>
                  </div>
                )}
              </Dropzone>
              {validation.touched.banner?.image &&
                validation.errors.banner?.image && (
                  <p className="text-danger">
                    {validation.errors.banner?.image}
                  </p>
                )}
              <div className="p-2">
                <Row className="align-items-center">
                  <Col sm="8">
                    <Col className="col-auto">
                      <LazyImageWithFallback
                        src={
                          uploadImage[0]?.preview
                            ? uploadImage[0]?.preview
                            : asset(validation?.values?.banner?.image)
                        }
                        alt="banner"
                        className="rounded bg-light w-25"
                      />
                    </Col>
                  </Col>
                </Row>
              </div>
            </Col>
          )}
        </FormGroup>
      )}
      {data?.banner?.link && (
        <FormGroup className="mb-4" row>
          <Label htmlFor="link" md="2" className="col-form-label">
            Link
          </Label>
          <Col md="10">
            <Input
              type="text"
              className="form-control"
              id="link"
              placeholder="Enter Link"
              name="banner.link"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.banner?.link || ""}
              invalid={
                !!(
                  validation.touched.banner?.link &&
                  validation.errors.banner?.link
                )
              }
            />
            {validation.touched.banner?.link &&
              validation.errors.banner?.link && (
                <FormFeedback className="fs-6 mt-0" type="invalid">
                  {validation.errors.banner?.link}
                </FormFeedback>
              )}
          </Col>
        </FormGroup>
      )}
      {data?.banner?.title && (
        <FormGroup className="mb-4" row>
          <Label htmlFor="title" md="2" className="col-form-label">
            Banner Title
          </Label>
          <Col md="10">
            <Input
              type="text"
              className="form-control"
              id="title"
              placeholder="Enter Title"
              name="banner.title"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.banner?.title || ""}
              invalid={
                !!(
                  validation.touched.banner?.title &&
                  validation.errors.banner?.title
                )
              }
            />
            {validation.touched.banner?.title &&
              validation.errors.banner?.title && (
                <FormFeedback className="fs-6 mt-0" type="invalid">
                  {validation.errors.banner?.title}
                </FormFeedback>
              )}
          </Col>
        </FormGroup>
      )}
      {(getPath(pathname) == "home" ||
        getPath(pathname) == "about-us" ||
        getPath(pathname) == "riding" ||
        getPath(pathname) == "business-service" ||
        getPath(pathname) == "faq") && (
        <div className="pt-5 ">
          <h3>Meta</h3>
          <FormGroup className="mb-4" row>
            <Label htmlFor="MetaTitle" className="col-form-label col-lg-2">
              Title
            </Label>
            <Col lg="10">
              <Input
                id="metaTitle"
                name="banner.meta.title"
                type="text"
                className="form-control"
                placeholder="Enter Meta Title"
                value={validation.values?.banner?.meta?.title || ""}
                onChange={e => {
                  validation.setFieldValue("banner.meta.title", e.target.value)
                }}
                onBlur={validation.handleBlur}
                invalid={
                  !!(
                    validation.touched?.banner?.meta?.title &&
                    validation?.errors?.banner?.meta?.title
                  )
                }
              />
              {validation?.touched?.banner?.meta?.title &&
                validation?.errors?.banner?.meta?.title && (
                  <FormFeedback className="fs-6 mt-0" type="invalid">
                    {validation?.errors?.banner?.meta?.title}
                  </FormFeedback>
                )}
            </Col>
          </FormGroup>
          <FormGroup className="mb-4" row>
            <Label
              htmlFor="banner.meta.tags"
              className="col-form-label col-lg-2 pt-3"
            >
              Tags
            </Label>
            <Col lg="10">
              <em className="text-info">
                Press enter to add multiple Meta Tags
              </em>
              <TagsInput
                value={validation.values?.banner?.meta?.tags}
                onChange={value =>
                  validation.setFieldValue("banner.meta.tags", value)
                }
                name="banner.meta.tags"
                placeHolder="Enter Meta Tag"
              />
              {/* <em>press enter to add new tag</em> */}
            </Col>
          </FormGroup>
          <FormGroup className="mb-4" row>
            <Label
              htmlFor="metaDescription"
              className="col-form-label col-lg-2"
            >
              Description
            </Label>
            <Col lg="10">
              <Input
                id="metaDescription"
                name="banner.meta.description"
                type="textarea"
                className="form-control"
                placeholder="Enter Meta description"
                value={validation?.values?.banner?.meta?.description || ""}
                onChange={e => {
                  validation.setFieldValue(
                    "banner.meta.description",
                    e.target.value,
                  )
                }}
                onBlur={validation.handleBlur}
                invalid={
                  !!(
                    validation.touched?.banner?.meta?.description &&
                    validation.errors?.banner?.meta?.description
                  )
                }
              />
              {validation.touched?.banner?.meta?.description &&
                validation.errors?.banner?.meta?.description && (
                  <FormFeedback className="fs-6 mt-0" type="invalid">
                    {validation.errors?.meta?.description}
                  </FormFeedback>
                )}
            </Col>
          </FormGroup>
        </div>
      )}
      {isCropModalOpen && (
        <ImageCroperModel
          imageArry={file}
          ratio={[{ value: getImageRatio([activeTab]), text: "" }]}
          // ratio={[{ value: config.IMAGE_RATIO?.banner, text: `${config.IMAGE_RATIO?.banner}` }]}
          onCancel={handleCloseCropModal}
          setFile={setFile}
          isOpen={isCropModalOpen}
        />
      )}
      <Row className="mt-4">
        <Col sm="12">
          <div className="text-sm-end">
            <button
              type="submit"
              className="btn btn-success me-2"
              disabled={btnDisable}
            >
              {btnDisable ? (
                <i className="bx bx-loader font-size-16 label-icon align-middle me-2">
                  {" "}
                  Loading
                </i>
              ) : (
                <i className="bx bx-check-double font-size-16 label-icon me-2 align-middle">
                  {" "}
                  Submit
                </i>
              )}
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                setUploadImage([])
                validation.resetForm()
              }}
            >
              <i className="bx bx-block font-size-16 label-icon me-2 align-middle" />{" "}
              Cancel{" "}
            </button>
          </div>
        </Col>
      </Row>
    </Form>
  )
}
