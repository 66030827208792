import { createSlice } from "@reduxjs/toolkit"
const rfaSlice = createSlice({
  name: "rfa",
  initialState: {
    list: [],
    pageInfo: { limit: 10, page: 1, totalPages: 1, totalResults: 0 },
  },
  reducers: {
    setRfaList: (state, action) => {
      const { rfa, pageInfo } = action.payload
      state.list = rfa
      state.pageInfo = pageInfo
      return
    },
  },
})

export const { setRfaList } = rfaSlice.actions

export default rfaSlice.reducer

export const selectRfaList = state => state.rfa?.list
export const selectRfaTotalRecord = state => state.rfa?.pageInfo
