import React, { useState } from "react"
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from "reactstrap"

import LazyImageWithFallback from "./LazyImageWithFallback"
import { asset, convertToEmbeddedLink } from "helpers/util"

const Slidewithindicator = ({ ImageArray = [], isFaq = false }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)

  const onExiting = () => {
    setAnimating(true)
  }

  const onExited = () => {
    setAnimating(false)
  }

  const next = () => {
    if (animating) return
    const nextIndex =
      activeIndex === ImageArray.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex =
      activeIndex === 0 ? ImageArray.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  const goToIndex = newIndex => {
    if (animating) return
    setActiveIndex(newIndex)
  }

  const slides = ImageArray.map(item => {
    if (item?.image || item?.url) {
      return (
        <CarouselItem
          onExiting={onExiting}
          onExited={onExited}
          key={isFaq ? asset(item.image) : item.url}
        >
          <div
            style={{
              width: "100%",
              backgroundColor: "#edeeef",
              minHeight: "320px",
            }}
            className="d-flex justify-content-center "
          >
            <LazyImageWithFallback
              style={{ maxHeight: "320px" }}
              src={isFaq ? asset(item.image) : item.url || ""}
              className="img-fluid"
              alt={""}
            />
          </div>
        </CarouselItem>
      )
    }
    if (item?.videoLink && convertToEmbeddedLink(item?.videoLink)) {
      return (
        <CarouselItem
          onExiting={onExiting}
          onExited={onExited}
          key={item.videoLink}
        >
          <div
            style={{
              width: "100%",
              backgroundColor: "#edeeef",
              minHeight: "320px",
            }}
            className="d-flex justify-content-center "
          >
            <iframe
              width="560"
              height="320px"
              src={`${convertToEmbeddedLink(item.videoLink)}?autoplay=1&rel=0`}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              data-src={`${convertToEmbeddedLink(
                data?.videoLink,
              )}?autoplay=1&modestbranding=1&rel=0&hl=ru&showinfo=0&color=white`}
              allowFullScreen
            ></iframe>
          </div>
        </CarouselItem>
      )
    }
  })
  return (
    ImageArray.length > 0 && (
      <Carousel activeIndex={activeIndex} next={next} previous={previous}>
        <CarouselIndicators
          className="text-dark"
          items={ImageArray}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        />
        {slides}
        <CarouselControl
          direction="prev"
          directionText="Previous"
          onClickHandler={previous}
        />
        <CarouselControl
          direction="next"
          directionText="Next"
          onClickHandler={next}
        />
      </Carousel>
    )
  )
}

export default Slidewithindicator
