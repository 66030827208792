import React, { useState } from "react"
import {
  Container,
  Row,
  Col,
  Table,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  Form,
  FormGroup,
  Label,
  CardBody,
  CardTitle,
  CardSubtitle,
  FormFeedback,
  Button,
} from "reactstrap"
import { Link, useLocation } from "react-router-dom"

import classnames from "classnames"
import Dropzone from "react-dropzone"
import {
  asset,
  getImageRatio,
  getPath,
  isvalidFileSize,
  showFirst20Words,
} from "helpers/util"
import FaqModal from "./Modal"
import LoadingSpinner from "components/Common/LoadingSpinner"
import { useFormik } from "formik"
import {
  createSectionInitValue,
  createValidationSchema,
} from "../Common/formik"
import * as Yup from "yup"
import { FaqTable } from "./FaqTable"
import ImageCroperModel from "components/Common/ImageCroper/ImageCroperModel"
import { showError, showToaster } from "helpers"
import { useUpdatePageMutation } from "redux/cms/api"
import LazyImageWithFallback from "components/Common/LazyImageWithFallback"
import config from "config"
import { Helmet } from "react-helmet"
import { TagsInput } from "react-tag-input-component"

export const FaqForm = ({ data, isLoading, isSuccess, refetch }) => {
  const { pathname } = useLocation()
  const [activeTab, setactiveTab] = useState("faqBanner")
  const [faqId, setFaqId] = useState("") //state for managing add and edit modal by faqId
  const [modal_center, setmodal_center] = useState(false)
  const [file, setFile] = useState([])
  const [isCropModalOpen, setIsCropModalOpen] = useState(false)
  const [uploadImage, setUploadImage] = useState([])
  const [btnDisable, setBtnDisable] = useState(false)

  const [updatePage] = useUpdatePageMutation()

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  function tog_center(id) {
    setmodal_center(!modal_center)
    if (id) {
      setFaqId(id) // only for edit faq data
    } else {
      setFaqId("")
    }
    removeBodyCss()
  }

  const validation = useFormik({
    // enableReinitialize: use this flag when initial values need to be changed
    enableReinitialize: true,
    initialValues: createSectionInitValue(
      data?.data,
      activeTab,
      getPath(pathname),
    ),
    validationSchema: createValidationSchema(Yup, activeTab, getPath(pathname)),
    onSubmit: async values => {
      try {
        let apiObj = {
          pageName: getPath(pathname),
          data: values?.banner,
          section: activeTab === "faqBanner" ? "banner" : "section",
        }
        if (uploadImage[0]?.file) {
          apiObj = {
            ...apiObj,
            data: { ...values?.banner, image: uploadImage[0]?.file },
            fileField: "image",
          }
        }
        setBtnDisable(true)
        const result = await updatePage(apiObj).unwrap()
        refetch()
        showToaster("success", `${result.message}`, "Success")
        setBtnDisable(false)
      } catch (error) {
        showError(error)
      }
    },
  })

  // Image Upload Process
  const handleOpenCropModal = () => {
    setIsCropModalOpen(true)
  }

  const handleCloseCropModal = (Ufile = file) => {
    setIsCropModalOpen(false)
    if (Ufile.length > 0) {
      setUploadImage([...Ufile])
    }
  }
  const handleSetSelectFiles = files => {
    if (files.length == 1) {
      if (
        !!!files.filter(val => !config.IMAGE_UPLOAD_TYPE.includes(val.type))
          .length
      ) {
        if (isvalidFileSize(files)) {
          setFile([...files])
          handleOpenCropModal()
        } else {
          showToaster(
            "error",
            "File size should be less than or equal to 5MB",
            "Error",
          )
        }
      } else {
        showToaster(
          "error",
          "Select Valid Image(only jpg, jpeg or png)",
          "Error",
        )
      }
    } else {
      showToaster("error", "Select only one file", "Error")
    }
  }
  return (
    <div className="checkout-tabs">
      <Helmet>
        <title>FAQ | Discover Equestrian</title>
      </Helmet>
      {isSuccess && (
        <>
          <FaqModal
            modal_center={modal_center}
            tog_center={tog_center}
            setmodal_center={setmodal_center}
            faqId={faqId}
            activeTab={activeTab}
            listRefetch={refetch}
          />
          <Row>
            <Col lg="2" sm="3">
              <Nav className="flex-column" pills>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "faqBanner",
                    })}
                    onClick={() => {
                      setactiveTab("faqBanner")
                    }}
                  >
                    <i className="bx bx-photo-album d-block check-nav-icon mt-4 mb-2" />
                    <p className="fw-bold mb-4">Banner</p>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "faqSection",
                    })}
                    onClick={() => {
                      setactiveTab("faqSection")
                    }}
                  >
                    <i className="bx bx-card d-block check-nav-icon mt-4 mb-2" />
                    <p className="fw-bold mb-4">FAQs</p>
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col lg="10" sm="9">
              <Card>
                <CardBody>
                  <TabContent activeTab={activeTab}>
                    {/* faq banner section */}
                    <TabPane tabId="faqBanner">
                      <div>
                        <CardTitle>FAQ's: Banner</CardTitle>
                        <p className="card-title-desc">
                          Fill all the below mentioned information
                        </p>
                        <Form
                          onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                            // return
                          }}
                        >
                          <FormGroup className="mb-4" row>
                            <Label
                              htmlFor="billing-name"
                              md="2"
                              className="col-form-label"
                            >
                              Banner Image
                            </Label>
                            <Col md="10">
                              <Dropzone
                                name="banner.image"
                                onDrop={acceptedFiles => {
                                  handleSetSelectFiles(acceptedFiles)
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div className="dropzone">
                                    <div
                                      className="dz-message needsclick"
                                      {...getRootProps()}
                                    >
                                      <input
                                        {...getInputProps()}
                                        accept="image/*"
                                      />
                                      <div className="dz-message needsclick">
                                        <div className="mb-3">
                                          <i className="display-4 text-muted bx bxs-cloud-upload" />
                                        </div>
                                        <h4>
                                          Drop files here or click to upload.
                                        </h4>
                                        <h6 className="text-danger">
                                          Maximum image size allowed is 5MB.{" "}
                                        </h6>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col sm="8">
                                    <Col className="col-auto">
                                      <LazyImageWithFallback
                                        src={
                                          uploadImage[0]?.preview
                                            ? uploadImage[0]?.preview
                                            : asset(
                                                validation?.values?.banner
                                                  ?.image,
                                              )
                                        }
                                        alt="banner"
                                        className="rounded bg-light w-25"
                                      />
                                    </Col>
                                  </Col>
                                </Row>
                              </div>
                              {validation.touched.banner?.image &&
                                validation.errors.banner?.image && (
                                  <p className="text-danger">
                                    {validation.errors.banner?.image}
                                  </p>
                                )}
                            </Col>
                          </FormGroup>
                          {data?.data?.banner?.title && (
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="title"
                                md="2"
                                className="col-form-label"
                              >
                                Banner Title
                              </Label>
                              <Col md="10">
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="title"
                                  placeholder="Enter Title"
                                  name="banner.title"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.banner?.title || ""}
                                  invalid={
                                    !!(
                                      validation.touched.banner?.title &&
                                      validation.errors.banner?.title
                                    )
                                  }
                                />
                                {validation.touched.banner?.title &&
                                  validation.errors.banner?.title && (
                                    <FormFeedback
                                      className="fs-6 mt-0"
                                      type="invalid"
                                    >
                                      {validation.errors.banner?.title}
                                    </FormFeedback>
                                  )}
                              </Col>
                            </FormGroup>
                          )}
                          <div className="pt-5">
                            <h3>Meta</h3>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="MetaTitle"
                                className="col-form-label col-lg-2"
                              >
                                Title
                              </Label>
                              <Col lg="10">
                                <Input
                                  id="metaTitle"
                                  name="banner.meta.title"
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Meta Title"
                                  value={
                                    validation.values?.banner?.meta?.title || ""
                                  }
                                  onChange={e => {
                                    validation.setFieldValue(
                                      "banner.meta.title",
                                      e.target.value,
                                    )
                                  }}
                                  onBlur={validation.handleBlur}
                                  invalid={
                                    !!(
                                      validation.touched?.banner?.meta?.title &&
                                      validation?.errors?.banner?.meta?.title
                                    )
                                  }
                                />
                                {validation?.touched?.banner?.meta?.title &&
                                  validation?.errors?.banner?.meta?.title && (
                                    <FormFeedback
                                      className="fs-6 mt-0"
                                      type="invalid"
                                    >
                                      {validation?.errors?.banner?.meta?.title}
                                    </FormFeedback>
                                  )}
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="banner.meta.tags"
                                className="col-form-label col-lg-2 pt-3"
                              >
                                Tags
                              </Label>
                              <Col lg="10">
                                <em className="text-info">
                                  Press enter to add multiple Meta Tags
                                </em>
                                <TagsInput
                                  value={validation.values?.banner?.meta?.tags}
                                  onChange={value =>
                                    validation.setFieldValue(
                                      "banner.meta.tags",
                                      value,
                                    )
                                  }
                                  name="banner.meta.tags"
                                  placeHolder="Enter Meta Tag"
                                />
                                {/* <em>press enter to add new tag</em> */}
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="metaDescription"
                                className="col-form-label col-lg-2"
                              >
                                Description
                              </Label>
                              <Col lg="10">
                                <Input
                                  id="metaDescription"
                                  name="banner.meta.description"
                                  type="textarea"
                                  className="form-control"
                                  placeholder="Enter Meta description"
                                  value={
                                    validation?.values?.banner?.meta
                                      ?.description || ""
                                  }
                                  onChange={e => {
                                    validation.setFieldValue(
                                      "banner.meta.description",
                                      e.target.value,
                                    )
                                  }}
                                  onBlur={validation.handleBlur}
                                  invalid={
                                    !!(
                                      validation.touched?.banner?.meta
                                        ?.description &&
                                      validation.errors?.banner?.meta
                                        ?.description
                                    )
                                  }
                                />
                                {validation.touched?.banner?.meta
                                  ?.description &&
                                  validation.errors?.banner?.meta
                                    ?.description && (
                                    <FormFeedback
                                      className="fs-6 mt-0"
                                      type="invalid"
                                    >
                                      {validation.errors?.meta?.description}
                                    </FormFeedback>
                                  )}
                              </Col>
                            </FormGroup>
                          </div>
                          {isCropModalOpen && (
                            <ImageCroperModel
                              imageArry={file}
                              ratio={[
                                {
                                  value: getImageRatio([
                                    activeTab === "faqBanner"
                                      ? "banner"
                                      : "section",
                                  ]),
                                  text: "",
                                },
                              ]}
                              // ratio={[{ value: config.IMAGE_RATIO?.banner, text: `${config.IMAGE_RATIO?.banner}` }]}
                              onCancel={handleCloseCropModal}
                              setFile={setFile}
                              isOpen={isCropModalOpen}
                            />
                          )}
                          <Row className="mt-4">
                            <Col sm="12">
                              <div className="text-sm-end">
                                <Button
                                  type="submit"
                                  disabled={btnDisable}
                                  className="btn btn-success me-2"
                                >
                                  {btnDisable ? (
                                    <i className="bx bx-loader font-size-16 label-icon align-middle me-2">
                                      {" "}
                                      Loading
                                    </i>
                                  ) : (
                                    <i className="bx bx-check-double font-size-16 label-icon me-2 align-middle">
                                      {" "}
                                      Submit
                                    </i>
                                  )}
                                </Button>
                                <Button
                                  type="button"
                                  onClick={() => {
                                    setUploadImage([])
                                    validation.resetForm()
                                  }}
                                  className="btn btn-danger"
                                >
                                  <i className="bx bx-block font-size-16 align-middle me-2" />{" "}
                                  Cancel{" "}
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    </TabPane>
                    {/* faq form section */}
                    <TabPane
                      tabId="faqSection"
                      id="v-pills-payment"
                      role="tabpanel"
                      aria-labelledby="v-pills-payment-tab"
                    >
                      <FaqTable
                        data={data?.data}
                        isLoading={isLoading}
                        isSuccess={isSuccess}
                        refetch={refetch}
                        tog_center={tog_center}
                        modal_center={modal_center}
                      />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )}
      {isLoading && (
        <Row>
          <Col lg="5" sm="2"></Col>
          <Col lg="4" sm="2">
            {<LoadingSpinner />}
          </Col>
        </Row>
      )}
    </div>
  )
}
