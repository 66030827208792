import LazyImageWithFallback from "components/Common/LazyImageWithFallback"
import LoadingSpinner from "components/Common/LoadingSpinner"
import { showError } from "helpers"
import { wordTitleCase } from "helpers/data-formatters"
import { convertDate } from "helpers/util"
import React, { useEffect, useState } from "react"
import { Col, Container, Modal, ModalBody, ModalHeader, Row } from "reactstrap"
import { useGetBannerAdsMutation } from "redux/bannerAds/api"

function ViewModel({ openModal, setOpenModal, id = null, setId }) {
  const [getData, getDataAPI] = useGetBannerAdsMutation()
  const [data, setData] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getData(id).unwrap()
        setData(result?.data)
      } catch (error) {
        showError(error)
      }
    }
    if (id) {
      fetchData()
    }
  }, [id])
  const toggle = () => {
    if (openModal) {
      setOpenModal(openModal => !openModal)
      setId(null)
      setData([])
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Modal
            // size="lg"
            isOpen={openModal}
            toggle={toggle}
            backdrop={"static"}
            centered={true}
          >
            <ModalHeader toggle={toggle} tag="h4" className="align-items-start">
              {data?.name}
            </ModalHeader>
            <ModalBody>
              {getDataAPI?.isLoading || getDataAPI?.isFetching ? (
                <Col lg="12" className="w-100 d-flex justify-content-center">
                  {" "}
                  <LoadingSpinner />{" "}
                </Col>
              ) : (
                <Row>
                  <Col md="12">
                    <div
                      style={{
                        width: "100%",
                        backgroundColor: "#edeeef",
                        minHeight: "350px",
                      }}
                      className="d-flex justify-content-center "
                    >
                      <LazyImageWithFallback
                        src={`${data?.image?.url}`}
                        alt=""
                        style={{ height: "350px", width: "auto" }}
                        className="img-thumbnail"
                      />
                    </div>
                  </Col>
                  <Col className="ps-3">
                    <p className="text-muted mb-0 text-break">
                      <i className="bx bx-calendar-event align-middle text-muted me-1"></i>{" "}
                      {convertDate(data?.createdAt)}
                    </p>
                    <div className="pt-2">
                      <h6 className="mb-2">
                        <i className="bx bx-user align-middle text-muted me-1"></i>{" "}
                        <b> Listed By</b>: {wordTitleCase(data?.user?.name)}
                      </h6>
                      <div>
                        <h6>
                          <b>Description: </b>
                        </h6>
                        <p
                          className="ql-editor"
                          dangerouslySetInnerHTML={{
                            __html: data?.description,
                          }}
                        ></p>
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ViewModel
