import { apiSlice } from "../api"

export const reportApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    reportList: builder.query({
      query: props => {
        let url = `/reports`
        if (props) {
          const {
            page = 1,
            limit = 10,
            status = "",
            type = "",
            search = "",
          } = props?.state
          url = `/reports?${type ? "type=" + type + "&" : ""}${
            search ? "search=" + search + "&" : ""
          }page=${page}&limit=${limit}`
        }
        return {
          url: `${url} `,
          method: "GET",
        }
      },
    }),
  }),
})
export const { useReportListQuery } = reportApiSlice
