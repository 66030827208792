import React, { useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// Redux
import { useSelector } from "react-redux"
import { selectCurrentUser } from "redux/authentication/slice"
import { useUpdateProfileMutation } from "redux/authentication/api"
import { showError, showToaster } from "helpers"

function AccountDetails({ activeTab, setLoad, load }) {
  const user = useSelector(selectCurrentUser)
  const [updateDetail, updateDetailAPI] = useUpdateProfileMutation()
  useEffect(() => {
    formik.resetForm()
  }, [activeTab])
  // Formik setup
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: user?.name ?? "",
      // contact: user?.contact ?? "",
      // dob: user?.dob
      //   ? new Date(user?.dob)?.toISOString()?.substring(0, 10)
      //   : "",
      email: user?.email ?? "",
      // address: user?.address ?? "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required("Name is required")
        .max(50, "Name should be less than or equal to 50 characters."),
      // contact: Yup.string().required("Contact is required"),
      // dob: Yup.date().required("Date of Birth is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      // address: Yup.string().required("Address is required"),
    }),
    onSubmit: async values => {
      try {
        // Call the updateDetail function to update user details
        const result = await updateDetail(values).unwrap()
        setLoad(load => !load)
        showToaster("success", `${result.message}`, "Success")
      } catch (error) {
        showError(error)
      }
    },
  })

  return (
    <Row>
      <Col xl="6">
        <h4 className="card-title my-4">Account Details</h4>
        <Card>
          <CardBody>
            <Form
              className="form-horizontal"
              onSubmit={e => {
                e.preventDefault()
                formik.handleSubmit()
                return false
              }}
            >
              <div className="mb-3">
                <Label className="form-label">Name</Label>
                <Input
                  name="name"
                  className="form-control"
                  placeholder="Enter name"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name || ""}
                  invalid={!!(formik.touched.name && formik.errors.name)}
                />
                {formik.touched.name && formik.errors.name ? (
                  <FormFeedback className="fs-6 mt-0" type="invalid">
                    {formik.errors.name}
                  </FormFeedback>
                ) : null}
              </div>
              {/* <div className="mb-3">
                <Label className="form-label">Contact</Label>
                <Input
                  name="contact"
                  className="form-control"
                  placeholder="Enter contact"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.contact || ""}
                  invalid={!!(formik.touched.contact && formik.errors.contact)}
                />
                {formik.touched.contact && formik.errors.contact ? (
                   <FormFeedback className="fs-6 mt-0"  type="invalid">
                    {formik.errors.contact}
                  </FormFeedback>
                ) : null}
              </div> */}
              {/* <div className="mb-3">
                <Label className="form-label"> Date of Birth</Label>
                <Input
                  name="dob"
                  className="form-control"
                  placeholder="Enter dob"
                  type="date"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.dob || ""}
                  invalid={!!(formik.touched.dob && formik.errors.dob)}
                />
                {formik.touched.dob && formik.errors.dob ? (
                   <FormFeedback className="fs-6 mt-0"  type="invalid">
                    {formik.errors.dob}
                  </FormFeedback>
                ) : null}
              </div> */}
              <div className="mb-3">
                <Label className="form-label">Email</Label>
                <Input
                  name="email"
                  className="form-control"
                  placeholder="Enter email"
                  type="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email || ""}
                  invalid={!!(formik.touched.email && formik.errors.email)}
                />
                {formik.touched.email && formik.errors.email ? (
                  <FormFeedback className="fs-6 mt-0" type="invalid">
                    {formik.errors.email}
                  </FormFeedback>
                ) : null}
              </div>
              {/* <div className="mb-3">
                <Label className="form-label">Address</Label>
                <Input
                  name="address"
                  className="form-control"
                  placeholder="Enter address"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address || ""}
                  invalid={!!(formik.touched.address && formik.errors.address)}
                />
                {formik.touched.address && formik.errors.address ? (
                   <FormFeedback className="fs-6 mt-0"  type="invalid">
                    {formik.errors.address}
                  </FormFeedback>
                ) : null}
              </div> */}
              <Row className="mb-3">
                <Col className="text-end">
                  <button
                    type="submit"
                    disabled={
                      formik.isSubmitting ||
                      formik.initialValues === formik.values
                    }
                    className={`btn btn-primary w-md ${
                      formik.isSubmitting ? "cursor-wait" : "cursor-pointer"
                    }`}
                  >
                    {formik.isSubmitting ? (
                      <div className="d-flex justify-content-center align-items-center">
                        <div
                          className="spinner-border spinner-border-sm me-1"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                        <span>Updating...</span>
                      </div>
                    ) : (
                      "Update"
                    )}
                  </button>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default AccountDetails
