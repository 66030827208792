import { apiSlice } from "../api"

export const bannerAdsApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    bannerAdsList: builder.query({
      query: props => {
        let url = `/banners`
        if (props) {
          const {
            page = 1,
            limit = 10,
            status = "",
            search = "",
          } = props?.state
          const userID = props?.userId
          url = `/banners?${status ? "status=" + status + "&" : ""}${
            search ? "search=" + search + "&" : ""
          }page=${page}&limit=${limit}`
          if (userID) {
            url = `/banners/user/${userID}?${status ? "status=" + status + "&" : ""}${
              search ? "search=" + search + "&" : ""
            }page=${page}&limit=${limit}`
          }
        }
        return {
          url: `${url} `,
          method: "GET",
        }
      },
    }),
    addBannerAds: builder.mutation({
      query: payload => {
        const keys = Object.keys(payload)
        var data = new FormData()
        if (keys.length > 0) {
          for (const key of keys) {
            if (key) {
              data.append(`${key}`, payload[key])
            }
          }
        }
        return {
          url: "/banners",
          method: "POST",
          body: data,
        }
      },
    }),
    getBannerAds: builder.mutation({
      query: props => {
        return {
          url: `/banners/${props}`,
          method: "GET",
        }
      },
    }),
    updateBannerAds: builder.mutation({
      query: payload => {
        const keys = Object.keys(payload.value)
        var data = new FormData()
        if (keys.length > 0) {
          for (const key of keys) {
            if (key && key !== "user") {
              data.append(`${key}`, payload.value[key])
            }
          }
        }
        return {
          url: `/banners/${payload.id}`,
          method: "PATCH",
          body: data,
        }
      },
    }),
    deleteBannerAds: builder.mutation({
      query: props => {
        return {
          url: `/banners/${props}`,
          method: "DELETE",
        }
      },
    }),
  }),
})
export const {
  useBannerAdsListQuery,
  useAddBannerAdsMutation,
  useGetBannerAdsMutation,
  useUpdateBannerAdsMutation,
  useDeleteBannerAdsMutation,
} = bannerAdsApiSlice
