import React, { useEffect, useMemo, useState } from "react"

//Import Breadcrumb
import CustomTable from "components/Common/Table/CustomeTable"
import { useDispatch, useSelector } from "react-redux"
import {
  SweetAlert,
  authentiCateUserPermission,
  confirmAlert,
  showError,
  showToaster,
} from "helpers"
import { useBlogsListQuery, useDeleteBlogMutation } from "redux/newsBlogs/api"
import {
  selectBlogsList,
  selectBlogsTotalRecord,
  setblogsList,
} from "redux/newsBlogs/slice"
import { SelectColumnFilter } from "./Filter"
import { Description, Tag, TitleText, Type } from "./ColumnFormatters"
import { Link } from "react-router-dom"
import { UncontrolledTooltip } from "reactstrap"
import {
  selectCurrentUser,
  selectPermissions,
} from "redux/authentication/slice"
function List() {
  const data = useSelector(selectBlogsList)
  const pageInfo = useSelector(selectBlogsTotalRecord)
  const [deleteBlog, deleteBlogAPI] = useDeleteBlogMutation()
  const user = useSelector(selectCurrentUser)
  const userPermissions = useSelector(selectPermissions)
  const [state, setState] = useState({
    search: undefined,
    type: null,
    limit: 10,
    page: 1,
    status: null,
  })
  const [load, setLoad] = useState(false)
  const dispatch = useDispatch()
  const onDelete = async id => {
    try {
      const confirmationMessage = `You want to delete this News & Blog!`
      const confirmation = await confirmAlert(
        confirmationMessage,
        "warning",
        "Yes, delete it!",
      )
      if (confirmation.isConfirmed) {
        const result = await deleteBlog(id).unwrap()
        setLoad(load => !load)
        SweetAlert(`${result.message}`, "success")
      }
    } catch (error) {
      showError(error)
    }
  }
  const columns = useMemo(() => [
    {
      Header: "Id",
      accessor: "id",
      // filter: false,
      Cell: cellProps => {
        return <div> {cellProps.row.original.id} </div>
      },
    },
    {
      Header: "Title",
      accessor: "title",
      filter: false,
      filterable: false,
      disableFilters: true,
      disableSortBy: true,
      Cell: cellProps => {
        return <TitleText {...cellProps} />
      },
    },
    {
      Header: "Category",
      accessor: "category.name",
      filter: false,
      filterable: false,
      disableFilters: true,
      disableSortBy: true,
      Cell: cellProps => {
        return <TitleText {...cellProps} />
      },
    },
    {
      Header: "Tag",
      accessor: "keywords",
      filter: false,
      filterable: false,
      maxSize: 500,
      disableFilters: true,
      disableSortBy: true,
      Cell: cellProps => {
        return <Tag {...cellProps} />
      },
    },
    // {
    //     Header: "Category",
    //     accessor: "category",
    //     filter: false,
    //     filterable: false,
    //     disableFilters: true,
    //     disableSortBy: true,
    //     Cell: cellProps => {
    //         return <TitleText {...cellProps} />
    //     },
    // },
    {
      Header: "Type",
      accessor: "mediaType",
      maxSize: 200, // add size in px
      filter: "equals", // Set the filter property to specify filter type
      // Filter: SelectColumnFilter, // Specify the custom filter component
      disableFilters: true, // Enable filtering for the column
      disableSortBy: true,
      Cell: cellProps => {
        return <Type {...cellProps} />
      },
    },
    {
      Header: "Action",
      accessor: "action",
      disableFilters: true,
      disableSortBy: true,
      Cell: cellProps => {
        return (
          <div className="d-flex gap-2">
            {authentiCateUserPermission(
              ["updateBlog"],
              userPermissions,
              user?.role,
            ) && (
              <Link
                to={`/news&blogs/edit/${cellProps.row.original.id}`}
                className="text-success"
              >
                <i
                  className="mdi mdi-lead-pencil font-size-18"
                  id={`edit${cellProps.row.original.id}`}
                />
                <UncontrolledTooltip
                  placement="top"
                  target={`edit${cellProps.row.original.id}`}
                >
                  Edit
                </UncontrolledTooltip>
              </Link>
            )}
            {authentiCateUserPermission(
              ["listBlog"],
              userPermissions,
              user?.role,
            ) && (
              <Link
                to={`/news&blogs/${cellProps.row.original.id}`}
                className="text-info"
              >
                <i
                  className="mdi mdi-eye font-size-18"
                  id={`view${cellProps.row.original.id}`}
                />
                <UncontrolledTooltip
                  placement="top"
                  target={`view${cellProps.row.original.id}`}
                >
                  View
                </UncontrolledTooltip>
              </Link>
            )}
            {authentiCateUserPermission(
              ["deleteBlog"],
              userPermissions,
              user?.role,
            ) && (
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const user = cellProps.row.original
                  onDelete(user.id)
                }}
              >
                {" "}
                <i
                  className="mdi mdi-delete font-size-18"
                  id={`delete${cellProps.row.original.id}`}
                />
                <UncontrolledTooltip
                  placement="top"
                  target={`delete${cellProps.row.original.id}`}
                >
                  Delete
                </UncontrolledTooltip>
              </Link>
            )}
          </div>
        )
      },
    },
  ])
  const list = useBlogsListQuery(state)

  if (list.isSuccess) {
    dispatch(setblogsList(list.data.data))
  }

  if (list.isError) {
    showError(list.error)
  }

  useEffect(() => {
    list.refetch()

    return () => {
      dispatch(
        setblogsList({
          list: [],
          pageInfo: { limit: 10, page: 1, totalPages: 1, totalResults: 0 },
        }),
      )
    }
  }, [state, load])
  return (
    <React.Fragment>
      <CustomTable
        data={data}
        list={list}
        totalRecords={pageInfo?.totalResults}
        stateAPI={state}
        setStateAPI={setState}
        columns={columns}
      />
    </React.Fragment>
  )
}

export default List
