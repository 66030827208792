import { apiSlice } from "../api"

export const settingSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getSettings: builder.query({
      query: settingKey => {
        let url = `/settings/${settingKey}`
        return {
          url: `${url}`,
          method: "GET",
        }
      },
    }),
    updateSettings: builder.mutation({
      query: ({ settingKey, payload }) => {
        return {
          url: `/settings/${settingKey}`,
          method: "PATCH",
          body: payload,
        }
      },
    }),
  }),
})
export const { useGetSettingsQuery, useUpdateSettingsMutation } = settingSlice
