import React, { useEffect, useState } from "react"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import { Helmet } from "react-helmet"
import { showError } from "helpers"
import {
  convertToEmbeddedLink,
  isValidVideoUrl,
  youtubeLinkToThumbnailUrl,
} from "helpers/util"
import { useGetpropertyDataMutation } from "redux/property/api"
import "./Index.scss"
import classnames from "classnames"
import { formatBigNumber, wordTitleCase } from "helpers/data-formatters"
import LazyImageWithFallback from "components/Common/LazyImageWithFallback"
import LoadingSpinner from "components/Common/LoadingSpinner"
const PropertyDetails = () => {
  const { id } = useParams()
  const [getData, getDataAPI] = useGetpropertyDataMutation()
  const [data, setData] = useState([])
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const reviewPage = pathname.includes("review")
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getData(id).unwrap()
        setData(result?.data)
      } catch (error) {
        navigate("/properties/list")
        showError(error)
      }
    }
    if (id) {
      fetchData()
    }
  }, [id])
  const BreadcrumbDetails = {
    breadcrumbItem: reviewPage
      ? {
          label: `${data?.title}`,
          link: `review-properties/list/${data?.title}`,
        }
      : {
          label: `${data?.title}`,
          link: `properties/list/${data?.title}`,
        },
    paths: reviewPage
      ? [
          {
            label: "Properties",
            link: "review-properties/list",
          },
          {
            label: "Review",
            link: "review-properties/list",
          },
        ]
      : [
          {
            label: "Properties",
            link: "properties/list",
          },
          {
            label: "List",
            link: "properties/list",
          },
        ],
  }
  const BreadcrumbDetails2 = {
    breadcrumbItem: {
      label: `${data?.title}`,
      link: `user/property/${id}`,
    },
    paths: [
      {
        label: "User",
        link: `user/property/${id}`,
      },
      {
        label: "Property",
        link: `user/property/${id}`,
      },
    ],
  }
  const [activeTab, setActiveTab] = useState(0)
  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }
  const goToPreviousPath = () => {
    navigate(-1) // This will navigate to the previous path
  }
  return (
    <React.Fragment>
      <Helmet>
        <title>Property Detail | Discover Equestrian</title>
      </Helmet>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            breadcrumbDetails={
              pathname.includes("user") ? BreadcrumbDetails2 : BreadcrumbDetails
            }
          />
          {getDataAPI?.isLoading || getDataAPI?.isFetching ? (
            <Col lg="12" className="w-100 d-flex justify-content-center">
              {" "}
              <LoadingSpinner />{" "}
            </Col>
          ) : (
            <Row>
              <Col>
                <Card>
                  <CardBody style={{ minHeight: "85vh" }}>
                    <Link
                      // to={"/properties/list"}
                      onClick={() => goToPreviousPath()}
                      className="badge bg-white text-muted font-size-12 pb-5"
                    >
                      <i className="bx bx-left-arrow-circle  font-size-24 align-middle text-muted me-1"></i>{" "}
                      Back
                    </Link>
                    <Row>
                      <Col xl={6}>
                        <div className="product-detai-imgs">
                          <Row className="border">
                            <Col
                              md={2}
                              sm={3}
                              className="col-4 overflow-auto images-responsive"
                              style={{ height: "350px", overflowY: "scroll" }}
                            >
                              <Nav className="flex-column" pills>
                                {!!convertToEmbeddedLink(data?.videoLink) &&
                                  !!convertToEmbeddedLink(data?.videoLink) && (
                                    <NavItem>
                                      <NavLink
                                        className={classnames({
                                          active: activeTab === "video",
                                        })}
                                        onClick={() => {
                                          toggleTab("video")
                                        }}
                                      >
                                        <div style={{ position: "relative" }}>
                                          {/* Thumbnail Image */}
                                          {isValidVideoUrl(data.videoLink) ? (
                                            <video
                                              className="img-fluid mx-auto d-block rounded"
                                              title="YouTube video player"
                                              // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                              allowfullscreen
                                              // controls
                                            >
                                              <source
                                                src={data.videoLink}
                                                type="video/mp4"
                                              />
                                            </video>
                                          ) : (
                                            <LazyImageWithFallback
                                              src={
                                                data?.videoLink
                                                  ? `${youtubeLinkToThumbnailUrl(
                                                      data?.videoLink,
                                                    )}`
                                                  : "placeholder"
                                              }
                                              alt=""
                                              className="img-fluid mx-auto d-block rounded"
                                            />
                                            // <img
                                            //   src={`${youtubeLinkToThumbnailUrl(
                                            //     data?.videoLink,
                                            //   )}`}
                                            //   alt="video"
                                            //   className="img-fluid mx-auto d-block rounded"
                                            // />
                                          )}

                                          {/* Video Icon */}
                                          <div
                                            style={{
                                              position: "absolute",
                                              top: "50%",
                                              left: "50%",
                                              transform:
                                                "translate(-50%, -50%)",
                                            }}
                                          >
                                            {/* You can replace the following icon with your own video icon */}
                                            <i className="bx bx bx-play-circle  font-size-24 align-middle text-black me-1"></i>{" "}
                                          </div>
                                        </div>
                                      </NavLink>
                                    </NavItem>
                                  )}
                                {data?.images?.map((val, index) => {
                                  return (
                                    <NavItem key={index}>
                                      <NavLink
                                        className={classnames({
                                          active: activeTab === index,
                                        })}
                                        onClick={() => {
                                          toggleTab(index)
                                        }}
                                      >
                                        <LazyImageWithFallback
                                          src={
                                            val?.url
                                              ? `${val.url}`
                                              : "placeholder"
                                          }
                                          alt=""
                                          className="img-fluid mx-auto d-block rounded"
                                        />
                                      </NavLink>
                                    </NavItem>
                                  )
                                })}
                              </Nav>
                            </Col>
                            <Col md={7} sm={9} className="offset-md-1 col-8">
                              <TabContent activeTab={activeTab}>
                                {data?.images?.map((val, index) => {
                                  return (
                                    <TabPane tabId={index} key={index}>
                                      <div className="">
                                        <LazyImageWithFallback
                                          src={val?.url}
                                          alt=""
                                          id={`expandedImg+${index}`}
                                          style={{
                                            height: "350px",
                                            width: "auto",
                                            maxWidth: "100%",
                                          }}
                                          className="object-fit-contain rounded"
                                        />
                                      </div>
                                    </TabPane>
                                  )
                                })}
                                {!!convertToEmbeddedLink(data?.videoLink) && (
                                  <TabPane tabId={"video"}>
                                    <div className="">
                                      {isValidVideoUrl(data.videoLink) ? (
                                        <video
                                          width="560"
                                          height="350"
                                          title="YouTube video player"
                                          // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                          allowFullScreen
                                          controls
                                        >
                                          <source
                                            src={data.videoLink}
                                            type="video/mp4"
                                          />
                                        </video>
                                      ) : (
                                        <iframe
                                          width="560"
                                          height="350"
                                          src={`${convertToEmbeddedLink(
                                            data?.videoLink,
                                          )}?autoplay=1&rel=0`}
                                          title="YouTube video player"
                                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                          data-src={`${convertToEmbeddedLink(
                                            data?.videoLink,
                                          )}?autoplay=1&modestbranding=1&rel=0&hl=ru&showinfo=0&color=white`}
                                          allowFullScreen
                                        ></iframe>
                                      )}
                                    </div>
                                  </TabPane>
                                )}
                              </TabContent>
                            </Col>
                          </Row>
                        </div>
                      </Col>

                      <Col xl="6" className="border">
                        <div className="mt-4 mt-xl-3">
                          <div className="d-flex gap-3">
                            <h4 className="mt-1 mb-3">{data?.title}</h4>
                          </div>

                          <p className="text-muted mb-4">
                            <i className="bx bx-map  font-size-24 align-middle text-danger me-1" />
                            {data?.address}
                          </p>
                          <Row>
                            <h6 className="mb-4 col-6">
                              <b> Type </b>: For {wordTitleCase(data?.type)}
                            </h6>
                            <h6 className="mb-4 col-6">
                              <b> Listed By</b>:{" "}
                              {wordTitleCase(data?.user?.name)}
                            </h6>
                          </Row>
                          <Row>
                            <h6 className="mb-4 col-6">
                              <b> Price ( € )</b>:{" "}
                              {formatBigNumber(data?.price)}
                            </h6>
                            <h6 className="mb-4 col-6">
                              <b> Area</b>: {formatBigNumber(data?.area)}{" "}
                              (Acres)
                            </h6>
                          </Row>
                          <Row>
                            <h6 className="mb-4 col-3">
                              <b> Number of Bads</b>:{" "}
                              {formatBigNumber(data?.beds)}
                            </h6>
                            <h6 className="mb-4 col-12">
                              <b> Number of BathRooms</b>:{" "}
                              {formatBigNumber(data?.bathrooms)}
                            </h6>
                          </Row>

                          <div className="control-group d-flex flex-wrap gap-3">
                            <div className="form-check mb-2">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="Stable"
                                checked={data?.stable || false}
                                onChange={() => {}}
                                // disabled // Disable onChange for static data
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="Stable"
                              >
                                Stables
                              </Label>
                            </div>
                            <div className="form-check mb-2">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="OutBuilding"
                                checked={data?.outBuilding || false}
                                onChange={() => {}}
                                // disabled // Disable onChange for static data
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="OutBuilding"
                              >
                                Outbuilding(s)
                              </Label>
                            </div>
                            <div className="form-check mb-2">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="Manege"
                                checked={data?.manage || false}
                                onChange={() => {}}
                                // disabled // Disable onChange for static data
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="Manege"
                              >
                                Manège
                              </Label>
                            </div>
                            <div className="form-check mb-2">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="Annex"
                                checked={data?.annex || false}
                                onChange={() => {}}
                                // disabled // Disable onChange for static data
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="Annex"
                              >
                                Annex
                              </Label>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xl="12" className="pt-5 ps-5">
                        <Row>
                          <h5 className="mb-4">
                            <b>Description :</b>
                          </h5>
                          <div
                            className="ql-editor"
                            dangerouslySetInnerHTML={{
                              __html: data?.description,
                            }}
                          />
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PropertyDetails
