import { createSlice } from "@reduxjs/toolkit"

// import { useNavigate } from "react-router-dom";
const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    counts: {},
    analytics: {},
  },
  reducers: {
    setdashboard: (state, action) => {
      const { counts, analytics } = action.payload
      state.counts = counts
      state.analytics = analytics

      return
    },
  },
})

export const { setdashboard } = dashboardSlice.actions

export default dashboardSlice.reducer

export const selectDashboardCount = state => state.dashboard?.counts
export const selectDashboardAnalytics = state => state.dashboard?.analytics
