import { formatBigNumber } from "helpers/data-formatters"
import React from "react"

export const TableRangeFooterLabel = ({
  pageIndex,
  pageSize,
  totalRecords,
  label,
}) => {
  const from = totalRecords > 0 ? pageIndex * pageSize + 1 : 0
  const to =
    (pageIndex + 1) * pageSize > totalRecords
      ? totalRecords
      : (pageIndex + 1) * pageSize
  return (
    <>
      Showing {from} to {to} {label ? label : "records"} out of{" "}
      {formatBigNumber(totalRecords)}
    </>
  )
}
