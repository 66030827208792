import React from "react"
import { formatDate } from "helpers/data-formatters"
import {
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"

function ViewModel({ openModal, toggle, data = {} }) {
  return (
    <React.Fragment>
      <Modal
        size="lg"
        isOpen={openModal}
        toggle={toggle}
        backdrop={"static"}
        centered={true}
      >
        <ModalHeader className="text-primary" toggle={toggle} tag="h4">
          {"Message"}
        </ModalHeader>
        <ModalBody style={{ maxHeight: "70vh", overflow: "auto" }}>
          <Card>
            <CardBody>
              <div>
                <Row className="">
                  <Col className="text-start">
                    <p className="fw-bold m-0 px-0 py-2">Full Name</p>
                    <p className="">{data.name}</p>
                  </Col>
                  <Col className="text-start">
                    <p className="fw-bold m-0 px-0 py-2">Email</p>
                    <p className="">{data.email}</p>
                  </Col>
                  <Col className="item-end ps-3">
                    <p className="fw-bold m-0 text-start px-0 py-2">Date</p>
                    <p className="">{formatDate(new Date(data.createdAt))}</p>
                  </Col>
                </Row>
                <p className="fw-bold m-0 px-0 py-2 d-flex justify-content-between">
                  Message:
                </p>
                <p className="  p-2">{data.message}</p>
              </div>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default ViewModel
