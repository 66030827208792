import { createSlice } from "@reduxjs/toolkit"

// import { useNavigate } from "react-router-dom";
const usersSlice = createSlice({
  name: "users",
  initialState: {
    list: [],
    pageInfo: { limit: 10, page: 1, totalPages: 1, totalResults: 0 },
  },
  reducers: {
    setUserList: (state, action) => {
      const { users, pageInfo } = action.payload
      state.list = users
      state.pageInfo = pageInfo
      return
    },
  },
})

export const { setUserList } = usersSlice.actions

export default usersSlice.reducer

export const selectUsersList = state => state.users?.list
export const selectUsersPageInfo = state => state.users?.pageInfo
