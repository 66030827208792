import { apiSlice } from "../api"

export const userSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    userList: builder.query({
      query: props => {
        let url = `users/all`
        if (props?.searchValue) {
          url = `users/list?search=${props?.searchValue}${props?.pageValue > 1 ? "&page=" + props?.pageValue : ""}`
        }
        if (props && props?.page) {
          const { page = 1, limit = 10, search = "", type = "active" } = props

          url = `users/all?page=${page}&limit=${limit}${
            search != "" ? "&search=" + search : ""
          }${type || type == null ? (type == null ? "&type=active" : "&type=" + type) : ""}`
        }
        return {
          url: `${url}`,
          method: "GET",
        }
      },
    }),
    addUser: builder.mutation({
      query: payload => {
        return {
          url: "/users",
          method: "POST",
          body: payload,
        }
      },
    }),
    getUser: builder.mutation({
      query: props => {
        return {
          url: `/users/${props}`,
          method: "GET",
        }
      },
    }),
    updateUser: builder.mutation({
      query: payload => {
        return {
          url: `/users/${payload.id}`,
          method: "PATCH",
          body: payload.value,
        }
      },
    }),
    deleteUser: builder.mutation({
      query: props => {
        return {
          url: `/users/${props}`,
          method: "DELETE",
        }
      },
    }),
    updateUserProfileImg: builder.mutation({
      query: ({ payload, id }) => {
        const data = new FormData()
        data.append("profileImage", payload)
        return {
          url: `/users/profile-picture/${id}`,
          method: "PUT",
          body: data,
        }
      },
    }),
    removeProfileImg: builder.mutation({
      query: ({ id }) => {
        return {
          url: `/users/profile-picture/remove/${id}`,
          method: "PUT",
        }
      },
    }),
    banUser: builder.mutation({
      query: payload => {
        return {
          url: `/users/ban`,
          method: "PUT",
          body: { ...payload },
        }
      },
    }),
    logoutAllDevice: builder.mutation({
      query: payload => {
        return {
          url: `/auth/logout-all-devices/${payload}`,
          method: "DELETE",
        }
      },
    }),
  }),
})
export const {
  useUserListQuery,
  useAddUserMutation,
  useGetUserMutation,
  useUpdateUserMutation,
  useUpdateUserProfileImgMutation,
  useRemoveProfileImgMutation,
  useDeleteUserMutation,
  useBanUserMutation,
  useLogoutAllDeviceMutation,
} = userSlice
