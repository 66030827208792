import React from "react"
export const SelectColumnFilterStatus = ({ column }) => {
  const { filterValue, setFilter } = column
  return (
    <select
      id="custom-select"
      className="form-select"
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value="">All</option>
      <option value="draft">Draft</option>
      <option value="live">Live</option>
    </select>
  )
}
