import React from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import ReactApexChart from "react-apexcharts"
import { formatBigNumber } from "helpers/data-formatters"
import Flatpickr from "react-flatpickr"
const Analytics = ({
  handeClick,
  duration,
  flatpickrRef,
  selectedRange,
  handleDateChange,
  handelOpen,
  series,
  options,
  chartData,
}) => {
  return (
    <React.Fragment>
      <Col xl={12}>
        <Card>
          <CardBody>
            <Row>
              <div className="clearfix">
                <h4 className="card-title mb-4 float-start">
                  {" "}
                  <i
                    className={"font-size-24 mdi mdi-google-analytics "}
                  ></i>{" "}
                  Analytics{" "}
                </h4>
                <div className="float-end">
                  <div className="input-group input-group-sm ">
                    <div className="toolbar d-flex flex-wrap gap-2 text-end">
                      <button
                        type="button"
                        className={`btn btn-light btn-sm ${
                          duration == "all" ? "active" : ""
                        }`}
                        onClick={() => handeClick("all")}
                      >
                        ALL
                      </button>{" "}
                      <button
                        type="button"
                        className={`btn btn-light btn-sm ${
                          duration == "thisMonth" ? "active" : ""
                        }`}
                        onClick={() => handeClick("thisMonth")}
                      >
                        1M
                      </button>{" "}
                      <button
                        type="button"
                        className={`btn btn-light btn-sm me-1 ${
                          duration == "last6Months" ? "active" : ""
                        }`}
                        onClick={() => handeClick("last6Months")}
                      >
                        6M
                      </button>{" "}
                      <button
                        type="button"
                        className={`btn btn-light btn-sm ${
                          duration == "thisYear" ? "active" : ""
                        }`}
                        onClick={() => handeClick("thisYear")}
                      >
                        1Y
                      </button>{" "}
                    </div>
                    {/* <Flatpickr
                      className="form-control d-block ms-2"
                      placeholder="Search By Date Range"
                      ref={flatpickrRef}
                      value={selectedRange}
                      onOpen={handelOpen}
                      onChange={handleDateChange}
                      options={{
                        // Flatpickr options here (e.g., dateFormat, minDate, maxDate, etc.)
                        mode: "range",
                        maxDate: null,
                        showMonths: 2,
                        dateFormat: "YYYY-MM-DD",
                        type: "range",
                        enableTime: false,
                        altInput: true,
                      }}
                    /> */}
                  </div>
                </div>
              </div>
              <Col xl={12}>
                <Row className="text-center">
                  <Col>
                    <div className="mt-4">
                      <p className="text-muted mb-1">Total Properties Visits</p>
                      <h5>{formatBigNumber(chartData.totalPropertiesClick)}</h5>
                    </div>
                  </Col>
                  <Col>
                    <div className="mt-4">
                      <p className="text-muted mb-1">
                        Total Property Impressions
                      </p>
                      <h5>
                        {formatBigNumber(chartData.totalPropertyImpressions)}
                      </h5>
                    </div>
                  </Col>
                  <Col>
                    <div className="mt-4">
                      <p className="text-muted mb-1">
                        Total Businesses & Services Visits
                      </p>
                      <h5>{formatBigNumber(chartData.totalAdvertsClick)}</h5>
                    </div>
                  </Col>
                  <Col>
                    <div className="mt-4">
                      <p className="text-muted mb-1">Total Adverts Visits</p>
                      <h5>{formatBigNumber(chartData.totalBannersClick)}</h5>
                    </div>
                  </Col>
                </Row>
                <hr className="mb-4" />
                <Row>
                  <Col>
                    <div>
                      <div id="area-chart" dir="ltr">
                        <ReactApexChart
                          options={options}
                          series={series}
                          type="area"
                          height="350"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default Analytics
