import { useFormik } from "formik"
import React, { useState } from "react"
import Dropzone from "react-dropzone"
import * as Yup from "yup"
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap"
import { asset, getImageRatio, isvalidFileSize } from "helpers/util"
import LazyImageWithFallback from "components/Common/LazyImageWithFallback"
import { useGetPagesDataQuery, useUpdatePageMutation } from "redux/cms/api"
import { showError, showToaster } from "helpers"
import ImageCroperModel from "components/Common/ImageCroper/ImageCroperModel"
import config from "config"
export const AdvertiseBanner = ({ activeTab }) => {
  const { data, isLoading, isSuccess, refetch } =
    useGetPagesDataQuery("advertise-with-us")
  const [updatePage] = useUpdatePageMutation()
  const [btnDisable, setBtnDisable] = useState(false)
  const [file, setFile] = useState([])
  const [isCropModalOpen, setIsCropModalOpen] = useState(false)
  const [uploadImage, setUploadImage] = useState([])
  const validation = useFormik({
    // enableReinitialize: use this flag when initial values need to be changed
    enableReinitialize: true,
    initialValues: {
      banner: {
        title: data?.data?.banner?.title ? data?.data?.banner?.title : "",
        image: data?.data?.banner?.image ? data?.data?.banner?.image : "",
      },
    },
    validationSchema: Yup.object({
      banner: Yup.object({
        image: Yup.string().required("Image is required"),
        title: Yup.string().required("Title is required"),
      }),
    }),
    onSubmit: async values => {
      try {
        let apiObj = {
          pageName: "advertise-with-us",
          data: values?.banner,
          section: "banner",
        }
        if (uploadImage[0]?.file) {
          apiObj = {
            ...apiObj,
            data: { ...values?.banner, image: uploadImage[0]?.file },
            fileField: "image",
          }
        }
        setBtnDisable(true)
        const result = await updatePage(apiObj).unwrap()
        refetch()
        showToaster("success", `${result.message}`, "Success")
        setBtnDisable(false)
      } catch (error) {
        showError(error)
      }
    },
  })

  // Image Upload Process
  const handleOpenCropModal = () => {
    setIsCropModalOpen(true)
  }

  const handleCloseCropModal = (Ufile = file) => {
    setIsCropModalOpen(false)
    if (Ufile.length > 0) {
      setUploadImage([...Ufile])
    }
  }
  const handleSetSelectFiles = files => {
    if (
      !!!files.filter(val => !config.IMAGE_UPLOAD_TYPE.includes(val.type))
        .length
    ) {
      if (isvalidFileSize(files)) {
        setFile([...files])
        handleOpenCropModal()
      } else {
        showToaster(
          "error",
          "File size should be less than or equal to 5MB",
          "Error",
        )
      }
    } else {
      showToaster("error", "Select Valid Image(only jpg, jpeg or png)", "Error")
    }
  }
  {
    /* CMS Contact us Form */
  }
  return (
    isSuccess && (
      <Form
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
        }}
      >
        {data?.data?.banner?.image && (
          <FormGroup className="mb-4" row>
            <Label htmlFor="image" md="2" className="col-form-label">
              Banner Image
            </Label>
            <Col md="10">
              <Dropzone
                name="banner.image"
                onDrop={acceptedFiles => {
                  handleSetSelectFiles(acceptedFiles)
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone">
                    <div className="dz-message needsclick" {...getRootProps()}>
                      <input {...getInputProps()} accept="image/*" />
                      <div className="dz-message needsclick">
                        <div className="mb-3">
                          <i className="display-4 text-muted bx bxs-cloud-upload" />
                        </div>
                        <h4>Drop files here or click to upload.</h4>
                        <h6 className="text-danger">
                          Maximum image size allowed is 5MB.{" "}
                        </h6>
                      </div>
                    </div>
                  </div>
                )}
              </Dropzone>
              {validation.touched.banner?.image &&
                validation.errors.banner?.image && (
                  <p className="text-danger">
                    {validation.errors.banner?.image}
                  </p>
                )}
              <div className="p-2">
                <Row className="align-items-center">
                  <Col sm="8">
                    <Col className="col-auto">
                      <LazyImageWithFallback
                        src={
                          uploadImage[0]?.preview
                            ? uploadImage[0]?.preview
                            : asset(validation?.values?.banner?.image)
                        }
                        alt="banner"
                        className="rounded bg-light w-25"
                      />
                    </Col>
                  </Col>
                </Row>
              </div>
            </Col>
          </FormGroup>
        )}
        {data?.data?.banner?.link && (
          <FormGroup className="mb-4" row>
            <Label htmlFor="link" md="2" className="col-form-label">
              Link
            </Label>
            <Col md="10">
              <Input
                type="text"
                className="form-control"
                id="link"
                placeholder="Enter Link"
                name="banner.link"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.banner?.link || ""}
                invalid={
                  !!(
                    validation.touched.banner?.link &&
                    validation.errors.banner?.link
                  )
                }
              />
              {validation.touched.banner?.link &&
                validation.errors.banner?.link && (
                  <FormFeedback className="fs-6 mt-0" type="invalid">
                    {validation.errors.banner?.link}
                  </FormFeedback>
                )}
            </Col>
          </FormGroup>
        )}
        {data?.data?.banner?.title && (
          <FormGroup className="mb-4" row>
            <Label htmlFor="title" md="2" className="col-form-label">
              Banner Title
            </Label>
            <Col md="10">
              <Input
                type="text"
                className="form-control"
                id="title"
                placeholder="Enter Title"
                name="banner.title"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.banner?.title || ""}
                invalid={
                  !!(
                    validation.touched.banner?.title &&
                    validation.errors.banner?.title
                  )
                }
              />
              {validation.touched.banner?.title &&
                validation.errors.banner?.title && (
                  <FormFeedback className="fs-6 mt-0" type="invalid">
                    {validation.errors.banner?.title}
                  </FormFeedback>
                )}
            </Col>
          </FormGroup>
        )}
        {isCropModalOpen && (
          <ImageCroperModel
            imageArry={file}
            ratio={[{ value: getImageRatio(["banner"]), text: "" }]}
            // ratio={[{ value: config.IMAGE_RATIO?.banner, text: `${config.IMAGE_RATIO?.banner}` }]}
            onCancel={handleCloseCropModal}
            setFile={setFile}
            isOpen={isCropModalOpen}
          />
        )}
        <Row className="mt-4">
          <Col sm="12">
            <div className="text-sm-end">
              <button
                type="submit"
                className="btn btn-success me-2"
                disabled={btnDisable}
              >
                {btnDisable ? (
                  <i className="bx bx-loader font-size-16 label-icon align-middle me-2">
                    {" "}
                    Loading
                  </i>
                ) : (
                  <i className="bx bx-check-double font-size-16 label-icon me-2 align-middle">
                    {" "}
                    Submit
                  </i>
                )}
              </button>
              <button
                className="btn btn-danger"
                type="button"
                onClick={() => {
                  setUploadImage([])
                  validation.resetForm()
                }}
              >
                <i className="bx bx-block font-size-16 label-icon me-2 align-middle" />{" "}
                Cancel{" "}
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    )
  )
}
