import React, { useEffect, useRef, useState } from "react"
import { showError, showToaster } from "helpers"
import {
  useAddCategoryMutation,
  useGetCategoryMutation,
  useUpdateCategoryMutation,
} from "redux/category/api"
import { useFormik } from "formik"
import * as Yup from "yup"
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import ImageCroperModel from "components/Common/ImageCroper/ImageCroperModel"
import config from "config"
import LazyImageWithFallback from "components/Common/LazyImageWithFallback"
import { useDispatch, useSelector } from "react-redux"
import {
  selectCategoryList,
  selectCategoryPageInfo,
  setCategoryList,
} from "redux/category/slice"
import LoadingSpinner from "components/Common/LoadingSpinner"

function AddEditModel({
  categoryType = null,
  openModal,
  setOpenModal,
  setLoadList,
  loadList,
  id = null,
  setId,
}) {
  const [addCategory, addCategoryAPI] = useAddCategoryMutation()
  const [getData, getDataAPI] = useGetCategoryMutation()
  const [editInfo, setEditInfo] = useState([])
  const CategoriesData = useSelector(selectCategoryList)
  const CategoriespageInfo = useSelector(selectCategoryPageInfo)
  const dispatch = useDispatch()
  const [updateData, UpdateDataAPI] = useUpdateCategoryMutation()
  const IsEdit = !!id
  const [file, setFile] = useState([])
  const [isCropModalOpen, setIsCropModalOpen] = useState(false)
  const toggle = () => {
    if (openModal) {
      setOpenModal(openModal => !openModal)
      formik.resetForm()
      setEditInfo([])
      setId(null)
      setFile([])
    }
  }
  // get edit data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getData(id).unwrap()
        setEditInfo(result?.data)
      } catch (error) {
        showError(error)
      }
    }
    if (id) {
      fetchData()
    }
  }, [id])
  // Yup validation
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .matches(/^[a-zA-Z\s]+$/, "Name must contain only alphabetic characters")
      .required("Name is required"),
    image: Yup.mixed()
      .required("Image is required")
      .test(
        "fileSize",
        "File size should be less than or equal to 5MB",
        value => {
          // Check if value exists and has a size property
          if (value && value?.size) {
            if (!(value.size <= config.IMAGE_MAXSIZE)) {
              showToaster(
                "error",
                "File size should be less than or equal to 5MB",
                "Wrong",
              )
            }
            return value.size <= config.IMAGE_MAXSIZE // 5MB limit
          }
          // If value or size is not available, skip the validation
          return true
        },
      ),
  })
  // formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: IsEdit ? editInfo.name : "",
      image: IsEdit ? editInfo?.image : null,
    },
    validationSchema,
    onSubmit: async values => {
      var data = {
        name: values.name,
      }
      if (file?.length > 0 && file[0]?.file) {
        data = { ...data, image: file[0].file }
      }
      if (!IsEdit) {
        try {
          const result = await addCategory({
            ...data,
            type: categoryType,
          }).unwrap()
          setLoadList(loadList => !loadList)
          toggle()
          showToaster("success", `${result.message}`, "Success")
        } catch (error) {
          showError(error)
        }
      } else {
        try {
          const result = await updateData({
            value: data,
            id: id,
          }).unwrap()
          const updatedArray = await CategoriesData.map(item => {
            if (item.id == result?.data?.id) {
              return result?.data // Replace object with id 2
            } else {
              return item // Keep other objects unchanged
            }
          })
          dispatch(
            setCategoryList({
              categories: updatedArray,
              pageInfo: CategoriespageInfo,
            }),
          )
          toggle()
          showToaster("success", `${result.message}`, "Success")
        } catch (error) {
          showError(error)
        }
      }
    },
  })

  const handleOpenCropModal = () => {
    setIsCropModalOpen(true)
  }
  const handleCloseCropModal = (Ufile = file) => {
    setIsCropModalOpen(false)
    if (Ufile.length > 0) {
      setFile([...Ufile])
    } else {
      formik.setFieldValue("image", null)
      handleFileInputClick()
    }
  }
  const fileInputRef = useRef(null)
  // Function to open the file input dialog when the "Change Avatar" button is clicked
  const handleFileInputClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = null
    }
  }
  useEffect(() => {
    if (!formik.values.image) {
      if (IsEdit) {
        formik.setFieldValue("image", editInfo?.image)
        setFile([editInfo?.image])
        return
      }
      setFile([])
      return
    }

    if (
      formik.values.image &&
      formik.values.image?.size <= config?.IMAGE_MAXSIZE &&
      config.IMAGE_UPLOAD_TYPE.includes(formik.values.image.type)
    ) {
      setFile([formik.values.image])
      handleOpenCropModal()
    }
  }, [formik.values.image])
  return (
    <React.Fragment>
      <Modal
        // size="lg"
        isOpen={openModal}
        toggle={toggle}
        backdrop={"static"}
        centered={true}
      >
        <ModalHeader toggle={toggle} tag="h4">
          {!IsEdit
            ? categoryType == "blog"
              ? "Create Blog Category"
              : "Create Category"
            : "Edit Category"}
        </ModalHeader>
        <ModalBody>
          {getDataAPI?.isLoading || getDataAPI?.isFetching ? (
            <Col lg="12" className="w-100 d-flex justify-content-center">
              {" "}
              <LoadingSpinner />{" "}
            </Col>
          ) : (
            <Form
              className="outer-repeater"
              onSubmit={e => {
                e.preventDefault()
                formik.handleSubmit()
                return
              }}
            >
              <div data-repeater-list="outer-group" className="outer">
                <div data-repeater-item className="outer">
                  <FormGroup className="mb-4" row>
                    <Label htmlFor="name" className="col-form-label col-lg-2">
                      Name
                    </Label>
                    <Col lg="10">
                      <Input
                        id="name"
                        name="name"
                        type="text"
                        className="form-control"
                        placeholder="Enter Category Name"
                        value={formik.values.name || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        invalid={!!(formik.touched.name && formik.errors.name)}
                      />
                      {formik.touched.name && formik.errors.name && (
                        <FormFeedback className="fs-6 mt-0" type="invalid">
                          {formik.errors.name}
                        </FormFeedback>
                      )}
                    </Col>
                  </FormGroup>
                  <FormGroup className="mb-4" row>
                    <Label
                      htmlFor="formFile"
                      className="col-form-label col-lg-2"
                    >
                      Image
                    </Label>
                    <Col lg="7">
                      <Input
                        className="form-control"
                        innerRef={fileInputRef}
                        type="file"
                        name="image"
                        accept="image/*"
                        onBlur={formik.handleBlur}
                        invalid={
                          !!(formik.touched.image && formik.errors.image)
                        }
                        onChange={event => {
                          formik.setFieldValue(
                            "image",
                            event.currentTarget.files[0],
                          )
                        }}
                        id="formFile"
                      />
                      {formik.touched.image && formik.errors.image && (
                        <div className="fs-6 mt-0 text-danger" type="invalid">
                          {formik.errors.image}
                        </div>
                      )}
                    </Col>
                    <Col lg="3">
                      {(IsEdit || !!file[0]?.preview) && (
                        <LazyImageWithFallback
                          src={file[0]?.preview || editInfo?.image}
                          alt="Selected Image"
                          className="rounded"
                          style={{ height: "auto", width: "100%" }}
                        />
                      )}
                    </Col>
                  </FormGroup>
                </div>
              </div>
              <Row className="">
                <div className="modal-footer">
                  <Col className="text-end">
                    <button
                      type="button"
                      className="btn btn-secondary me-2"
                      onClick={() => toggle()}
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      disabled={formik.isSubmitting}
                      className={`btn btn-success w-sm ${
                        formik.isSubmitting ? "cursor-wait" : "cursor-pointer"
                      }`}
                    >
                      {formik.isSubmitting ? (
                        <div className="d-flex justify-content-center align-items-center">
                          <div
                            className="spinner-border spinner-border-sm me-1"
                            role="status"
                          >
                            <span className="sr-only">{"Loading"}...</span>
                          </div>
                          <span>{"Loading"}...</span>
                        </div>
                      ) : !IsEdit ? (
                        "Add"
                      ) : (
                        "Update"
                      )}
                    </button>
                  </Col>
                </div>
              </Row>
              {isCropModalOpen && (
                <ImageCroperModel
                  imageArry={file}
                  onCancel={handleCloseCropModal}
                  setFile={setFile}
                  isOpen={isCropModalOpen}
                />
              )}
            </Form>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default AddEditModel
