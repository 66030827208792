import config from "config"
import { useFormik } from "formik"
import { showError, showToaster } from "helpers"
import { wordTitleCase } from "helpers/data-formatters"
import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap"
import { useUpdateSettingsMutation } from "redux/charges/api"
import SimpleBar from "simplebar-react"

function EditModel({ isOpen, onCancel, data = {}, load, setLoad }) {
  const [updateData, UpdateData] = useUpdateSettingsMutation()
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: data,
    onSubmit: async values => {
      try {
        const result = await updateData({
          settingKey: "charges",
          payload: values,
        }).unwrap()
        setLoad(load => !load)
        onCancel()
        showToaster("success", `${result.message}`, "Success")
      } catch (error) {
        showError(error)
      }
    },
  })
  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        toggle={onCancel}
        centered
        backdrop={"static"}
        size="md"
      >
        <ModalHeader toggle={onCancel}>Update</ModalHeader>
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <Form
                  className="outer-repeater"
                  onSubmit={e => {
                    e.preventDefault()
                    formik.handleSubmit()
                    return
                  }}
                >
                  <ModalBody style={{ width: "100%" }}>
                    {Object.keys(data).map((key, index) => {
                      return (
                        <FormGroup className="mb-4" row key={index}>
                          <Label
                            htmlFor="name"
                            className="col-form-label col-lg-4"
                          >
                            {config?.CHARGES_LABEL[key]?.name}
                          </Label>
                          <Col lg="8">
                            <Input
                              id={`${key}`}
                              name={`${key}`}
                              type="number"
                              className="form-control"
                              placeholder="Enter value..."
                              value={formik.values[key] || ""}
                              onChange={e => {
                                if (!isNaN(e.target.value)) {
                                  formik.handleChange(e)
                                }
                              }}
                              onBlur={formik.handleBlur}
                              invalid={
                                !!(formik.touched[key] && formik.errors[key])
                              }
                            />
                            {formik.touched[key] && formik.errors[key] && (
                              <FormFeedback
                                className="fs-6 mt-0"
                                type="invalid"
                              >
                                {formik.errors[key]}
                              </FormFeedback>
                            )}
                          </Col>
                        </FormGroup>
                      )
                    })}
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      type="submit"
                      disabled={formik.isSubmitting}
                      className={`btn btn-success w-md ${
                        formik.isSubmitting ? "cursor-wait" : "cursor-pointer"
                      }`}
                    >
                      {formik.isSubmitting ? (
                        <div className="d-flex justify-content-center align-items-center">
                          <div
                            className="spinner-border spinner-border-sm me-1"
                            role="status"
                          >
                            <span className="sr-only">Adding...</span>
                          </div>
                          <span>Adding...</span>
                        </div>
                      ) : (
                        <>Update</>
                      )}
                    </Button>
                  </ModalFooter>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Modal>
    </React.Fragment>
  )
}

export default EditModel
