import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Table,
  Card,
  CardBody,
  CardTitle,
  UncontrolledTooltip,
} from "reactstrap"
import { useLocation } from "react-router-dom"
import { asset, getPath, showFirst20Words } from "helpers/util"
import { isArray } from "lodash"
import { useDeleteFaqMutation } from "redux/cms/api"
import { SweetAlert, confirmAlert, showError, showToaster } from "helpers"
import FaqView from "./View"
import { DragTable } from "pages/CMS/Common/table/DragTable"

export const FaqTable = ({
  data,
  isLoading,
  isSuccess,
  refetch,
  tog_center,
  modal_center,
}) => {
  const { pathname } = useLocation()
  const [View, setView] = useState("")
  // useEffect(() => {
  //   refetch()
  // }, [modal_center])
  const [deleteFaq] = useDeleteFaqMutation()
  // delete faq
  const handleDeleteFaq = async id => {
    try {
      if (id) {
        const confirmationMessage = `You want to delete this faq?`
        const confirmation = await confirmAlert(
          confirmationMessage,
          "warning",
          "Yes, delete it!",
        )
        if (confirmation.isConfirmed) {
          const result = await deleteFaq({
            pageName:
              pathname === "/pages/settings"
                ? "advertise-with-us"
                : getPath(pathname),
            id: id,
          }).unwrap()
          SweetAlert(`${result.message}`, "success")
          refetch()
        }
      }
    } catch (error) {
      showError(error)
    }
  }
  const columns = React.useMemo(
    () => [
      {
        Header: "Question",
        accessor: "que",
        Cell: cellProps => {
          return <div className="d-inline"> {cellProps.value} </div>
        },
      },
      {
        Header: "Answer",
        accessor: "ans",
        Cell: cellProps => {
          const faqItem = cellProps.row.original
          return (
            <p
              className="text-muted mb-0"
              onClick={() => setView(faqItem?._id)}
            >
              {showFirst20Words(cellProps?.cell?.value)}
            </p>
          )
        },
      },
      {
        Header: "Action",
        accessor: "",
        Cell: cellProps => {
          const faqItem = cellProps.row.original
          return (
            <div className="d-flex gap-2">
              <p className="text-info">
                <i
                  className="mdi mdi-eye font-size-18"
                  id={`showImages${faqItem?._id}`}
                  onClick={() => {
                    // open modal
                    setView(faqItem?._id)
                    // tog_center(faqItem?._id)
                  }}
                />
                <UncontrolledTooltip
                  placement="top"
                  target={`showImages${faqItem?._id}`}
                >
                  View FAQ's Detail
                </UncontrolledTooltip>
              </p>
              <p className="text-success">
                <i
                  className="mdi mdi-lead-pencil font-size-18"
                  id={`edit${faqItem?._id}`}
                  onClick={() => {
                    tog_center(faqItem?._id)
                  }}
                />
                <UncontrolledTooltip
                  placement="top"
                  target={`edit${faqItem?._id}`}
                >
                  Edit
                </UncontrolledTooltip>
              </p>
              <p className="text-danger">
                <i
                  className="mdi mdi-delete font-size-18"
                  id={`delete${faqItem?._id}`}
                  onClick={() => {
                    handleDeleteFaq(faqItem?._id)
                  }}
                />
                <UncontrolledTooltip
                  placement="top"
                  target={`delete${faqItem?._id}`}
                >
                  Delete
                </UncontrolledTooltip>
              </p>
            </div>
          )
        },
      },
    ],
    [],
  )

  const [datas, setData] = useState([])
  useEffect(() => {
    if (data?.faq?.length) {
      const Data = data?.faq?.slice().sort((a, b) => a?.order - b?.order)
      setData(
        Data?.map((val, index) => {
          return { ...val, id: index + 1 }
        }),
      )
    }
  }, [data?.faq])

  //meta title
  return (
    <>
      {/* <CardBody> */}
      <Row className="mt-4">
        <Col sm="12">
          {pathname !== "/pages/settings" && (
            <CardTitle className="mb-4">FAQs List</CardTitle>
          )}
          <div className="text-md-end">
            <button className="btn btn-info" onClick={() => tog_center()}>
              Add
            </button>
          </div>
        </Col>
      </Row>
      <div>
        <CardBody>
          <div className="table-responsive">
            <DragTable
              refetch={refetch}
              isLoading={isLoading}
              isSuccess={isSuccess}
              columns={columns}
              data={datas}
              setData={setData}
            />
          </div>
        </CardBody>
      </div>
      {/* </CardBody> */}
      {View && <FaqView View={View} setView={setView} data={data?.data} />}
    </>
  )
}
