import { createSlice } from "@reduxjs/toolkit"
const prefixSlice = createSlice({
  name: "prefix",
  initialState: {
    list: [],
    pageInfo: { limit: 10, page: 1, totalPages: 1, totalResults: 0 },
  },
  reducers: {
    setPrefixList: (state, action) => {
      const { prefixes, pageInfo } = action.payload
      state.list = prefixes
      state.pageInfo = pageInfo
      return
    },
  },
})

export const { setPrefixList } = prefixSlice.actions

export default prefixSlice.reducer

export const selectPrefixList = state => state.prefix?.list
export const selectPrefixPageInfo = state => state.prefix?.pageInfo
