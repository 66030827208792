import { apiSlice } from "../api"

export const categorySlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    categoryList: builder.query({
      query: props => {
        let url = `category/all`
        if (props == "blog" || props == "other") {
          url = `category/all?type=${props}`
        }
        if (props && props.state) {
          const { page = 1, limit = 10, search = "" } = props?.state
          const type = props?.type
          url = `category/all?page=${page}&limit=${limit}${
            type == "blog" ? "&type=" + type : ""
          }${search != "" ? "&search=" + search : ""}`
        }
        return {
          url: `${url}`,
          method: "GET",
        }
      },
    }),
    addCategory: builder.mutation({
      query: props => {
        var data = new FormData()
        Object.keys(props).map(key => {
          data.append(`${key}`, props[key])
        })
        return {
          url: "/category",
          method: "POST",
          body: data,
        }
      },
    }),
    getCategory: builder.mutation({
      query: props => {
        return {
          url: `/category/${props}`,
          method: "GET",
        }
      },
    }),
    updateCategory: builder.mutation({
      query: props => {
        var data = new FormData()
        Object.keys(props.value).map(key => {
          data.append(`${key}`, props.value[key])
        })
        return {
          url: `/category/${props.id}`,
          method: "PATCH",
          body: data,
        }
      },
    }),
    deleteCategory: builder.mutation({
      query: props => {
        return {
          url: `/category`,
          method: "DELETE",
          body: { deleteCategories: [...props] },
        }
      },
    }),
  }),
})
export const {
  useCategoryListQuery,
  useAddCategoryMutation,
  useGetCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
} = categorySlice
