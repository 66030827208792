import { createSlice } from "@reduxjs/toolkit"
const contactsSlice = createSlice({
  name: "contacts",
  initialState: {
    list: [],
    pageInfo: { limit: 10, page: 1, totalPages: 1, totalResults: 0 },
  },
  reducers: {
    setContactsList: (state, action) => {
      const { data, pageInfo } = action.payload
      state.list = data
      state.pageInfo = pageInfo
      return
    },
  },
})

export const { setContactsList } = contactsSlice.actions

export default contactsSlice.reducer

export const selectContactsList = state => state.contacts?.list
export const selectContactsTotalRecord = state => state.contacts?.pageInfo
