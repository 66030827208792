import React, { useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Input,
  FormGroup,
  Label,
  Form,
  FormFeedback,
} from "reactstrap"

import "react-datepicker/dist/react-datepicker.css"
import { useFormik } from "formik"
import * as Yup from "yup"
import { showError, showToaster } from "helpers"
import {
  useAddBannerAdsMutation,
  useGetBannerAdsMutation,
  useUpdateBannerAdsMutation,
} from "redux/bannerAds/api"
import ImageCroperModel from "components/Common/ImageCroper/ImageCroperModel"
import config from "config"
import LazyImageWithFallback from "components/Common/LazyImageWithFallback"
import QuillEditor from "components/Common/ReactQuill/QuillEditor"
import { replaceQuillClasses } from "helpers/util"
import LoadingSpinner from "components/Common/LoadingSpinner"
import UserListDropdown from "components/Common/UserListDropdown/UserListDropdown"

function BannerAdsAdd() {
  // for edit
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [isCropModalOpen, setIsCropModalOpen] = useState(false)
  const [selectedFiles, setselectedFiles] = useState([])
  const [bannerAds, bannerAdsAPI] = useAddBannerAdsMutation()
  // edit page
  const IsEdit = pathname?.includes("edit")
  const [editInfo, setEditInfo] = useState([])
  const { id } = useParams()
  const [getData, getDataAPI] = useGetBannerAdsMutation()
  const [updateBannerAds, setUpdateBannerAds] = useUpdateBannerAdsMutation()
  // userList Option
  const [userOption, setUserOption] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getData(id).unwrap()
        setEditInfo(result?.data)
        setUserOption([result?.data.user])
      } catch (error) {
        showError(error)
      }
    }
    if (id) {
      fetchData()
    }
  }, [id])

  const BreadcrumbDetails = {
    breadcrumbItem: {
      label: !IsEdit ? "Add Advert" : "Edit Advert" || editInfo?.name,
      link: !IsEdit
        ? "adverts/add"
        : pathname.includes("user")
          ? `user/adverts/edit/${id}`
          : `adverts/edit/${id}`,
    },
    paths: IsEdit
      ? pathname.includes("user")
        ? [
            {
              label: "User",
              link: `user/adverts/edit/${id}`,
            },
            {
              label: "Adverts",
              link: `user/adverts/edit/${id}`,
            },
          ]
        : [
            {
              label: "Adverts",
              link: "adverts/list",
            },
          ]
      : [
          {
            label: "Adverts",
            link: "adverts/add",
          },
        ],
  }

  const validationSchema = Yup.object().shape({
    user: Yup.string().required("User selection is required"),
    name: Yup.string()
      .required("Name is required")
      .min(5, "Name should be at least 5 characters")
      .max(100, "Name should be less than or equal to 100 characters"),
    website: Yup.string()
      .trim()
      .required("Website Link is required")
      .matches(/^\S*$/, "Whitespace is not allowed")
      .matches(
        /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
        "Enter a valid URL for Website link",
      )
      .max(255, "Website should be less than or equal to 255 characters"),
    image: Yup.mixed()
      .required("Image is required")
      .test(
        "fileType",
        "Select Valid Image  (only jpg, jpeg or png)",
        value => {
          // Check Image type
          if (value && value.type) {
            if (!config.IMAGE_UPLOAD_TYPE.includes(value.type)) {
              handleFileInputClick()
            }
            return config.IMAGE_UPLOAD_TYPE.includes(value.type)
          }
          // If value or size is not available, skip the validation
          return true
        },
      )
      .test(
        "fileSize",
        "File size should be less than or equal to 5MB",
        value => {
          // Check if value exists and has a size property
          if (value && value?.size) {
            if (!(value.size <= config.IMAGE_MAXSIZE)) {
              handleFileInputClick()
            }
            return value.size <= config.IMAGE_MAXSIZE // 5MB limit
          }
          // If value or size is not available, skip the validation
          return true
        },
      ),
    description: Yup.string().trim().required("Description is required"),
  })
  const editInitialValues = {
    user: editInfo?.user?._id,
    name: editInfo?.name,
    website: editInfo?.website,
    description: editInfo?.description,
    image: editInfo?.image?.url,
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: IsEdit
      ? editInitialValues
      : {
          description: "",
          user: "",
          name: "",
          website: "",
          image: null,
        },
    validationSchema,
    onSubmit: async values => {
      let newValue = {
        ...values,
        description: replaceQuillClasses(values.description),
      }
      if (selectedFiles.length && selectedFiles[0]?.file) {
        newValue = { ...newValue, image: selectedFiles[0]?.file }
      } else {
        const { image, ...data } = newValue
        newValue = data
      }
      if (!IsEdit) {
        try {
          const result = await bannerAds(newValue).unwrap()
          navigate("/adverts/list")
          showToaster("success", `${result.message}`, "Success")
        } catch (error) {
          showError(error)
        }
      } else {
        try {
          const result = await updateBannerAds({
            value: newValue,
            id: id,
          }).unwrap()
          navigate(-1)
          showToaster("success", `${result.message}`, "Success")
        } catch (error) {
          showError(error)
        }
      }
    },
  })
  // croper model manage
  const handleOpenCropModal = () => {
    setIsCropModalOpen(true)
  }
  const handleCloseCropModal = (Ufile = selectedFiles) => {
    setIsCropModalOpen(false)
    if (Ufile && Ufile?.length > 0) {
      setselectedFiles([...Ufile])
    } else {
      formik.setFieldValue("image", null)
      handleFileInputClick()
    }
  }

  useEffect(() => {
    if (!formik.values.image) {
      if (IsEdit) {
        formik.setFieldValue("image", editInfo?.image?.url)
        setselectedFiles([editInfo?.image])
      }
      setselectedFiles([])
      return
    }

    if (
      formik.values.image &&
      formik.values.image?.size <= config.IMAGE_MAXSIZE &&
      config.IMAGE_UPLOAD_TYPE.includes(formik.values.image.type)
    ) {
      setselectedFiles([formik.values.image])
      handleOpenCropModal()
    }
  }, [formik.values.image])
  const handleInputChange = (fieldName, e) => {
    const sanitizedValue = e.target.value.replace(/\s+/g, " ")
    formik.handleChange(e)
    formik.setFieldValue(fieldName, sanitizedValue)
  }
  const fileInputRef = useRef(null)
  // Function to open the file input dialog when the "Change Avatar" button is clicked
  const handleFileInputClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = null
    }
  }
  return (
    <React.Fragment>
      <Helmet>
        <title>
          {!IsEdit ? "Add Adverts" : "Edit Adverts"} | Discover Equestrian
        </title>
      </Helmet>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbDetails={BreadcrumbDetails} />

          {getDataAPI?.isLoading || getDataAPI?.isFetching ? (
            <Col lg="12" className="w-100 d-flex justify-content-center">
              {" "}
              <LoadingSpinner />{" "}
            </Col>
          ) : (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4"></CardTitle>
                    <Form
                      className="outer-repeater"
                      onSubmit={e => {
                        e.preventDefault()
                        formik.handleSubmit()
                        // return
                      }}
                    >
                      <div data-repeater-list="outer-group" className="outer">
                        <div data-repeater-item className="outer">
                          <FormGroup className="mb-4" row>
                            <Label
                              htmlFor="user"
                              className="col-form-label col-lg-2"
                            >
                              User
                            </Label>
                            <Col lg="10">
                              {IsEdit ? (
                                <Input
                                  type="select"
                                  name="user"
                                  className="form-control"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.user || ""}
                                  disabled={IsEdit} // Add this line to disable the input in "edit" mode
                                  invalid={
                                    !!(
                                      formik.touched.user && formik.errors.user
                                    )
                                  }
                                >
                                  <option value="">Select User</option>
                                  {userOption?.map((val, index) => {
                                    return (
                                      <option key={index} value={`${val?._id}`}>
                                        {val?.name}
                                      </option>
                                    )
                                  })}
                                </Input>
                              ) : (
                                <UserListDropdown formik={formik} />
                              )}
                              {formik.touched.user && formik.errors.user && (
                                <div
                                  className="fs-6 mt-0 text-danger"
                                  type="invalid"
                                >
                                  {formik.errors.user}
                                </div>
                              )}
                            </Col>
                          </FormGroup>
                          <FormGroup className="mb-4" row>
                            <Label
                              htmlFor="name"
                              className="col-form-label col-lg-2"
                            >
                              Name
                            </Label>
                            <Col lg="10">
                              <Input
                                id="name"
                                name="name"
                                type="text"
                                className="form-control"
                                placeholder="Enter Name"
                                value={formik.values.name || ""}
                                onChange={e => {
                                  handleInputChange("name", e)
                                }}
                                onBlur={formik.handleBlur}
                                invalid={
                                  !!(formik.touched.name && formik.errors.name)
                                }
                              />
                              {formik.touched.name && formik.errors.name && (
                                <FormFeedback
                                  className="fs-6 mt-0"
                                  type="invalid"
                                >
                                  {formik.errors.name}
                                </FormFeedback>
                              )}
                            </Col>
                          </FormGroup>
                          <FormGroup className="mb-4" row>
                            <Label
                              htmlFor="taskname"
                              className="col-form-label col-lg-2"
                            >
                              Website Link
                            </Label>
                            <Col lg="10">
                              <Input
                                id="website"
                                name="website"
                                type="text"
                                className="form-control"
                                placeholder="https://www.google.com"
                                value={formik.values.website || ""}
                                onChange={e => {
                                  handleInputChange("website", e)
                                }}
                                onBlur={formik.handleBlur}
                                invalid={
                                  !!(
                                    formik.touched.website &&
                                    formik.errors.website
                                  )
                                }
                              />
                              {formik.touched.website &&
                                formik.errors.website && (
                                  <FormFeedback
                                    className="fs-6 mt-0"
                                    type="invalid"
                                  >
                                    {formik.errors.website}
                                  </FormFeedback>
                                )}
                            </Col>
                          </FormGroup>
                          <FormGroup className="mb-4" row>
                            <Label
                              htmlFor="formFile"
                              className="col-form-label col-lg-2"
                            >
                              Image
                            </Label>
                            <Col lg="5">
                              <Input
                                innerRef={fileInputRef}
                                className="form-control"
                                type="file"
                                name="image"
                                accept="image/*"
                                onBlur={formik.handleBlur}
                                invalid={
                                  !!(
                                    formik.touched.image && formik.errors.image
                                  )
                                }
                                onChange={event => {
                                  formik.setFieldValue(
                                    "image",
                                    event.currentTarget.files[0],
                                  )
                                }}
                                id="formFile"
                              />
                              {formik.touched.image && formik.errors.image && (
                                <div
                                  className="fs-6 mt-0 text-danger"
                                  type="invalid"
                                >
                                  {formik.errors.image}
                                </div>
                              )}
                            </Col>
                            <Col lg="5">
                              {(IsEdit || !!selectedFiles[0]?.preview) && (
                                <LazyImageWithFallback
                                  src={
                                    selectedFiles[0]?.preview ||
                                    editInfo?.image?.url
                                  }
                                  alt="Selected Image"
                                  className="rounded"
                                  style={{ height: "50px", width: "auto" }}
                                />
                              )}
                            </Col>
                          </FormGroup>
                          <FormGroup className="mb-4" row>
                            <Label className="col-form-label col-lg-2">
                              Description
                            </Label>
                            <Col lg="10">
                              <QuillEditor
                                hideIcon={{ image: true }}
                                setFieldTouched={() =>
                                  formik.setFieldTouched("description", true)
                                }
                                data={formik.values.description}
                                handleChange={description =>
                                  formik.setFieldValue(
                                    "description",
                                    description,
                                  )
                                }
                              />
                              {formik.errors.description &&
                                formik.touched.description && (
                                  <div className="text-danger">
                                    {formik.errors.description}
                                  </div>
                                )}
                            </Col>
                          </FormGroup>
                        </div>
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          {IsEdit && (
                            <Link
                              // to={"/businesses&services/list"}
                              onClick={() => navigate(-1)}
                              type="button"
                              className="btn btn-danger me-1"
                            >
                              <i className="bx bx-left-arrow-alt font-size-16 align-middle me-2"></i>{" "}
                              Back
                            </Link>
                          )}
                          <button
                            type="submit"
                            disabled={
                              formik.isSubmitting ||
                              (IsEdit && formik.initialValues == formik.values)
                            }
                            className={`btn btn-success w-md ${
                              formik.isSubmitting
                                ? "cursor-wait"
                                : "cursor-pointer"
                            }`}
                          >
                            {formik.isSubmitting ? (
                              <div className="d-flex justify-content-center align-items-center">
                                <div
                                  className="spinner-border spinner-border-sm me-1"
                                  role="status"
                                >
                                  <span className="sr-only">Adding...</span>
                                </div>
                                <span>Adding...</span>
                              </div>
                            ) : !IsEdit ? (
                              <>
                                <i className="bx bx-plus font-size-16 align-middle me-2"></i>{" "}
                                Add
                              </>
                            ) : (
                              <>
                                <i className="bx bx-up-arrow-alt font-size-16 align-middle me-2"></i>{" "}
                                Update
                              </>
                            )}
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          {isCropModalOpen && (
            <ImageCroperModel
              // handleFileInputClick={handleFileInputClick}
              imageArry={selectedFiles}
              onCancel={handleCloseCropModal}
              setFile={setselectedFiles}
              isOpen={isCropModalOpen}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BannerAdsAdd
