import config from "config"
import moment from "moment"

export const getAuthToken = () => {
  return localStorage.getItem("AUTH_TOKEN") ?? ""
}

export const setAuthToken = data => {
  return localStorage.setItem("AUTH_TOKEN", data)
}
export const removeAuthToken = data => {
  localStorage.removeItem("AUTH_TOKEN")
  return
}
export const showFirst20Words = inputString => {
  // Split the input string into words
  const words = inputString.split(/\s+/)
  // Using a regular expression to match alphabetical letters
  const letterCount = (inputString?.match(/[a-zA-Z0-9]/g) || []).length
  if (letterCount < 20) {
    // If the count is greater than 15, return the first 15 letters
    return inputString.slice(0, 20)
  } else {
    const first20Words = words.slice(0, 20)
    const result = first20Words.join(" ")
    return inputString.slice(0, 20) + "..."
  }
}
export const showFirst10Words = inputString => {
  // Split the input string into words
  const words = inputString.split(/\s+/)
  // Using a regular expression to match alphabetical letters
  const letterCount = (inputString?.match(/[a-zA-Z]/g) || []).length
  if (letterCount < 10) {
    // If the count is greater than 15, return the first 15 letters
    return inputString.slice(0, 10)
  } else {
    const first20Words = words.slice(0, 10)
    const result = first20Words.join(" ")
    return inputString.slice(0, 10) + "..."
  }
}
export const asset = prefix => {
  // Create image path url
  if (prefix) {
    // check if image exists or not [if not exits then add image placeholder]
    return process.env.REACT_APP_IMAGE_BASEURL + prefix
  }
}
export const addLineBreaks = string => {
  // Use regular expression to replace all occurrences of '\n' with '<br/>'
  let stringWithBR = string?.replace(/\r\n/g, "<br/>")
  // Replace \n with <br/>
  stringWithBR = stringWithBR?.replace(/\n/g, "<br/>")
  return stringWithBR
}
export const replaceBrTagsWithNewlines = string => {
  // Use regular expression to replace all occurrences of '<br/>' with '\n'
  return string?.replace(/<br\s*\/?>/g, "\n")
}
export const getObjectKeys = inputObject => {
  if (inputObject) {
    return Object.keys(inputObject)
  }
}
// Funtion to get specific path keyt ex. "/path/page/home" it will return "home"
export const getPath = path => {
  if (path) {
    const parts = path.split("/")
    return parts[parts.length - 1]
  }
  return path
}
export const isvalidFileSize = (data, mb = 5) => {
  var value = true
  data?.map(file => {
    if (file.size > 1048576 * mb) {
      value = false
    }
  })
  return value
}
export function convertToTitleCase(input) {
  return input
    .replace(/([A-Z])/g, " $1") // Add space before capital letters
    .replace(/^./, str => str.toUpperCase()) // Capitalize the first letter
    .trim() // Trim any leading/trailing spaces
}
export const getImageRatio = keys => {
  if (keys.length) {
    return keys.reduce((acc, key) => acc[key], config.IMAGE_RATIO)
  }
}
export const convertDateFormat = inputDate => {
  // Split the input date into day, month, and year
  const [day, month, year] = inputDate.split("/")
  return `${year}-${month}-${day}`
}
export function isValidVideoUrl(videoLink = "") {
  const youtubeRegex = /\.(mp4|avi|mov|mkv)$/i
  const LinkMatch = videoLink?.match(youtubeRegex)
  return LinkMatch
}
export function convertToEmbeddedLink(videoLink = "") {
  // Regular expressions for different video providers
  const youtubeRegex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
  const youtubeShortRegex =
    /https?:\/\/(?:www\.)?youtube\.com\/shorts\/([a-zA-Z0-9_-]{11})/
  const vimeoRegex = /https:\/\/(?:www\.)?vimeo\.com\/(\d+)/

  // Check for YouTube link
  const youtubeMatch =
    videoLink?.match(youtubeRegex) || videoLink?.match(youtubeShortRegex)
  if (youtubeMatch) {
    const youtubeVideoId = youtubeMatch[1]
    return `https://www.youtube.com/embed/${youtubeVideoId}`
  }

  // Check for Vimeo link
  const vimeoMatch = videoLink?.match(vimeoRegex)
  if (vimeoMatch) {
    const vimeoVideoId = vimeoMatch[1]
    return `https://player.vimeo.com/video/${vimeoVideoId}`
  }

  // Return the input link if it doesn't match YouTube or Vimeo
  return false
}

export function convertDate(date = new Date()) {
  const options = { day: "2-digit", month: "short", year: "numeric" }
  const formattedDate = new Date(date).toLocaleDateString("en-US", options)

  // Split the formatted date into day, month, and year parts
  const [month, day, year] = formattedDate.split(" ")

  // Convert the month abbreviation to uppercase
  const capitalizedMonth = month.toUpperCase()

  // Return the formatted date with uppercase month abbreviation and desired format
  return `${day} ${capitalizedMonth} ${year}`
}
export function youtubeLinkToThumbnailUrl(youtubeLink = "") {
  // Extract the video ID from the YouTube link
  const videoIdMatch =
    youtubeLink?.match(
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/,
    ) ||
    youtubeLink?.match(
      /https?:\/\/(?:www\.)?youtube\.com\/shorts\/([a-zA-Z0-9_-]{11})/,
    )

  if (!videoIdMatch || !videoIdMatch[1]) {
    // If the video ID is not found, return null or handle the error accordingly
    return null
  }

  const videoId = videoIdMatch[1]

  // Construct the thumbnail image URL
  const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/0.jpg`

  return thumbnailUrl
}

// Sort the array based on the "date" property
export const createAllDateArray = (dateArray = []) => {
  const sortedArray = dateArray.sort((a, b) => new Date(a) - new Date(b))
  // Generate an array of all dates between the first and last dates
  var allDates = []
  const startDate = new Date(sortedArray[0])
  const endDate = new Date(sortedArray[sortedArray.length - 1])
  for (
    let currentDate = startDate;
    currentDate <= endDate;
    currentDate.setDate(currentDate.getDate() + 1)
  ) {
    allDates = [...allDates, currentDate.toISOString().split("T")[0]]
  }
  return allDates
}

export const generateMonthRangeArray = (
  datesArray,
  formate = "YYYY-MM",
  addMethod = "months",
) => {
  // Find the minimum and maximum dates in the array
  const minDate = moment.min(datesArray.map(date => moment(date, formate)))
  const maxDate = moment.max(datesArray.map(date => moment(date, formate)))

  // Generate an array of months between the minimum and maximum dates
  var monthsArray = []
  let currentMonth = minDate.clone()

  while (currentMonth.isSameOrBefore(maxDate)) {
    monthsArray = [...monthsArray, currentMonth.format(formate)]
    // monthsArray.push(currentMonth.format(formate));
    currentMonth.add(1, addMethod)
  }

  return monthsArray
}

// update class for font side add boostrap class
export function replaceQuillClasses(inputHTML) {
  if (typeof inputHTML !== "string") {
    return inputHTML
  }
  const classMap = {
    "ql-size-large": "ql-size-large fs-3",
    "ql-size-huge": "ql-size-huge fs-1",
    "ql-size-small": "ql-size-small small",
    "ql-syntax": "ql-syntax bg-dark text-light p-3",
    "ql-align-center": "ql-align-center text-center",
    "ql-align-left": "ql-align-left text-left",
    "ql-align-right": "ql-align-right text-end",
    "ql-align-justify": "ql-align-justify text-justify",
    "ql-indent": "pr",
    "<blockquote":
      "<blockquote style='border-left: 4px solid #007bff; padding: 0 1em; margin: 0;'",
  }

  // Replace each key with its corresponding value in the HTML content
  const replacedHTML = inputHTML.replace(
    /\bql-size-large|<blockquote|ql-indent|ql-size-huge|ql-size-small|ql-syntax|ql-align-center|ql-align-left|ql-align-right|ql-align-justify\b/g,
    match => classMap[match],
  )

  return replacedHTML
}

export function getAvatarValue(name) {
  var nameArray = name
  if (name?.toLowerCase()?.split(" ")?.length > 1 && name?.includes(" ")) {
    nameArray = name?.toLowerCase()?.split(" ")
  }
  return {
    character:
      nameArray?.[0]?.[0]?.toUpperCase() + nameArray?.[1]?.[0]?.toUpperCase(),
    backgroundColor: "gray",
    fontColor: "white",
  }
}
