import { useFormik } from "formik"
import React, { useState } from "react"
import { useGetPagesDataQuery, useUpdatePageMutation } from "redux/cms/api"
import * as Yup from "yup"
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap"
import { showToaster } from "helpers"
export const FooterSetting = () => {
  {
    /* CMS Footer page */
  }
  const { data, isLoading, isSuccess, refetch } = useGetPagesDataQuery("footer")
  const [updatePage] = useUpdatePageMutation()
  const [btnDisable, setBtnDisable] = useState(false)
  const validation = useFormik({
    // enableReinitialize: use this flag when initial values need to be changed
    enableReinitialize: true,
    initialValues: {
      description1: data?.data?.description1 ? data?.data?.description1 : "",
      description2: data?.data?.description2 ? data?.data?.description2 : "",
      email: data?.data?.email ? data?.data?.email : "",
      address: data?.data?.address ? data?.data?.address : "",
      facebookLink: data?.data?.facebookLink ? data?.data?.facebookLink : "",
      tiktokLink: data?.data?.tiktokLink ? data?.data?.tiktokLink : "",
      twitterLink: data?.data?.twitterLink ? data?.data?.twitterLink : "",
      linkedinLink: data?.data?.linkedinLink ? data?.data?.linkedinLink : "",
      youtubeLink: data?.data?.youtubeLink ? data?.data?.youtubeLink : "",
    },
    validationSchema: Yup.object({
      description1: Yup.string().required("Description1 is required"),
      description2: Yup.string().required("Description2 is required"),
      email: Yup.string().email().required("Email is required"),
      address: Yup.string().required("Address is required"),
      facebookLink: Yup.string()
        .matches(
          /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
          "Enter a valid link",
        )
        .required("Video Link is required"),
      tiktokLink: Yup.string()
        .matches(
          /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
          "Enter a valid link",
        )
        .required("Tiktok Link is required"),
      twitterLink: Yup.string()
        .matches(
          /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
          "Enter a valid link",
        )
        .required("Twitter App Link is required"),
      linkedinLink: Yup.string()
        .matches(
          /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
          "Enter a valid link",
        )
        .required("Linkedin App Link is required"),
      youtubeLink: Yup.string()
        .matches(
          /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
          "Enter a valid link",
        )
        .required("Youtube App Link is required"),
    }),
    onSubmit: async values => {
      try {
        let apiObj = {
          pageName: "footer",
          data: values,
          section: "",
        }
        setBtnDisable(true)
        const result = await updatePage(apiObj).unwrap()
        showToaster("success", `${result.message}`, "Success")
        setBtnDisable(false)
        refetch()
      } catch (error) {
        showError(error)
      }
    },
  })
  return (
    isSuccess && (
      <Form
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
        }}
      >
        <FormGroup className="mb-4" row>
          <Label htmlFor="link" md="2" className="col-form-label">
            Email
          </Label>
          <Col md="10">
            <Input
              type="email"
              className="form-control"
              id="email"
              placeholder="Enter Email"
              name="email"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values?.email || ""}
              invalid={!!(validation.touched.email && validation.errors.email)}
            />
            {validation.touched.email && validation.errors.email && (
              <FormFeedback className="fs-6 mt-0" type="invalid">
                {validation.errors.email}
              </FormFeedback>
            )}
          </Col>
        </FormGroup>
        <FormGroup className="mb-4" row>
          <Label htmlFor="link" md="2" className="col-form-label">
            Address
          </Label>
          <Col md="10">
            <Input
              type="text"
              className="form-control"
              id="address"
              placeholder="Enter Address"
              name="address"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values?.address || ""}
              invalid={
                !!(validation.touched.address && validation.errors.address)
              }
            />
            {validation.touched.address && validation.errors.address && (
              <FormFeedback className="fs-6 mt-0" type="invalid">
                {validation.errors.address}
              </FormFeedback>
            )}
          </Col>
        </FormGroup>
        <FormGroup className="mb-4" row>
          <Label htmlFor="link" md="2" className="col-form-label">
            Facebook Link
          </Label>
          <Col md="10">
            <Input
              type="text"
              className="form-control"
              id="facebookLink"
              placeholder="Enter Facebook Link"
              name="facebookLink"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values?.facebookLink || ""}
              invalid={
                !!(
                  validation.touched.facebookLink &&
                  validation.errors.facebookLink
                )
              }
            />
            {validation.touched.facebookLink &&
              validation.errors.facebookLink && (
                <FormFeedback className="fs-6 mt-0" type="invalid">
                  {validation.errors.facebookLink}
                </FormFeedback>
              )}
          </Col>
        </FormGroup>
        <FormGroup className="mb-4" row>
          <Label htmlFor="link" md="2" className="col-form-label">
            Tiktok Link
          </Label>
          <Col md="10">
            <Input
              type="text"
              className="form-control"
              id="tiktokLink"
              placeholder="Enter Tiktok Link"
              name="tiktokLink"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values?.tiktokLink || ""}
              invalid={
                !!(
                  validation.touched.tiktokLink && validation.errors.tiktokLink
                )
              }
            />
            {validation.touched.tiktokLink && validation.errors.tiktokLink && (
              <FormFeedback className="fs-6 mt-0" type="invalid">
                {validation.errors.tiktokLink}
              </FormFeedback>
            )}
          </Col>
        </FormGroup>
        <FormGroup className="mb-4" row>
          <Label htmlFor="link" md="2" className="col-form-label">
            Twitter Link
          </Label>
          <Col md="10">
            <Input
              type="text"
              className="form-control"
              id="twitterLink"
              placeholder="Enter Twitter Link"
              name="twitterLink"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values?.twitterLink || ""}
              invalid={
                !!(
                  validation.touched.twitterLink &&
                  validation.errors.twitterLink
                )
              }
            />
            {validation.touched.twitterLink &&
              validation.errors.twitterLink && (
                <FormFeedback className="fs-6 mt-0" type="invalid">
                  {validation.errors.twitterLink}
                </FormFeedback>
              )}
          </Col>
        </FormGroup>
        <FormGroup className="mb-4" row>
          <Label htmlFor="link" md="2" className="col-form-label">
            Linkedin Link
          </Label>
          <Col md="10">
            <Input
              type="text"
              className="form-control"
              id="linkedinLink"
              placeholder="Enter Linkedin Link"
              name="linkedinLink"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values?.linkedinLink || ""}
              invalid={
                !!(
                  validation.touched.linkedinLink &&
                  validation.errors.linkedinLink
                )
              }
            />
            {validation.touched.linkedinLink &&
              validation.errors.linkedinLink && (
                <FormFeedback className="fs-6 mt-0" type="invalid">
                  {validation.errors.linkedinLink}
                </FormFeedback>
              )}
          </Col>
        </FormGroup>
        <FormGroup className="mb-4" row>
          <Label htmlFor="link" md="2" className="col-form-label">
            Youtube Link
          </Label>
          <Col md="10">
            <Input
              type="text"
              className="form-control"
              id="youtubeLink"
              placeholder="Enter Youtube Link"
              name="youtubeLink"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values?.youtubeLink || ""}
              invalid={
                !!(
                  validation.touched.youtubeLink &&
                  validation.errors.youtubeLink
                )
              }
            />
            {validation.touched.youtubeLink &&
              validation.errors.youtubeLink && (
                <FormFeedback className="fs-6 mt-0" type="invalid">
                  {validation.errors.youtubeLink}
                </FormFeedback>
              )}
          </Col>
        </FormGroup>
        <Row className="mt-4">
          <Col sm="12">
            <div className="text-sm-end">
              <button
                type="submit"
                className="btn btn-success me-2"
                disabled={btnDisable}
              >
                {btnDisable ? (
                  <i className="bx bx-loader font-size-16 label-icon align-middle me-2">
                    {" "}
                    Loading
                  </i>
                ) : (
                  <i className="bx bx-check-double font-size-16 label-icon me-2 align-middle">
                    {" "}
                    Submit
                  </i>
                )}
              </button>
              <button
                className="btn btn-danger"
                type="button"
                onClick={() => validation.resetForm()}
              >
                <i className="bx bx-block font-size-16 label-icon me-2 align-middle" />{" "}
                Cancel{" "}
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    )
  )
}
