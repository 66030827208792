import { authentiCateUserPermission } from "helpers"
import React from "react"
import { useSelector } from "react-redux"
import { Navigate, useLocation } from "react-router-dom"
import {
  selectCurrentToken,
  selectCurrentUser,
  selectPermissions,
} from "redux/authentication/slice"

const RequireAuth = props => {
  const token = useSelector(selectCurrentToken)
  const user = useSelector(selectCurrentUser)
  const location = useLocation()
  const userPermissions = useSelector(selectPermissions)
  if (!token || !user) {
    return (
      <Navigate
        to={{ pathname: "/login" }}
        state={{ from: location }}
        replace
      />
    )
  }
  if (
    token &&
    user &&
    !authentiCateUserPermission(props.userRole, userPermissions, user?.role)
  ) {
    const previousPath = "/dashboard"
    return (
      <Navigate
        to={{ pathname: previousPath }}
        state={{ from: location.pathname }}
        replace
      />
    )
  }
  return <React.Fragment>{props.children}</React.Fragment>
}

export default RequireAuth
