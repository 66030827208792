import React from "react"
export const SelectColumnFilterType = ({ column }) => {
  const { filterValue, setFilter } = column
  return (
    <select
      id="custom-select"
      className="form-select"
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value="">Active</option>
      <option value="banned">Banned</option>
      <option value="deleted">Deleted</option>
    </select>
  )
}
