import { apiSlice } from "../api"

export const rfaApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    rfaList: builder.query({
      query: props => {
        let url = `/rfa`
        if (props) {
          const {
            page = 1,
            limit = 10,
            status = "",
            type = "",
            search = "",
          } = props?.state
          url = `/rfa?${type ? "category=" + type + "&" : ""}${
            status ? "status=" + status + "&" : ""
          }${search ? "search=" + search + "&" : ""}page=${page}&limit=${limit}`
        }
        return {
          url: `${url} `,
          method: "GET",
        }
      },
    }),
  }),
})
export const { useRfaListQuery } = rfaApiSlice
