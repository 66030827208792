import { createSlice } from "@reduxjs/toolkit"

// import { useNavigate } from "react-router-dom";
const paymentsSlice = createSlice({
  name: "payments",
  initialState: {
    list: [],
    pageInfo: { limit: 10, page: 1, totalPages: 1, totalResults: 0 },
  },
  reducers: {
    setPaymentsList: (state, action) => {
      const { payments, pageInfo } = action.payload
      state.list = payments
      state.pageInfo = pageInfo
      return
    },
  },
})

export const { setPaymentsList } = paymentsSlice.actions

export default paymentsSlice.reducer

export const selectPaymentList = state => state.payments?.list
export const selectPaymentPageInfo = state => state.payments?.pageInfo
