import React, { useEffect, useMemo, useState } from "react"

//Import Breadcrumb
import { useDispatch, useSelector } from "react-redux"
import { SweetAlert, confirmAlert, showError, showToaster } from "helpers"
import { Link } from "react-router-dom"
import { UncontrolledTooltip } from "reactstrap"
import CustomTable2 from "components/Common/Table/CustomeTable2"
import { PermissionsValue, TitleText } from "./ColumnFormatters"
import { useDeleteRoleMutation, useRolesListQuery } from "redux/role/api"
import {
  selectRolesList,
  selectRolesPageInfo,
  setRolesList,
} from "redux/role/slice"

function List({ setLoad, load, onAddRole }) {
  const data = useSelector(selectRolesList)
  const pageInfo = useSelector(selectRolesPageInfo)
  const [state, setState] = useState({ search: undefined, limit: 10, page: 1 })
  const [deleteRole, deleteRoleAPI] = useDeleteRoleMutation()
  const dispatch = useDispatch()
  const onDelete = async id => {
    try {
      const confirmationMessage = `Are you sure want to Delete
                this Role ?`
      const confirmation = await confirmAlert(
        confirmationMessage,
        "warning",
        "Yes, delete it!",
      )
      if (confirmation.isConfirmed) {
        const result = await deleteRole(id).unwrap()
        setLoad(load => !load)
        SweetAlert(`${result.message}`, "success")
      }
    } catch (error) {
      showError(error)
    }
  }
  const columns = useMemo(() => [
    {
      Header: "Id",
      accessor: "id",
      // filter: false,
      Cell: cellProps => {
        return <div> {cellProps.row.original.id} </div>
      },
    },
    {
      Header: "Name",
      accessor: "name",
      filter: false,
      filterable: false,
      disableFilters: true,
      disableSortBy: true,
      Cell: cellProps => {
        return <div>{cellProps.value}</div>
      },
    },
    {
      Header: "Action",
      accessor: "action",
      disableFilters: true,
      disableSortBy: true,
      Cell: cellProps => {
        return (
          <>
            <div className="d-flex gap-2">
              {
                <Link
                  to={"#"}
                  onClick={() => onAddRole(cellProps.row.original.id)}
                  className="text-success"
                >
                  <i
                    className="mdi mdi-lead-pencil font-size-18"
                    id={`edit${cellProps.row.original.id}`}
                  />
                  <UncontrolledTooltip
                    placement="top"
                    target={`edit${cellProps.row.original.id}`}
                  >
                    Edit
                  </UncontrolledTooltip>
                </Link>
              }
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const user = cellProps.row.original
                  onDelete(user.id)
                }}
              >
                {" "}
                <i
                  className="mdi mdi-delete font-size-18"
                  id={`delete${cellProps.row.original.id}`}
                />
                <UncontrolledTooltip
                  placement="top"
                  target={`delete${cellProps.row.original.id}`}
                >
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          </>
        )
      },
    },
  ])

  const list = useRolesListQuery(state)
  useEffect(() => {
    if (list.isSuccess) {
      dispatch(setRolesList(list.data.data))
    }

    if (list.isError) {
      showError(list.error)
    }
  }, [list])

  useEffect(() => {
    list.refetch()

    return () => {
      dispatch(
        setRolesList({
          list: [],
          pageInfo: { limit: 10, page: 1, totalPages: 1, totalResults: 0 },
        }),
      )
    }
  }, [state, load])
  return (
    <React.Fragment>
      <CustomTable2
        data={data}
        list={list}
        totalRecords={pageInfo?.totalResults}
        stateAPI={state}
        setStateAPI={setState}
        columns={columns}
        createButton={{
          visible: true,
          lable: "Create Role",
          onClick: onAddRole,
        }}
      />
    </React.Fragment>
  )
}
export default List
