import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardBody,
} from "reactstrap"

// i18n
import { withTranslation } from "react-i18next"

// Redux
import { connect, useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"

import user1 from "../../../assets/images/users/avatar-1.jpg"
import { logOut, selectCurrentUser } from "redux/authentication/slice"
import LazyImageWithFallback from "components/Common/LazyImageWithFallback"
import { getAvatarValue, showFirst10Words } from "helpers/util"

const ProfileMenu = props => {
  const [menu, setMenu] = useState(false)
  const dispatch = useDispatch()
  const user = useSelector(selectCurrentUser)

  function userSelfLogOut() {
    dispatch(logOut({ selfLogout: true }))
  }
  const avatar = user?.name && getAvatarValue(user?.name)
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item d-flex align-items-center"
          id="page-header-user-dropdown"
          tag="button"
        >
          {user?.profileImage ? (
            <LazyImageWithFallback
              className="rounded-circle header-profile-user"
              src={`${user?.profileImage}?v=${Math.floor(
                Math.random() * 90 + 10,
              )}`}
              alt={user?.name[0]}
            />
          ) : (
            <div
              className="d-inline rounded-circle d-flex align-items-center justify-content-center header-profile-user"
              style={{
                backgroundColor: `${avatar?.backgroundColor}`,
                color: `${avatar?.fontColor}`,
              }}
            >
              <h4 className="font-weight-bold   mb-0">
                {avatar && avatar?.character}
              </h4>
            </div>
          )}
          <span className="d-none d-xl-inline-block ms-2 me-1 word-wrap">
            {user?.name ? showFirst10Words(user?.name?.split(" ")[0]) : "Admin"}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link
            to="/profile"
            replace={true}
            onClick={() => setMenu(!menu)}
            className="dropdown-item"
          >
            <i className="bx bx-user font-size-16 align-middle me-1"></i>
            <span>{props.t("Profile")}</span>
          </Link>
          <div className="dropdown-divider" />
          <button onClick={userSelfLogOut} className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </button>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = []
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu)),
)
