import { apiSlice } from "../api"

export const rolesSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    rolesList: builder.query({
      query: props => {
        let url = `roles`
        if (props) {
          const { page = 1, limit = 10, search = "" } = props
          url = `roles?page=${page}&limit=${limit}${
            search != "" ? "&search=" + search : ""
          }`
        }
        return {
          url: `${url}`,
          method: "GET",
        }
      },
    }),
    addRole: builder.mutation({
      query: props => {
        return {
          url: "/roles",
          method: "POST",
          body: props,
        }
      },
    }),
    getRole: builder.mutation({
      query: props => {
        return {
          url: `/roles/${props}`,
          method: "GET",
        }
      },
    }),
    updateRole: builder.mutation({
      query: props => {
        return {
          url: `/roles/${props.id}`,
          method: "PUT",
          body: props.value,
        }
      },
    }),
    deleteRole: builder.mutation({
      query: props => {
        return {
          url: `/roles/${props}`,
          method: "DELETE",
        }
      },
    }),
  }),
})
export const {
  useRolesListQuery,
  useAddRoleMutation,
  useGetRoleMutation,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
} = rolesSlice
