import { apiSlice } from "../api"

export const advApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    advList: builder.query({
      query: props => {
        let url = `/adverts`
        if (props) {
          const {
            page = 1,
            limit = 10,
            status = "",
            type = "",
            search = "",
          } = props?.state
          const userID = props?.userId
          url = `/adverts?${type ? "category=" + type + "&" : ""}${
            status ? "status=" + status + "&" : ""
          }${search ? "search=" + search + "&" : ""}page=${page}&limit=${limit}`
          if (userID) {
            url = `/adverts/user/${userID}?${type ? "category=" + type + "&" : ""}${
              status ? "status=" + status + "&" : ""
            }${search ? "search=" + search + "&" : ""}page=${page}&limit=${limit}`
          }
        }
        return {
          url: `${url} `,
          method: "GET",
        }
      },
    }),
    addAdvert: builder.mutation({
      query: payload => {
        const keys = Object.keys(payload)
        var data = new FormData()
        data.append(
          "location[coordinates][0]",
          payload.location["coordinates"][0],
        )
        data.append(
          "location[coordinates][1]",
          payload.location["coordinates"][1],
        )
        if (keys.length > 0) {
          for (const key of keys) {
            if (
              key &&
              key !== "location" &&
              key !== "lat" &&
              key !== "long" &&
              key !== "postal_code" &&
              key !== "image" &&
              key !== "categories"
            ) {
              data.append(`${key}`, payload[key])
            }
          }
        }
        if (payload.image.length > 0) {
          for (const file of payload.image) {
            if (file?.file) {
              data.append("images", file.file)
            }
          }
        }
        if (payload?.categories.length === 1) {
          data.append("categories[0]", payload?.categories)
        }
        if (payload?.categories.length > 1) {
          for (const category of payload?.categories) {
            if (category) {
              data.append("categories", category)
            }
          }
        }
        return {
          url: "/adverts",
          method: "POST",
          body: data,
        }
      },
    }),
    getAdvert: builder.mutation({
      query: props => {
        return {
          url: `/adverts/${props}`,
          method: "GET",
        }
      },
    }),
    updateAdvert: builder.mutation({
      query: payload => {
        const keys = Object.keys(payload.value)
        var data = new FormData()
        data.append(
          "location[coordinates][0]",
          payload.value.location["coordinates"][0],
        )
        data.append(
          "location[coordinates][1]",
          payload.value.location["coordinates"][1],
        )
        if (keys.length > 0) {
          for (const key of keys) {
            if (
              key &&
              key !== "user" &&
              key !== "location" &&
              key !== "lat" &&
              key !== "long" &&
              key !== "postal_code" &&
              key !== "image" &&
              key !== "categories" &&
              key !== "deleteImg"
            ) {
              data.append(`${key}`, payload.value[key])
            }
          }
        }
        if (payload?.value?.image?.length > 0) {
          for (const file of payload.value?.image) {
            if (file?.file) {
              data.append("images", file.file)
            }
          }
        }
        if (payload?.value?.deleteImg?.length > 0) {
          if (payload.value?.deleteImg.length == 1) {
            data.append("deleteImages[0]", payload.value?.deleteImg[0])
          } else {
            for (const file of payload.value?.deleteImg) {
              if (file) {
                data.append("deleteImages", file)
              }
            }
          }
        }
        return {
          url: `/adverts/${payload.id}`,
          method: "PATCH",
          body: data,
        }
      },
    }),
    deleteAdv: builder.mutation({
      query: props => {
        return {
          url: `/adverts/${props}`,
          method: "DELETE",
        }
      },
    }),
  }),
})
export const {
  useAdvListQuery,
  useAddAdvertMutation,
  useGetAdvertMutation,
  useUpdateAdvertMutation,
  useDeleteAdvMutation,
} = advApiSlice
