import React from "react"
export const SelectColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column
  return (
    <select
      id="custom-select"
      className="form-select"
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value="">All</option>
      <option value="draft">Draft</option>
      <option value="live">Live</option>
      <option value="archived">Archived</option>
      <option value="deleted">Deleted</option>
      <option value="payment-remaining">Payment Remaining</option>
      <option value="rejected">Rejected</option>
    </select>
  )
}
