import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { Container, Card, CardBody, Col, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import { Helmet } from "react-helmet"
import { useGetBlogMutation } from "redux/newsBlogs/api"
import { showError } from "helpers"
import { convertDate, convertToEmbeddedLink } from "helpers/util"
import { wordTitleCase } from "helpers/data-formatters"
import LazyImageWithFallback from "components/Common/LazyImageWithFallback"
import LoadingSpinner from "components/Common/LoadingSpinner"

const BlogDetails = () => {
  const { id } = useParams()
  const [getData, getDataAPI] = useGetBlogMutation()
  const [data, setData] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getData(id).unwrap()
        setData(result?.data)
      } catch (error) {
        showError(error)
      }
    }
    if (id) {
      fetchData()
    }
  }, [id])
  const BreadcrumbDetails = {
    breadcrumbItem: {
      label: `${data?.title}`,
      link: `news&blogs/list/${data?.title}`,
    },
    paths: [
      {
        label: "News & Blogs",
        link: "news&blogs/list",
      },
      {
        label: "List",
        link: "news&blogs/list",
      },
    ],
  }
  return (
    <React.Fragment>
      <Helmet>
        <title>
          {data?.meta?.title || "Blog Detail"} | Discover Equestrian
        </title>
        {data?.meta?.description && (
          <meta name="description" content={data?.meta?.description} />
        )}
        {data?.meta?.tags?.length && (
          <meta name="keywords" content={data?.meta?.tags?.join(", ")} />
        )}
      </Helmet>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs breadcrumbDetails={BreadcrumbDetails} />
          {getDataAPI?.isLoading || getDataAPI?.isFetching ? (
            <Col lg="12" className="w-100 d-flex justify-content-center">
              {" "}
              <LoadingSpinner />{" "}
            </Col>
          ) : (
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <Link
                      to={"/news&blogs/list"}
                      className="badge bg-white text-muted font-size-12"
                    >
                      <i className="bx bx-left-arrow-circle font-size-24 align-middle text-muted me-1"></i>{" "}
                      Back
                    </Link>
                    <div className="pt-3">
                      <div className="row justify-content-center">
                        <div className="col-xl-8">
                          <div>
                            <div className="text-center">
                              <div className="mb-4"></div>
                              <h4>{wordTitleCase(data?.title)}</h4>
                            </div>

                            <hr />
                            <div className="text-center">
                              <Row>
                                <Col sm={4}>
                                  <div>
                                    <b className="fs-bold mb-2">Category</b>
                                    <p className="font-size-15">
                                      {wordTitleCase(data?.category?.name)}
                                    </p>
                                  </div>
                                </Col>
                                <Col sm={4}>
                                  <div className="mt-4 mt-sm-0">
                                    <b className="fs-bold mb-2">Date</b>
                                    <p className="font-size-15">{`${convertDate(
                                      data?.createdAt,
                                    )}`}</p>
                                  </div>
                                </Col>
                                <Col sm={4}>
                                  <div className="mt-4 mt-sm-0">
                                    <p className="text-muted mb-2">
                                      <b>Tags</b>
                                    </p>
                                    {data?.keywords?.map((value, index) => {
                                      return (
                                        <li
                                          className="list-inline-item"
                                          key={index}
                                        >
                                          <Link
                                            to="#"
                                            className="badge bg-light text-primary font-size-12 mt-2"
                                          >
                                            {value}
                                          </Link>
                                        </li>
                                      )
                                    })}
                                  </div>
                                </Col>
                              </Row>
                            </div>
                            <div className="my-5">
                              <Row>
                                <Col
                                  lg={12}
                                  className="d-flex justify-content-center"
                                >
                                  <CardBody>
                                    {data?.mediaType &&
                                    data?.mediaType == "image" ? (
                                      <div className="d-flex justify-content-center">
                                        <LazyImageWithFallback
                                          src={data?.image?.url}
                                          alt=""
                                          className="img-thumbnail w-100 mx-auto d-block"
                                        />
                                      </div>
                                    ) : (
                                      <div className="embed-responsive embed-responsive-16by9 ratio ratio-16x9">
                                        {convertToEmbeddedLink(
                                          data?.videoLink,
                                        ) ? (
                                          <iframe
                                            title="test"
                                            className="embed-responsive-item"
                                            src={`${convertToEmbeddedLink(
                                              data?.videoLink,
                                            )}?autoplay=1&rel=0`}
                                            data-src={`${convertToEmbeddedLink(
                                              data?.videoLink,
                                            )}?autoplay=1&modestbranding=1&rel=0&hl=ru&showinfo=0&color=white`}
                                          />
                                        ) : (
                                          <LazyImageWithFallback
                                            src={""}
                                            alt=""
                                            className="img-thumbnail mx-auto d-block"
                                          />
                                        )}
                                      </div>
                                    )}
                                  </CardBody>
                                </Col>
                              </Row>
                            </div>
                            <hr />
                            <div
                              className="ql-editor my-2"
                              dangerouslySetInnerHTML={{
                                __html: data?.description,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BlogDetails
