import PropTypes from "prop-types"
import React, { useState } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

// Redux
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { useDispatch } from "react-redux"
import { useLoginMutation } from "redux/authentication/api"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// Import images
import profile from "assets/images/profile-img.png"

// Import config
import { Helmet } from "react-helmet"
import { EMAIL_REGEX } from "helpers/regex"
import { setCredentials } from "redux/authentication/slice"
import { showError, showToaster } from "helpers"
import { setAuthToken } from "helpers/util"

// Define the Login component
const Login = props => {
  const [passwordShow, setPasswordShow] = useState(false)
  const dispatch = useDispatch()
  const [login, loginAPI] = useLoginMutation()

  const validation = useFormik({
    // enableReinitialize: use this flag when initial values need to be changed
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(/^\S*$/, "Whitespace is not allowed")
        .required("Please enter your email")
        .email("Please enter a valid email")
        .matches(EMAIL_REGEX, "Please enter a valid email"),
      password: Yup.string()
        .matches(/^\S*$/, "Whitespace is not allowed")
        .required("Please enter your password")
        .min(8, "Password should have a minimum of 8 characters")
        .max(20, "Password should have a maximum of 20 characters"),
    }),
    onSubmit: async values => {
      try {
        const credentials = { ...values, role: "admin" }
        const result = await login(credentials).unwrap()
        setAuthToken(result.data.tokens.refresh.token)
        dispatch(setCredentials(result.data))
        showToaster("success", `${result.message}`, "Success")
      } catch (error) {
        showError(error)
      }
    },
  })

  return (
    <React.Fragment>
      <Helmet>
        <title>Login | Discover Equestrian</title>
      </Helmet>

      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary fs-3 pt-3 ps-3">
                        Discover Equestrian
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            !!(
                              validation.touched.email &&
                              validation.errors.email
                            )
                          }
                        />
                        {validation.touched.email &&
                          validation.errors.email && (
                            <FormFeedback className="fs-6 mt-0" type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          )}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <div className="input-group auth-pass-inputgroup">
                          <Input
                            name="password"
                            value={validation.values.password || ""}
                            type={passwordShow ? "text" : "password"}
                            placeholder="Enter Password"
                            autoComplete="off"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              !!(
                                validation.touched.password &&
                                validation.errors.password
                              )
                            }
                          />
                          <button
                            onClick={() => setPasswordShow(!passwordShow)}
                            className="btn btn-light"
                            type="button"
                            id="password-login"
                          >
                            <i
                              className={`mdi ${
                                !passwordShow
                                  ? "mdi-eye-outline"
                                  : "mdi-eye-off-outline"
                              }`}
                            ></i>
                          </button>

                          {validation.touched.password &&
                            validation.errors.password && (
                              <FormFeedback
                                className="fs-6 mt-0"
                                type="invalid"
                              >
                                {validation.errors.password}
                              </FormFeedback>
                            )}
                        </div>
                      </div>
                      <div className="mt-3 d-grid">
                        <button
                          type="submit"
                          disabled={validation.isSubmitting}
                          className={`btn btn-primary btn-block ${
                            validation.isSubmitting ? "cursor-wait" : ""
                          }`}
                        >
                          {validation.isSubmitting ? (
                            <div className="d-flex justify-content-center align-items-center">
                              <div
                                className="spinner-border spinner-border-sm me-1"
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                              <span>Log in...</span>
                            </div>
                          ) : (
                            "Log In"
                          )}
                        </button>
                      </div>
                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// PropTypes for the component
Login.propTypes = {
  history: PropTypes.object,
}

export default withRouter(Login)
