import GoogleAddressField from "components/Common/Location/GoogleLocation"
import SelectMapAddress from "components/Common/Location/SelectMapAddress"
import React from "react"
import { Col, Input, Label, FormFeedback } from "reactstrap"
import UserListDropdown from "../../../components/Common/UserListDropdown/UserListDropdown"
function FormikInput({
  validation,
  IsEdit,
  prefixOption = [],
  userOption = [],
  setMapModalShow,
  mapModalShow,
  editData,
  handleInputChange,
}) {
  return (
    <>
      <Col lg="6" md="6">
        <div className="mb-3">
          <Label className="form-label">User</Label>
          {IsEdit && !(editData?.status == "draft") ? (
            <Input
              type="select"
              name="user"
              className="form-control"
              onChange={e => {
                if (!IsEdit) {
                  validation.handleChange(e)
                }
              }}
              onBlur={validation.handleBlur}
              value={validation.values.user || ""}
              disabled={IsEdit && !(editData?.status == "draft")}
              invalid={!!(validation.touched.user && validation.errors.user)}
            >
              <option value="">Select User</option>
              {userOption.map((val, index) => {
                return (
                  <option key={index} value={`${val?.value}`}>
                    {val?.label}
                  </option>
                )
              })}
            </Input>
          ) : (
            <UserListDropdown
              userOption={editData?.status == "draft" ? userOption : null}
              formik={validation}
            />
          )}
          {validation.touched.user && validation.errors.user && (
            <div className="fs-6 mt-0 text-danger" type="invalid">
              {validation.errors.user}
            </div>
          )}
        </div>
      </Col>

      <Col lg="6" md="6">
        <div className="mb-3">
          <Label className="form-label">Type</Label>
          <Input
            type="select"
            name="type"
            className="form-control"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.type || ""}
            invalid={!!(validation.touched.type && validation.errors.type)}
          >
            <option value="">Select Type</option>
            <option value="sale">Available for sale</option>
            <option value="rent">Available for rent</option>
          </Input>
          {validation.touched.type && validation.errors.type && (
            <FormFeedback className="fs-6 mt-0" type="invalid">
              {validation.errors.type}
            </FormFeedback>
          )}
        </div>
      </Col>
      <Col lg="6" md="6">
        <div className="mb-3">
          <Label className="form-label">Title</Label>
          <Input
            name="title"
            className="form-control"
            placeholder="Enter Title"
            type="text"
            onChange={e => {
              handleInputChange("title", e)
            }}
            onBlur={validation.handleBlur}
            value={validation.values.title || ""}
            invalid={!!(validation.touched.title && validation.errors.title)}
          />
          {validation.touched.title && validation.errors.title && (
            <FormFeedback className="fs-6 mt-0" type="invalid">
              {validation.errors.title}
            </FormFeedback>
          )}
        </div>
      </Col>
      <Col lg="6" md="6">
        <div className="mb-3">
          <Label className="form-label">Number of Beds</Label>
          <Input
            name="beds"
            className="form-control"
            placeholder="Enter Number of Beds"
            type="text"
            onBlur={e => {
              validation.handleBlur(e)
              if (e.target.value == "") {
                validation.setFieldValue("beds", "0")
              }
            }}
            onChange={e => {
              if (!isNaN(e.target.value)) {
                validation.handleChange(e)
              }
            }}
            value={validation.values.beds || ""}
            invalid={!!(validation.touched.beds && validation.errors.beds)}
          />
          {validation.touched.beds && validation.errors.beds && (
            <FormFeedback className="fs-6 mt-0" type="invalid">
              {validation.errors.beds}
            </FormFeedback>
          )}
        </div>
      </Col>
      <Col lg="6" md="6">
        <div className="mb-3">
          <Label className="form-label">Number of Bathrooms</Label>
          <Input
            name="bathrooms"
            className="form-control"
            placeholder="Enter Number of Bathrooms"
            type="text"
            onChange={e => {
              if (!isNaN(e.target.value)) {
                validation.handleChange(e)
              }
            }}
            onBlur={e => {
              validation.handleBlur(e)
              if (e.target.value == "") {
                validation.setFieldValue("bathrooms", "0")
              }
            }}
            value={validation.values.bathrooms || ""}
            invalid={
              !!(validation.touched.bathrooms && validation.errors.bathrooms)
            }
          />
          {validation.touched.bathrooms && validation.errors.bathrooms && (
            <FormFeedback className="fs-6 mt-0" type="invalid">
              {validation.errors.bathrooms}
            </FormFeedback>
          )}
        </div>
      </Col>
      <Col lg="6" md="6">
        <div className="mb-3">
          <Label className="form-label">Area (Acres)</Label>
          <Input
            name="area"
            className="form-control"
            placeholder="Enter Area (Acres)"
            type="text"
            onChange={e => {
              if (
                !isNaN(e.target.value) &&
                !/\.\d{3,}/.test(e.target.value) &&
                e.target.value <= 1000000000000000 &&
                String(e.target.value) != "00"
              ) {
                validation.handleChange(e)
              }
            }}
            onBlur={validation.handleBlur}
            value={validation.values.area || ""}
            invalid={!!(validation.touched.area && validation.errors.area)}
          />
          {validation.touched.area && validation.errors.area && (
            <FormFeedback className="fs-6 mt-0" type="invalid">
              {validation.errors.area}
            </FormFeedback>
          )}
        </div>
      </Col>
      <Col lg="6" md="6">
        <div className="mb-3">
          <Label className="form-label">Price (GBP)</Label>
          <Input
            name="price"
            className="form-control"
            placeholder="Enter Price"
            type="text"
            onChange={e => {
              if (
                !isNaN(e.target.value) &&
                e.target.value.indexOf(".") === -1 &&
                e.target.value < 100000000000 &&
                String(e.target.value) != "0"
              ) {
                validation.handleChange(e)
              }
            }}
            onBlur={validation.handleBlur}
            value={validation.values.price || ""}
            invalid={!!(validation.touched.price && validation.errors.price)}
          />
          {validation.touched.price && validation.errors.price && (
            <FormFeedback className="fs-6 mt-0" type="invalid">
              {validation.errors.price}
            </FormFeedback>
          )}
        </div>
      </Col>
      <Col lg="6" md="6">
        <div className="mb-3">
          <Label className="form-label">Address</Label>
          <button
            type="button"
            className="btn btn-link btn-sm bg-light ms-1"
            onClick={() => setMapModalShow(true)}
          >
            {" "}
            Pin on map
          </button>
          {mapModalShow && (
            <SelectMapAddress
              mapModalShow={mapModalShow}
              setMapModalShow={setMapModalShow}
              formik={validation}
            />
          )}
          <GoogleAddressField
            isPropertyLocation={true}
            formik={validation}
            type="text"
            className="form-control goo"
            placeholder="Find Your Address"
            id="address"
            name="address"
            invalid={
              !!(validation.touched.address && validation.errors.address)
            }
          />
          {validation.touched.address && validation.errors.address && (
            <FormFeedback className="fs-6 mt-0" type="invalid">
              {validation.errors.address}
            </FormFeedback>
          )}
        </div>
      </Col>
      <Col lg="6" md="6">
        <div className="mb-3">
          <Label className="form-label">Add Prefix</Label>
          <Input
            type="select"
            name="prefixId"
            className="form-control"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.prefixId || ""}
            invalid={
              !!(validation.touched.prefixId && validation.errors.prefixId)
            }
          >
            <option value="">Select Prefix</option>
            {prefixOption.map((val, index) => {
              return (
                <option key={index} value={`${val?.id}`}>
                  {val?.name}
                </option>
              )
            })}
          </Input>
          {validation.touched.prefixId && validation.errors.prefixId && (
            <FormFeedback className="fs-6 mt-0" type="invalid">
              {validation.errors.prefixId}
            </FormFeedback>
          )}
        </div>
      </Col>
      <Col lg="6" md="6">
        <div className="mb-3">
          <Label className="form-label">Video Link</Label>
          <Input
            name="videoLink"
            className="form-control"
            placeholder="Enter Video Link"
            type="text"
            onChange={e => {
              handleInputChange("videoLink", e)
            }}
            onBlur={validation.handleBlur}
            value={validation.values.videoLink || ""}
            invalid={
              !!(validation.touched.videoLink && validation.errors.videoLink)
            }
          />
          {validation.touched.videoLink && validation.errors.videoLink && (
            <FormFeedback className="fs-6 mt-0" type="invalid">
              {validation.errors.videoLink}
            </FormFeedback>
          )}
        </div>
      </Col>
      <Col
        lg="12"
        md="12"
        className="d-flex gap-5 justify-content-between flex-wrap"
      >
        <div className="mt-1 d-flex ">
          <Label className="form-label pe-3">Stables: </Label>
          <div className="d-flex  gap-3">
            <Label check>
              <Input
                type="radio"
                name="stable"
                value={"yes"}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                checked={validation.values.stable === "yes"}
              />{" "}
              Yes
            </Label>
            <Label check>
              <Input
                type="radio"
                name="stable"
                value={"no"}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                checked={validation.values.stable === "no"}
              />{" "}
              No
            </Label>
          </div>
          {validation.touched.stable && validation.errors.stable && (
            <FormFeedback className="fs-6 mt-0" type="invalid">
              {validation.errors.stable}
            </FormFeedback>
          )}
        </div>

        <div className="mt-1  d-flex  ">
          <Label className="form-label pe-3">Outbuilding(s): </Label>
          <div className="d-flex  gap-3">
            <Label check>
              <Input
                type="radio"
                name="outBuilding"
                value={"yes"}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                checked={validation.values.outBuilding === "yes"}
              />{" "}
              Yes
            </Label>
            <Label check>
              <Input
                type="radio"
                name="outBuilding"
                value={"no"}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                checked={validation.values.outBuilding === "no"}
              />{" "}
              No
            </Label>
          </div>
          {validation.touched.outBuilding && validation.errors.outBuilding && (
            <FormFeedback className="fs-6 mt-0" type="invalid">
              {validation.errors.outBuilding}
            </FormFeedback>
          )}
        </div>

        <div className="mt-1  d-flex  ">
          <Label className="form-label pe-3">Manège: </Label>
          <div className="d-flex  gap-3">
            <Label check>
              <Input
                type="radio"
                name="manage"
                value={"yes"}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                checked={validation.values.manage === "yes"}
              />{" "}
              Yes
            </Label>
            <Label check>
              <Input
                type="radio"
                name="manage"
                value={"no"}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                checked={validation.values.manage === "no"}
              />{" "}
              No
            </Label>
          </div>
          {validation.touched.manage && validation.errors.manage && (
            <FormFeedback className="fs-6 mt-0" type="invalid">
              {validation.errors.manage}
            </FormFeedback>
          )}
        </div>

        <div className="mt-1  d-flex  ">
          <Label className="form-label pe-3">Annex: </Label>
          <div className="d-flex  gap-3">
            <Label check>
              <Input
                type="radio"
                name="annex"
                value={"yes"}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                checked={validation.values.annex === "yes"}
              />{" "}
              Yes
            </Label>
            <Label check>
              <Input
                type="radio"
                name="annex"
                value={"no"}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                checked={validation.values.annex === "no"}
              />{" "}
              No
            </Label>
          </div>
          {validation.touched.annex && validation.errors.annex && (
            <FormFeedback className="fs-6 mt-0" type="invalid">
              {validation.errors.annex}
            </FormFeedback>
          )}
        </div>

        <div className="mt-1  d-flex mb-2  ">
          <Label className="form-label pe-3">Auto Update: </Label>
          <div className="d-flex  gap-3">
            <Label check>
              <Input
                type="radio"
                name="autoUpdate"
                value={"yes"}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                checked={validation.values.autoUpdate === "yes"}
              />{" "}
              Yes
            </Label>
            <Label check>
              <Input
                type="radio"
                name="autoUpdate"
                value={"no"}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                checked={validation.values.autoUpdate === "no"}
              />{" "}
              No
            </Label>
          </div>
          {validation.touched.autoUpdate && validation.errors.autoUpdate && (
            <FormFeedback className="fs-6 mt-0" type="invalid">
              {validation.errors.autoUpdate}
            </FormFeedback>
          )}
        </div>
      </Col>
    </>
  )
}

export default FormikInput
