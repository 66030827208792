import { apiSlice } from "../api"

export const dashboardApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    dashboardData: builder.mutation({
      query: props => {
        const duration = props
        var url = `/analytics?filter=${duration}`
        return {
          url: `${url} `,
          method: "GET",
        }
      },
    }),
  }),
})
export const { useDashboardDataMutation } = dashboardApiSlice
