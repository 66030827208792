import React from "react"
import { Container } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Helmet } from "react-helmet"
import { useGetPagesDataQuery } from "redux/cms/api"
import CmsTab from "../Common/Tab"
function BusinessServices() {
  const { data, isLoading, isSuccess, refetch } =
    useGetPagesDataQuery("business-service")

  const BreadcrumbDetails = {
    breadcrumbItem: {
      label: "Business & Services",
      link: "/pages/businessServices",
    },
    paths: [
      {
        label: "Pages",
        link: "/pages/businessServices",
      },
    ],
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Business & Services | Discover Equestrian</title>
      </Helmet>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs breadcrumbDetails={BreadcrumbDetails} />
          <CmsTab
            data={data}
            isLoading={isLoading}
            isSuccess={isSuccess}
            tab={"Business & Services"}
            refetch={refetch}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BusinessServices
