import { getAuthToken } from "helpers/util"
import { apiSlice } from "../api"

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    login: builder.mutation({
      query: credentials => ({
        url: "/auth/login",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    refresh: builder.mutation({
      query: refreshToken => ({
        url: "/auth/refresh-tokens",
        method: "POST",
        body: { ...refreshToken },
      }),
      extraOptions: {
        customRefresh: true,
      },
    }),
    userProfile: builder.mutation({
      query: () => ({
        url: "/users",
        method: "GET",
      }),
    }),
    logout: builder.mutation({
      query: refreshToken => ({
        url: "/auth/logout",
        method: "POST",
        body: { ...refreshToken },
      }),
    }),
    forgotPassword: builder.mutation({
      query: payload => ({
        url: "/auth/forgot-password",
        method: "POST",
        body: { ...payload },
      }),
    }),
    resetPassword: builder.mutation({
      query: payload => ({
        url: `/auth/reset-password${payload[1]}`,
        method: "POST",
        body: { ...payload[0] },
      }),
    }),
    updateProfile: builder.mutation({
      query: payload => ({
        url: "/users",
        method: "PATCH",
        body: { ...payload },
      }),
    }),
    updateProfileImg: builder.mutation({
      query: body => {
        return {
          url: "/users/profile-picture",
          method: "PUT",
          body,
        }
      },
    }),
    changePassword: builder.mutation({
      query: payload => ({
        url: "/users/change-password",
        method: "PUT",
        body: { ...payload },
      }),
    }),
    userChangePassword: builder.mutation({
      query: ({ payload, id }) => ({
        url: `/users/change-password/${id}`,
        method: "PUT",
        body: { ...payload },
      }),
    }),
    checkTokenValidity: builder.mutation({
      query: payload => ({
        url: "/auth/check-token-validity",
        method: "POST",
        body: { ...payload },
      }),
    }),
    GetPermission: builder.mutation({
      query: payload => ({
        url: "/auth/permissions",
        method: "GET",
      }),
    }),
  }),
})
export const {
  useLoginMutation,
  useRefreshMutation,
  useUserProfileMutation,
  useLogoutMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useUpdateProfileMutation,
  useUpdateProfileImgMutation,
  useChangePasswordMutation,
  useUserChangePasswordMutation,
  useCheckTokenValidityMutation,
  useGetPermissionMutation,
} = authApiSlice
