import { createSlice } from "@reduxjs/toolkit"

// import { useNavigate } from "react-router-dom";
const rolesSlice = createSlice({
  name: "roles",
  initialState: {
    list: [],
    pageInfo: { limit: 10, page: 1, totalPages: 1, totalResults: 0 },
  },
  reducers: {
    setRolesList: (state, action) => {
      const { roles, pageInfo } = action.payload
      state.list = roles
      state.pageInfo = pageInfo
      return
    },
  },
})

export const { setRolesList } = rolesSlice.actions

export default rolesSlice.reducer

export const selectRolesList = state => state.roles?.list
export const selectRolesPageInfo = state => state.roles?.pageInfo
