import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"

const Breadcrumb = props => {
  const { breadcrumbDetails } = props
  const { breadcrumbItem, paths } = breadcrumbDetails
  return (
    <Row>
      <Col className="col-12">
        <div className="page-title-box align-items-center justify-content-between">
          <h4 className="mb-sm-0 font-size-18">{breadcrumbItem.label}</h4>
          <div className="page-title-left mt-2">
            <ol className="breadcrumb m-0">
              {paths?.map((path, index) => {
                return (
                  <BreadcrumbItem key={index}>
                    <Link
                      to={`/${path.link}`}
                      className={path.link == breadcrumbItem.link && "pe-none"}
                    >
                      {path.label}
                    </Link>
                  </BreadcrumbItem>
                )
              })}
              {paths?.length ? (
                <BreadcrumbItem active>
                  <Link
                    to={`/${breadcrumbItem.link}`}
                    className="text-info pe-none"
                  >
                    {breadcrumbItem.label}
                  </Link>
                </BreadcrumbItem>
              ) : (
                <></>
              )}
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default Breadcrumb
