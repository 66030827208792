import { createSlice } from "@reduxjs/toolkit"

// import { useNavigate } from "react-router-dom";
const propertySlice = createSlice({
  name: "property",
  initialState: {
    list: [],
    pageInfo: { limit: 10, page: 1, totalPages: 1, totalResults: 0 },
  },
  reducers: {
    setPropertiesList: (state, action) => {
      const { properties, pageInfo } = action.payload
      state.list = properties
      state.totalRecord = pageInfo
      return
    },
  },
})

export const { setPropertiesList } = propertySlice.actions

export default propertySlice.reducer

export const selectPropertyList = state => state.property?.list
export const selectPropertyTotalRecord = state => state.property?.totalRecord
