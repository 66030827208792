import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { Container, Row, Col } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { Helmet } from "react-helmet"
import MiniCards from "./mini-card"
import Analiytics from "./Chart/Analytics"
import { useDispatch, useSelector } from "react-redux"
import { authentiCateUserPermission, showError } from "helpers"
import { addDays, subDays } from "date-fns"
import moment from "moment"
import { useDashboardDataMutation } from "redux/dashboard/api"
import {
  selectDashboardAnalytics,
  selectDashboardCount,
  setdashboard,
} from "redux/dashboard/slice"
import {
  formatBigNumber,
  wordTitleCase,
  wordsTitleCase,
} from "helpers/data-formatters"
import { generateMonthRangeArray } from "helpers/util"
import config from "config"
import {
  selectCurrentUser,
  selectPermissions,
} from "redux/authentication/slice"
import LoadingSpinner from "components/Common/LoadingSpinner"

const Dashboard = props => {
  const [card, setCard] = useState([])
  const user = useSelector(selectCurrentUser)
  const userPermission = useSelector(selectPermissions)
  const BreadcrumbDetails = {
    breadcrumbItem: {
      label: "Dashboard",
      link: "/dashboard",
    },
  }
  // State variables
  const [duration, setDuration] = useState("thisMonth")
  const [selectedRange, setSelectedRange] = useState([
    moment(
      moment(new Date()).startOf("month").format("YYYY-MM-DD"),
      "YYYY-MM-DD",
    ),
    moment(new Date(), "YYYY-MM-DD"),
  ])
  const [chartData, setChartData] = useState({
    advertCount: [],
    propertyCount: [],
    Xaxis: [],
  })

  // Ref and hooks
  const flatpickrRef = useRef(null)
  const dispatch = useDispatch()
  const analytics = useSelector(selectDashboardAnalytics)
  const counts = useSelector(selectDashboardCount)
  const [getDashboardRecord, getDashboardRecordAPI] = useDashboardDataMutation()
  useEffect(() => {
    var saveData = []
    const cardData = Object.keys(counts).map(key => {
      if (Object.keys(counts[key]).length > 0) {
        Object.keys(counts[key]).map(subKey => {
          const configData = config?.DASHBOARD_CARD[subKey + key] || {
            title: `${wordsTitleCase(subKey + key)}`,
            iconClass: "bx bxs-user",
          }
          saveData = [
            ...saveData,
            {
              text: formatBigNumber(counts[key][subKey]),
              ...configData,
            },
          ]
          return
        })
      } else {
        const dataTemp = config?.DASHBOARD_CARD[key] || {
          title: `${wordsTitleCase(key)}`,
          iconClass: "bx bxs-user",
        }
        saveData = [
          ...saveData,
          { text: formatBigNumber(counts[key]), ...dataTemp },
        ]
        return
      }
    })
    setCard(saveData)
  }, [counts])

  // Function to handle the click of the duration buttons
  const handeClick = (day, numberOfDay) => {
    setDuration(day)
    if (day == "thisMonth") {
      setSelectedRange([
        moment(
          moment(new Date()).startOf("month").format("YYYY-MM-DD"),
          "YYYY-MM-DD",
        ),
        moment(new Date(), "YYYY-MM-DD"),
      ])
    } else if (day == "last6Months") {
      setSelectedRange([
        moment(moment().subtract(5, "months"), "YYYY-MM"),
        moment(new Date(), "YYYY-MM"),
      ])
    } else if (day == "thisYear") {
      setSelectedRange([
        moment(moment(new Date()).startOf("year").format("YYYY-MM"), "YYYY-MM"),
        moment(new Date(), "YYYY-MM"),
      ])
    } else {
      setSelectedRange([
        [
          moment(moment().subtract(11, "months"), "YYYY-MM"),
          moment(new Date(), "YYYY-MM"),
        ],
      ])
    }
  }

  // Function to handle date changes in the Flatpickr
  const handleDateChange = selectedDates => {
    setSelectedRange(selectedDates)
    if (selectedDates?.length === 1) {
      const maxDate = addDays(selectedDates[0], 60)
      flatpickrRef.current.flatpickr.set("minDate", new Date(selectedDates[0]))
      flatpickrRef.current.flatpickr.set(
        "maxDate",
        maxDate > new Date() ? new Date() : maxDate,
      )
    } else {
      setDuration(selectedDates)
      // If date range is selected, clear minDate and maxDate restrictions
      flatpickrRef.current.flatpickr.set("maxDate", null)
      flatpickrRef.current.flatpickr.set("minDate", null)
    }
  }

  const handelOpen = () => {
    // If date range is selected, clear minDate and maxDate restrictions
    flatpickrRef.current.flatpickr.set("maxDate", new Date())
  }

  // Function to generate chart data from campaigns data
  const generateChartData = () => {
    const tempChartData = {
      advertCount: [],
      propertyCount: [],
      bannerCount: [],
      propertyImpressions: [],
      Xaxis: [],
    }
    let dateArray
    if (duration == "thisMonth") {
      dateArray = generateMonthRangeArray(
        [selectedRange[0], selectedRange[1]],
        "YYYY-MM-DD",
        "days",
      )
    } else if (duration == "last6Months") {
      dateArray = generateMonthRangeArray([selectedRange[0], selectedRange[1]])
    } else if (duration == "thisYear") {
      dateArray = generateMonthRangeArray([selectedRange[0], selectedRange[1]])
    } else if (duration == "all") {
      const avalibleDateAdvert = analytics?.adverts.map(val => {
        return val.date
      })
      const avalibleDateProperty = analytics?.properties.map(val => {
        return val.date
      })
      dateArray = generateMonthRangeArray(
        [...avalibleDateProperty, ...avalibleDateAdvert],
        "YYYY",
        "years",
      )
    }
    tempChartData.Xaxis = dateArray

    // // Propeties Array set
    if (analytics && analytics?.properties) {
      const Property = analytics?.properties
        ?.slice()
        .sort((a, b) => new Date(a.date) - new Date(b.date))
      const avalibleDate = Property.map(val => {
        return val.date
      })
      const propertiesCount = dateArray.map(date => {
        if (avalibleDate.includes(date)) {
          const index = avalibleDate.findIndex(element => element == date)
          const count = Property[index].clickCount
          return count
        } else {
          return 0
        }
      })

      tempChartData.propertyCount = propertiesCount
    }

    // business & service Array Create
    if (analytics && analytics.adverts) {
      const Adverts = analytics?.adverts
        ?.slice()
        .sort((a, b) => new Date(a.date) - new Date(b.date))
      const avalibleDate = Adverts.map(val => {
        return val.date
      })
      const advertsCount = dateArray.map(date => {
        if (avalibleDate.includes(date)) {
          const index = avalibleDate.findIndex(element => element == date)
          const count = Adverts[index].clickCount
          return count
        } else {
          return 0
        }
      })

      tempChartData.advertCount = advertsCount
    }

    // Adverts Array set
    if (analytics && analytics?.banners) {
      const banner = analytics?.banners
        ?.slice()
        .sort((a, b) => new Date(a.date) - new Date(b.date))
      const avalibleDate = banner.map(val => {
        return val.date
      })
      const bannersCount = dateArray.map(date => {
        if (avalibleDate.includes(date)) {
          const index = avalibleDate.findIndex(element => element == date)
          const count = banner[index].clickCount
          return count
        } else {
          return 0
        }
      })

      tempChartData.bannerCount = bannersCount
    }

    //propertyImpressions
    if (analytics && analytics?.propertyImpressions) {
      const propertyImpressions = analytics?.propertyImpressions
        ?.slice()
        .sort((a, b) => new Date(a.date) - new Date(b.date))
      const avalibleDate = propertyImpressions.map(val => {
        return val.date
      })
      const propertyImpressionsCount = dateArray.map(date => {
        if (avalibleDate.includes(date)) {
          const index = avalibleDate.findIndex(element => element == date)
          const count = propertyImpressions[index].impressionCount
          return count
        } else {
          return 0
        }
      })

      tempChartData.propertyImpressions = propertyImpressionsCount
    }
    const propertiesCount = _.sum(tempChartData.propertyCount)
    const advertsCount = _.sum(tempChartData.advertCount)
    const bannersCount = _.sum(tempChartData.bannerCount)
    const propertyImpressions = _.sum(tempChartData.propertyImpressions)
    setChartData({
      ...chartData,
      ...tempChartData,
      totalAdvertsClick: advertsCount,
      totalPropertiesClick: propertiesCount,
      totalBannersClick: bannersCount,
      totalPropertyImpressions: propertyImpressions,
    })
  }
  // // Effect to fetch data when duration changes
  useEffect(() => {
    const getData = async () => {
      var timeDuration = {}
      if (duration == "cutsomRange") {
        const startdate = moment(selectedRange[0]).format("YYYY-MM-DD")
        const enddate = moment(selectedRange[1]).format("YYYY-MM-DD")
        timeDuration = { startDate: startdate, endDate: enddate }
      }
      try {
        const result = await getDashboardRecord(
          duration,
          // timeDuration: timeDuration,
        ).unwrap()
        dispatch(setdashboard(result.data))
      } catch (error) {
        showError(error)
      }
    }
    if (authentiCateUserPermission(["dashboard"], userPermission, user?.role)) {
      getData()
    }
  }, [duration])
  // Effect to generate chart data when campaigns data changes
  useEffect(() => {
    if (analytics?.adverts && analytics?.properties) {
      generateChartData()
    }
  }, [analytics?.adverts, analytics?.properties])
  // Chart options and series
  const series = [
    {
      name: "Properties Visits",
      data: chartData?.propertyCount || [],
    },
    {
      name: "Property Impressions",
      data: chartData?.propertyImpressions || [],
    },
    {
      name: "Businesses & Services Visits",
      data: chartData?.advertCount || [],
    },
    {
      name: "Adverts Visits",
      data: chartData?.bannerCount || [],
    },
  ]
  const options = {
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    colors: ["#FFC154", "#039e91", "#5b55aa", "#7aaa55"],
    dataLabels: {
      enabled: false,
      trim: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [20, 100, 100, 100],
      },
    },
    xaxis: {
      categories: chartData?.Xaxis?.map(
        value => value[value.length - 2] + value[value.length - 1],
      ),
    },

    markers: {
      size: 3,
      strokeWidth: 3,

      hover: {
        size: 4,
        sizeOffset: 2,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
    tooltip: {
      x: {
        formatter: function (
          value,
          { series, seriesIndex, dataPointIndex, w },
        ) {
          return chartData.Xaxis[value - 1]
        },
      },
      y: {
        formatter: function (
          value,
          { series, seriesIndex, dataPointIndex, w },
        ) {
          return formatBigNumber(value)
        },
      },
    },
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Dashboard | Discover Equestrian</title>
      </Helmet>

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs breadcrumbDetails={BreadcrumbDetails} />
          {authentiCateUserPermission(
            ["dashboard"],
            userPermission,
            user?.role,
          ) &&
            (getDashboardRecordAPI?.isLoading ||
            getDashboardRecordAPI?.isFetching ? (
              <Col lg="12" className="w-100 d-flex justify-content-center">
                {" "}
                <LoadingSpinner />{" "}
              </Col>
            ) : (
              <Row>
                <Col xl="12">
                  <Row>
                    {/* Reports Render */}
                    {card.map((report, key) => (
                      <MiniCards {...report} key={key} />
                    ))}
                  </Row>
                  <Col>
                    <Analiytics
                      handeClick={handeClick}
                      duration={duration}
                      flatpickrRef={flatpickrRef}
                      selectedRange={selectedRange}
                      handleDateChange={handleDateChange}
                      handelOpen={handelOpen}
                      series={series}
                      options={options}
                      chartData={chartData}
                    />
                  </Col>
                </Col>
              </Row>
            ))}
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
