import { wordTitleCase } from "helpers/data-formatters"
import React from "react"
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Card,
  CardBody,
  Table,
} from "reactstrap"

function EmailViewModel({ openModal, setOpenModal, emailData, setEmailData }) {
  const toggle = () => {
    if (openModal) {
      setOpenModal(openModal => !openModal)
      setEmailData([])
    }
  }

  return (
    <React.Fragment>
      <Modal
        size="md"
        isOpen={openModal}
        toggle={toggle}
        backdrop={"static"}
        centered={true}
      >
        <ModalHeader toggle={toggle} tag="h4">
          Council Email
        </ModalHeader>
        {emailData && emailData?.length > 0 && (
          <ModalBody style={{ maxHeight: "70vh", overflow: "auto" }}>
            <Row className="m-3">
              <Table className="table-bordered">
                <thead>
                  <tr>
                    <th style={{ width: "20px" }}>#</th>
                    <th> Email</th>
                  </tr>
                </thead>
                <tbody>
                  {emailData &&
                    emailData?.map((value, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row"> {index + 1}</th>
                          <td> {value}</td>
                        </tr>
                      )
                    })}
                </tbody>
              </Table>
            </Row>
          </ModalBody>
        )}
      </Modal>
    </React.Fragment>
  )
}

export default EmailViewModel
