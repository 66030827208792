import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api"
import React, { useEffect, useState } from "react"
// import { Button, Modal } from "react-bootstrap";
import config from "../../../config"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

const containerStyle = {
  width: "100%",
  height: "400px",
}

const ukBounds = {
  north: 60.85,
  south: 49.86,
  east: 2.69,
  west: -8.64,
}
const SelectMapAddress = ({ setMapModalShow, formik, mapModalShow }) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    libraries: config.MAP_LIBRARIES,
    googleMapsApiKey: config.GOOGLE_MAP_KEY,
  })
  const [markers, setMarkers] = useState([]) // State to store selected markers on the map
  const [selectedAddress, setSelectedAddress] = useState("") // State to store the selected address
  const [center, setCenter] = useState({
    lat: 54.807662160773084,
    lng: -2.7232523667169573,
  })
  useEffect(() => {
    if (formik?.values?.lat) {
      setMarkers([
        {
          lat: formik?.values?.lat ? formik?.values?.lat : undefined,
          lng: formik?.values?.long ? formik?.values?.long : undefined,
        },
      ])
      setCenter({
        lat: formik?.values?.lat ? formik?.values?.lat : undefined,
        lng: formik?.values?.long ? formik?.values?.long : undefined,
      })
    }
  }, [formik?.values?.lng])
  // Function to handle a map click event and add a marker to the map
  const onMapClick = e => {
    setMarkers(() => [
      {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      },
    ])
  }
  // Effect to fetch the address when a marker is selected
  useEffect(() => {
    if (markers.length > 0) {
      const geocoder = new window.google.maps.Geocoder()
      const latLng = markers[0]

      geocoder.geocode({ location: latLng }, (results, status) => {
        if (status === "OK" && results[0]) {
          const address = results[0].formatted_address
          formik.touched.address = false
          setSelectedAddress(address)
        }
      })
    }
  }, [markers])

  // Function to handle form submission
  const onSubmit = () => {
    setMapModalShow(false) // Close the map modal
    formik.setFieldValue("address", selectedAddress)
    formik.setFieldValue("lat", markers[0]?.lat)
    formik.setFieldValue("long", markers[0]?.lng)
  }
  return (
    <Modal
      isOpen={mapModalShow}
      toggle={() => setMapModalShow(false)}
      backdrop={"static"}
      keyboard={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalHeader>Select Location</ModalHeader>
      <ModalBody>
        <div className="mb-3">
          <b>Selected Address:</b> {selectedAddress}
        </div>

        {isLoaded ? (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={6}
            onClick={onMapClick}
            options={{
              restriction: {
                latLngBounds: ukBounds,
                strictBounds: true,
              },
              styles: [
                {
                  featureType: "poi",
                  elementType: "labels",
                  stylers: [
                    {
                      visibility: "off",
                    },
                  ],
                },
                {
                  featureType: "transit.station",
                  stylers: [
                    {
                      visibility: "off",
                    },
                  ],
                },
              ],
              disableDefaultUI: true, // This removes all default UI controls
              zoomControl: true, // You can enable the zoom control separately if needed
              streetViewControl: true, // This removes the street view icon
              mapTypeControl: true, // This removes the satellite view option
            }}
          >
            {markers?.map((marker, index) => (
              <Marker
                key={index}
                position={{
                  lat: marker.lat,
                  lng: marker.lng,
                }}
              />
            ))}
          </GoogleMap>
        ) : (
          <></>
        )}
      </ModalBody>
      <ModalFooter>
        <Button variant="secondary" onClick={() => setMapModalShow(false)}>
          Close
        </Button>
        <Button variant="primary" onClick={() => onSubmit()}>
          Select
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default SelectMapAddress
