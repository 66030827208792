import React, { Fragment, useEffect, useMemo, useState } from "react"
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  useFilters,
  usePagination,
  useAsyncDebounce,
} from "react-table"

import { Table, Row, Col, Button, Input } from "reactstrap"
import {
  Filter,
  DefaultColumnFilter,
  SelectColumnFilter,
  GlobalFilter,
} from "./Filters"
import LoadingSpinner from "../LoadingSpinner"
import { TableRangeFooterLabel } from "./TableRangeFooterLabel"

const CustomTable2 = ({
  data = [],
  columns = [],
  totalRecords = 0,
  stateAPI = { limit: 10, search: "", page: 1 },
  setStateAPI = () => {},
  list = { isFetching: false, isLoading: false },
  deleteButton,
  createButton = {
    visible: false,
    lable: "Create New",
    onClick: () => {},
  },
}) => {
  const [pSize, setPSize] = useState(10)
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    visibleColumns,
    state: { pageIndex, pageSize, globalFilter, filters },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        // type: pSize.type,
        sortBy: [
          {
            desc: true,
            id: "id",
          },
        ],
        hiddenColumns: ["id"],
      },
      autoResetFilters: false,
      autoResetGlobalFilter: false,
      autoResetSortBy: false,
      manualPagination: true,
      manualSortBy: true,
      manualFilters: true,
      autoResetPage: false,
      pageCount: Math.ceil((totalRecords || 10) / pSize),
    },
    useFilters,
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
  )
  useEffect(() => {
    if (state?.globalFilter != stateAPI.search) {
      gotoPage(0)
      setStateAPI({
        ...stateAPI,
        search: state?.globalFilter,
        page: 1,
        limit: state.pageSize,
      })
    } else {
      setStateAPI({
        ...stateAPI,
        search: state?.globalFilter,
        page: state.pageIndex + 1,
        limit: state.pageSize,
      })
    }
  }, [state?.globalFilter, state.pageIndex, state.pageSize])
  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const onChangeInPageSize = event => {
    setPageSize(Number(event.target.value))
    setPSize(Number(event.target.value))
  }

  // const onGoToPage = event => {
  //   const page = event.target.value ? Number(event.target.value - 1) : 0
  //   gotoPage(page)
  // }
  const onGoToPage = useAsyncDebounce(event => {
    const page = event.target.value ? Number(event.target.value - 1) : 0
    gotoPage(page)
  }, 500)
  useEffect(() => {
    if (
      !list.isLoading &&
      !list.isFetching &&
      !data.length &&
      !totalRecords &&
      pageIndex - 1 > 0
    ) {
      gotoPage(pageIndex - 1)
    }
  }, [data])
  return (
    <Fragment>
      <Row className="mb-2">
        <Col md={2}>
          <select
            className="form-select"
            value={pageSize}
            onChange={onChangeInPageSize}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        {deleteButton && deleteButton?.selectedRows?.length > 0 && (
          <Col>
            <div className="text-sm-start">
              <Button
                type="button"
                color="danger"
                className="btn mb-2 me-2"
                onClick={() => deleteButton.onClick(deleteButton.selectedRows)}
              >
                <i className="mdi mdi-delete me-1" />
                Delete
              </Button>
            </div>
          </Col>
        )}
        {createButton?.visible && (
          <Col>
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2"
                onClick={() => createButton.onClick()}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                {createButton.lable}
              </Button>
            </div>
          </Col>
        )}
      </Row>

      <div className="table-responsive react-table">
        <Table bordered hover {...getTableProps()}>
          <thead className="table-light table-nowrap">
            <tr>
              <th
                colSpan={visibleColumns.length}
                style={{
                  textAlign: "left",
                }}
              >
                <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
              </th>
            </tr>

            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    key={column.id}
                    style={
                      column.maxSize
                        ? { width: `${column.maxSize}px`, wordWrap: "anywhere" }
                        : {}
                    }
                  >
                    <div className="mb-2" {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                    <Filter column={column} />
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {(list?.isFetching || list?.isLoading) && (
              <tr>
                <td
                  colSpan={visibleColumns.length}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <LoadingSpinner />
                </td>
              </tr>
            )}

            {!page?.length && !list?.isFetching && !list?.isLoading && (
              <tr>
                <td
                  colSpan={visibleColumns.length}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <p>
                    {" "}
                    Data Not Found{" "}
                    <span
                      style={{
                        fontSize: "100px;",
                      }}
                    >
                      &#128581;
                    </span>
                  </p>
                </td>
              </tr>
            )}

            {!list?.isFetching &&
              !list?.isLoading &&
              page.map(row => {
                prepareRow(row)
                return (
                  <Fragment key={row.getRowProps().key}>
                    <tr>
                      {row.cells.map(cell => {
                        return (
                          <td key={cell.id} {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        )
                      })}
                    </tr>
                  </Fragment>
                )
              })}
          </tbody>
        </Table>
      </div>

      {totalRecords ? (
        <Row className="justify-content-md-end justify-content-center align-items-center mb-2">
          <Col className="col justify-content-start">
            <TableRangeFooterLabel
              pageIndex={pageIndex}
              pageSize={pageSize}
              totalRecords={totalRecords}
            />
          </Col>
          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                color="primary"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </Button>
              <Button
                color="primary"
                onClick={previousPage}
                disabled={!canPreviousPage}
              >
                {"<"}
              </Button>
            </div>
          </Col>
          <Col className="col-md-auto d-none d-md-block">
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </Col>
          <Col className="col-md-auto">
            <Input
              type="number"
              min={1}
              style={{ width: 70 }}
              max={pageOptions.length}
              defaultValue={pageIndex + 1}
              onChange={onGoToPage}
            />
          </Col>
          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                color="primary"
                onClick={nextPage}
                disabled={!canNextPage}
              >
                {">"}
              </Button>
              <Button
                color="primary"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </Button>
            </div>
          </Col>
        </Row>
      ) : (
        <></>
      )}
    </Fragment>
  )
}
export default CustomTable2
