import { createSlice } from "@reduxjs/toolkit"

const advetPaymentsSlice = createSlice({
  name: "advetPayments",
  initialState: {
    list: [],
    pageInfo: { limit: 10, page: 1, totalPages: 1, totalResults: 0 },
  },
  reducers: {
    setAdvetPaymentsList: (state, action) => {
      const { payments, pageInfo } = action.payload
      state.list = payments
      state.pageInfo = pageInfo
      return
    },
  },
})

export const { setAdvetPaymentsList } = advetPaymentsSlice.actions

export default advetPaymentsSlice.reducer

export const selectadvetPaymentsList = state => state.advetPayments?.list
export const selectadvetPaymentsPageInfo = state =>
  state.advetPayments?.pageInfo
