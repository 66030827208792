import Slidewithindicator from "components/Common/Carousel"
import LazyImageWithFallback from "components/Common/LazyImageWithFallback"
import { asset, convertToEmbeddedLink, getPath } from "helpers/util"
import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { Modal, ModalBody, Row, Table } from "reactstrap"
import { useGetPagesDataQuery } from "redux/cms/api"

const FaqView = ({ View, setView }) => {
  const [modal_center, setmodal_center] = useState(true)
  const [carouselData, setCarouselData] = useState([])
  const { pathname } = useLocation()
  const { data, isLoading, isSuccess, refetch } = useGetPagesDataQuery(
    `${
      pathname === "/pages/settings" ? "advertise-with-us" : getPath(pathname)
    }/${View}`,
  )
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  function tog_center(id) {
    setmodal_center(!modal_center)
    removeBodyCss()
  }
  useEffect(() => {
    if (View && modal_center) {
      refetch()
    }
    if (!modal_center) {
      setView("")
    }
  }, [modal_center, View])
  useEffect(() => {
    const images = data?.data?.faq?.images || []
    const videoLink = data?.data?.faq?.videoLink
    if (videoLink) {
      const imageArrayWithVideo = [...images, { videoLink: videoLink }]
      setCarouselData(imageArrayWithVideo)
    } else {
      setCarouselData(images)
    }
  }, [data?.data?.faq?.images, data?.data?.faq?.videoLink])
  return (
    <Modal
      size="lg"
      isOpen={modal_center}
      toggle={() => {
        tog_center()
      }}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">View FAQ</h5>
        <button
          type="button"
          onClick={() => {
            setmodal_center(false)
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      {isSuccess && (
        <ModalBody>
          <Table>
            <thead>
              <tr style={{ fontSize: "14px" }}>
                <th
                  style={{
                    width: "20px",
                    borderRadius: "7px 0px 0px 7px",
                    backgroundColor: "#556ee6",
                    color: "#fff",
                  }}
                >
                  Question:
                </th>
                <td
                  style={{
                    wordWrap: "break-word",
                    borderRadius: "0px 7px 7px 0px",
                    backgroundColor: "#556ee6",
                    color: "#fff",
                  }}
                >
                  {" "}
                  {data?.data?.faq?.que}{" "}
                </td>
              </tr>
            </thead>
            <tbody>
              <tr style={{ fontSize: "14px" }}>
                <th
                  style={{
                    width: "20px",
                    borderRadius: "7px 0px 0px 7px",
                  }}
                >
                  Answer:
                </th>
                <td
                  style={{
                    wordWrap: "break-word",
                    borderRadius: "0px 7px 7px 0px",
                  }}
                >
                  {" "}
                  {data?.data?.faq?.ans}{" "}
                </td>
              </tr>
            </tbody>
          </Table>
          <Row>
            {(data?.data?.faq?.images?.length ||
              data?.data?.faq?.videoLink) && (
              <Slidewithindicator ImageArray={carouselData} isFaq={true} />
            )}
          </Row>
        </ModalBody>
      )}
    </Modal>
  )
}
export default FaqView
