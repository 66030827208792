import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useGetSettingsQuery } from "redux/charges/api"
import { Link } from "react-router-dom"
import { formatBigNumber, wordTitleCase } from "helpers/data-formatters"
import EditModel from "./EditModel"
import config from "config"
import LoadingSpinner from "components/Common/LoadingSpinner"

function Charges() {
  const BreadcrumbDetails = {
    breadcrumbItem: {
      label: "Charges",
      link: "/setting/charges",
    },
    paths: [
      {
        label: "Setting",
        link: "/setting/charges",
      },
    ],
  }
  const [load, setLoad] = useState(false)
  const [data, setData] = useState({})
  const [openEditModel, setOpenEditModel] = useState(false)
  const handleCloseModal = () => {
    setOpenEditModel(false)
  }
  const handleOpenModal = () => {
    setOpenEditModel(true)
  }
  const list = useGetSettingsQuery("charges")
  useEffect(() => {
    if (list.isSuccess) {
      setData(list.data.data)
    }

    if (list.isError) {
      showError(list.error)
    }
  }, [list?.data])
  useEffect(() => {
    list.refetch()
  }, [load])
  return (
    <React.Fragment>
      <Helmet>
        <title>Charges Manage | Discover Equestrian</title>
      </Helmet>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}

          <Breadcrumbs breadcrumbDetails={BreadcrumbDetails} />
          {list?.isLoading || list?.isFetching ? (
            <Col lg="12" className="w-100 d-flex justify-content-center">
              {" "}
              <LoadingSpinner />{" "}
            </Col>
          ) : (
            <Row>
              <Col lg="12">
                <Col md="12">
                  <div className="d-flex justify-content-end me-3 ">
                    <button
                      className="btn btn-sm btn-primary  mb-3 h-50 align-self-end"
                      onClick={() => handleOpenModal()}
                    >
                      <div className="d-flex align-item-center justify-content-center pt-1">
                        {" "}
                        Edit{" "}
                        <i className="bx bxs-edit-alt font-size-16 text-text ps-1" />
                      </div>
                    </button>
                  </div>
                </Col>
                <Row className="d-flex flex-row flex-wrap">
                  {data?.value &&
                    Object.keys(data?.value).map((key, index) => {
                      return (
                        <Col md="3" key={index}>
                          <Card className="mini-stats-wid">
                            <CardBody>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <p className="text-muted fw-medium mb-2">
                                    {config?.CHARGES_LABEL[key]?.name}
                                  </p>
                                </div>
                                <Badge
                                  className={
                                    "font-size-15 badge-soft-info rounded pt-1"
                                  }
                                >
                                  {" "}
                                  £ {formatBigNumber(data.value[key])}
                                </Badge>{" "}
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      )
                    })}
                </Row>
              </Col>
            </Row>
          )}
          <EditModel
            load={load}
            setLoad={setLoad}
            onCancel={handleCloseModal}
            isOpen={openEditModel}
            data={data?.value}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Charges
