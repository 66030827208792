import React, { useEffect, useState } from "react"
import { showError } from "helpers"
import {
  Badge,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap"
import { useGetCrmDetailMutation } from "redux/user/CRM/api"

function ViewModel({ openModal, setOpenModal, id = null, setId }) {
  const [getData, getDataAPI] = useGetCrmDetailMutation()
  const [data, setData] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getData(id).unwrap()
        setData(result?.data)
      } catch (error) {
        showError(error)
      }
    }
    if (id) {
      fetchData()
    }
  }, [id])
  const toggle = () => {
    if (openModal) {
      setOpenModal(openModal => !openModal)
      setId(null)
      setData([])
    }
  }
  // const [showLicenseKey, setShowLicenseKey] = useState(false);
  // const encryptedLicenseKey = "XXXX-XXXX-XXXX-XXXX";

  // const toggleShowLicenseKey = () => {
  //     setShowLicenseKey(!showLicenseKey);
  // };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Modal
            // size="lg"
            isOpen={openModal}
            toggle={toggle}
            backdrop={"static"}
            centered={true}
          >
            <ModalHeader toggle={toggle} tag="h4" className="align-items-start">
              {data?.crm}
            </ModalHeader>
            <ModalBody>
              <Row>
                <Table className=" bg-white mt-3">
                  <tbody>
                    {/* {data?.authentication?.licenseKey && <tr>
                      <th style={{ width: "150px" }} className="bg-white pt-3">
                        License Key
                      </th> */}
                    {/* <td className="bg-white d-flex justify-content-between align-items-center">
                        {data?.authentication?.licenseKey}{" "} */}
                    {/* {showLicenseKey ? data?.authentication?.licenseKey : encryptedLicenseKey} */}
                    {/* <span
                                                    className="ms-0"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={toggleShowLicenseKey}
                                                >
                                                 <i
                      className={`mdi ${
                        !showLicenseKey
                          ? "mdi-eye-outline"
                          : "mdi-eye-off-outline"
                      } font-size-18 p-0 m-0`}
                    ></i> 
                                                </span> */}
                    {/* </td>
                    </tr>} */}
                    <tr>
                      <th
                        style={{ width: "150px", borderStyle: "none" }}
                        className="bg-white"
                      >
                        Categories
                      </th>
                      <td
                        style={{ borderStyle: "none" }}
                        className=" bg-white d-flex flex-wrap gap-2"
                      >
                        {" "}
                        {data?.filters?.categories &&
                          Object?.keys(data?.filters?.categories).map(
                            (value, index) => {
                              return (
                                <Badge
                                  className={
                                    "font-size-12 badge-soft-dark text-primary"
                                  }
                                  key={index}
                                >
                                  {data?.filters?.categories[value]}{" "}
                                </Badge>
                              )
                            },
                          )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Row>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ViewModel
