import { useFormik } from "formik"
import { formatBigNumber, formatDate } from "helpers/data-formatters"
import React from "react"
import {
  Badge,
  CardBody,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"

function RejectModel({ openModal, toggle, formik, data = {} }) {
  const { notes, totalNotes } = data
  return (
    <React.Fragment>
      <Modal
        // size="lg"

        isOpen={openModal}
        toggle={toggle}
        backdrop={"static"}
        centered={true}
      >
        <ModalHeader
          toggle={toggle}
          tag="h4"
          className="d-flex justify-content-center"
        >
          {"Comment"}{" "}
          {totalNotes && totalNotes > 0 && (
            <Badge
              color="primary"
              style={{
                fontSize: "14px",
                padding: "5px 10px",
                margin: "0px 0px",
              }}
            >
              {formatBigNumber(totalNotes)}
            </Badge>
          )}
        </ModalHeader>
        <ModalBody style={{ maxHeight: "70vh", overflow: "auto" }}>
          {!(notes?.length > 0) && (
            <Form
              className="outer-repeater"
              onSubmit={e => {
                e.preventDefault()
                formik.handleSubmit()
                // return
              }}
            >
              <div data-repeater-list="outer-group" className="outer">
                <div data-repeater-item className="outer">
                  <FormGroup className="mb-4" row>
                    <Col lg="12">
                      <Input
                        id="comments"
                        name="comments"
                        type="textarea"
                        className="form-control"
                        placeholder="Add Comment..."
                        value={formik.values.comments || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        invalid={
                          !!(formik.touched.comments && formik.errors.comments)
                        }
                      />
                      {formik.touched.comments && formik.errors.comments && (
                        <FormFeedback className="fs-6 mt-0" type="invalid">
                          {formik.errors.comments}
                        </FormFeedback>
                      )}
                    </Col>
                  </FormGroup>
                </div>
              </div>
              <Row className="">
                <div className="modal-footer">
                  <Col className="text-end">
                    {/* <button
                      type="button"
                      className="btn btn-secondary me-2"
                      onClick={() => toggle()}
                    >
                      <i className="mdi mdi-close-thick label-icon"></i>
                      Close
                    </button> */}
                    <button
                      type="submit"
                      disabled={formik.isSubmitting}
                      className={`btn btn-danger w-sm ${
                        formik.isSubmitting ? "cursor-wait" : "cursor-pointer"
                      }`}
                    >
                      {formik.isSubmitting ? (
                        <div className="d-flex justify-content-center align-items-center">
                          <div
                            className="spinner-border spinner-border-sm me-1"
                            role="status"
                          >
                            <span className="sr-only">{"Processing"}...</span>
                          </div>
                          <span>{"Processing"}...</span>
                        </div>
                      ) : (
                        <>Reject</>
                      )}
                    </button>
                  </Col>
                </div>
              </Row>
            </Form>
          )}
          {notes?.length > 0 && (
            <Row>
              <Col>
                <CardBody>
                  {notes.map((value, index) => {
                    return (
                      <div key={index} className="border-bottom">
                        <p className="text-mute m-0 px-0 py-2">
                          {formatDate(new Date(value.createdAt))}
                        </p>
                        <p className="text-black">{value.comments}</p>
                        <hr />
                      </div>
                    )
                  })}
                </CardBody>
              </Col>
            </Row>
          )}
        </ModalBody>
        {/* {notes?.length > 0 && (
          <ModalFooter>
            <Row>
              <Col className="text-end">
                <button
                  type="button"
                  className="btn btn-secondary me-2"
                  onClick={() => toggle()}
                >
                  <i className="mdi mdi-close-thick label-icon"></i>
                  Close
                </button>
              </Col>
            </Row>
          </ModalFooter>
        )} */}
      </Modal>
    </React.Fragment>
  )
}

export default RejectModel
