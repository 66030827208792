import { apiSlice } from "../api"

export const propetyApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    propertyPayment: builder.query({
      query: props => {
        let url = `/payment`
        if (props) {
          const { page = 1, limit = 10, search = "" } = props?.state
          const type = props?.type
          url = `/payment?${
            type ? "type=" + type + "&" : ""
          }page=${page}&limit=${limit}${
            search != "" ? "&search=" + search : ""
          }`
        }
        return {
          url: `${url} `,
          method: "GET",
        }
      },
    }),
  }),
})
export const { usePropertyPaymentQuery } = propetyApiSlice
