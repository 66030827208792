import { createSlice } from "@reduxjs/toolkit"
import {
  layoutTypes,
  layoutModeTypes,
  layoutWidthTypes,
  topBarThemeTypes,
  leftBarThemeImageTypes,
  leftSidebarTypes,
  leftSideBarThemeTypes,
} from "../../constants/layout"
const INIT_STATE = {
  layoutType: layoutTypes.VERTICAL,
  layoutModeType: layoutModeTypes.LIGHT,
  layoutWidth: layoutWidthTypes.FLUID,
  leftSideBarTheme: leftSideBarThemeTypes.DARK,
  leftSideBarThemeImage: leftBarThemeImageTypes.NONE,
  leftSideBarType: leftSidebarTypes.DEFAULT,
  topbarTheme: topBarThemeTypes.LIGHT,
  isPreloader: false,
  showRightSidebar: false,
  isMobile: false,
  showSidebar: true,
  leftMenu: false,
}
// import { useNavigate } from "react-router-dom";
const LayoutSlice = createSlice({
  name: "Layout",
  initialState: INIT_STATE,
  reducers: {
    changeLayout: (state, action) => {
      state.layoutType = action.payload
    },

    changePreloader: (state, action) => {
      state.isPreloader = action.payload
    },

    changeLayoutMode: (state, action) => {
      state.layoutModeType = action.payload
    },

    changeLayoutWidth: (state, action) => {
      state.layoutWidth = action.payload
    },

    changeSidebarTheme: (state, action) => {
      state.leftSideBarTheme = action.payload
    },

    changeSidebarThemeImage: (state, action) => {
      state.leftSideBarThemeImage = action.payload
    },

    changeSidebarType: (state, action) => {
      state.leftSideBarType = action.payload
    },

    changeTopbarTheme: (state, action) => {
      state.topbarTheme = action.payload
    },

    showRightSidebarAction: (state, action) => {
      state.showRightSidebar = action.payload
    },

    showSidebar: (state, action) => {
      state.showSidebar = action.payload
    },

    toggleLeftmenu: (state, action) => {
      state.leftMenu = action.payload
    },
  },
})

export const {
  changeLayout,
  changeLayoutMode,
  changeLayoutWidth,
  changePreloader,
  changeSidebarTheme,
  changeSidebarThemeImage,
  changeSidebarType,
  changeTopbarTheme,
  showRightSidebarAction,
  showSidebar,
  toggleLeftmenu,
} = LayoutSlice.actions

export default LayoutSlice.reducer

export const selectCurrentlayoutType = state => state.Layout.layoutType
export const selectCurrentlayoutModeType = state => state.Layout.layoutModeType
export const selectCurrentlayoutWidth = state => state.Layout.layoutWidth
export const selectCurrentleftSideBarTheme = state =>
  state.Layout.leftSideBarTheme
export const selectCurrentleftSideBarThemeImage = state =>
  state.Layout.leftSideBarThemeImage
export const selectCurrentleftSideBarType = state =>
  state.Layout.leftSideBarType
export const selectCurrenttopbarTheme = state => state.Layout.topbarTheme
export const selectCurrentisPreloader = state => state.Layout.isPreloader
export const selectCurrentshowRightSidebar = state =>
  state.Layout.showRightSidebar
export const selectCurrentisMobile = state => state.Layout.isMobile
export const selectCurrentshowSidebar = state => state.Layout.showSidebar
export const selectCurrentleftMenu = state => state.Layout.leftMenu
