import CustomTable2 from "components/Common/Table/CustomeTable2"
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { TitleText } from "./ColumnFormatters"
import {
  SweetAlert,
  authentiCateUserPermission,
  confirmAlert,
  showError,
} from "helpers"
import {
  selectCategoryList,
  selectCategoryPageInfo,
  setCategoryList,
} from "redux/category/slice"
import {
  useCategoryListQuery,
  useDeleteCategoryMutation,
} from "redux/category/api"
import { Link } from "react-router-dom"
import { UncontrolledTooltip } from "reactstrap"
import {
  selectCurrentUser,
  selectPermissions,
} from "redux/authentication/slice"
import LazyImageWithFallback from "components/Common/LazyImageWithFallback"

function List({ load, setLoad, onCategoryAdd, categoryType = null }) {
  const data = useSelector(selectCategoryList)
  const pageInfo = useSelector(selectCategoryPageInfo)
  const [state, setState] = useState({ search: undefined, limit: 10, page: 1 })
  const dispatch = useDispatch()
  const [deleteCategory, deleteCategoryAPI] = useDeleteCategoryMutation()
  const [selectedRows, setSelectedRows] = useState([])
  const userPermissions = useSelector(selectPermissions)
  const user = useSelector(selectCurrentUser)

  const onDelete = async id => {
    try {
      const confirmationMessage = `You want to delete this Category!`
      const confirmation = await confirmAlert(
        confirmationMessage,
        "warning",
        "Yes, delete it!",
      )
      if (confirmation.isConfirmed) {
        const result = await deleteCategory(id).unwrap()
        setLoad(load => !load)
        setSelectedRows([])
        SweetAlert(`${result.message}`, "success")
      }
    } catch (error) {
      setSelectedRows([])
      showError(error)
    }
  }
  const handleCheckAll = e => {
    if (e.target.checked && data) {
      const allIds = data?.map(item => item.id)
      setSelectedRows(allIds)
    } else {
      setSelectedRows([])
    }
  }
  const handleRowCheck = rowId => {
    if (selectedRows.includes(rowId)) {
      setSelectedRows(selectedRows.filter(id => id !== rowId))
    } else {
      setSelectedRows([...selectedRows, rowId])
    }
  }
  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        // filter: false,
        Cell: cellProps => {
          return <div> {cellProps.row.original.id} </div>
        },
      },
      {
        Header: (
          <input
            type="checkbox"
            onChange={e => handleCheckAll(e)}
            checked={
              selectedRows?.length && selectedRows?.length === data?.length
            }
          />
        ),
        accessor: "selectAll",
        filterable: false,
        disableFilters: true,
        disableSortBy: true,
        maxSize: 2,
        Cell: cellProps => {
          const rowId = cellProps.row.original.id
          const isChecked = selectedRows?.includes(rowId)
          return (
            <div className="d-flex gap-3" style={{ width: "1px" }}>
              <input
                type="checkbox"
                checked={isChecked}
                onChange={() => handleRowCheck(rowId)}
              />
            </div>
          )
        },
      },
      {
        Header: "Image",
        accessor: "image.url",
        filter: false,
        filterable: false,
        disableFilters: true,
        maxSize: 150,
        disableSortBy: true,
        Cell: cellProps => {
          return (
            <LazyImageWithFallback width={"100%"} src={`${cellProps?.value}`} />
          )
        },
      },
      {
        Header: "Name",
        accessor: "name",
        filter: false,
        filterable: false,
        disableFilters: true,
        disableSortBy: true,
        Cell: cellProps => {
          return <TitleText {...cellProps} />
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        disableSortBy: true,
        Cell: cellProps => {
          return (
            <>
              <div className="d-flex gap-2">
                {authentiCateUserPermission(
                  ["updateCategory"],
                  userPermissions,
                  user?.role,
                ) && (
                  <Link
                    to={"#"}
                    onClick={() => onCategoryAdd(cellProps.row.original.id)}
                    className="text-success"
                  >
                    <i
                      className="mdi mdi-lead-pencil font-size-18"
                      id={`edit${cellProps.row.original.id}`}
                    />
                    <UncontrolledTooltip
                      placement="top"
                      target={`edit${cellProps.row.original.id}`}
                    >
                      Edit
                    </UncontrolledTooltip>
                  </Link>
                )}
                {authentiCateUserPermission(
                  ["deleteCategory"],
                  userPermissions,
                  user?.role,
                ) && (
                  <Link
                    to="#"
                    className="text-danger"
                    onClick={() => {
                      const user = cellProps.row.original
                      onDelete([user.id])
                    }}
                  >
                    {" "}
                    <i
                      className="mdi mdi-delete font-size-18"
                      id={`delete${cellProps.row.original.id}`}
                    />
                    <UncontrolledTooltip
                      placement="top"
                      target={`delete${cellProps.row.original.id}`}
                    >
                      Delete
                    </UncontrolledTooltip>
                  </Link>
                )}
              </div>
            </>
          )
        },
      },
    ],
    [selectedRows],
  )
  const list = useCategoryListQuery({ state: state, type: categoryType })

  useEffect(() => {
    if (list.isSuccess) {
      dispatch(setCategoryList(list.data.data))
    }

    if (list.isError) {
      showError(list.error)
    }
  }, [list])
  useEffect(() => {
    list.refetch()
    setSelectedRows([])
    return () => {
      dispatch(
        setCategoryList({
          categories: [],
          pageInfo: { limit: 10, page: 1, totalPages: 1, totalResults: 0 },
        }),
      )
    }
  }, [state, load, categoryType])
  var Table2Prop = {
    data: data,
    list: list,
    totalRecords: pageInfo?.totalResults,
    stateAPI: state,
    setStateAPI: setState,
    columns: columns,
    deleteButton: authentiCateUserPermission(
      ["deleteCategory"],
      userPermissions,
      user?.role,
    )
      ? { selectedRows: selectedRows, onClick: onDelete }
      : undefined,
    createButton: authentiCateUserPermission(
      ["createCategory"],
      userPermissions,
      user?.role,
    )
      ? {
          visible: true,
          lable: "Create Category",
          onClick: onCategoryAdd,
        }
      : undefined,
  }
  return (
    <React.Fragment>
      <CustomTable2 {...Table2Prop} />
    </React.Fragment>
  )
}
//
export default List
