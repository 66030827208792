import React, { useEffect, useState } from "react"
import { showError, showToaster } from "helpers"
import { Helmet } from "react-helmet"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useFormik } from "formik"
import * as Yup from "yup"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  useAddRoleMutation,
  useGetRoleMutation,
  useUpdateRoleMutation,
} from "redux/role/api"
import { permission } from "config"
import { useGetPermissionMutation } from "redux/authentication/api"
function RoleAdd() {
  const navigate = useNavigate()
  const [addRole, addRoleAPI] = useAddRoleMutation()
  const [getpermisionArray, getpermisionArrayAPI] = useGetPermissionMutation()
  const [getData, getDataAPI] = useGetRoleMutation()
  const [updateRole, UpdateRoleAPI] = useUpdateRoleMutation()
  const [permissionArray, setPermissionArray] = useState([])
  // edit Edit
  const { pathname } = useLocation()
  const IsEdit = pathname?.includes("edit")
  const [editInfo, setEditInfo] = useState([])
  const { id } = useParams()

  const BreadcrumbDetails = {
    breadcrumbItem: {
      label: !IsEdit ? "Add" : "Edit",
      link: !IsEdit ? "role/add" : `${pathname}`,
    },
    paths: [
      {
        label: "Role",
        link: !IsEdit ? "role/add" : "user/list",
      },
    ],
  }
  // list of permision checkbox
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getpermisionArray().unwrap()
        setPermissionArray(result?.data.admin)
      } catch (error) {
        // remove this line when api chenge comlete and uncomment below line
        setPermissionArray(error?.data?.data?.admin)
        // showError(error)
      }
    }
    fetchData()
  }, [])
  // get edit role data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getData(id).unwrap()
        setEditInfo(result?.data)
      } catch (error) {
        showError(error)
      }
    }
    if (id) {
      fetchData()
    }
  }, [id])
  // Yup validation
  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required("Name is required"),
  })
  // formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: IsEdit ? editInfo.name : "",
    },
    validationSchema,
    onSubmit: async values => {
      const { name, ...permission } = values
      const key = Object.keys(permission)
      const newpermissions = key.filter(
        val => !Object.keys(permissionArray).includes(val) && permission[val],
      )
      const data = { name: name, permissions: newpermissions }
      if (!IsEdit) {
        try {
          const result = await addRole(data).unwrap()
          navigate("/role/list")
          showToaster("success", `${result.message}`, "Success")
        } catch (error) {
          showError(error)
        }
      } else {
        try {
          const result = await updateRole({
            value: data,
            id: id,
          }).unwrap()
          navigate("/role/list")
          showToaster("success", `${result.message}`, "Success")
        } catch (error) {
          showError(error)
        }
      }
    },
  })
  const requiredCheck = (val, IsCheck) => {
    Object.keys(permissionArray[val]).map(value => {
      if (IsCheck == false) {
        formik.setFieldValue(`${value}`, IsCheck)
      }
      if (permissionArray[val][value].isRequired) {
        formik.setFieldValue(`${value}`, IsCheck)
      }
      return
    })
  }
  useEffect(() => {
    if (editInfo?.permissions) {
      editInfo.permissions.map(value => {
        formik.setFieldValue(`${value}`, true)

        Object.keys(permissionArray).map(key => {
          const subkeyArray = Object.keys(permissionArray[key])
          if (subkeyArray.includes(value)) {
            formik.setFieldValue(`${key}`, true)
          }
        })
      })
    }
  }, [editInfo])
  return (
    <React.Fragment>
      <Helmet>
        <title>
          {!IsEdit ? "Add Role" : "Edit User"} | Discover Equestrian
        </title>
      </Helmet>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbDetails={BreadcrumbDetails} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    {!IsEdit ? "Create Role" : "Update Role"}
                  </CardTitle>
                  <Form
                    className="outer-repeater"
                    onSubmit={e => {
                      e.preventDefault()
                      formik.handleSubmit()
                      // return
                    }}
                  >
                    <div data-repeater-list="outer-group" className="outer">
                      <div data-repeater-item className="outer">
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="name"
                            className="col-form-label col-lg-2"
                          >
                            Name
                          </Label>
                          <Col lg="10">
                            <Input
                              id="name"
                              name="name"
                              type="text"
                              className="form-control"
                              placeholder="Enter User Name..."
                              value={formik.values.name || ""}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              invalid={
                                !!(formik.touched.name && formik.errors.name)
                              }
                            />
                            {formik.touched.name && formik.errors.name && (
                              <FormFeedback
                                className="fs-6 mt-0"
                                type="invalid"
                              >
                                {formik.errors.name}
                              </FormFeedback>
                            )}
                          </Col>
                        </FormGroup>
                        {Object.keys(permissionArray).map((val, inx) => {
                          const subcheckboxKey = Object.keys(
                            permissionArray[val],
                          )
                          return (
                            <div key={inx}>
                              <FormGroup check>
                                <Label check>
                                  <Input
                                    type="checkbox"
                                    name={`${val}`}
                                    checked={formik.values[val]} // Use val as a property accessor
                                    onChange={e => {
                                      formik.setFieldValue(
                                        `${val}`,
                                        e.target.checked,
                                      )
                                      requiredCheck(val, e.target.checked)
                                    }}
                                  />{" "}
                                  {val}
                                </Label>
                              </FormGroup>
                              <div className="ps-5">
                                {formik.values[val] && // Conditionally render sub-checkboxes based on val's state
                                  subcheckboxKey.map((value, index) => {
                                    return (
                                      <div key={index} className="pl-4">
                                        <FormGroup check>
                                          <Label check>
                                            <Input
                                              type="checkbox"
                                              name={`${value}`}
                                              checked={formik.values[value]} // Use value as a property accessor
                                              onChange={e => {
                                                if (
                                                  !permissionArray[val][value]
                                                    .isRequired
                                                ) {
                                                  formik.setFieldValue(
                                                    `${value}`,
                                                    e.target.checked,
                                                  )
                                                }
                                              }}
                                              disabled={
                                                permissionArray[val][value]
                                                  .isRequired
                                              }
                                            />{" "}
                                            {permissionArray[val][value].name}{" "}
                                            {permissionArray[val][value]
                                              .isRequired && (
                                              <span className="text-danger">
                                                {" "}
                                                Required
                                              </span>
                                            )}
                                          </Label>
                                        </FormGroup>
                                      </div>
                                    )
                                  })}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                    <Row className="mb-3">
                      <Col className="text-end">
                        <button
                          type="submit"
                          disabled={formik.isSubmitting}
                          className={`btn btn-success w-md ${
                            formik.isSubmitting
                              ? "cursor-wait"
                              : "cursor-pointer"
                          }`}
                        >
                          {formik.isSubmitting ? (
                            <div className="d-flex justify-content-center align-items-center">
                              <div
                                className="spinner-border spinner-border-sm me-1"
                                role="status"
                              >
                                <span className="sr-only">Adding...</span>
                              </div>
                              <span>Adding...</span>
                            </div>
                          ) : !IsEdit ? (
                            "Add"
                          ) : (
                            "update"
                          )}
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default RoleAdd
