import React, { useEffect, useMemo, useState } from "react"

//Import Breadcrumb
import CustomTable from "components/Common/Table/CustomeTable"
import { useDispatch, useSelector } from "react-redux"
import { showError } from "helpers"
import { Card, CardBody, UncontrolledTooltip } from "reactstrap"

import { Adderss, Date, TitleText, Type } from "./ColumnFormatters"
import {
  selectReportList,
  selectReportTotalRecord,
  setReportList,
} from "redux/report/slice"
import { useReportListQuery } from "redux/report/api"
import { SelectColumnFilter } from "./Filter"
import { Link } from "react-router-dom"
import EmailViewModel from "pages/ReportingLocation/List/EmailViewModel"
function List() {
  const data = useSelector(selectReportList)
  const pageInfo = useSelector(selectReportTotalRecord)
  const [state, setState] = useState({
    search: undefined,
    type: null,
    limit: 10,
    page: 1,
    status: null,
  })
  const dispatch = useDispatch()
  const columns = useMemo(() => [
    {
      Header: "Id",
      accessor: "id",
      // filter: false,
      Cell: cellProps => {
        return <div> {cellProps.row.original.id} </div>
      },
    },
    {
      Header: "User Name",
      accessor: "user.name",
      // filter: false,
      filterable: false,
      disableFilters: true,
      disableSortBy: true,
      maxSize: 300,
      Cell: cellProps => {
        return <TitleText {...cellProps} />
      },
    },
    {
      Header: "Date",
      accessor: "createdAt",
      filterable: false,
      disableFilters: true,
      disableSortBy: true,
      maxSize: 200,
      Cell: cellProps => {
        return <Date {...cellProps} />
      },
    },
    {
      Header: "Type",
      accessor: "type",
      filter: "equals", // Set the filter property to specify filter type
      Filter: SelectColumnFilter, // Specify the custom filter component
      disableFilters: false, // Enable filtering for the column
      disableSortBy: true,
      maxSize: 200,
      Cell: cellProps => {
        return <Type {...cellProps} />
      },
    },
    {
      Header: "Location",
      accessor: "location",
      // filter: false,
      filterable: false,
      disableFilters: true,
      disableSortBy: true,
      maxSize: 300,
      Cell: cellProps => {
        return <Adderss {...cellProps} />
      },
    },
    {
      Header: "Location",
      accessor: "location.name",
      // filter: false,
      filterable: false,
      disableFilters: true,
      disableSortBy: true,
      // maxSize: 500,
      Cell: cellProps => {
        return <div> {cellProps.value} </div>
      },
    },
    {
      Header: "Action",
      accessor: "",
      // filter: false,
      filterable: false,
      disableFilters: true,
      disableSortBy: true,
      maxSize: 100,
      Cell: cellProps => {
        return (
          <>
            <div className="d-flex gap-2">
              {cellProps?.row?.original?.authorities?.length > 0 ? (
                <Link
                  to={"/#"}
                  onClick={e => {
                    e.preventDefault()
                    if (cellProps?.row?.original?.authorities?.length) {
                      const councilEmails =
                        cellProps?.row?.original?.authorities
                      onshowMessage(councilEmails)
                    }
                  }}
                  className="text-info"
                >
                  <i
                    className="mdi mdi-email font-size-18"
                    id={`CouncilEmail${cellProps.row.original._id}`}
                  />
                  <UncontrolledTooltip
                    placement="top"
                    target={`CouncilEmail${cellProps.row.original._id}`}
                  >
                    Authorities Email
                  </UncontrolledTooltip>
                </Link>
              ) : (
                "-"
              )}
            </div>
          </>
        )
      },
    },
  ])

  const list = useReportListQuery({ state: state })
  if (list.isSuccess) {
    dispatch(setReportList(list.data.data))
  }

  if (list.isError) {
    showError(list.error)
  }

  useEffect(() => {
    list.refetch()

    return () => {
      dispatch(
        setReportList({
          list: [],
          pageInfo: { limit: 10, page: 1, totalPages: 1, totalResults: 0 },
        }),
      )
    }
  }, [state])

  const [openEmailModal, setOpenEmailModal] = useState(false)
  const [emailData, setEmailData] = useState(false)
  const onshowMessage = emailData => {
    if (emailData) {
      setEmailData(emailData)
    }
    setOpenEmailModal(true)
  }
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CustomTable
            data={data}
            list={list}
            totalRecords={pageInfo?.totalResults}
            stateAPI={state}
            setStateAPI={setState}
            columns={columns}
            hideColumns={["location.name"]}
          />
        </CardBody>
      </Card>
      {openEmailModal && (
        <EmailViewModel
          openModal={openEmailModal}
          setOpenModal={setOpenEmailModal}
          emailData={emailData}
          setEmailData={setEmailData}
        />
      )}
    </React.Fragment>
  )
}
export default List
