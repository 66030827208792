import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { useChangePasswordMutation } from "redux/authentication/api"
import { showError, showToaster } from "helpers"

function ChangePassword({ activeTab }) {
  const [changePassword, changePasswordAPI] = useChangePasswordMutation()
  const [passwordShow, setPasswordShow] = useState(false)
  useEffect(() => {
    validation.resetForm()
  }, [activeTab])
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string()
        .required("Please enter old password")
        .matches(/[a-zA-Z]/, "Please enter valid old password")
        .matches(/[0-9]/, "Please enter valid old password")
        .min(8, "Please enter valid old password"),
      newPassword: Yup.string()
        .required("Please enter password")
        .matches(
          /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
          "Password should contain minimum 8 characters including 1 number, 1 alphabet, and 1 special character",
        )
        .min(
          8,
          "Password should contain minimum 8 characters including 1 number, 1 alphabet, and 1 special character",
        )
        .max(50, "Password should be less than or equal to 50 characters")
        .test(
          "not-same-as-old",
          "New Password cannot be the same as the Old Password",
          function (value) {
            return value !== this.parent.oldPassword
          },
        ),
      confirmPassword: Yup.string()
        .oneOf(
          [Yup.ref("newPassword"), null],
          "New Password and Confirm New Password should be same",
        )
        .required("Please confirm new password"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const result = await changePassword(values).unwrap()
        showToaster("success", `${result.message}`, "Success")
        // Reset the form values to empty strings
        resetForm({
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        })
      } catch (error) {
        showError(error)
      }
    },
  })

  return (
    <Row>
      <Col xl="6">
        <h4 className="card-title my-4">Change Password</h4>
        <Card>
          <CardBody>
            <Form
              className="form-horizontal"
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
              <div className="mb-3">
                <Label className="form-label">Old Password</Label>
                <Input
                  name="oldPassword"
                  autoComplete="false"
                  className="form-control"
                  placeholder="Enter Old Password"
                  type="password"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.oldPassword || ""}
                  invalid={
                    !!(
                      validation.touched.oldPassword &&
                      validation.errors.oldPassword
                    )
                  }
                />
                {validation.touched.oldPassword &&
                validation.errors.oldPassword ? (
                  <FormFeedback className="fs-6 mt-0" type="invalid">
                    {validation.errors.oldPassword}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">New Password</Label>
                <div className="input-group auth-pass-inputgroup">
                  <Input
                    name="newPassword"
                    value={validation.values.newPassword || ""}
                    type={passwordShow ? "text" : "password"}
                    placeholder="Enter New Password"
                    autoComplete="false"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      !!(
                        validation.touched.newPassword &&
                        validation.errors.newPassword
                      )
                    }
                  />
                  <button
                    onClick={() => setPasswordShow(!passwordShow)}
                    className="btn btn-light"
                    type="button"
                    id="password-addon1"
                  >
                    <i
                      className={`mdi ${
                        !passwordShow
                          ? "mdi-eye-outline"
                          : "mdi-eye-off-outline"
                      }`}
                    ></i>
                  </button>
                  {validation.touched.newPassword &&
                    validation.errors.newPassword && (
                      <FormFeedback className="fs-6 mt-0" type="invalid">
                        {validation.errors.newPassword}
                      </FormFeedback>
                    )}
                </div>
              </div>

              <div className="mb-3">
                <Label className="form-label">Confirm New Password</Label>
                <Input
                  name="confirmPassword"
                  className="form-control"
                  placeholder="Confirm New Password"
                  type="password"
                  autoComplete="false"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.confirmPassword || ""}
                  invalid={
                    !!(
                      validation.touched.confirmPassword &&
                      validation.errors.confirmPassword
                    )
                  }
                />
                {validation.touched.confirmPassword &&
                validation.errors.confirmPassword ? (
                  <FormFeedback className="fs-6 mt-0" type="invalid">
                    {validation.errors.confirmPassword}
                  </FormFeedback>
                ) : null}
              </div>
              <Row className="mb-3">
                <Col className="text-end">
                  <button
                    type="submit"
                    disabled={validation.isSubmitting}
                    className={`btn btn-primary w-md ${
                      validation.isSubmitting ? "cursor-wait" : "cursor-pointer"
                    }`}
                  >
                    {validation.isSubmitting ? (
                      <div className="d-flex justify-content-center align-items-center">
                        <div
                          className="spinner-border spinner-border-sm me-1"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                        <span>waiting...</span>
                      </div>
                    ) : (
                      "Update Password"
                    )}
                  </button>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default ChangePassword
