import { apiSlice } from "../api"

export const prefixSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    prefixList: builder.query({
      query: props => {
        let url = `prefix/all`
        if (props) {
          const { page = 1, limit = 10, search = "" } = props
          url = `prefix/all?page=${page}&limit=${limit}${
            search != "" ? "&search=" + search : ""
          }`
        }
        return {
          url: `${url}`,
          method: "GET",
        }
      },
    }),
    addPrefix: builder.mutation({
      query: props => {
        return {
          url: "/prefix",
          method: "POST",
          body: props,
        }
      },
    }),
    getPrefix: builder.mutation({
      query: props => {
        return {
          url: `/prefix/${props}`,
          method: "GET",
        }
      },
    }),
    updatePrefix: builder.mutation({
      query: props => {
        return {
          url: `/prefix/${props.id}`,
          method: "PATCH",
          body: props.value,
        }
      },
    }),
    deletePrefix: builder.mutation({
      query: props => {
        return {
          url: `/prefix/${props}`,
          method: "DELETE",
        }
      },
    }),
  }),
})
export const {
  usePrefixListQuery,
  useAddPrefixMutation,
  useGetPrefixMutation,
  useUpdatePrefixMutation,
  useDeletePrefixMutation,
} = prefixSlice
