import { createSlice } from "@reduxjs/toolkit"

// import { useNavigate } from "react-router-dom";
const advSlice = createSlice({
  name: "adv",
  initialState: {
    list: [],
    pageInfo: { limit: 10, page: 1, totalPages: 1, totalResults: 0 },
  },
  reducers: {
    setadvList: (state, action) => {
      const { adverts, pageInfo } = action.payload
      state.list = adverts
      state.pageInfo = pageInfo
      return
    },
  },
})

export const { setadvList } = advSlice.actions

export default advSlice.reducer

export const selectAdvList = state => state.adv?.list
export const selectAdvTotalRecord = state => state.adv?.pageInfo
