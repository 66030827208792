import React, { useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Input,
  FormGroup,
  Label,
  Form,
  FormFeedback,
} from "reactstrap"

import "react-datepicker/dist/react-datepicker.css"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useCategoryListQuery } from "redux/category/api"
import { showError, showToaster } from "helpers"
import {
  useAddAdvertMutation,
  useGetAdvertMutation,
  useUpdateAdvertMutation,
} from "redux/advert/api"
import GoogleAddressField from "components/Common/Location/GoogleLocation"
import { useUserListQuery } from "redux/user/api"
import ImageCroperModel from "components/Common/ImageCroper/ImageCroperModel"
import config from "config"
import LazyImageWithFallback from "components/Common/LazyImageWithFallback"
import Dropzone from "react-dropzone"
import Select from "react-select"
import LoadingSpinner from "components/Common/LoadingSpinner"
import UserListDropdown from "components/Common/UserListDropdown/UserListDropdown"

function AdvAdd() {
  // for edit
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [isCropModalOpen, setIsCropModalOpen] = useState(false)
  const [selectedFiles, setselectedFiles] = useState([])
  const [addAdvert, addAdvertAPI] = useAddAdvertMutation()
  // edit page
  const IsEdit = pathname?.includes("edit")
  const [editInfo, setEditInfo] = useState([])
  const { id } = useParams()
  const [getData, getDataAPI] = useGetAdvertMutation()
  const [updateAdvert, setUpdateAdvert] = useUpdateAdvertMutation()
  // malti image upload
  const [file, setFile] = useState([])
  const [uploadImage, setUploadImage] = useState([])
  const [deletephoto, setdeletphoto] = useState([])
  const categoryData = useCategoryListQuery({}, { skip: IsEdit })
  const [categoryOption, setcategoryOption] = useState([])
  const [userOption, setUserOption] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getData(id).unwrap()
        setEditInfo(result?.data)
        setUploadImage(result?.data?.images || [])
        setUserOption([result?.data?.user])
        setcategoryOption(result?.data?.categories)
      } catch (error) {
        showError(error)
      }
    }
    if (id) {
      fetchData()
    } else {
      categoryData.refetch()
      setEditInfo([])
      setUploadImage([])
    }
  }, [id])
  // category option data list
  useEffect(() => {
    if (categoryData.isSuccess) {
      setcategoryOption(categoryData?.data?.data?.categories)
    }
    if (categoryData.isError) {
      showError(categoryData.error)
    }
    return () => {
      setcategoryOption([])
    }
  }, [categoryData])
  const BreadcrumbDetails = {
    breadcrumbItem: {
      label: !IsEdit
        ? "Add Businesses & Services"
        : "Edit Businesses & Services" || editInfo?.name,
      link: !IsEdit
        ? "businesses&services/add"
        : pathname.includes("user")
          ? `user/businesses&services/edit/${id}`
          : `businesses&services/edit/${id}`,
    },
    paths: IsEdit
      ? pathname.includes("user")
        ? [
            {
              label: "User",
              link: `user/businesses&services/edit/${id}`,
            },
            {
              label: "Businesses & Services",
              link: `user/businesses&services/edit/${id}`,
            },
          ]
        : [
            {
              label: "Businesses & Services",
              link: "businesses&services/list",
            },
          ]
      : [
          {
            label: "Businesses & Services",
            link: "businesses&services/add",
          },
        ],
  }

  const validationSchema = Yup.object().shape({
    user: Yup.string().required("User selection is required"),
    name: Yup.string()
      .required("Name is required")
      .min(5, "Name should be at least 5 characters")
      .max(100, "Name should be less than or equal to 100 characters"),
    website: Yup.string()
      .trim()
      .required("Website Link is required")
      .matches(/^\S*$/, "Whitespace is not allowed")
      .matches(
        /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
        "Enter a valid URL for Website link",
      )
      .max(255, "Website should be less than or equal to 255 characters"),
    categories: Yup.array().min(
      1,
      `Selection of at least one category is required`,
    ),
    address: Yup.string()
      .trim()
      .required("Location is required")
      .test("not-same-as-old", "Enter valid Location", function (value) {
        return typeof this.parent.lat == "number"
      }),
    image: Yup.mixed()
      .required("Images is required")
      .test("fileType", "Select Valid Image (only jpg, jpeg or png)", value => {
        // Check Image type
        if (value && value[0]?.type) {
          if (
            !!value.filter(val => !config.IMAGE_UPLOAD_TYPE.includes(val.type))
              .length
          ) {
            showToaster(
              "error",
              "Select Valid Image (only jpg, jpeg or png)",
              "Wrong",
            )
          }
          return !!!value.filter(
            val => !config.IMAGE_UPLOAD_TYPE.includes(val.type),
          ).length
        }
        return true
      })
      .test(
        "uploadImage",
        "File size should be less than or equal to 5MB",
        value => {
          if (value && value[0]?.size) {
            if (
              !!value.filter(val => val.size >= config.IMAGE_MAXSIZE).length
            ) {
              showToaster(
                "error",
                "File size should be less than or equal to 5MB",
                "Wrong",
              )
            }
            return !!!value.filter(val => val.size >= config.IMAGE_MAXSIZE)
              .length
          }
          return true
        },
      )
      .test("totalFile", "Maximum 3 Images are allowed to upload.", value => {
        if (value && value?.length) {
          if (value?.length > 3) {
            showToaster(
              "error",
              "Maximum 3 Images are allowed to upload.",
              "Wrong",
            )
          }
          return value?.length <= 3
        }
        return true
      }),
    description: Yup.string()
      .trim()
      .required("Message is required")
      .min(5, "Message should be at least 5 characters")
      .max(500, "Message should be less than or equal to 500 characters"),
  })
  const editInitialValues = {
    user: editInfo?.user?._id,
    name: editInfo?.name,
    website: editInfo?.website,
    // category: editInfo?.category?._id ?? "",
    categories: editInfo?.categories?.map(item => ({
      label: item?.name,
      value: item?._id,
    })),
    address: editInfo?.address,
    long: editInfo?.location?.coordinates[0],
    lat: editInfo?.location?.coordinates[1],
    description: editInfo?.description,
    image: editInfo?.images,
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: IsEdit
      ? editInitialValues
      : {
          description: "",
          user: "",
          name: "",
          website: "",
          categories: [],
          // price: "",
          address: "",
          image: null,
        },
    validationSchema,
    onSubmit: async values => {
      let newValue = {
        ...values,
        address: values.address,
        location: {
          coordinates: [values?.long, values?.lat],
        },
        categories: values?.categories.map(item => item.value),
      }
      if (uploadImage?.length && uploadImage) {
        newValue = { ...newValue, image: uploadImage }
      }
      if (deletephoto && deletephoto.length) {
        newValue = { ...newValue, deleteImg: deletephoto }
      }
      if (!IsEdit) {
        try {
          const result = await addAdvert(newValue).unwrap()
          navigate("/businesses&services/list")
          showToaster("success", `${result.message}`, "Success")
        } catch (error) {
          showError(error)
        }
      } else {
        try {
          const result = await updateAdvert({
            value: newValue,
            id: id,
          }).unwrap()
          navigate(-1)
          showToaster("success", `${result.message}`, "Success")
        } catch (error) {
          showError(error)
        }
      }
    },
  })

  // croper model manage
  const handleOpenCropModal = () => {
    setIsCropModalOpen(true)
  }

  const handleInputChange = (fieldName, e) => {
    const sanitizedValue = e.target.value.replace(/\s+/g, " ")
    formik.handleChange(e)
    formik.setFieldValue(fieldName, sanitizedValue)
  }
  // delete Image remove in array and and add deleteArray delete image key
  const handleDeleteImage = (index, IsEdit = false) => {
    const updatedFiles = [...uploadImage]
    updatedFiles.splice(index, 1)
    setUploadImage(updatedFiles)
    if (updatedFiles?.length) {
      formik.setFieldValue("image", uploadImage)
    } else {
      formik.setFieldValue("image", null)
    }
    if (IsEdit) {
      setdeletphoto([...deletephoto, IsEdit])
    }
  }
  const handleCloseCropModal = (Ufile = file) => {
    setIsCropModalOpen(false)
    if (Ufile.length <= 0) {
      formik.setFieldValue("image", null)
    }
    if (Ufile.length > 0) {
      setUploadImage([...uploadImage, ...Ufile])
    }
  }
  useEffect(() => {
    if (
      formik.values.image?.length &&
      !!!formik.values?.image[0]?.url &&
      !!!formik.values.image.filter(val => val?.size >= config.IMAGE_MAXSIZE)
        .length &&
      !!!formik.values.image.filter(
        val => !config.IMAGE_UPLOAD_TYPE.includes(val.type),
      ).length &&
      formik.values.image?.length <= 3
    ) {
      if (
        uploadImage.length == 3 ||
        formik.values.image?.length + uploadImage.length > 3
      ) {
        formik.setFieldValue("image", null)
        showToaster("error", "Maximum 3 Images are allowed to upload", "Wrong")
        return
      }
      setFile([...formik.values.image])
      handleOpenCropModal()
    } else {
      if (uploadImage.length > 0) {
        formik.setFieldValue("image", uploadImage)
      }
    }
  }, [formik.values.image])
  return (
    <React.Fragment>
      <Helmet>
        <title>
          {!IsEdit ? "Add Businesses & Services" : "Edit Businesses & Services"}{" "}
          | Discover Equestrian
        </title>
      </Helmet>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbDetails={BreadcrumbDetails} />

          {getDataAPI?.isLoading || getDataAPI?.isFetching ? (
            <Col lg="12" className="w-100 d-flex justify-content-center">
              {" "}
              <LoadingSpinner />{" "}
            </Col>
          ) : (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">
                      {/* {!IsEdit ? "Add Advert" : "Update Advert"} */}
                    </CardTitle>
                    <Form
                      className="outer-repeater"
                      onSubmit={e => {
                        e.preventDefault()
                        formik.handleSubmit()
                        // return
                      }}
                    >
                      <div data-repeater-list="outer-group" className="outer">
                        <div data-repeater-item className="outer">
                          <FormGroup className="mb-4" row>
                            <Label
                              htmlFor="user"
                              className="col-form-label col-lg-2"
                            >
                              User
                            </Label>
                            <Col lg="10">
                              {IsEdit ? (
                                <Input
                                  type="select"
                                  name="user"
                                  className="form-control"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.user || ""}
                                  disabled={IsEdit} // Add this line to disable the input in "edit" mode
                                  invalid={
                                    !!(
                                      formik.touched.user && formik.errors.user
                                    )
                                  }
                                >
                                  <option value="">Select User</option>
                                  {userOption?.map((val, index) => {
                                    return (
                                      <option key={index} value={`${val?._id}`}>
                                        {val?.name}
                                      </option>
                                    )
                                  })}
                                </Input>
                              ) : (
                                <UserListDropdown formik={formik} />
                              )}
                              {formik.touched.user && formik.errors.user && (
                                <div
                                  className="fs-6 mt-0 text-danger"
                                  type="invalid"
                                >
                                  {formik.errors.user}
                                </div>
                              )}
                            </Col>
                          </FormGroup>
                          <FormGroup className="mb-4" row>
                            <Label
                              htmlFor="name"
                              className="col-form-label col-lg-2"
                            >
                              Name
                            </Label>
                            <Col lg="10">
                              <Input
                                id="name"
                                name="name"
                                type="text"
                                className="form-control"
                                placeholder="Enter Name"
                                value={formik.values.name || ""}
                                onChange={e => {
                                  handleInputChange("name", e)
                                }}
                                onBlur={formik.handleBlur}
                                invalid={
                                  !!(formik.touched.name && formik.errors.name)
                                }
                              />
                              {formik.touched.name && formik.errors.name && (
                                <FormFeedback
                                  className="fs-6 mt-0"
                                  type="invalid"
                                >
                                  {formik.errors.name}
                                </FormFeedback>
                              )}
                            </Col>
                          </FormGroup>
                          <FormGroup className="mb-4" row>
                            <Label
                              htmlFor="taskname"
                              className="col-form-label col-lg-2"
                            >
                              Website Link
                            </Label>
                            <Col lg="10">
                              <Input
                                id="website"
                                name="website"
                                type="text"
                                className="form-control"
                                placeholder="https://www.google.com"
                                value={formik.values.website || ""}
                                onChange={e => {
                                  handleInputChange("website", e)
                                }}
                                onBlur={formik.handleBlur}
                                invalid={
                                  !!(
                                    formik.touched.website &&
                                    formik.errors.website
                                  )
                                }
                              />
                              {formik.touched.website &&
                                formik.errors.website && (
                                  <FormFeedback
                                    className="fs-6 mt-0"
                                    type="invalid"
                                  >
                                    {formik.errors.website}
                                  </FormFeedback>
                                )}
                            </Col>
                          </FormGroup>
                          <FormGroup className="mb-4" row>
                            <Label
                              htmlFor="category"
                              className="col-form-label col-lg-2"
                            >
                              Category
                            </Label>
                            <Col lg="10">
                              {/* <Input
                              type="select"
                              name="category"
                              className="form-control"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.category || ""}
                              invalid={
                                !!(
                                  formik.touched.category &&
                                  formik.errors.category
                                )
                              }
                            >
                              <option value="">Select Category</option>
                              {categoryOption?.map((val, index) => {
                                return (
                                  <option key={index} value={`${val?.id}`}>
                                    {val?.name}
                                  </option>
                                )
                              })}
                            </Input> */}
                              <Select
                                maxMenuHeight={200}
                                className={`basic-single sortBy-dropdown ${IsEdit ? "closeIconRemove" : ""} ${IsEdit ? "disabled-select" : ""} ${formik.errors.categories && formik.touched.categories ? "custom-select-validation" : ""}`}
                                classNamePrefix="select"
                                isMulti
                                isDisabled={IsEdit ? true : false}
                                value={formik.values?.categories}
                                placeholder={`Select Categories`}
                                name="categories"
                                isSearchable={true}
                                options={categoryOption?.map(item => ({
                                  label: item?.name,
                                  value: item?._id,
                                }))}
                                onChange={event => {
                                  formik.setFieldValue("categories", event)
                                }}
                                onBlur={event => {
                                  formik.setFieldTouched("categories", true)
                                }}
                                invalid={!!formik.errors.categories}
                              />
                              {formik.touched.categories &&
                                formik.errors.categories && (
                                  <span className="text-danger">
                                    {" "}
                                    {formik.errors.categories}
                                  </span>
                                )}
                            </Col>
                          </FormGroup>
                          <FormGroup className="mb-4" row>
                            <Label
                              htmlFor="location"
                              className="col-form-label col-lg-2"
                            >
                              Location
                            </Label>
                            <Col lg="10">
                              <GoogleAddressField
                                formik={formik}
                                type="text"
                                className="form-control goo"
                                placeholder="Find Your Location"
                                id="address"
                                name="address"
                                invalid={
                                  !!(
                                    formik.touched.address &&
                                    formik.errors.address
                                  )
                                }
                              />
                              {formik.touched.address &&
                                formik.errors.address && (
                                  <FormFeedback
                                    className="fs-6 mt-0"
                                    type="invalid"
                                  >
                                    {formik.errors.address}
                                  </FormFeedback>
                                )}
                            </Col>
                          </FormGroup>
                          <FormGroup className="mb-4" row>
                            <Label className="col-form-label col-lg-2">
                              Message
                            </Label>
                            <Col lg="10">
                              <Input
                                id="description"
                                name="description"
                                type="textarea"
                                className="form-control"
                                placeholder="Enter Message Here"
                                value={formik.values.description}
                                onChange={e => {
                                  handleInputChange("description", e)
                                }}
                                onBlur={formik.handleBlur}
                                invalid={
                                  !!(
                                    formik.touched.description &&
                                    formik.errors.description
                                  )
                                }
                              />
                              {formik.touched.description &&
                                formik.errors.description && (
                                  <FormFeedback
                                    className="fs-6 mt-0"
                                    type="invalid"
                                  >
                                    {formik.errors.description}
                                  </FormFeedback>
                                )}
                            </Col>
                          </FormGroup>
                          <Row className="mb-4">
                            <Label className="col-form-label col-lg-2">
                              Select Images
                            </Label>
                            <Col
                              lg="4"
                              md="6"
                              onClick={() => {
                                formik.setFieldTouched("image", true)
                                if (uploadImage?.length == 3) {
                                  showToaster(
                                    "error",
                                    "Maximum 3 Images are allowed to upload.",
                                    "Wrong",
                                  )
                                }
                              }}
                            >
                              <Dropzone
                                onDrop={acceptedFiles => {
                                  if (acceptedFiles.length <= 3) {
                                    formik.setFieldValue("image", acceptedFiles)
                                  } else {
                                    showToaster(
                                      "error",
                                      "Maximum 3 Images are allowed to upload.",
                                      "Wrong",
                                    )
                                  }
                                }}
                                disabled={uploadImage?.length >= 3}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div className="dropzone">
                                    <div
                                      className="dz-message needsclick"
                                      {...getRootProps()}
                                    >
                                      <input
                                        {...getInputProps()}
                                        onBlur={formik.handleBlur}
                                        accept="image/*"
                                        disabled={uploadImage?.length >= 3} // Disable input if 3 images are already selected
                                      />
                                      <div className="dz-message needsclick">
                                        <div className="mb-3">
                                          <i className="display-4 text-muted bx bxs-cloud-upload" />
                                        </div>
                                        <h4>
                                          Drop images here or click to upload.
                                        </h4>
                                        <h6 className="text-danger">
                                          Maximum image size allowed is 5MB.{" "}
                                        </h6>
                                        <h6 className="text-danger">
                                          Maximum 3 Images are allowed to
                                          upload.{" "}
                                        </h6>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                              {formik.touched.image && formik.errors.image && (
                                <div
                                  className="fs-6 mt-0 text-danger"
                                  type="invalid"
                                >
                                  {formik.errors.image}
                                </div>
                              )}
                            </Col>
                            <Col lg="6" md="6">
                              <div
                                className="dropzone-previews mt-3"
                                id="file-previews"
                              >
                                <Row>
                                  {uploadImage.map((IMG, rowIndex) => {
                                    if (IMG) {
                                      return (
                                        <Col
                                          xxl="3"
                                          xl="4"
                                          lg="6"
                                          md="6"
                                          key={rowIndex + "-file"}
                                        >
                                          <Card
                                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                            key={rowIndex + "-file"}
                                          >
                                            <div className="p-2">
                                              <Row className="align-items-center">
                                                <Col sm="8">
                                                  <Col className="col-auto">
                                                    <LazyImageWithFallback
                                                      src={
                                                        IMG?.preview
                                                          ? IMG?.preview
                                                          : IMG?.url
                                                      }
                                                      alt="banner"
                                                      className="rounded bg-light w-75"
                                                    />
                                                  </Col>
                                                </Col>
                                                <Col
                                                  sm="4"
                                                  className="col-auto"
                                                >
                                                  <button
                                                    onClick={() =>
                                                      handleDeleteImage(
                                                        rowIndex,
                                                        IMG?.key,
                                                      )
                                                    }
                                                    className="btn btn-danger"
                                                    type="button"
                                                  >
                                                    <i className="fas fa-times font-16"></i>
                                                  </button>
                                                </Col>
                                              </Row>
                                            </div>
                                          </Card>
                                        </Col>
                                        // )
                                      )
                                    } else {
                                      return
                                    }
                                  })}
                                </Row>
                              </div>
                            </Col>

                            {isCropModalOpen && (
                              <ImageCroperModel
                                imageArry={file}
                                onCancel={handleCloseCropModal}
                                setFile={setFile}
                                isOpen={isCropModalOpen}
                              />
                            )}
                          </Row>
                        </div>
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          {IsEdit && (
                            <Link
                              // to={"/businesses&services/list"}
                              onClick={() => navigate(-1)}
                              type="button"
                              className="btn btn-danger me-1"
                            >
                              <i className="bx bx-left-arrow-alt font-size-16 align-middle me-2"></i>{" "}
                              Back
                            </Link>
                          )}
                          <button
                            type="submit"
                            disabled={
                              formik.isSubmitting ||
                              (IsEdit && formik.initialValues == formik.values)
                            }
                            className={`btn btn-success w-md ${
                              formik.isSubmitting
                                ? "cursor-wait"
                                : "cursor-pointer"
                            }`}
                          >
                            {formik.isSubmitting ? (
                              <div className="d-flex justify-content-center align-items-center">
                                <div
                                  className="spinner-border spinner-border-sm me-1"
                                  role="status"
                                >
                                  <span className="sr-only">Adding...</span>
                                </div>
                                <span>Adding...</span>
                              </div>
                            ) : !IsEdit ? (
                              <>
                                <i className="bx bx-plus font-size-16 align-middle me-2"></i>{" "}
                                Add
                              </>
                            ) : (
                              <>
                                <i className="bx bx-up-arrow-alt font-size-16 align-middle me-2"></i>{" "}
                                Update
                              </>
                            )}
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AdvAdd
