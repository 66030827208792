import React, { useEffect, useMemo, useState } from "react"

//Import Breadcrumb
import { useDispatch, useSelector } from "react-redux"
import {
  SweetAlert,
  authentiCateUserPermission,
  confirmAlert,
  showError,
  showToaster,
} from "helpers"
import { Link, useNavigate } from "react-router-dom"
import { UncontrolledTooltip } from "reactstrap"
import {
  selectUsersList,
  selectUsersPageInfo,
  setUserList,
} from "redux/user/slice"
import {
  useBanUserMutation,
  useDeleteUserMutation,
  useLogoutAllDeviceMutation,
  useUserListQuery,
} from "redux/user/api"
import CustomTable2 from "components/Common/Table/CustomeTable2"
import { DateFormate, Name, ProfileImage, TypeStatus } from "./ColumnFormatters"
import {
  selectCurrentUser,
  selectPermissions,
} from "redux/authentication/slice"
import ChangePasswordModel from "./ChangePasswordModel"
import { SelectColumnFilterType } from "./Filter"
import CustomTable from "components/Common/Table/CustomeTable"

function List() {
  const data = useSelector(selectUsersList)
  const pageInfo = useSelector(selectUsersPageInfo)
  const [state, setState] = useState({
    search: undefined,
    type: null,
    limit: 10,
    page: 1,
    status: null,
  })
  const [selectedRows, setSelectedRows] = useState([])
  const [load, setLoad] = useState(false)
  const [deleteuser, deleteuserAPI] = useDeleteUserMutation()
  const [banUser, banUserAPI] = useBanUserMutation()
  const [logoutAllDevice, logoutAllDeviceAPI] = useLogoutAllDeviceMutation()
  const dispatch = useDispatch()
  const user = useSelector(selectCurrentUser)
  const userPermissions = useSelector(selectPermissions)

  const isAllRowsSelected = selectedRows?.length === data?.length
  const onDelete = async id => {
    try {
      const confirmationMessage = `You want to delete this User ?`
      const confirmation = await confirmAlert(
        confirmationMessage,
        "warning",
        "Yes, delete it!",
      )
      if (confirmation.isConfirmed) {
        const result = await deleteuser(id).unwrap()
        setLoad(load => !load)
        SweetAlert(`${result.message}`, "success")
      }
    } catch (error) {
      showError(error)
    }
  }
  const onBan = async (id, IsBan) => {
    try {
      const confirmationMessage = `Are you sure want to ${
        IsBan ? "Unban" : "Ban"
      }
                this User ?`
      const confirmation = await confirmAlert(confirmationMessage)
      if (confirmation.isConfirmed) {
        const result = await banUser({ user: id, isBanned: !IsBan }).unwrap()
        setLoad(load => !load)
        SweetAlert(`${result.message}`, "success")
      }
    } catch (error) {
      showError(error)
    }
  }
  const onLogoutAllDevice = async id => {
    try {
      const confirmationMessage = `Confirm logout from all devices?`
      const confirmation = await confirmAlert(confirmationMessage)
      if (confirmation.isConfirmed) {
        const result = await logoutAllDevice(id).unwrap()
        SweetAlert(`${result.message}`, "success")
      }
    } catch (error) {
      showError(error)
    }
  }
  const navigate = useNavigate()
  const onAddUser = () => {
    navigate("/user/add", { replace: true })
  }
  const [openPasswordModel, setOpenPasswordModel] = useState(false)
  const [selectId, setSelectId] = useState(null)
  const handleCloseModal = () => {
    setOpenPasswordModel(false)
    setSelectId(null)
  }
  const handleOpenModal = ID => {
    setSelectId(ID)
    setOpenPasswordModel(true)
  }
  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        // filter: false,
        Cell: cellProps => {
          return <div> {cellProps.row.original.id} </div>
        },
      },
      {
        Header: "Profile",
        accessor: "profileImage",
        filter: false,
        filterable: false,
        disableFilters: true,
        disableSortBy: true,
        Cell: cellProps => {
          return <ProfileImage {...cellProps} />
        },
      },
      {
        Header: "Name",
        accessor: "name",
        filter: false,
        filterable: false,
        disableFilters: true,
        disableSortBy: true,
        Cell: cellProps => {
          return (
            <>
              <div className="d-inline-flex flex-column">
                <Name {...cellProps} />
              </div>
              <UncontrolledTooltip
                placement="top"
                target={`review${cellProps.row.original.id}`}
              >
                Review
              </UncontrolledTooltip>
            </>
          )
        },
      },
      {
        Header: "Phone Number",
        accessor: "contact",
        filterable: false,
        disableFilters: true,
        disableSortBy: true,
        Cell: cellProps => {
          return <div> {cellProps.value} </div>
        },
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: false,
        disableFilters: true,
        disableSortBy: true,
        Cell: cellProps => {
          return <div> {cellProps.value} </div>
        },
      },
      {
        Header: "Address",
        accessor: "address",
        filterable: false,
        maxSize: 300,
        disableFilters: true,
        disableSortBy: true,
        Cell: cellProps => {
          return <div> {cellProps.value} </div>
        },
      },
      {
        Header: "Type",
        accessor: "type",
        // filterable: false,
        Filter: SelectColumnFilterType, // Specify the custom filter component
        maxSize: 150,
        disableFilters: false, // Enable filtering for the column
        disableSortBy: true,
        Cell: cellProps => {
          return <TypeStatus {...cellProps} />
        },
      },
      {
        Header: "Created At",
        accessor: "createdAt",
        filterable: false,
        disableFilters: true,
        maxSize: 150,
        disableSortBy: true,
        Cell: cellProps => {
          return <DateFormate {...cellProps} />
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        disableSortBy: true,
        Cell: cellProps => {
          return (
            <>
              {!cellProps.row.original?.isDeleted ? (
                <div className="d-flex gap-2">
                  {!cellProps.row.original?.isDeleted &&
                    !cellProps.row.original.isBanned?.status && (
                      <>
                        {authentiCateUserPermission(
                          ["updateUser"],
                          userPermissions,
                          user?.role,
                        ) && (
                          <Link
                            to={`/user/edit/${cellProps.row.original.id}`}
                            className="text-success"
                          >
                            <i
                              className="mdi mdi-lead-pencil font-size-18"
                              id={`edit${cellProps.row.original.id}`}
                            />
                            <UncontrolledTooltip
                              placement="top"
                              target={`edit${cellProps.row.original.id}`}
                            >
                              Edit
                            </UncontrolledTooltip>
                          </Link>
                        )}
                        {authentiCateUserPermission(
                          ["getUser"],
                          userPermissions,
                          user?.role,
                        ) && (
                          <Link
                            to={`/user/${cellProps.row.original.id}`}
                            className="text-info"
                          >
                            <i
                              className="mdi mdi-eye font-size-18"
                              id={`view${cellProps.row.original.id}`}
                            />
                            <UncontrolledTooltip
                              placement="top"
                              target={`view${cellProps.row.original.id}`}
                            >
                              View
                            </UncontrolledTooltip>
                          </Link>
                        )}
                        {authentiCateUserPermission(
                          [],
                          userPermissions,
                          user?.role,
                        ) && (
                          <Link
                            to={`#`}
                            className="text-warning"
                            onClick={() => {
                              const user = cellProps.row.original
                              handleOpenModal(user?.id)
                            }}
                          >
                            <i
                              className="mdi mdi-pen-lock font-size-18"
                              id={`password${cellProps.row.original.id}`}
                            />
                            <UncontrolledTooltip
                              placement="top"
                              target={`password${cellProps.row.original.id}`}
                            >
                              Change Password
                            </UncontrolledTooltip>
                          </Link>
                        )}
                        {authentiCateUserPermission(
                          [],
                          userPermissions,
                          user?.role,
                        ) && (
                          <Link
                            to={`#`}
                            className="text-danger"
                            onClick={() => {
                              const user = cellProps.row.original
                              onLogoutAllDevice(user?.id)
                            }}
                          >
                            <i
                              className="mdi mdi-logout font-size-18"
                              id={`logout${cellProps.row.original.id}`}
                            />
                            <UncontrolledTooltip
                              placement="top"
                              target={`logout${cellProps.row.original.id}`}
                            >
                              Logout from all device
                            </UncontrolledTooltip>
                          </Link>
                        )}
                      </>
                    )}

                  {authentiCateUserPermission(
                    ["banUser"],
                    userPermissions,
                    user?.role,
                  ) && (
                    <Link
                      to="#"
                      className={`${
                        cellProps.row.original?.isBanned?.status
                          ? "text-primary"
                          : "text-dark"
                      }`}
                      onClick={() => {
                        const user = cellProps.row.original
                        onBan(user?.id, user?.isBanned?.status)
                      }}
                    >
                      {" "}
                      <i
                        className={`mdi ${
                          cellProps.row.original?.isBanned?.status
                            ? "mdi-account-convert"
                            : "mdi-account-off"
                        } font-size-18`}
                        id={`ban${cellProps.row.original.id}`}
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target={`ban${cellProps.row.original.id}`}
                      >
                        {cellProps.row.original?.isBanned?.status
                          ? "UnBan"
                          : "Ban"}
                      </UncontrolledTooltip>
                    </Link>
                  )}
                  {authentiCateUserPermission(
                    ["deleteUser"],
                    userPermissions,
                    user?.role,
                  ) && (
                    <Link
                      to="#"
                      className="text-danger"
                      onClick={() => {
                        const user = cellProps.row.original
                        onDelete(user.id)
                      }}
                    >
                      {" "}
                      <i
                        className="mdi mdi-delete font-size-18"
                        id={`delete${cellProps.row.original.id}`}
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target={`delete${cellProps.row.original.id}`}
                      >
                        Delete
                      </UncontrolledTooltip>
                    </Link>
                  )}
                </div>
              ) : (
                "-"
              )}
            </>
          )
        },
      },
    ],
    [selectedRows, isAllRowsSelected],
  )

  const list = useUserListQuery(state)

  if (list.isSuccess) {
    dispatch(setUserList(list.data.data))
  }

  if (list.isError) {
    showError(list.error)
  }

  useEffect(() => {
    list.refetch()

    return () => {
      dispatch(
        setUserList({
          list: [],
          pageInfo: { limit: 10, page: 1, totalPages: 1, totalResults: 0 },
        }),
      )
    }
  }, [state, load])
  return (
    <React.Fragment>
      <CustomTable
        data={data}
        list={list}
        totalRecords={pageInfo?.totalResults}
        stateAPI={state}
        setStateAPI={setState}
        columns={columns}
        createButton={
          authentiCateUserPermission(
            ["createUser"],
            userPermissions,
            user?.role,
          )
            ? {
                visible: true,
                lable: "Create User",
                onClick: onAddUser,
              }
            : undefined
        }
      />
      {openPasswordModel && (
        <ChangePasswordModel
          onCancel={handleCloseModal}
          isOpen={openPasswordModel}
          Id={selectId}
        />
      )}
    </React.Fragment>
  )
}
export default List
