import Slidewithindicator from "components/Common/Carousel"
import LazyImageWithFallback from "components/Common/LazyImageWithFallback"
import LoadingSpinner from "components/Common/LoadingSpinner"
import { showError } from "helpers"
import { formatBigNumber, wordTitleCase } from "helpers/data-formatters"
import { convertDate, convertToTitleCase } from "helpers/util"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import { useGetAdvertMutation } from "redux/advert/api"

function ViewModel({ openModal, setOpenModal, id = null, setId }) {
  const [getData, getDataAPI] = useGetAdvertMutation()
  const [data, setData] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getData(id).unwrap()
        setData(result?.data)
      } catch (error) {
        showError(error)
      }
    }
    if (id) {
      fetchData()
    }
  }, [id])
  const toggle = () => {
    if (openModal) {
      setOpenModal(openModal => !openModal)
      setId(null)
      setData([])
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Modal
            // size="lg"
            isOpen={openModal}
            toggle={toggle}
            backdrop={"static"}
            centered={true}
          >
            <ModalHeader toggle={toggle} tag="h4" className="align-items-start">
              {data?.name}
            </ModalHeader>
            <ModalBody>
              {getDataAPI?.isLoading || getDataAPI?.isFetching ? (
                <Col lg="12" className="w-100 d-flex justify-content-center">
                  {" "}
                  <LoadingSpinner />{" "}
                </Col>
              ) : (
                <Row>
                  <Row className="m-0 p-0">
                    <Slidewithindicator ImageArray={data?.images} />
                  </Row>
                  <Col md="12" className="pt-2">
                    <Col className="d-flex flex-row gap-0 justify-content-between">
                      <p className="text-muted mb-0 text-break">
                        <i className="bx bx-calendar-event align-middle text-muted me-1"></i>{" "}
                        {convertDate(data?.createdAt)}
                      </p>
                    </Col>
                    <Row className="pt-0">
                      <Link to="#" className="text-muted text-break">
                        <i className="bx bx-map align-middle text-muted me-1"></i>{" "}
                        {data?.address}
                      </Link>
                    </Row>
                  </Col>
                  <div className="pt-2">
                    <h6 className="mb-2">
                      <b> Listed By</b>: {wordTitleCase(data?.user?.name)}
                    </h6>
                    <div>
                      <h6>
                        <b>Description: </b>
                      </h6>
                      <p
                        className="ps-2"
                        dangerouslySetInnerHTML={{ __html: data?.description }}
                      ></p>
                    </div>
                  </div>
                  <div className="pt-2">
                    <div>
                      <h6>
                        <b>Categories: </b>
                      </h6>
                      {data?.categories?.map((category, ind) => (
                        <Badge
                          className={"m-2 font-size-12 badge-soft-" + "info"}
                          key={ind}
                        >
                          <Link to="#" className="text-muted">
                            <i className="bx bx-purchase-tag-alt align-middle text-muted"></i>{" "}
                            {wordTitleCase(category?.name)}
                          </Link>
                        </Badge>
                      ))}
                    </div>
                  </div>
                  {/* <Col className="d-flex flex-row gap-0 justify-content-between">
                  <h6 className="mb-2">
                    <b> Categories</b>:
                  </h6>
                  <p className="text-muted mb-0 text-break">
                    {data?.categories?.map((category, ind) =>
                      <Badge className={"font-size-12 badge-soft-" + "info"} key={ind}>
                        <Link to="#" className="text-muted" >
                          <i className="bx bx-purchase-tag-alt align-middle text-muted"></i>{" "}
                          {wordTitleCase(category?.name)}
                        </Link>
                      </Badge>
                    )}
                  </p>
                </Col> */}
                </Row>
              )}
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ViewModel
