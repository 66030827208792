import { apiSlice } from "redux/api"

export const userSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    crmList: builder.query({
      query: props => {
        let url
        if (props) {
          const { page = 1, limit = 10, search = "" } = props?.state
          const userID = props?.userId
          if (userID) {
            url = `/crm/user/${userID}?page=${page}&limit=${limit}${
              search != "" ? "&search=" + search : ""
            }`
          }
        }
        return {
          url: `${url}`,
          method: "GET",
        }
      },
    }),
    getCrmDetail: builder.mutation({
      query: props => {
        return {
          url: `/crm/${props}`,
          method: "GET",
        }
      },
    }),
  }),
})
export const { useCrmListQuery, useGetCrmDetailMutation } = userSlice
