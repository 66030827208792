import PropTypes from "prop-types"
import React, { useEffect } from "react"
import withRouter from "components/Common/withRouter"
import {
  changeLayout,
  changeLayoutMode,
  changeSidebarTheme,
  changeSidebarThemeImage,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
  showRightSidebarAction,
} from "../../redux/layout/slice"

// Layout Related Components
import Header from "./Header"
import Sidebar from "./Sidebar"
import Footer from "./Footer"
import RightSidebar from "../CommonForBoth/RightSidebar"

//redux
import { useSelector, useDispatch } from "react-redux"
// import { showRightSidebarAction } from "../../redux/layout/slice"

import layoutHelper from "../../helpers/layout"

const Layout = props => {
  const dispatch = useDispatch()

  const {
    isPreloader,
    leftSideBarThemeImage,
    layoutWidth,
    leftSideBarType,
    topbarTheme,
    showRightSidebar,
    leftSideBarTheme,
    layoutModeType,
  } = useSelector(state => state.Layout)

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  const toggleMenuCallback = () => {
    if (leftSideBarType === "default") {
      dispatch(changeSidebarType("condensed", isMobile))
    } else if (leftSideBarType === "condensed") {
      dispatch(changeSidebarType("default", isMobile))
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    dispatch(changeLayout("vertical"))
  }, [dispatch])

  useEffect(() => {
    if (leftSideBarTheme) {
      layoutHelper.changeBodyAttribute("data-sidebar", leftSideBarTheme)
    }
  }, [leftSideBarTheme])

  useEffect(() => {
    if (layoutModeType) {
      layoutHelper.changeBodyAttribute("data-layout-mode", layoutModeType)
    }
  }, [layoutModeType])

  useEffect(() => {
    if (layoutWidth) {
      if (layoutWidth === "boxed") {
        dispatch(changeSidebarType("icon"))
        layoutHelper.changeBodyAttribute("data-layout-size", layoutWidth)
        layoutHelper.changeBodyAttribute("data-layout-scrollable", false)
      } else if (layoutWidth === "scrollable") {
        dispatch(changeSidebarType("default"))
        layoutHelper.changeBodyAttribute("data-layout-scrollable", true)
      } else {
        dispatch(changeSidebarType("default"))
        layoutHelper.changeBodyAttribute("data-layout-size", layoutWidth)
        layoutHelper.changeBodyAttribute("data-layout-scrollable", false)
      }
    }
  }, [layoutWidth, dispatch])

  useEffect(() => {
    if (leftSideBarType) {
      switch (leftSideBarType) {
        case "compact":
          layoutHelper.changeBodyAttribute("data-sidebar-size", "small")
          layoutHelper.manageBodyClass("sidebar-enable", "remove")
          layoutHelper.manageBodyClass("vertical-collpsed", "remove")
          break
        case "icon":
          layoutHelper.changeBodyAttribute("data-sidebar-size", "")
          layoutHelper.changeBodyAttribute("data-keep-enlarged", "true")
          layoutHelper.manageBodyClass("vertical-collpsed", "add")
          break
        case "condensed":
          layoutHelper.manageBodyClass("sidebar-enable", "add")
          if (window.screen.width >= 992) {
            layoutHelper.manageBodyClass("vertical-collpsed", "remove")
            layoutHelper.manageBodyClass("sidebar-enable", "remove")
            layoutHelper.manageBodyClass("vertical-collpsed", "add")
            layoutHelper.manageBodyClass("sidebar-enable", "add")
          } else {
            layoutHelper.manageBodyClass("sidebar-enable", "add")
            layoutHelper.manageBodyClass("vertical-collpsed", "add")
          }
          break
        default:
          layoutHelper.changeBodyAttribute("data-sidebar-size", "")
          layoutHelper.manageBodyClass("sidebar-enable", "remove")
      }
    }
  }, [leftSideBarType, dispatch])

  useEffect(() => {
    if (isMobile) {
      layoutHelper.manageBodyClass("vertical-collpsed", "remove")
    }
  }, [isMobile])

  useEffect(() => {
    if (topbarTheme) {
      layoutHelper.changeBodyAttribute("data-topbar", topbarTheme)
    }
  }, [topbarTheme])
  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header toggleMenuCallback={toggleMenuCallback} />
        <Sidebar
          theme={leftSideBarTheme}
          type={leftSideBarType}
          isMobile={isMobile}
        />
        <div className="main-content">{props.children}</div>
        <Footer />
        {/* {showRightSidebar ? <RightSidebar /> : null} */}
      </div>
    </React.Fragment>
  )
}

Layout.propTypes = {
  changeLayoutWidth: PropTypes.func,
  changeSidebarTheme: PropTypes.func,
  changeSidebarThemeImage: PropTypes.func,
  changeSidebarType: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  children: PropTypes.any,
  isPreloader: PropTypes.any,
  layoutWidth: PropTypes.any,
  leftSideBarTheme: PropTypes.any,
  leftSideBarThemeImage: PropTypes.any,
  leftSideBarType: PropTypes.any,
  location: PropTypes.object,
  showRightSidebar: PropTypes.any,
  topbarTheme: PropTypes.any,
}

export default withRouter(Layout)
