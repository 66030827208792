import React, { useEffect, useState, useRef } from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import "./imageCroper.scss"
import {
  centerAspectCrop,
  useDebounceEffect,
  canvasPreview,
} from "./GetCroppedImg"
import "react-image-crop/dist/ReactCrop.css"
import ReactCrop, { convertToPixelCrop } from "react-image-crop"
import config from "config"

function ImageCroperModel({
  isOpen,
  imageArry,
  onCancel,
  setFile,
  ratio = [{ value: undefined }],
  propertyImageCrop = false,
}) {
  const [allUploadedFile, SetAllUploadedFile] = useState(imageArry)
  const [imageUrl, SetImageUrl] = useState([])
  const [imaegIndex, setImageindex] = useState(0)
  const previewCanvasRef = useRef(null)
  const imgRef = useRef(null)
  const blobUrlRef = useRef("")
  const [crop, setCrop] = useState()
  const [completedCrop, setCompletedCrop] = useState()
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState(ratio[0].value)

  async function getCroppedFile(
    image,
    previewCanvas,
    completedCrop,
    originalFileName,
    fileType,
  ) {
    if (!image || !previewCanvas || !completedCrop) {
      throw new Error("Crop canvas does not exist")
    }

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
    )
    const ctx = offscreen.getContext("2d")
    if (!ctx) {
      throw new Error("No 2d context")
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height,
    )

    const blob = await offscreen.convertToBlob({ type: fileType })
    const croppedImage = new File([blob], originalFileName, {
      type: fileType,
      lastModified: Date.now(),
    })

    if (
      croppedImage.size >
      (propertyImageCrop ? config.PROPERTY_IMAGE_MAXSIZE : config.IMAGE_MAXSIZE)
    ) {
      // Resize the image if it exceeds 2 MB
      const maxWidth = image.width
      const maxHeight = image.height
      const resizedBlob = await resizeImageBlob(
        offscreen,
        fileType,
        maxHeight,
        maxWidth,
      )
      return createResult(resizedBlob, originalFileName)
    }

    // Return the original cropped image
    return createResult(blob, originalFileName)
  }

  // Helper function to resize the image blob
  async function resizeImageBlob(offscreen, fileType, maxHeights, maxWidths) {
    const maxWidth = maxWidths // Set your desired max width
    const maxHeight = maxHeights // Set your desired max height

    const scale = Math.min(
      (maxWidth / offscreen.width) * 2,
      (maxHeight / offscreen.height) * 2,
    )
    const resizedOffscreen = new OffscreenCanvas(
      offscreen.width * scale,
      offscreen.height * scale,
    )
    const resizedCtx = resizedOffscreen.getContext("2d")
    if (!resizedCtx) {
      throw new Error("No 2d context for resized canvas")
    }
    resizedCtx.drawImage(
      offscreen,
      0,
      0,
      resizedOffscreen.width,
      resizedOffscreen.height,
    )

    return resizedOffscreen.convertToBlob({ type: fileType })
  }

  // Helper function to create the result object
  function createResult(blob, originalFileName) {
    if (blobUrlRef.current) {
      URL.revokeObjectURL(blobUrlRef.current)
    }

    const croppedImage = new File([blob], originalFileName, {
      type: blob.type,
      lastModified: Date.now(),
    })

    blobUrlRef.current = URL.createObjectURL(croppedImage)

    return {
      file: croppedImage,
      preview: URL.createObjectURL(croppedImage),
    }
  }

  useEffect(() => {
    if (allUploadedFile) {
      Promise.all(
        allUploadedFile.map(file => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = function (e) {
              resolve(reader.result)
            }
          })
        }),
      )
        .then(results => {
          // results is an array of all the reader results
          SetImageUrl([...results, ...imageUrl])
        })
        .catch(error => {
          // Handle any potential errors
          console.error("Error reading files:", error)
        })
      setFile([]) // This line might need to be moved based on your requirements
    }
  }, [])

  function handleToggleAspectClick() {
    if (imgRef.current) {
      const { width, height } = imgRef.current
      const newCrop = centerAspectCrop(width, height, 16 / 9)
      setCrop(newCrop)
      // Updates the preview
      setCompletedCrop(convertToPixelCrop(newCrop, width, height))
    }
    // }
  }
  function onImageLoad(e) {
    const { width, height } = e.currentTarget
    if (aspect) {
      setCrop(centerAspectCrop(width, height, aspect))
    } else {
      setCrop(centerAspectCrop(width, height, 16 / 9))
    }
  }
  const [btnLoad, setBtnLoad] = useState(false)
  // Example usage in onDownloadCropClick:
  async function onCrop() {
    setBtnLoad(true)
    const image = imgRef.current
    const previewCanvas = previewCanvasRef.current
    if (!image || !previewCanvas || !completedCrop) {
      handleToggleAspectClick()
      setBtnLoad(false)
      return
    }
    try {
      const croppedFile = await getCroppedFile(
        image,
        previewCanvas,
        completedCrop,
        allUploadedFile[imaegIndex]?.name,
        allUploadedFile[imaegIndex]?.type,
      ).then(async blob => {
        const updatedFile = [...allUploadedFile] // Clone the array to avoid mutating state directly
        updatedFile[imaegIndex] = blob // Update the specific index with the new blob
        if (imageUrl.length - 1 === imaegIndex) {
          SetAllUploadedFile([]) // Clear the array when all images are processed
          onCancel(updatedFile)
          setCrop([{ value: undefined }])
          setBtnLoad(false)
        } else {
          SetAllUploadedFile(updatedFile)
          setImageindex(imaegIndex + 1)
          setBtnLoad(false)
        }
        setAspect(undefined)
      })
      // Now you can use the cropped file and preview URL as needed
    } catch (error) {
      console.error("Error cropping image:", error)
      setBtnLoad(false)
    }
  }
  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        )
      }
    },
    100,
    [completedCrop, scale, rotate, imaegIndex],
  )

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        toggle={onCancel}
        backdrop={"static"}
        className="crop-img-modal"
      >
        <ModalHeader toggle={onCancel} tag="h4">
          Crop Image
        </ModalHeader>
        <ModalBody
          style={{ width: "100%", height: "auto" }}
          className="d-flex justify-content-center"
        >
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={c => setCompletedCrop(c)}
            aspect={aspect}
            minHeight={50}
            minWidth={50}
          >
            <img
              ref={imgRef}
              alt="Crop me"
              src={imageUrl[imaegIndex]}
              style={{
                transform: `scale(${scale}) rotate(${rotate}deg)`,
                width: "100%",
                height: "auto",
              }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
          {!!completedCrop && (
            <div>
              <canvas
                ref={previewCanvasRef}
                style={{
                  border: "1px solid black",
                  objectFit: "contain",
                  width: completedCrop.width,
                  height: completedCrop.height,
                  display: "none",
                }}
              />
            </div>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-between align-items-center">
          <div className="d-flex align-items-center gap-2">
            <div className="pagination-btn px-2 rounded">
              {imaegIndex + 1} / {imageUrl.length}
            </div>
          </div>
          <div>
            <Button
              type="button"
              className="btn btn-danger mx-2"
              onClick={() => {
                onCancel()
              }}
            >
              Cancel
            </Button>
            <button
              className="btn btn-success"
              disabled={btnLoad}
              onClick={e => {
                e.preventDefault()
                setBtnLoad(true)
                onCrop()
              }}
            >
              {btnLoad ? (
                <div className="d-flex justify-content-center align-items-center">
                  <div
                    className="spinner-border spinner-border-sm me-1"
                    role="status"
                  >
                    <span className="sr-only">...</span>
                  </div>
                  <span>...</span>
                </div>
              ) : imaegIndex == imageUrl.length - 1 ? (
                "Done"
              ) : (
                "Next"
              )}
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default ImageCroperModel
