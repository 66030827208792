import { configureStore } from "@reduxjs/toolkit"
import { apiSlice } from "./api"
import authReducer from "./authentication/slice"
import LayoutReducer from "./layout/slice"
import propertyReducer from "./property/slice"
import advReducer from "./advert/slice"
import usersReducer from "./user/slice"
import rolesReducer from "./role/slice"
import prefixReducer from "./prefix/slice"
import categoryReducer from "./category/slice"
import blogsReducer from "./newsBlogs/slice"
import paymentsReducer from "./payment/slice"
import advetPaymentsReducer from "./payment/advertSlice"
import dashboardReducer from "./dashboard/slice"
import contactsReducer from "./contacts/slice"
import rfaReducer from "./rfa/slice"
import reportReducer from "./report/slice"
import bannerAdsReducer from "./bannerAds/slice"
import reportingLocationReducer from "./reportingList/slice"
import crmReducer from "./user/CRM/slice"
const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    Layout: LayoutReducer,
    dashboard: dashboardReducer,
    property: propertyReducer,
    adv: advReducer,
    users: usersReducer,
    roles: rolesReducer,
    prefix: prefixReducer,
    category: categoryReducer,
    blogs: blogsReducer,
    payments: paymentsReducer,
    advetPayments: advetPaymentsReducer,
    contacts: contactsReducer,
    rfa: rfaReducer,
    report: reportReducer,
    bannerAds: bannerAdsReducer,
    reportingLocation: reportingLocationReducer,
    crm: crmReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      apiSlice.middleware,
    ),
  devTools: true,
})
export default store
