import React, { useEffect, useMemo, useState } from "react"

//Import Breadcrumb
import CustomTable from "components/Common/Table/CustomeTable"
import { useDispatch, useSelector } from "react-redux"
import { showError } from "helpers"
import { Card, CardBody, UncontrolledTooltip } from "reactstrap"
import { Link } from "react-router-dom"
import { Date, TitleText } from "./ColumnFormatters"
import { useContactsListQuery } from "redux/contacts/api"
import {
  selectContactsList,
  selectContactsTotalRecord,
  setContactsList,
} from "redux/contacts/slice"
import ViewModel from "./ViewModel"
function List() {
  const data = useSelector(selectContactsList)
  const pageInfo = useSelector(selectContactsTotalRecord)
  const [state, setState] = useState({
    search: undefined,
    type: null,
    limit: 10,
    page: 1,
    status: null,
  })
  const dispatch = useDispatch()
  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        // filter: false,
        Cell: cellProps => {
          return <div> {cellProps.row.original.id} </div>
        },
      },
      {
        Header: "Full Name",
        accessor: "name",
        // filter: false,
        filterable: false,
        disableFilters: true,
        disableSortBy: true,
        maxSize: 500,
        Cell: cellProps => {
          return <TitleText {...cellProps} />
        },
      },
      {
        Header: "Email",
        accessor: "email",
        // filter: false,
        filterable: false,
        disableFilters: true,
        disableSortBy: true,
        maxSize: 500,
        Cell: cellProps => {
          return <div> {cellProps.value} </div>
        },
      },
      {
        Header: "Date",
        accessor: "createdAt",
        filterable: false,
        disableFilters: true,
        disableSortBy: true,
        maxSize: 200,
        Cell: cellProps => {
          return <Date {...cellProps} />
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        disableSortBy: true,
        Cell: cellProps => {
          return (
            <>
              <div className="d-flex gap-2">
                <Link
                  // to={`/#`}
                  className="text-info"
                  onClick={() => {
                    onOpenModel(cellProps.row.original)
                  }}
                >
                  <i
                    className="mdi mdi-eye font-size-18"
                    id={`edit${cellProps.row.original._id}`}
                  />
                  <UncontrolledTooltip
                    placement="top"
                    target={`edit${cellProps.row.original._id}`}
                  >
                    View Message
                  </UncontrolledTooltip>
                </Link>
              </div>
            </>
          )
        },
      },
    ],
    [data],
  )

  const list = useContactsListQuery({ state: state })
  if (list.isSuccess) {
    dispatch(setContactsList(list.data.data))
  }

  if (list.isError) {
    showError(list.error)
  }

  useEffect(() => {
    list.refetch()

    return () => {
      dispatch(
        setContactsList({
          list: [],
          pageInfo: { limit: 10, page: 1, totalPages: 1, totalResults: 0 },
        }),
      )
    }
  }, [state])
  const [modelData, setModelDate] = useState({})
  const [openModal, setOpenModal] = useState(false)
  const onOpenModel = data => {
    setModelDate(data)
    setOpenModal(true)
  }
  const toggle = () => {
    if (openModal) {
      setOpenModal(openModal => !openModal)
      setModelDate([])
    }
  }
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CustomTable
            data={data}
            list={list}
            totalRecords={pageInfo?.totalResults}
            stateAPI={state}
            setStateAPI={setState}
            columns={columns}
          />
        </CardBody>
      </Card>
      {openModal && (
        <ViewModel openModal={openModal} toggle={toggle} data={modelData} />
      )}
    </React.Fragment>
  )
}
export default List
