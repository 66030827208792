import { apiSlice } from "../api"

export const reviewApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    reviewAdvList: builder.query({
      query: props => {
        let url = `/review/advert`
        if (props) {
          const { page = 1, limit = 10, type = "" } = props?.state
          url = `/review/advert?${
            type ? "status=" + type + "&" : ""
          }page=${page}&limit=${limit}`
        }
        return {
          url: `${url} `,
          method: "GET",
        }
      },
    }),
    reviewPropertyCount: builder.query({
      query: props => {
        let url = `/review/pending/count`
        return {
          url: `${url} `,
          method: "GET",
        }
      },
    }),
    reviewPropertyList: builder.query({
      query: props => {
        let url = `/review/property`
        if (props) {
          const {
            page = 1,
            limit = 10,
            status = "",
            type = "",
            search = "",
          } = props?.state
          const user = props?.user
          url = `/review/property?${
            status ? "status=" + status + "&" : ""
          }page=${page}&limit=${limit}${
            search != "" ? "&search=" + search : ""
          }`
          if (user) {
            url = `/review/property?${user ? "type=" + userID + "&" : ""}${
              type ? "type=" + type + "&" : ""
            }page=${page}&limit=${limit}${
              search != "" ? "&search=" + search : ""
            }`
          }
        }
        return {
          url: `${url} `,
          method: "GET",
        }
      },
    }),
    rejectMessageList: builder.mutation({
      query: props => {
        let url = `/notes/comment/${props}`
        return {
          url: `${url} `,
          method: "GET",
        }
      },
    }),
    approveProperty: builder.mutation({
      query: data => {
        let url = `/review/approve/property`
        return {
          url: `${url} `,
          method: "PATCH",
          body: data,
        }
      },
    }),
  }),
})
export const {
  useReviewAdvListQuery,
  useReviewPropertyCountQuery,
  useReviewPropertyListQuery,
  useRejectMessageListMutation,
  useApprovePropertyMutation,
} = reviewApiSlice
