import React, { useEffect, useMemo, useState } from "react"

//Import Breadcrumb
import CustomTable from "components/Common/Table/CustomeTable"
import { useDispatch, useSelector } from "react-redux"
import {
  selectAdvList,
  selectAdvTotalRecord,
  setadvList,
} from "redux/advert/slice"
import {
  authentiCateUserPermission,
  confirmAlert,
  showError,
  showToaster,
} from "helpers"
import { Link } from "react-router-dom"
import { UncontrolledTooltip } from "reactstrap"
import { useAdvListQuery, useDeleteAdvMutation } from "redux/advert/api"
import { useReviewAdvListQuery } from "redux/review/api"
import { NumberFormate, Type } from "../../Advert/List/ColumnFormatters"
import { SelectColumnFilter } from "../../Advert/List/Filter"
import {
  selectCurrentUser,
  selectPermissions,
} from "redux/authentication/slice"

function List() {
  const data = useSelector(selectAdvList)
  const pageInfo = useSelector(selectAdvTotalRecord)
  const [state, setState] = useState({
    search: undefined,
    type: null,
    limit: 10,
    page: 1,
    status: null,
  })
  const [load, setLoad] = useState(false)
  const dispatch = useDispatch()
  const user = useSelector(selectCurrentUser)
  const userPermission = useSelector(selectPermissions)
  const columns = useMemo(() => [
    {
      Header: "Id",
      accessor: "id",
      // filter: false,
      Cell: cellProps => {
        return <div> {cellProps.row.original.id} </div>
      },
    },
    {
      Header: "Name",
      accessor: "name",
      filter: false,
      filterable: false,
      disableFilters: true,
      disableSortBy: true,
      Cell: cellProps => {
        return <div> {cellProps.value} </div>
      },
    },
    {
      Header: "listed by",
      accessor: "user.name",
      filterable: false,
      disableFilters: true,
      disableSortBy: true,
      Cell: cellProps => {
        return <div> {cellProps.value} </div>
      },
    },
    {
      Header: "Type",
      accessor: "type",
      filter: "equals", // Set the filter property to specify filter type
      // Filter: SelectColumnFilter, // Specify the custom filter component
      disableFilters: true, // Enable filtering for the column
      disableSortBy: true,
      maxSize: 100,
      Cell: cellProps => {
        return <Type {...cellProps} />
      },
    },
    {
      Header: "Price ( € )",
      accessor: "price",
      filterable: false,
      disableFilters: true,
      disableSortBy: true,
      Cell: cellProps => {
        return <NumberFormate {...cellProps} />
      },
    },
    {
      Header: "Address",
      accessor: "address",
      filterable: false,
      disableFilters: true,
      disableSortBy: true,
      Cell: cellProps => {
        return <div> {cellProps.value} </div>
      },
    },
    {
      Header: "Action",
      accessor: "action",
      disableFilters: true,
      disableSortBy: true,
      Cell: cellProps => {
        return (
          <>
            <div className="d-flex gap-2">
              {!cellProps.row.original.isDeleted &&
                authentiCateUserPermission(
                  [true],
                  userPermission,
                  user?.role,
                ) && (
                  <>
                    <button
                      type="button"
                      className="btn btn-danger btn-sm  btn-label"
                    >
                      <i className="bx bx-block label-icon"></i> Reject
                    </button>
                    <button
                      type="button"
                      className="btn btn-success btn-sm  btn-label"
                    >
                      <i className="bx bx-check-double label-icon"></i> Aprove
                    </button>
                  </>
                )}
            </div>
          </>
        )
      },
    },
  ])

  const list = useReviewAdvListQuery({ state: state })

  if (list.isSuccess) {
    dispatch(setadvList(list.data.data))
  }

  if (list.isError) {
    showError(list.error)
  }

  useEffect(() => {
    list.refetch()

    return () => {
      dispatch(
        setadvList({
          list: [],
          pageInfo: { limit: 10, page: 1, totalPages: 1, totalResults: 0 },
        }),
      )
    }
  }, [state, load])
  return (
    <React.Fragment>
      <CustomTable
        data={data}
        list={list}
        totalRecords={pageInfo?.totalResults}
        stateAPI={state}
        setStateAPI={setState}
        columns={columns}
      />
    </React.Fragment>
  )
}
export default List
