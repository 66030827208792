import { showError } from "helpers"
import React, { useState, useEffect, useRef, useMemo } from "react"
import { useLocation } from "react-router-dom"
import Select, { components } from "react-select"
import { useUserListQuery } from "redux/user/api"
// Simple debounce function
const debounce = (func, delay) => {
  let timeoutId

  return function (...args) {
    const later = () => {
      timeoutId = null
      func.apply(this, args)
    }

    clearTimeout(timeoutId)

    timeoutId = setTimeout(later, delay)
  }
}

const UserListDropdown = ({ formik, userOption = null }) => {
  const [options, setOptions] = useState([])
  const { pathname } = useLocation()
  const [selectedOption, setSelectedOption] = useState(() => {
    return (userOption && userOption[0]) || null
  }, [pathname])
  const [searchValue, setSearchValue] = useState("")
  const [pageValue, setPageValue] = useState(1)
  const [searchText, setSearchText] = useState("")
  const {
    data: userData,
    error: userError,
    isLoading,
    isFetching,
    refetch,
  } = useUserListQuery(
    { searchValue: searchValue, pageValue: pageValue },
    { skip: searchValue == "" },
  )
  useEffect(() => {
    if (pathname == "/properties/add") {
      setSelectedOption(null)
    }
  }, [pathname])
  useEffect(() => {
    const fetchData = async () => {
      try {
        await refetch()
      } catch (error) {
        showError(error)
      }
    }
    if (searchValue !== "" && !isLoading && !isFetching) {
      fetchData()
    }
    return () => {}
  }, [searchValue, pageValue])
  useEffect(() => {
    if (userData && userData.data && userData.data.users) {
      const newData = userData?.data?.users.map(value => ({
        label: value.name,
        email: value.email,
        value: value._id,
      }))
      if (userData?.data?.pageInfo?.page > 1) {
        setOptions(prevOptions => [...prevOptions, ...newData])
      } else {
        setOptions(newData)
      }
    }
  }, [userData?.data, searchText])
  const handleSelectChange = selectedOption => {
    setSelectedOption(selectedOption)
  }
  const handleInputChange = useMemo(
    () =>
      debounce(value => {
        setPageValue(1)
        setSearchValue(() => value)
      }, 600),
    [],
  )
  const handleMenuScroll = event => {
    if (userData?.data?.pageInfo?.totalPages > userData?.data?.pageInfo?.page) {
      setPageValue(page => page + 1)
    }
  }
  const CustomOption = ({ data, ...props }) => (
    <components.Option {...props}>
      <div>
        <div>{data.label}</div>
        <span className="option-container-email">{data.email}</span>
      </div>
    </components.Option>
  )
  const inputRef = useRef(null)
  function downKey(event) {
    if (event?.key === "ArrowDown") {
      if (inputRef.current) {
        const ariaDescribedByValue = inputRef.current.querySelector("input")
        if (ariaDescribedByValue) {
          const ariaDescribedByValue2 = ariaDescribedByValue.getAttribute(
            "aria-activedescendant",
          )
          const record = ariaDescribedByValue2.split("-").pop()
          if (userData?.data?.pageInfo?.page * 10 - 3 < record)
            handleMenuScroll()
        }
      }
    }
  }
  return (
    <>
      <div ref={inputRef} onKeyDown={downKey}>
        <Select
          isClearable
          noOptionsMessage={() => {
            return !options.length && searchText !== "" ? (
              <div className="border border-light bg-light p-2 text-black">
                User Not Found{" "}
              </div>
            ) : (
              <div className="border border-light bg-light p-2 text-black">
                Search user by name
              </div>
            )
          }}
          value={selectedOption}
          name="user"
          onBlur={formik.handleBlur}
          onChange={selectedOption => {
            handleSelectChange(selectedOption)
            formik?.setFieldValue("user", selectedOption?.value)
          }}
          options={searchText != "" ? options : []}
          inputValue={searchText}
          onInputChange={data => {
            setSearchText(data)
            handleInputChange(data)
          }}
          onFocus={() => {
            setSearchText(selectedOption?.label || "")
            setSearchValue(selectedOption?.label || "")
          }}
          placeholder="Select user"
          invalid={!!(formik.touched.user && formik.errors.user)}
          className={
            formik?.errors.user && formik?.touched.user
              ? "border border-danger"
              : ""
          }
          styles={{ menuPortal: base => ({ ...base, borderRadius: "4px" }) }}
          onMenuScrollToBottom={() => {
            handleMenuScroll()
          }}
          components={{ Option: CustomOption }}
        />
      </div>
    </>
  )
}

export default UserListDropdown
