import ImageCroperModel from "components/Common/ImageCroper/ImageCroperModel"
import { useFormik } from "formik"
import { showError, showToaster } from "helpers"
import { asset, getPath, isvalidFileSize } from "helpers/util"
import React, { useEffect, useState } from "react"
import Dropzone from "react-dropzone"
import { useLocation } from "react-router-dom"
import {
  Modal,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Input,
  FormGroup,
  Label,
  Button,
  FormFeedback,
  Form,
} from "reactstrap"
import { useGetPagesDataQuery, useUpdatePageMutation } from "redux/cms/api"
import * as Yup from "yup"
import {
  createSectionInitValue,
  createValidationSchema,
} from "../Common/formik"
import config from "config"

const FaqModal = ({
  modal_center,
  tog_center,
  setmodal_center,
  faqId,
  activeTab,
  listRefetch,
}) => {
  const { pathname } = useLocation()
  const { data, isLoading, isSuccess, refetch } = useGetPagesDataQuery(
    `${
      pathname === "/pages/settings" ? "advertise-with-us" : getPath(pathname)
    }/${faqId}`,
  )
  const [uploadImage, setUploadImage] = useState([])
  const [deletePhoto, setDeletPhoto] = useState([])
  const [btnDisable, setBtnDisable] = useState(false)
  const [file, setFile] = useState([])
  const [isCropModalOpen, setIsCropModalOpen] = useState(false)
  const [updatePage] = useUpdatePageMutation()
  const validation = useFormik({
    // enableReinitialize: use this flag when initial values need to be changed
    enableReinitialize: true,
    initialValues: createSectionInitValue(
      data?.data,
      activeTab,
      getPath(pathname),
    ),
    validationSchema: createValidationSchema(Yup, activeTab, getPath(pathname)),
    onSubmit: async values => {
      try {
        let updateData = { ...values?.faq }
        if (!faqId) {
          updateData = { ...updateData, order: 0 }
        }
        // delete images
        if (faqId && deletePhoto?.length) {
          updateData = {
            ...updateData,
            deleteImages: deletePhoto,
          }
        }
        if (values?.faq?.images?.length) {
          updateData = {
            ...updateData,
            images: uploadImage?.filter(img => img?.file),
          }
        }
        let apiObj = {
          pageName:
            pathname === "/pages/settings"
              ? "advertise-with-us"
              : getPath(pathname),
          data: updateData,
          section: faqId ? faqId : "add",
        }
        if (updateData?.images?.length && uploadImage?.length) {
          apiObj = {
            ...apiObj,
            fileField: "images",
          }
        }
        setBtnDisable(true)
        const result = await updatePage(apiObj).unwrap()
        listRefetch()
        showToaster("success", `${result.message}`, "Success")
        setBtnDisable(false)
        tog_center()
      } catch (error) {
        showError(error)
        setBtnDisable(true)
      }
    },
  })
  // get edit faq data
  useEffect(() => {
    refetch()
    if (faqId) {
      setUploadImage(data?.data?.faq?.images || [])
    } else {
      setUploadImage([])
    }
  }, [faqId, data?.data])

  useEffect(() => {
    validation?.resetForm()
  }, [modal_center])

  // Image Upload Process
  const handleOpenCropModal = () => {
    setIsCropModalOpen(true)
  }

  const handleCloseCropModal = (Ufile = file) => {
    setIsCropModalOpen(false)
    if (Ufile.length > 0) {
      setUploadImage([...uploadImage, ...Ufile])
    }
  }
  const handleSetSelectFiles = files => {
    if (
      !!!files.filter(val => !config.IMAGE_UPLOAD_TYPE.includes(val.type))
        .length
    ) {
      if (isvalidFileSize(files)) {
        setFile([...files])
        handleOpenCropModal()
      } else {
        showToaster(
          "error",
          "File size should be less than or equal to 5MB",
          "Error",
        )
      }
    } else {
      showToaster("error", "Select Valid Image(only jpg, jpeg or png)", "Error")
    }
  }

  // delete Image remove in array and and add deleteArray delete image key
  const handleDeleteImage = (index, imageName) => {
    const updatedFiles = [...uploadImage]
    updatedFiles.splice(index, 1)
    setUploadImage(updatedFiles)
    if (imageName) {
      setDeletPhoto([...deletePhoto, imageName])
    }
  }

  return (
    <Modal
      size="xl"
      isOpen={modal_center}
      toggle={() => {
        tog_center()
      }}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">{faqId ? "Update" : "Add"} FAQ</h5>
        <button
          type="button"
          onClick={() => {
            setmodal_center(false)
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Card>
          <CardBody>
            {/* <CardTitle className="mb-4">Add/Update FAQ</CardTitle> */}
            <Form
              className="outer-repeater"
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
              }}
            >
              <div data-repeater-list="outer-group" className="outer">
                <div data-repeater-item className="outer">
                  <FormGroup className="mb-4" row>
                    <Label htmlFor="que" className="col-form-label col-lg-2">
                      Question
                    </Label>
                    <Col lg="10">
                      <Input
                        id="que"
                        name="faq.que"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.faq?.que || ""}
                        type="text"
                        className="form-control"
                        placeholder="Enter Question..."
                      />
                      {validation.touched.faq?.que &&
                        validation.errors.faq?.que && (
                          <p className="text-danger">
                            {validation.errors.faq?.que}
                          </p>
                        )}
                    </Col>
                  </FormGroup>
                  <FormGroup className="mb-4" row>
                    <Label className="col-form-label col-lg-2">Answer</Label>
                    <Col lg="10">
                      <textarea
                        className="form-control"
                        id="example-textarea"
                        rows="3"
                        name="faq.ans"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.faq?.ans || ""}
                        placeholder="Enter Answer.."
                      />
                      {validation.touched.faq?.ans &&
                        validation.errors.faq?.ans && (
                          <p className="text-danger">
                            {validation.errors.faq?.ans}
                          </p>
                        )}
                    </Col>
                  </FormGroup>
                  <FormGroup className="mb-4" row>
                    <Label className="col-form-label col-lg-2">
                      Video Link
                    </Label>
                    <Col lg="10">
                      <Input
                        name="faq.videoLink"
                        className="form-control"
                        placeholder="Enter Video Link"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation?.values?.faq?.videoLink || ""}
                        invalid={
                          !!(
                            validation?.touched?.faq?.videoLink &&
                            validation?.errors?.faq?.videoLink
                          )
                        }
                      />
                      {validation?.touched?.faq?.videoLink &&
                        validation?.errors?.faq?.videoLink && (
                          <FormFeedback className="fs-6 mt-0" type="invalid">
                            {validation?.errors?.faq?.videoLink}
                          </FormFeedback>
                        )}
                    </Col>
                  </FormGroup>
                  <FormGroup className="mb-4" row>
                    <Label className="col-form-label col-lg-2">Images</Label>
                    <Col lg="10">
                      {/* <Input
                        className="form-control"
                        type="file"
                        multiple
                        id="formFile"
                        onChange={e => {
                          handleSetSelectFiles(Object.values(e.target.files))
                          validation.handleChange
                          validation?.setFieldValue(
                            "faq.images",
                            Object.values(e.target.files),
                          )
                        }}
                      /> */}
                      {/* ------------------------------------------------------ */}
                      <Dropzone
                        onDrop={acceptedFiles => {
                          handleSetSelectFiles(acceptedFiles)
                          validation?.setFieldValue("faq.images", acceptedFiles)
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} accept="image/*" />
                              <div className="dz-message needsclick">
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>Drop files here or click to upload.</h4>
                                <h6 className="text-danger">
                                  Maximum image size allowed is 5MB.{" "}
                                </h6>
                              </div>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      {/* ------------------------------------------------------------- */}

                      {validation.touched.faq?.images &&
                        validation.errors.faq?.images && (
                          <p className="text-danger">
                            {validation.errors.faq?.images}
                          </p>
                        )}
                    </Col>
                    <Label className="col-form-label col-lg-2"></Label>
                    <Col lg="10">
                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        <Row>
                          {uploadImage?.map((IMG, rowIndex) => {
                            if (IMG) {
                              return (
                                <Col
                                  xxl="3"
                                  xl="4"
                                  lg="6"
                                  key={rowIndex + "-file"}
                                >
                                  <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                    key={rowIndex + "-file"}
                                  >
                                    <div className="p-2">
                                      <Row className="align-items-center">
                                        <Col sm="8">
                                          <Col className="col-auto">
                                            <img
                                              data-dz-thumbnail=""
                                              height="50"
                                              width={"auto"}
                                              className=" rounded bg-light"
                                              alt={"Images"}
                                              src={
                                                asset(IMG?.image) ||
                                                IMG?.preview ||
                                                ""
                                              }
                                            />
                                          </Col>
                                        </Col>
                                        <Col sm="4" className="col-auto">
                                          <button
                                            onClick={() =>
                                              handleDeleteImage(
                                                rowIndex,
                                                IMG?.image,
                                              )
                                            }
                                            className="btn btn-danger"
                                            type="button"
                                          >
                                            <i className="fas fa-times font-16"></i>
                                          </button>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Card>
                                </Col>
                                // )
                              )
                            } else {
                              return
                            }
                          })}
                        </Row>
                      </div>
                    </Col>
                  </FormGroup>
                  {isCropModalOpen && (
                    <ImageCroperModel
                      imageArry={file}
                      // getPath(pathname)
                      onCancel={handleCloseCropModal}
                      setFile={setFile}
                      isOpen={isCropModalOpen}
                    />
                  )}
                </div>
              </div>
              <Row className="justify-content-end">
                <Col lg="10">
                  <Button type="submit" color="primary" disabled={btnDisable}>
                    {btnDisable ? (
                      <i className="bx bx-loader font-size-16 align-middle me-2">
                        Loading
                      </i>
                    ) : (
                      <i className="bx bx-check-double font-size-16 label-icon me-2 align-middle">
                        {" "}
                        Submit
                      </i>
                    )}
                  </Button>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </div>
    </Modal>
  )
}

export default FaqModal
