import { createSlice } from "@reduxjs/toolkit"

// import { useNavigate } from "react-router-dom";
const blogsSlice = createSlice({
  name: "blogs",
  initialState: {
    list: [],
    pageInfo: { limit: 10, page: 1, totalPages: 1, totalResults: 0 },
  },
  reducers: {
    setblogsList: (state, action) => {
      const { blogs, pageInfo } = action.payload
      state.list = blogs
      state.pageInfo = pageInfo
      return
    },
  },
})

export const { setblogsList } = blogsSlice.actions

export default blogsSlice.reducer

export const selectBlogsList = state => state.blogs?.list
export const selectBlogsTotalRecord = state => state.blogs?.pageInfo
