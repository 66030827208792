import React, { useState } from "react"
import {
  Row,
  Col,
  Card,
  NavItem,
  TabContent,
  TabPane,
  NavLink,
} from "reactstrap"
import classnames from "classnames"
import { FooterSetting } from "./Setting"
import { ContactSetting } from "./ContactUs"

export const FooterTabs = () => {
  let tabs = ["Footer", "Contact Us"]
  const [activeTab, toggleTab] = useState(tabs[0])
  {
    /* CMS Tabs for managing footer/advert with us and contact us forms */
  }
  return (
    <Col xl={12} lg={8}>
      <Card>
        <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
          {tabs?.map((item, index) => (
            <NavItem key={index}>
              <NavLink
                to="#"
                className={classnames({
                  active: activeTab === item,
                })}
                onClick={() => {
                  toggleTab(item)
                }}
              >
                {item}
              </NavLink>
            </NavItem>
          ))}
        </ul>

        <TabContent className="p-4" activeTab={activeTab}>
          {tabs?.map((item, index) => (
            <TabPane key={index} tabId={item}>
              {item === "Footer" && <FooterSetting />}
              {item === "Contact Us" && <ContactSetting />}
            </TabPane>
          ))}
        </TabContent>
      </Card>
    </Col>
  )
}
