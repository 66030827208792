import { authentiCateUserPermission } from "helpers"
import {
  formatBigNumber,
  formatDate,
  wordTitleCase,
} from "helpers/data-formatters"
import { showFirst20Words } from "helpers/util"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import {
  Badge,
  Button,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
import {
  selectCurrentUser,
  selectPermissions,
} from "redux/authentication/slice"
// import { wordTitleCase } from '../../helpers/data-formatters';

const TitleText = cell => {
  return showFirst20Words(cell.value) || "-"
}

const Srno = ({ cell }) => {
  return Number(cell) + 1
}
const Added = cell => {
  return wordTitleCase(cell.value) || "-"
}
const DateFormate = cell => {
  return formatDate(cell.value) || "-"
}
const NumberFormate = cell => {
  return formatBigNumber(cell.value) || "-"
}
const LiveStatus = cell => {
  const propertyStatus = {
    deleted: { color: "danger", text: "Deleted" },
    paymentRemaining: { color: "info", text: "Payment Remaining" },
    underReview: { color: "primary", text: "Under Review" }, //
    approved: { color: "success", text: "Live" }, // live
    draft: { color: "dark", text: "Draft" }, //
    rejected: { color: "danger", text: "Rejected" },
  }
  const status = cell?.row?.original?.status
  return cell.row.original.isArchived && !cell.row.original.isDeleted ? (
    <Badge className={"font-size-12 badge-soft-" + "warning"}>Archived</Badge>
  ) : (
    <Badge
      className={"font-size-12 badge-soft-" + propertyStatus[status]?.color}
    >
      {" "}
      {propertyStatus[status]?.text}
    </Badge>
  )
}
const Type = cell => {
  return cell.value ? (
    <Badge
      className={
        "font-size-12 badge-soft-" + (cell.value == "sale" ? "info" : "primary")
      }
    >
      {cell.value == "sale" ? "Sale" : "Rent"}
    </Badge>
  ) : (
    "-"
  )
}

export { Type, TitleText, Srno, Added, NumberFormate, LiveStatus, DateFormate }
