// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import React, { useState } from "react"
import {
  Button,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"
import { useUserChangePasswordMutation } from "redux/authentication/api"
import { showError, showToaster } from "helpers"

function ChangePasswordModel({ isOpen, onCancel, Id }) {
  // Form validation using Formik and Yup
  const [updatePassword, UpdatePasswordAPI] = useUserChangePasswordMutation()
  const [passwordShow, setPasswordShow] = useState(false)
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false)
  const validation = useFormik({
    // enableReinitialize: use this flag when initial values need to be changed
    enableReinitialize: true,
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .required("Please enter your password")
        .matches(
          /[a-zA-Z]/,
          "Password should contain minimum 8 characters including 1 number, 1 alphabet, and 1 special character",
        )
        .matches(
          /[0-9]/,
          "Password should contain minimum 8 characters including 1 number, 1 alphabet, and 1 special character",
        )
        .min(
          8,
          "Password should contain minimum 8 characters including 1 number, 1 alphabet, and 1 special character",
        )
        .max(20, "Password should be less than or equal to 20 characters"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("Please confirm your password"),
    }),
    onSubmit: async values => {
      try {
        // Call the resetPassword function to initiate password reset
        const result = await updatePassword({
          payload: values,
          id: Id,
        }).unwrap()
        showToaster("success", `${result.message}`, "Success")
        onCancel()
      } catch (error) {
        showError(error)
      }
    },
  })
  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        toggle={onCancel}
        centered
        backdrop={"static"}
        size="md"
      >
        <Form
          className="form-horizontal"
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <ModalHeader toggle={onCancel}>Change Password</ModalHeader>
          <ModalBody style={{ width: "100%" }}>
            <div className="mb-3">
              <Label className="form-label">Password</Label>
              <div className="input-group auth-pass-inputgroup">
                <Input
                  name="newPassword"
                  value={validation.values.newPassword || ""}
                  type={passwordShow ? "text" : "password"}
                  placeholder="Enter Password"
                  autoComplete="false"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    !!(
                      validation.touched.newPassword &&
                      validation.errors.newPassword
                    )
                  }
                />
                <button
                  onClick={() => setPasswordShow(!passwordShow)}
                  className="btn btn-light"
                  type="button"
                  id="password-addon1"
                >
                  <i
                    className={`mdi ${
                      !passwordShow ? "mdi-eye-outline" : "mdi-eye-off-outline"
                    }`}
                  ></i>
                </button>
                {validation.touched.newPassword &&
                  validation.errors.newPassword && (
                    <FormFeedback className="fs-6 mt-0" type="invalid">
                      {validation.errors.newPassword}
                    </FormFeedback>
                  )}
              </div>
            </div>
            <div className="mb-3">
              <Label className="form-label">Confirm Password</Label>
              <div className="input-group auth-pass-inputgroup">
                <Input
                  name="confirmPassword"
                  value={validation.values.confirmPassword || ""}
                  type={confirmPasswordShow ? "text" : "password"}
                  placeholder="Enter confirmPassword"
                  autoComplete="false"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    !!(
                      validation.touched.confirmPassword &&
                      validation.errors.confirmPassword
                    )
                  }
                />
                <button
                  onClick={() => setConfirmPasswordShow(!confirmPasswordShow)}
                  className="btn btn-light"
                  type="button"
                  id="confirmPassword-addon1"
                >
                  <i
                    className={`mdi ${
                      !confirmPasswordShow
                        ? "mdi-eye-outline"
                        : "mdi-eye-off-outline"
                    }`}
                  ></i>
                </button>
                {validation.touched.confirmPassword &&
                  validation.errors.confirmPassword && (
                    <FormFeedback className="fs-6 mt-0" type="invalid">
                      {validation.errors.confirmPassword}
                    </FormFeedback>
                  )}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                onCancel()
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={validation.isSubmitting}
              className={`btn btn-success w-md ${
                validation.isSubmitting ? "cursor-wait" : "cursor-pointer"
              }`}
            >
              {validation.isSubmitting ? (
                <div className="d-flex justify-content-center align-items-center">
                  <div
                    className="spinner-border spinner-border-sm me-1"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                  <span>Sending...</span>
                </div>
              ) : (
                "Update"
              )}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  )
}

export default ChangePasswordModel
