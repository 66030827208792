import { getPath } from "helpers/util"

export const createSectionInitValue = (data, activeTab, page) => {
  switch (activeTab) {
    case "banner":
      let bannerObj = {}
      if (page === "/pages/home") {
        bannerObj = {
          link: data?.banner?.link ? data?.banner?.link : "",
        }
      } else {
        bannerObj = {
          title: data?.banner?.title ? data?.banner?.title : "",
          image: data?.banner?.image ? data?.banner?.image : "",
        }
      }
      if (
        getPath(page) == "home" ||
        getPath(page) == "about-us" ||
        getPath(page) == "riding" ||
        getPath(page) == "business-service"
      ) {
        bannerObj = {
          ...bannerObj,
          meta: {
            title: data?.banner?.meta?.title ? data?.banner?.meta?.title : "",
            tags: data?.banner?.meta?.tags ? data?.banner?.meta?.tags : "",
            description: data?.banner?.meta?.description
              ? data?.banner?.meta?.description
              : "",
          },
        }
      }
      return {
        banner: bannerObj,
      }
    case "section1":
      let section1Obj = {
        tag: data?.section1?.tag ? data?.section1?.tag : "",
        title: data?.section1?.title ? data?.section1?.title : "",
      }
      if (page === "/pages/home" || page === "/pages/business-service") {
        section1Obj = {
          ...section1Obj,
          link: data?.section1?.link ? data?.section1?.link : "",
        }
      }
      if (page !== "/pages/shopping" || page !== "/pages/business-service") {
        section1Obj = {
          ...section1Obj,
          image: data?.section1?.image ? data?.section1?.image : "",
          description: data?.section1?.description
            ? data?.section1?.description
            : "",
        }
      }
      return {
        section: section1Obj,
      }
    // break;
    case "section2":
      let section2Obj = {
        tag: data?.section2?.tag ? data?.section2?.tag : "",
        title: data?.section2?.title ? data?.section2?.title : "",
      }
      if (page === "/pages/home") {
        section2Obj = {
          ...section2Obj,
          link: data?.section2?.link ? data?.section2?.link : "",
        }
      }
      if (page !== "/pages/shopping") {
        section2Obj = {
          ...section2Obj,
          image: data?.section2?.image ? data?.section2?.image : "",
          description: data?.section2?.description
            ? data?.section2?.description
            : "",
        }
      }
      return {
        section: section2Obj,
      }
    case "section3":
      let section3Obj = {
        tag: data?.section3?.tag ? data?.section3?.tag : "",
        image: data?.section3?.image ? data?.section3?.image : "",
        title: data?.section3?.title ? data?.section3?.title : "",
        description: data?.section3?.description
          ? data?.section3?.description
          : "",
      }
      if (page === "/pages/about-us") {
        // images must be required and array containing 3 images only
        section3Obj = {
          ...section3Obj,
          // images: data?.section3?.images?.length === 3 ? data?.section3?.images : [""],
          image1: data?.section3?.images[0]?.image
            ? data?.section3?.images[0]?.image
            : "",
          image2: data?.section3?.images[1]?.image
            ? data?.section3?.images[1]?.image
            : "",
          image3: data?.section3?.images[2]?.image
            ? data?.section3?.images[2]?.image
            : "",
        }
      }
      if (page === "/pages/home") {
        section3Obj = {
          ...section3Obj,
          link: data?.section3?.link ? data?.section3?.link : "",
        }
      }
      return {
        section: section3Obj,
      }
    case "section4":
      let section4Obj = {
        tag: data?.section4?.tag ? data?.section4?.tag : "",
        image: data?.section4?.image ? data?.section4?.image : "",
        title: data?.section4?.title ? data?.section4?.title : "",
        description: data?.section4?.description
          ? data?.section4?.description
          : "",
      }
      if (page === "/pages/home") {
        section4Obj = {
          ...section4Obj,
          link: data?.section4?.link ? data?.section4?.link : "",
        }
      }
      return {
        section: section4Obj,
      }
    case "section5":
      let section5Obj = {
        tag: data?.section5?.tag ? data?.section5?.tag : "",
        title: data?.section5?.title ? data?.section5?.title : "",
      }
      if (page === "/pages/home" || page === "/pages/riding") {
        section5Obj = {
          ...section5Obj,
          link: data?.section5?.link ? data?.section5?.link : "",
        }
      }
      if (page !== "/pages/riding") {
        section5Obj = {
          ...section5Obj,
          image: data?.section5?.image ? data?.section5?.image : "",
          description: data?.section5?.description
            ? data?.section5?.description
            : "",
        }
      }
      return {
        section: section5Obj,
      }
    case "section6":
      let section6Obj = {
        tag: data?.section6?.tag ? data?.section6?.tag : "",
        title: data?.section6?.title ? data?.section6?.title : "",
      }
      return {
        section: section6Obj,
      }
    case "section7":
      let section7Obj = {
        tag: data?.section7?.tag ? data?.section7?.tag : "",
        title: data?.section7?.title ? data?.section7?.title : "",
      }
      return {
        section: section7Obj,
      }
    case "faqBanner":
      return {
        banner: {
          title: data?.banner?.title ? data?.banner?.title : "",
          image: data?.banner?.image ? data?.banner?.image : "",
          meta: {
            title: data?.banner?.meta?.title ? data?.banner?.meta?.title : "",
            tags: data?.banner?.meta?.tags ? data?.banner?.meta?.tags : "",
            description: data?.banner?.meta?.description
              ? data?.banner?.meta?.description
              : "",
          },
        },
      }
    case "faqSection":
      return {
        faq: {
          que: data?.faq?.que ? data?.faq?.que : "",
          ans: data?.faq?.ans ? data?.faq?.ans : "",
          videoLink: data?.faq?.videoLink ? data?.faq?.videoLink : "",
          images: [""],
        },
      }
    default:
      return {}
    // break;
  }
}

export const createValidationSchema = (Yup, activeTab, page) => {
  switch (activeTab) {
    case "banner":
      let bannerSchema = {}
      if (page === "/pages/home") {
        bannerSchema = {
          link: Yup.string()
            .required("Link is required")
            .matches(
              /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
              "Enter a valid link",
            )
            .trim(),
        }
      } else {
        bannerSchema = {
          image: Yup.string().required("Image is required"),
          title: Yup.string().required("Title is required"),
        }
      }
      return Yup.object({
        banner: Yup.object(bannerSchema),
      })
    case "section1":
      let section1Schema = {
        tag: Yup.string().required("Tag is required"),
        title: Yup.string().required("Title is required"),
      }
      if (page === "/pages/home" || page === "/pages/business-service") {
        section1Schema = {
          ...section1Schema,
          link: Yup.string()
            .required("Link is required")
            .matches(
              /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
              "Enter a valid link",
            )
            .trim(),
        }
      }
      if (page !== "/pages/shopping" && page !== "/pages/business-service") {
        section1Schema = {
          ...section1Schema,
          image: Yup.string().required("Image is required"),
          description: Yup.string().required("Description is required"),
        }
      }
      return Yup.object({
        section: Yup.object(section1Schema),
      })
    // break;
    case "section2":
      let section2Schema = {
        tag: Yup.string().required("Tag is required"),
        title: Yup.string().required("Title is required"),
      }
      if (page === "/pages/home") {
        section2Schema = {
          ...section2Schema,
          link: Yup.string()
            .required("Link is required")
            .matches(
              /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
              "Enter a valid link",
            )
            .trim(),
        }
      }
      if (page !== "/pages/shopping") {
        section2Schema = {
          ...section2Schema,
          image: Yup.string().required("Image is required"),
          description: Yup.string().required("Description is required"),
        }
      }
      return Yup.object({
        section: Yup.object(section2Schema),
      })
    case "section3":
      let section3Schema = {
        tag: Yup.string().required("Tag is required"),
        image: Yup.string().required("Image is required"),
        // images: Yup.array().required("Images is required"), //max/min 3
        title: Yup.string().required("Title is required"),
        description: Yup.string().required("Description is required"),
        // link: Yup.string().required("Link is required").url("Invalid URL format").trim(),
      }
      if (page === "/pages/about-us") {
        section3Schema = {
          ...section3Schema,
          // images: Yup.string().required("Images is required"), //max/min 3
          image1: Yup.string().required("Image is required"),
          image2: Yup.string().required("Image is required"),
          image3: Yup.string().required("Image is required"),
        }
      }
      if (page === "/pages/home") {
        section3Schema = {
          ...section3Schema,
          link: Yup.string()
            .required("Link is required")
            .matches(
              /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
              "Enter a valid link",
            )
            .trim(),
        }
      }
      return Yup.object({
        section: Yup.object(section3Schema),
      })
    case "section4":
      let section4Schema = {
        tag: Yup.string().required("Tag is required"),
        image: Yup.string().required("Image is required"),
        title: Yup.string().required("Title is required"),
        description: Yup.string().required("Description is required"),
      }
      if (page === "/pages/home") {
        section4Schema = {
          ...section4Schema,
          link: Yup.string()
            .required("Link is required")
            .matches(
              /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
              "Enter a valid link",
            )
            .trim(),
        }
      }
      return Yup.object({
        section: Yup.object(section4Schema),
      })
    case "section5":
      let section5Schema = {
        tag: Yup.string().required("Tag is required"),
        title: Yup.string().required("Title is required"),
      }
      if (page === "/pages/home" || page === "/pages/riding") {
        section5Schema = {
          ...section5Schema,
          link: Yup.string()
            .required("Link is required")
            .matches(
              /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
              "Enter a valid link",
            )
            .trim(),
        }
      }
      if (page !== "/pages/riding") {
        section5Schema = {
          ...section5Schema,
          image: Yup.string().required("Image is required"),
          description: Yup.string().required("Description is required"),
        }
      }
      return Yup.object({
        section: Yup.object(section5Schema),
      })
    case "section6":
      let section6Schema = {
        tag: Yup.string().required("Tag is required"),
        title: Yup.string().required("Title is required"),
      }
      return Yup.object({
        section: Yup.object(section6Schema),
      })
    case "section7":
      let section7Schema = {
        tag: Yup.string().required("Tag is required"),
        title: Yup.string().required("Title is required"),
      }
      return Yup.object({
        section: Yup.object(section7Schema),
      })
    case "faqBanner":
      return Yup.object({
        banner: Yup.object({
          image: Yup.string().required("Image is required"),
          title: Yup.string().required("Title is required"),
        }),
      })
    case "faqSection":
      return Yup.object({
        faq: Yup.object({
          que: Yup.string().required("Question is required"),
          ans: Yup.string().required("Answer is required"),
          images: Yup.array().required("Images are required"),
          videoLink: Yup.string()
            .trim()
            .matches(/^\S*$/, "Whitespace is not allowed")
            .url("Please enter a valid URL")
            .matches(
              /^(https?:\/\/)?(www\.)?(youtube\.com|vimeo\.com|yourdomain\.com)\/.+$/,
              "Invalid video link",
            )
            .notRequired("Video URL is required"),
        }),
      })
    default:
      return {}
    // break;
  }
}
