import { useFormik } from "formik"
import React, { useRef, useState } from "react"
import * as Yup from "yup"
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap"
import {
  addLineBreaks,
  asset,
  getImageRatio,
  getPath,
  isvalidFileSize,
  replaceBrTagsWithNewlines,
} from "helpers/util"
import { createSectionInitValue, createValidationSchema } from "./formik"
import { useLocation } from "react-router-dom"
import { useUpdatePageMutation } from "redux/cms/api"
import { showError, showToaster } from "helpers"
import ImageCroperModel from "components/Common/ImageCroper/ImageCroperModel"
import config from "config"
import LazyImageWithFallback from "components/Common/LazyImageWithFallback"
export const CmsSection = ({
  data,
  activeTab,
  refetch,
  activeField,
  setActiveField,
}) => {
  const { pathname } = useLocation()
  const [updatePage] = useUpdatePageMutation()
  const [btnDisable, setBtnDisable] = useState(false)
  const [file, setFile] = useState([])
  const [isCropModalOpen, setIsCropModalOpen] = useState(false)
  const [uploadImage, setUploadImage] = useState([])
  const [multi, setMulti] = useState(false) // manage multiple images storage state
  const [uploadMultiImage, setUploadMultiImage] = useState([])
  const validation = useFormik({
    // enableReinitialize: use this flag when initial values need to be changed
    enableReinitialize: true,
    initialValues: createSectionInitValue(data, activeTab, pathname),
    validationSchema: createValidationSchema(Yup, activeTab, pathname),
    onSubmit: async values => {
      try {
        if (values?.section?.description) {
          values = {
            ...values,
            section: {
              ...values?.section,
              description: addLineBreaks(values?.section?.description),
            },
          }
        }
        if (uploadMultiImage?.length > 0) {
          values = {
            ...values,
            section: {
              ...values?.section,
              images: uploadMultiImage,
            },
          }
          if (values?.section?.image1?.name) {
            values = {
              ...values,
              section: {
                ...values?.section,
                image1: values?.section?.image1?.name,
              },
            }
          }
          if (values?.section?.image2?.name) {
            values = {
              ...values,
              section: {
                ...values?.section,
                image2: values?.section?.image2?.name,
              },
            }
          }
          if (values?.section?.image3?.name) {
            values = {
              ...values,
              section: {
                ...values?.section,
                image3: values?.section?.image3?.name,
              },
            }
          }
        }
        setBtnDisable(true)
        let apiObj = {
          pageName: getPath(pathname),
          data: values?.section,
          section: activeTab,
        }
        if (uploadImage[0]?.file) {
          apiObj = {
            ...apiObj,
            data: { ...values?.section, image: uploadImage[0]?.file },
            fileField: "image",
          }
        }
        if (uploadMultiImage?.length > 0) {
          apiObj = {
            ...apiObj,
            // data: { ...values?.section, image: uploadImage[0]?.file },
            fileField: "images",
          }
        }
        // }
        const result = await updatePage(apiObj).unwrap()
        setUploadImage([]) //empty uplaoded image state
        refetch()
        showToaster("success", `${result.message}`, "Success")
        // resetForm()
        setBtnDisable(false)
      } catch (error) {
        showError(error)
        setBtnDisable(false)
        // resetForm()
      }
    },
  })
  // Image Upload Process
  const handleOpenCropModal = () => {
    setIsCropModalOpen(true)
  }

  const handleCloseCropModal = (Ufile = file) => {
    setIsCropModalOpen(false)
    if (Ufile.length > 0) {
      if (multi) {
        setUploadMultiImage(prevUploadMultiImage => {
          const tempData = [...prevUploadMultiImage]
          tempData[multi - 1] = Ufile[0]
          return tempData
        })
        setMulti(false)
      } else {
        setUploadImage([...Ufile])
      }
    } else {
      setUploadImage([])
      if (multi) {
        setUploadMultiImage(prevUploadMultiImage => {
          const tempData = [...prevUploadMultiImage]
          tempData[multi - 1] = undefined
          return tempData
        })
      }
      setMulti(false)
      handleFileInputClick()
      if (multi == 1) {
        validation.setFieldValue(
          "section[image1]",
          data.section3.images[0].image,
        )
        handleFileInputClick()
      } else if (multi == 2) {
        validation.setFieldValue(
          "section[image2]",
          data.section3.images[1].image,
        )
        handleFileInputClickImage1()
      } else if (multi == 3) {
        validation.setFieldValue(
          "section[image3]",
          data.section3.images[2].image,
        )
        handleFileInputClickImage2()
      }
    }
    setActiveField("")
  }
  const fileInputRef = useRef(null)
  const fileInputRef1 = useRef(null)
  const fileInputRef2 = useRef(null)
  // Function to open the file input dialog when the "Change Avatar" button is clicked
  const handleFileInputClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = null
    }
  }
  const handleFileInputClickImage1 = () => {
    if (fileInputRef1.current) {
      fileInputRef1.current.value = null
    }
  }
  const handleFileInputClickImage2 = () => {
    if (fileInputRef2.current) {
      fileInputRef2.current.value = null
    }
  }
  const handleSetSelectFiles = (files, multi, index) => {
    if (!files[0]) {
      setUploadImage([])
      return
    }
    if (
      !!!files.filter(val => !config.IMAGE_UPLOAD_TYPE.includes(val.type))
        .length
    ) {
      if (isvalidFileSize(files)) {
        if (multi) {
          setMulti(index)
        }
        setFile([...files])
        handleOpenCropModal()
      } else {
        showToaster(
          "error",
          "File size should be less than or equal to 5MB",
          "Error",
        )
      }
    } else {
      showToaster("error", "Select Valid Image(only jpg, jpeg or png)", "Error")
    }
  }

  {
    /* Section Form */
  }
  return (
    <Form
      onSubmit={e => {
        e.preventDefault()
        validation.handleSubmit()
      }}
    >
      {!(activeTab === "section3" && getPath(pathname) === "riding") && (
        <FormGroup className="mb-4" row>
          <Label htmlFor="tag" md="2" className="col-form-label">
            Tag
          </Label>
          <Col md="10">
            <Input
              type="text"
              className="form-control"
              id="tag"
              placeholder="Enter Tag"
              name="section.tag"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.section?.tag || ""}
              invalid={
                !!(
                  validation.touched.section?.tag &&
                  validation.errors.section?.tag
                )
              }
            />
            {validation.touched.section?.tag &&
              validation.errors.section?.tag && (
                <FormFeedback className="fs-6 mt-0" type="invalid">
                  {validation.errors.section?.tag}
                </FormFeedback>
              )}
          </Col>
        </FormGroup>
      )}
      <FormGroup className="mb-4" row>
        <Label htmlFor="title" md="2" className="col-form-label">
          Title
        </Label>
        <Col md="10">
          <Input
            type="text"
            className="form-control"
            id="title"
            placeholder="Enter Title"
            name="section.title"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.section?.title || ""}
            invalid={
              !!(
                validation.touched.section?.title &&
                validation.errors.section?.title
              )
            }
          />
          {validation.touched.section?.title &&
            validation.errors.section?.title && (
              <FormFeedback className="fs-6 mt-0" type="invalid">
                {validation.errors.section?.title}
              </FormFeedback>
            )}
        </Col>
      </FormGroup>
      {activeTab !== "section6" && activeTab !== "section7" && (
        <>
          {(activeTab === "section5" && getPath(pathname) === "riding") ||
          getPath(pathname) === "shopping" ||
          (getPath(pathname) === "about-us" && activeTab === "section3") ||
          getPath(pathname) === "business-service" ? (
            <></>
          ) : (
            <FormGroup className="mb-4" row>
              <Label htmlFor="formFile" md="2" className="col-form-label">
                Image
              </Label>
              <Col md="5">
                <Input
                  innerRef={fileInputRef}
                  className="form-control mb-3"
                  type="file"
                  accept="image/*"
                  id="formFile"
                  name="section.image"
                  key={validation.values.section?.image}
                  onChange={e => {
                    if (
                      activeTab === "section3" &&
                      getPath(pathname) === "about-us"
                    ) {
                      setActiveField("image")
                    }
                    handleSetSelectFiles([e.target.files[0]])
                    validation.handleChange
                  }}
                  onBlur={validation.handleBlur}
                  // src={asset(validation.values.section?.image) || ""}
                  // invalid={!!(validation.touched.section?.image && validation.errors.section?.image)}
                />
                {validation.touched.section?.image &&
                  validation.errors.section?.image && (
                    <p className="text-danger">
                      {validation.errors.section?.image}
                    </p>
                  )}
                {/* <img
                  placeholder="image"
                  style={{ height: "auto", width: "100px" }}
                  src={asset(validation.values.section?.image)}
                /> */}
              </Col>
              <Col lg="5">
                {(!!uploadImage[0]?.preview ||
                  !!validation.values.section?.image) && (
                  <LazyImageWithFallback
                    src={
                      uploadImage[0]?.preview ||
                      asset(validation.values.section?.image)
                    }
                    alt="Selected Image"
                    className="rounded"
                    style={{ height: "50px", width: "auto" }}
                  />
                )}
              </Col>
            </FormGroup>
          )}
          {getPath(pathname) !== "shopping" &&
            getPath(pathname) !== "business-service" &&
            (activeTab !== "section5" || getPath(pathname) !== "riding") && (
              <FormGroup className="mb-0" row>
                <Label
                  md="2"
                  className="col-form-label"
                  htmlFor="example-textarea"
                >
                  Description
                </Label>
                <Col md="10">
                  <textarea
                    className="form-control"
                    id="example-textarea"
                    rows="3"
                    placeholder="Enter Description.."
                    name="section.description"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      replaceBrTagsWithNewlines(
                        validation.values.section?.description,
                      ) || ""
                    }
                    // invalid={!!(validation.touched.section?.description && validation.errors.section?.description)}
                  />
                  {validation.touched.section?.description &&
                    validation.errors.section?.description && (
                      <p className="text-danger">
                        {validation.errors.section?.description}
                      </p>
                    )}
                </Col>
              </FormGroup>
            )}
          {/* link : access in Home, Riding :section 5, shoping, b&s */}
          {(pathname === "/pages/home" ||
            (pathname === "/pages/riding" && activeTab === "section5") ||
            pathname == "/pages/business-service") && (
            <FormGroup className="mb-4" row>
              <Label htmlFor="link" md="2" className="col-form-label">
                Link
              </Label>
              <Col md="10">
                <Input
                  type="text"
                  className="form-control"
                  id="link"
                  placeholder="Enter Link"
                  name="section.link"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.section?.link || ""}
                  invalid={
                    !!(
                      validation.touched.section?.link &&
                      validation.errors.section?.link
                    )
                  }
                />
                {validation.touched.section?.link &&
                  validation.errors.section?.link && (
                    <FormFeedback className="fs-6 mt-0" type="invalid">
                      {validation.errors.section?.link}
                    </FormFeedback>
                  )}
              </Col>
            </FormGroup>
          )}

          {pathname === "/pages/about-us" && activeTab === "section3" && (
            <>
              <FormGroup className="mb-4" row>
                <Label htmlFor="formFile" md="2" className="col-form-label">
                  Image 1
                </Label>
                <Col md="5">
                  <Input
                    innerRef={fileInputRef}
                    className="form-control mb-2"
                    type="file"
                    accept="image/*"
                    id="formFile"
                    // multiple={false}
                    name="section.image1"
                    onChange={e => {
                      setActiveField("image1")
                      validation.handleChange
                      validation.setFieldValue(
                        "section.image1",
                        e.target.files[0],
                      )
                      handleSetSelectFiles([e.target.files[0]], true, 1)
                    }}
                    onBlur={validation.handleBlur}
                    // src={asset(validation.values.section?.images) || ""}
                    // invalid={!!(validation.touched.section?.image && validation.errors.section?.image)}
                  />
                  {validation.touched.section?.image1 &&
                    validation.errors.section?.image1 && (
                      <p className="text-danger">
                        {validation.errors.section?.image1}
                      </p>
                    )}
                  {/* {validation.values.section?.image1 &&
                    validation.values.section?.image1 &&
                    <img
                      className="p-1"
                      key={validation.values.section?.image1}
                      placeholder="image 1"
                      style={{ height: "auto", width: "100px" }}
                      src={asset(validation.values.section?.image1)}
                    />
                  } */}
                </Col>
                <Col lg="5">
                  {(!!uploadMultiImage[0]?.preview ||
                    !!validation.values.section?.image1) && (
                    <LazyImageWithFallback
                      src={
                        uploadMultiImage[0]?.preview ||
                        asset(data.section3.images[0].image)
                      }
                      alt="Selected Image"
                      className="rounded"
                      style={{ height: "50px", width: "auto" }}
                    />
                  )}
                </Col>
                {/* <Col md='1'>
                  {validation.values.section?.image1 &&
                    validation.values.section?.image1 &&
                    <i
                      className="mdi mdi-eye font-size-18"
                      onClick={() => 'open modal to view image'}
                      id={validation.values.section?.image1}
                    />
                  }
                </Col> */}
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label htmlFor="formFile" md="2" className="col-form-label">
                  Image 2
                </Label>
                <Col md="5">
                  <Input
                    innerRef={fileInputRef1}
                    className="form-control mb-3"
                    type="file"
                    accept="image/*"
                    id="formFile"
                    // multiple={false}
                    name="section.image2"
                    onChange={e => {
                      setActiveField("image2")
                      handleSetSelectFiles([e.target.files[0]], true, 2)
                      validation.setFieldValue(
                        "section.image2",
                        e.target.files[0],
                      )
                      validation.handleChange
                    }}
                    onBlur={validation.handleBlur}
                    // src={asset(validation.values.section?.images) || ""}
                    // invalid={!!(validation.touched.section?.image && validation.errors.section?.image)}
                  />
                  {validation.touched.section?.image2 &&
                    validation.errors.section?.image2 && (
                      <p className="text-danger">
                        {validation.errors.section?.image2}
                      </p>
                    )}
                  {/* {validation.values.section?.image2 &&
                    validation.values.section?.image2 &&
                    <img
                      className="p-1"
                      key={validation.values.section?.image2}
                      placeholder="image 2"
                      style={{ height: "auto", width: "100px" }}
                      src={asset(validation.values.section?.image2)}
                    />
                  } */}
                </Col>
                <Col lg="5">
                  {(!!uploadMultiImage[1]?.preview ||
                    !!validation.values.section?.image2) && (
                    <LazyImageWithFallback
                      src={
                        uploadMultiImage[1]?.preview ||
                        asset(data.section3.images[1].image)
                      }
                      alt="Selected Image"
                      className="rounded"
                      style={{ height: "50px", width: "auto" }}
                    />
                  )}
                </Col>
                {/* <Col md='1'>
                  {validation.values.section?.image2 &&
                    validation.values.section?.image2 &&
                    <i
                      className="mdi mdi-eye font-size-18"
                      onClick={() => 'open modal to view image'}
                      id={validation.values.section?.image2}
                    />
                  }
                </Col> */}
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label htmlFor="formFile" md="2" className="col-form-label">
                  Image 3
                </Label>
                <Col md="5">
                  <Input
                    innerRef={fileInputRef2}
                    className="form-control mb-3"
                    type="file"
                    id="formFile"
                    accept="image/*"
                    // multiple={false}
                    name="section.image3"
                    onChange={e => {
                      setActiveField("image3")
                      handleSetSelectFiles([e.target.files[0]], true, 3)
                      validation.setFieldValue(
                        "section.image3",
                        e.target.files[0],
                      )
                      validation.handleChange
                    }}
                    onBlur={validation.handleBlur}
                  />
                  {validation.touched.section?.image3 &&
                    validation.errors.section?.image3 && (
                      <p className="text-danger">
                        {validation.errors.section?.image3}
                      </p>
                    )}
                  {/* {validation.values.section?.image3 &&
                    validation.values.section?.image3 &&
                    <img
                      className="p-1"
                      key={validation.values.section?.image3}
                      placeholder="image 3"
                      style={{ height: "auto", width: "100px" }}
                      src={asset(validation.values.section?.image3)}
                    />
                  } */}
                </Col>
                <Col lg="5">
                  {(!!uploadMultiImage[2]?.preview ||
                    !!validation.values.section?.image3) && (
                    <LazyImageWithFallback
                      src={
                        uploadMultiImage[2]?.preview ||
                        asset(data.section3.images[2].image)
                      }
                      alt="Selected Image"
                      className="rounded"
                      style={{ height: "50px", width: "auto" }}
                    />
                  )}
                </Col>
                {/* <Col md='1'>
                  {validation.values.section?.image3 &&
                    validation.values.section?.image3 &&
                    <i
                      className="mdi mdi-eye font-size-18"
                      onClick={() => 'open modal to view image'}
                      id={validation.values.section?.image3}
                    />
                  }
                </Col> */}
              </FormGroup>
            </>
          )}
          {isCropModalOpen && (
            <ImageCroperModel
              imageArry={file}
              // getPath(pathname)
              ratio={[
                {
                  value: activeField
                    ? getImageRatio([getPath(pathname), activeTab, activeField])
                    : getImageRatio([getPath(pathname), activeTab]),
                  text: "",
                },
              ]}
              onCancel={handleCloseCropModal}
              setFile={setFile}
              isOpen={isCropModalOpen}
            />
          )}
        </>
      )}

      <Row className="mt-4">
        <Col sm="12">
          <div className="text-sm-end">
            <button
              type="submit"
              className="btn btn-success me-2"
              disabled={btnDisable}
            >
              {btnDisable ? (
                <i className="bx bx-loader font-size-16 align-middle me-2">
                  Loading
                </i>
              ) : (
                <i className="bx bx-check-double font-size-16 label-icon me-2 align-middle">
                  {" "}
                  Submit
                </i>
              )}
            </button>
            <button
              className="btn btn-danger"
              type="button"
              onClick={() => {
                validation.resetForm()
                setUploadImage([])
                setUploadMultiImage([])
                handleFileInputClick()
                handleFileInputClickImage1()
                handleFileInputClickImage2()
              }}
            >
              <i className="bx bx-block font-size-16 label-icon me-2 align-middle" />{" "}
              Cancel{" "}
            </button>
          </div>
        </Col>
      </Row>
    </Form>
  )
}
