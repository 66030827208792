import React from "react"
import { Container } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Helmet } from "react-helmet"
import { useGetPagesDataQuery } from "redux/cms/api"
import CmsTab from "../Common/Tab"
function PropertySetting() {
  const { data, isLoading, isSuccess, refetch } =
    useGetPagesDataQuery("properties")

  const BreadcrumbDetails = {
    breadcrumbItem: {
      label: "Property Setting",
      link: "/pages/properties",
    },
    paths: [
      {
        label: "Pages",
        link: "/pages/properties",
      },
    ],
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Property Setting | Discover Equestrian</title>
      </Helmet>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs breadcrumbDetails={BreadcrumbDetails} />
          <CmsTab
            data={data}
            isLoading={isLoading}
            isSuccess={isSuccess}
            tab={"Properties"}
            refetch={refetch}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PropertySetting
