import React from "react"
import { Spinner } from "reactstrap"
const LoadingSpinner = ({ height, width, type, color, margin }) => {
  return (
    <Spinner
      className={`m-${margin || 5}`}
      color={color || "primary"}
      style={{
        height: height || "3rem",
        width: width || "3rem",
      }}
      type={type || "grow"}
    >
      Loading...
    </Spinner>
  )
}

export default LoadingSpinner
