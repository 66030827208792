import React, { useEffect, useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import { showError, showToaster } from "helpers"
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import {
  useAddPrefixMutation,
  useGetPrefixMutation,
  useUpdatePrefixMutation,
} from "redux/prefix/api"
import {
  selectPrefixList,
  selectPrefixPageInfo,
  setPrefixList,
} from "redux/prefix/slice"
import { useDispatch, useSelector } from "react-redux"
import LoadingSpinner from "components/Common/LoadingSpinner"

function AddEditModel({
  openModal,
  setOpenModal,
  setLoadList,
  loadList,
  id = null,
  setId,
}) {
  const prefixData = useSelector(selectPrefixList)
  const prefixPageInfo = useSelector(selectPrefixPageInfo)
  const dispatch = useDispatch()
  const [addPrefix, addPrefixAPI] = useAddPrefixMutation()
  const [updatePrefix, updatePrefixAPI] = useUpdatePrefixMutation()
  const [editInfo, setEditInfo] = useState([])
  const [getData, getDataAPI] = useGetPrefixMutation()
  const IsEdit = !!id
  const toggle = () => {
    if (openModal) {
      setOpenModal(openModal => !openModal)
      formik.resetForm()
      setId(null)
    }
  }
  // get edit data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getData(id).unwrap()
        setEditInfo(result?.data)
      } catch (error) {
        showError(error)
      }
    }
    if (id) {
      fetchData()
    }
  }, [id])
  // Yup validation
  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required("Name is required"),
  })
  // formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: IsEdit ? editInfo?.name : "",
    },
    validationSchema,
    onSubmit: async values => {
      if (!IsEdit) {
        try {
          const result = await addPrefix(values).unwrap()
          setLoadList(loadList => !loadList)
          toggle()
          showToaster("success", `${result.message}`, "Success")
        } catch (error) {
          showError(error)
        }
      } else {
        try {
          const result = await updatePrefix({
            value: values,
            id: id,
          }).unwrap()
          const updatedArray = await prefixData.map(item => {
            if (item.id == result?.data?.id) {
              return result?.data // Replace object with id 2
            } else {
              return item // Keep other objects unchanged
            }
          })
          dispatch(
            setPrefixList({
              prefixes: updatedArray,
              pageInfo: prefixPageInfo,
            }),
          )
          // setLoadList(load => !load)
          toggle()
          showToaster("success", `${result.message}`, "Success")
        } catch (error) {
          showError(error)
        }
      }
    },
  })
  return (
    <React.Fragment>
      <Modal
        // size="lg"
        isOpen={openModal}
        toggle={toggle}
        backdrop={"static"}
        centered={true}
      >
        <ModalHeader toggle={toggle} tag="h4">
          {!IsEdit ? "Create Prefix" : "Edit Prefix"}
        </ModalHeader>
        <ModalBody>
          {getDataAPI?.isLoading || getDataAPI?.isFetching ? (
            <Col lg="12" className="w-100 d-flex justify-content-center">
              {" "}
              <LoadingSpinner />{" "}
            </Col>
          ) : (
            <Form
              className="outer-repeater"
              onSubmit={e => {
                e.preventDefault()
                formik.handleSubmit()
                return
              }}
            >
              <div data-repeater-list="outer-group" className="outer">
                <div data-repeater-item className="outer">
                  <FormGroup className="mb-4" row>
                    <Label htmlFor="name" className="col-form-label col-lg-2">
                      Name
                    </Label>
                    <Col lg="10">
                      <Input
                        id="name"
                        name="name"
                        type="text"
                        className="form-control"
                        placeholder="Enter Prefix Name..."
                        value={formik.values.name || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        invalid={!!(formik.touched.name && formik.errors.name)}
                      />
                      {formik.touched.name && formik.errors.name && (
                        <FormFeedback className="fs-6 mt-0" type="invalid">
                          {formik.errors.name}
                        </FormFeedback>
                      )}
                    </Col>
                  </FormGroup>
                </div>
              </div>
              <Row className="">
                <div className="modal-footer">
                  <Col className="text-end">
                    <button
                      type="button"
                      className="btn btn-secondary me-2"
                      onClick={() => toggle()}
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      disabled={formik.isSubmitting}
                      className={`btn btn-success w-sm ${
                        formik.isSubmitting ? "cursor-wait" : "cursor-pointer"
                      }`}
                    >
                      {formik.isSubmitting ? (
                        <div className="d-flex justify-content-center align-items-center">
                          <div
                            className="spinner-border spinner-border-sm me-1"
                            role="status"
                          >
                            <span className="sr-only">
                              {!IsEdit ? "Adding" : "Updating"}...
                            </span>
                          </div>
                          <span>{!IsEdit ? "Adding" : "Updating"}...</span>
                        </div>
                      ) : !IsEdit ? (
                        "Add"
                      ) : (
                        "Update"
                      )}
                    </button>
                  </Col>
                </div>
              </Row>
            </Form>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default AddEditModel
