import { formatDate, wordTitleCase } from "helpers/data-formatters"
import { showFirst20Words } from "helpers/util"
import React from "react"
import { Link } from "react-router-dom"
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap"
const TitleText = cell => {
  return showFirst20Words(cell.value)
}
const Date = cell => {
  return formatDate(cell.value)
}
const Adderss = cell => {
  return (
    <>
      <div>
        <Link
          rel="noopener"
          id={`location${cell.row.original._id}`}
          target="_blank"
          to={`https://maps.google.com/?q=${cell?.value?.coordinates[1]},${cell?.value?.coordinates[0]}`}
        >
          {" "}
          <i className="bx bx-map align-middle text-dark me-1"></i>
          {cell?.value?.name}
        </Link>
      </div>
      <UncontrolledTooltip
        placement="top"
        target={`location${cell.row.original._id}`}
      >
        View Location
      </UncontrolledTooltip>
    </>
  )
}
const ContactsDetails = cell => {
  // return cell?.value?.map((value, index) => {
  return (
    // <UncontrolledDropdown className="d-inline">
    <DropdownToggle tag="a" href="#" className="card-drop">
      <i className="mdi mdi-dots-horizontal font-size-18"></i>
    </DropdownToggle>

    //   <DropdownMenu className="dropdown-menu-end">
    //     <DropdownItem href="#"
    //       onClick={() => {
    //         const customerData = cell.row.original;
    //         handleCustomerClick(customerData);
    //       }
    //       }
    //     >
    //       <i className="mdi mdi-pencil font-size-16 text-success me-1" id="edittooltip"></i>
    //       Edit
    //       <UncontrolledTooltip placement="top" target="edittooltip">
    //         Edit
    //       </UncontrolledTooltip>
    //     </DropdownItem>

    //     <DropdownItem href="#"
    //       onClick={() => {
    //         const customerData = cell.row.original;
    //         onClickDelete(customerData);
    //       }}>
    //       <i className="mdi mdi-trash-can font-size-16 text-danger me-1" id="deletetooltip"></i>
    //       Delete
    //       <UncontrolledTooltip placement="top" target="deletetooltip">
    //         Delete
    //       </UncontrolledTooltip>
    //     </DropdownItem>
    //   </DropdownMenu>
    // </UncontrolledDropdown>
    // <div key={index} className="pb-1 border-bottom">
    //   <div>
    //     <i className="mdi mdi-account pe-1" /> {value?.name}
    //   </div>
    //   <div>
    //     <i className="mdi mdi-phone pe-2" />
    //     {value?.number}
    //   </div>
    // </div>
  )
  // })
}

export { TitleText, Date, Adderss, ContactsDetails }
