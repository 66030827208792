import { createSlice } from "@reduxjs/toolkit"
import { removeAuthToken } from "helpers/util"
import { jwtDecode } from "jwt-decode"

// import { useNavigate } from "react-router-dom";
const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    token: null,
    logout: false,
    selfLogout: false,
    serverError: false,
  },
  reducers: {
    setCredentials: (state, action) => {
      const { user, tokens, token } = action.payload
      state.user = user
      state.token = token || tokens.access.token
      state.logout = false
      state.selfLogout = false
      state.permissions = jwtDecode(token || tokens.access.token)?.permissions
      return
    },
    setUserProfile: (state, action) => {
      state.user = action.payload
      state.logout = false
      return
    },
    logOut: (state, action) => {
      state.user = null
      state.token = null
      state.logout = true
      state.selfLogout = action.payload?.selfLogout || false
      // removeAuthToken()
      return
    },
    serverError: (state, action) => {
      state.serverError = action.payload
      return
    },
  },
})

export const { setCredentials, logOut, setUserProfile, serverError } =
  authSlice.actions

export default authSlice.reducer

export const selectCurrentUser = state => state.auth.user
export const selectCurrentToken = state => state.auth.token
export const selectLogout = state => state.auth.logout
export const selectSelfLogout = state => state.auth.selfLogout
export const selectServerError = state => state.auth.serverError
export const selectPermissions = state => state.auth.permissions
