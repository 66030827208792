import { formatDate, wordTitleCase } from "helpers/data-formatters"
import { showFirst20Words } from "helpers/util"

const TitleText = cell => {
  return showFirst20Words(cell.value)
}
const Date = cell => {
  return formatDate(cell.value)
}

export { TitleText, Date }
