import PropTypes from "prop-types"
import React from "react"
import { Card, CardBody, Col } from "reactstrap"

const MiniCards = props => {
  const { title, text, iconClass } = props
  return (
    <React.Fragment>
      <Col sm="6" md="6" lg="6" xl="4" xxl="3">
        <Card className="mini-stats-wid">
          <CardBody>
            <div className="d-flex flex-wrap">
              <div className="me-3">
                <p className="text-muted fw-medium mb-2">{title}</p>
                <h4 className="mb-0">{text}</h4>
              </div>

              <div className="avatar-sm ms-auto">
                <div className="avatar-title bg-light rounded-circle text-primary">
                  <i className={"font-size-24 " + iconClass}></i>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}
export default MiniCards
