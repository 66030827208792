import React, { useEffect, useMemo, useState } from "react"

//Import Breadcrumb
import CustomTable from "components/Common/Table/CustomeTable"
import { useDispatch, useSelector } from "react-redux"
import {
  selectBannerAdsList,
  selectBannerAdsTotalRecord,
  setBannerAdsList,
} from "redux/bannerAds/slice"
import {
  SweetAlert,
  authentiCateUserPermission,
  confirmAlert,
  showError,
  showToaster,
} from "helpers"
import { Link } from "react-router-dom"
import { UncontrolledTooltip } from "reactstrap"
import {
  useBannerAdsListQuery,
  useDeleteBannerAdsMutation,
} from "redux/bannerAds/api"
import { LiveStatus, TitleText } from "./ColumnFormatters"
import { SelectColumnFilterStatus } from "./Filter"
import {
  selectCurrentUser,
  selectPermissions,
} from "redux/authentication/slice"
import ViewModel from "../View/ViewModel"

function List({ userId = null }) {
  const [openModal, setOpenModal] = useState(false)
  const [id, setId] = useState(null)
  const openViewModel = Id => {
    if (Id) {
      setId(Id)
    }
    setOpenModal(true)
  }
  const data = useSelector(selectBannerAdsList)
  const pageInfo = useSelector(selectBannerAdsTotalRecord)
  const [state, setState] = useState({
    search: undefined,
    type: null,
    limit: 10,
    page: 1,
    status: null,
  })
  const [load, setLoad] = useState(false)
  const [deleteBannerAdv, deleteBannerAdvAPI] = useDeleteBannerAdsMutation()
  const dispatch = useDispatch()
  const user = useSelector(selectCurrentUser)
  const userPermissions = useSelector(selectPermissions)

  const onDelete = async id => {
    try {
      const confirmationMessage = `You want to delete this Advert!`
      const confirmation = await confirmAlert(
        confirmationMessage,
        "warning",
        "Yes, delete it!",
      )
      if (confirmation.isConfirmed) {
        const result = await deleteBannerAdv(id).unwrap()
        setLoad(load => !load)
        SweetAlert(`${result.message}`, "success")
      }
    } catch (error) {
      showError(error)
    }
  }
  const columns = useMemo(() => [
    {
      Header: "Id",
      accessor: "_id",
      // filter: false,
      Cell: cellProps => {
        return <div> {cellProps.row.original._id} </div>
      },
    },
    {
      Header: "Name",
      accessor: "name",
      filter: false,
      filterable: false,
      disableFilters: true,
      disableSortBy: true,
      maxSize: 300,
      Cell: cellProps => {
        return <TitleText {...cellProps} />
      },
    },
    {
      Header: "Listed By",
      accessor: "user.name",
      filterable: false,
      disableFilters: true,
      disableSortBy: true,
      Cell: cellProps => {
        return <div> {cellProps.value} </div>
      },
    },
    {
      Header: "Status",
      accessor: "isLive",
      filter: "equals", // Set the filter property to specify filter type
      Filter: SelectColumnFilterStatus, // Specify the custom filter component
      disableFilters: false, // Enable filtering for the column
      disableSortBy: true,
      maxSize: 150,
      Cell: cellProps => {
        return <LiveStatus {...cellProps} />
      },
    },
    {
      Header: "Action",
      accessor: "action",
      disableFilters: true,
      disableSortBy: true,
      Cell: cellProps => {
        return (
          <>
            <div className="d-flex gap-2">
              {!cellProps.row.original.isDeleted &&
                authentiCateUserPermission(
                  ["updateBanner"],
                  userPermissions,
                  user?.role,
                ) && (
                  <Link
                    to={
                      userId
                        ? `/user/adverts/edit/${cellProps.row.original._id} `
                        : `/adverts/edit/${cellProps.row.original._id}`
                    }
                    className="text-success"
                  >
                    <i
                      className="mdi mdi-lead-pencil font-size-18"
                      id={`edit${cellProps.row.original._id}`}
                    />
                    <UncontrolledTooltip
                      placement="top"
                      target={`edit${cellProps.row.original._id}`}
                    >
                      Edit
                    </UncontrolledTooltip>
                  </Link>
                )}
              {authentiCateUserPermission(
                ["listBanner"],
                userPermissions,
                user?.role,
              ) && (
                <Link
                  to={`#`}
                  onClick={() => openViewModel(cellProps.row.original._id)}
                  className="text-info"
                >
                  <i
                    className="mdi mdi-eye font-size-18"
                    id={`view${cellProps.row.original._id}`}
                  />
                  <UncontrolledTooltip
                    placement="top"
                    target={`view${cellProps.row.original._id}`}
                  >
                    View
                  </UncontrolledTooltip>
                </Link>
              )}
              {authentiCateUserPermission(
                ["deleteBanner"],
                userPermissions,
                user?.role,
              ) && (
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    const user = cellProps.row.original
                    onDelete(user?._id)
                  }}
                >
                  {" "}
                  <i
                    className="mdi mdi-delete font-size-18"
                    id={`delete${cellProps.row.original._id}`}
                  />
                  <UncontrolledTooltip
                    placement="top"
                    target={`delete${cellProps.row.original._id}`}
                  >
                    Delete
                  </UncontrolledTooltip>
                </Link>
              )}
            </div>
          </>
        )
      },
    },
  ])

  const list = useBannerAdsListQuery({ state: state, userId: userId })

  if (list.isSuccess) {
    dispatch(setBannerAdsList(list.data.data))
  }

  if (list.isError) {
    showError(list.error)
  }

  useEffect(() => {
    list.refetch()

    return () => {
      dispatch(
        setBannerAdsList({
          banners: [],
          pageInfo: { limit: 10, page: 1, totalPages: 1, totalResults: 0 },
        }),
      )
    }
  }, [state, load])
  return (
    <React.Fragment>
      <CustomTable
        data={data}
        list={list}
        totalRecords={pageInfo?.totalResults}
        stateAPI={state}
        setStateAPI={setState}
        columns={columns}
        userId={userId}
      />
      {openModal && (
        <ViewModel
          openModal={openModal}
          setOpenModal={setOpenModal}
          id={id}
          setId={setId}
        />
      )}
    </React.Fragment>
  )
}
export default List
