import React, { useEffect, useMemo, useState } from "react"

//Import Breadcrumb
import CustomTable from "components/Common/Table/CustomeTable"
import { useDispatch, useSelector } from "react-redux"
import { showError } from "helpers"
import {
  NumberFormate,
  TitleText,
  Status,
  Property,
  Date,
  Type,
  Amount,
} from "./ColumnFormatters"
import { Card, CardBody } from "reactstrap"
import { SelectColumnFilter } from "./Filter"
import {
  selectPaymentList,
  selectPaymentPageInfo,
  setPaymentsList,
} from "redux/payment/slice"
import { usePropertyPaymentQuery } from "redux/payment/api"
function List() {
  const data = useSelector(selectPaymentList)
  const pageInfo = useSelector(selectPaymentPageInfo)
  const [state, setState] = useState({
    search: undefined,
    type: null,
    limit: 10,
    page: 1,
    status: null,
  })
  const [load, setLoad] = useState(false)
  const dispatch = useDispatch()
  const columns = useMemo(() => [
    {
      Header: "Id",
      accessor: "id",
      // filter: false,
      Cell: cellProps => {
        return <div> {cellProps.row.original.id} </div>
      },
    },
    {
      Header: "Property",
      accessor: "property",
      // filter: false,
      filterable: false,
      disableFilters: true,
      disableSortBy: true,
      maxSize: 500,
      Cell: cellProps => {
        return <Property {...cellProps} />
      },
    },
    {
      Header: "Address",
      accessor: "property.address",
      filterable: false,
      disableFilters: true,
      disableSortBy: true,
      Cell: cellProps => {
        return <div> {cellProps.value} </div>
      },
    },
    {
      Header: "Title",
      accessor: "property.title",
      filterable: false,
      disableFilters: true,
      disableSortBy: true,
      Cell: cellProps => {
        return <div> </div>
      },
    },
    {
      Header: "Listed By",
      accessor: "user.name",
      filterable: false,
      disableFilters: true,
      disableSortBy: true,
      maxSize: 200,
      Cell: cellProps => {
        return <TitleText {...cellProps} />
      },
    },
    {
      Header: "Date",
      accessor: "createdAt",
      filterable: false,
      disableFilters: true,
      disableSortBy: true,
      maxSize: 200,
      Cell: cellProps => {
        return <Date {...cellProps} />
      },
    },
    {
      Header: "Amount ( £ )",
      accessor: "amount",
      filterable: false,
      disableFilters: true,
      maxSize: 150,
      disableSortBy: true,
      Cell: cellProps => {
        return <Amount {...cellProps} />
      },
    },
    {
      Header: "Payment Method",
      accessor: "card",
      filterable: false,
      disableFilters: true, // Enable filtering for the column
      disableSortBy: true,
      maxSize: 150,
      Cell: cellProps => {
        return <Type {...cellProps} />
      },
    },
    {
      Header: "Payment Status",
      accessor: "status",
      filterable: false,
      disableFilters: true, // Enable filtering for the column
      disableSortBy: true,
      maxSize: 150,
      Cell: cellProps => {
        return <Status {...cellProps} />
      },
    },
  ])

  const list = usePropertyPaymentQuery({ state: state })

  if (list.isSuccess) {
    dispatch(setPaymentsList(list.data.data))
  }

  if (list.isError) {
    showError(list.error)
  }

  useEffect(() => {
    list.refetch()

    return () => {
      dispatch(
        setPaymentsList({
          list: [],
          pageInfo: { limit: 10, page: 1, totalPages: 1, totalResults: 0 },
        }),
      )
    }
  }, [state, load])
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CustomTable
            data={data}
            list={list}
            totalRecords={pageInfo?.totalResults}
            stateAPI={state}
            setStateAPI={setState}
            columns={columns}
            hideColumns={["property.title", "property.address"]}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default List
