import React, { useState } from "react"
import { Helmet } from "react-helmet"
import { Container } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import List from "./List"
import AddModel from "../Add/AddModel"
function ReportingLocationList() {
  const BreadcrumbDetails = {
    breadcrumbItem: {
      label: "List",
      link: "/reporting-location/list",
    },
    paths: [
      {
        label: "Setting",
        link: "/reporting-location/list",
      },
      {
        label: "Reporting Location",
        link: "/reporting-location/list",
      },
    ],
  }
  const [openAddEditModal, setOpenAppEditModal] = useState()
  const [load, setLoad] = useState(false)
  const onAddReportingLocation = ID => {
    if (ID) {
      setId(ID)
    }
    setOpenAppEditModal(true)
  }
  const [id, setId] = useState(null)
  return (
    <React.Fragment>
      <Helmet>
        <title>Reporting Location List | Discover Equestrian</title>
      </Helmet>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs breadcrumbDetails={BreadcrumbDetails} />

          <List
            setLoad={setLoad}
            load={load}
            onAddReportingLocation={onAddReportingLocation}
          />
        </Container>
        {openAddEditModal && (
          <AddModel
            openModal={openAddEditModal}
            setOpenModal={setOpenAppEditModal}
            setLoadList={setLoad}
            load={load}
            id={id}
            setId={setId}
          />
        )}
      </div>
    </React.Fragment>
  )
}

export default ReportingLocationList
