import {
  Badge,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import React, { useEffect, useState } from "react"
import { showError, showToaster } from "helpers"
import { useFormik } from "formik"
import * as Yup from "yup"
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"
import {
  useAddRoleMutation,
  useGetRoleMutation,
  useUpdateRoleMutation,
} from "redux/role/api"
import { useGetPermissionMutation } from "redux/authentication/api"
import {
  selectRolesList,
  selectRolesPageInfo,
  setRolesList,
} from "redux/role/slice"
import { useDispatch, useSelector } from "react-redux"
import LoadingSpinner from "components/Common/LoadingSpinner"
function AddModel({
  openModal,
  setOpenModal,
  setLoadList,
  load,
  id = null,
  setId,
}) {
  const [addRole, addRoleAPI] = useAddRoleMutation()
  const roleData = useSelector(selectRolesList)
  const rolePageInfo = useSelector(selectRolesPageInfo)
  const dispatch = useDispatch()
  const [getpermisionArray, getpermisionArrayAPI] = useGetPermissionMutation()
  const [getData, getDataAPI] = useGetRoleMutation()
  const [updateRole, UpdateRoleAPI] = useUpdateRoleMutation()
  const [permissionArray, setPermissionArray] = useState([])
  const IsEdit = !!id
  const [editInfo, setEditInfo] = useState([])
  const [activeTab, setActiveTab] = useState("tab1")
  // list of permision checkbox
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getpermisionArray().unwrap()
        setPermissionArray(result?.data.admin)
      } catch (error) {
        // remove this line when api chenge comlete and uncomment below line
        setPermissionArray(error?.data?.data?.admin)
        // showError(error)
      }
    }
    fetchData()
  }, [])
  // get edit role data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getData(id).unwrap()
        setEditInfo(result?.data)
      } catch (error) {
        showError(error)
      }
    }
    if (id) {
      fetchData()
    }
  }, [id])
  const toggle = () => {
    if (openModal) {
      setOpenModal(openModal => !openModal)
      setEditInfo([])
      formik.resetForm()
      setId(null)
      setActiveTab("tab1")
    }
  }
  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }
  // Yup validation
  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required("Name is required"),
  })

  // formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: IsEdit ? editInfo.name : "",
    },
    validationSchema,
    onSubmit: async values => {
      const { name, ...permission } = values
      const key = Object.keys(permission)
      const newpermissions = key.filter(
        val => !Object.keys(permissionArray).includes(val) && permission[val],
      )
      if (newpermissions.length == 0) {
        showToaster("info", `Plaese select at least one Permission`, "info")
        return
      }
      const data = { name: name, permissions: newpermissions }
      if (!IsEdit) {
        try {
          const result = await addRole(data).unwrap()
          setLoadList(load => !load)
          toggle()
          showToaster("success", `${result.message}`, "Success")
        } catch (error) {
          showError(error)
        }
      } else {
        try {
          const result = await updateRole({
            value: data,
            id: id,
          }).unwrap()
          const updatedArray = await roleData.map(item => {
            if (item.id == result?.data?.id) {
              return result?.data // Replace object with id 2
            } else {
              return item // Keep other objects unchanged
            }
          })
          dispatch(
            setRolesList({
              roles: updatedArray,
              pageInfo: rolePageInfo,
            }),
          )
          // setLoadList(load => !load)
          toggle()
          showToaster("success", `${result.message}`, "Success")
        } catch (error) {
          showError(error)
        }
      }
    },
  })
  useEffect(() => {
    if (editInfo?.permissions) {
      editInfo.permissions.map(value => {
        formik.setFieldValue(`${value}`, true)
      })
      Object.keys(permissionArray).map(key => {
        const subkeyArray = Object.keys(permissionArray[key])
        if (
          subkeyArray.filter(value => {
            return editInfo?.permissions?.includes(value)
          }).length == subkeyArray.length
        ) {
          formik.setFieldValue(`${key}`, true)
        } else {
          formik.setFieldValue(`${key}`, false)
        }
      })
    }
  }, [editInfo])

  const allSelect = (key, value) => {
    formik.setFieldValue(`${key}`, value)
    Object.keys(permissionArray[key]).map(subKey => {
      if (permissionArray[key][subKey].isRequired) {
        formik.setFieldValue(subKey, value)
      }
      formik.setFieldValue(`${subKey}`, value)
    })
  }
  // select pemision value  manage Required and all checkbox
  const oneSelect = (key, subkeyCu, value) => {
    let allselect = [value]
    formik.setFieldValue(`${subkeyCu}`, value)
    const val = Object.keys(permissionArray[key]).map((subKey, index) => {
      if (subKey !== subkeyCu && !permissionArray[key][subKey].isRequired) {
        allselect = [...allselect, formik.values[subKey]]
      }
      if (permissionArray[key][subKey].isRequired) {
        formik.setFieldValue(subKey, true)
      }
    })
    if (allselect.includes(undefined) || allselect.includes(false)) {
      formik.setFieldValue(key, false)
    } else {
      formik.setFieldValue(key, true)
    }
    Object.keys(permissionArray[key]).map((subKey, index) => {
      if (!allselect.includes(true)) {
        formik.setFieldValue(subKey, false)
      }
    })
  }
  return (
    <React.Fragment>
      <Modal
        size="lg"
        isOpen={openModal}
        toggle={toggle}
        backdrop={"static"}
        centered={true}
      >
        <ModalHeader toggle={toggle} tag="h4">
          {!IsEdit ? "Create Role" : "Edit Role"}
        </ModalHeader>
        <ModalBody>
          {getDataAPI?.isLoading || getDataAPI?.isFetching ? (
            <Col lg="12" className="w-100 d-flex justify-content-center">
              {" "}
              <LoadingSpinner />{" "}
            </Col>
          ) : (
            <Form
              className="outer-repeater"
              onSubmit={e => {
                e.preventDefault()
                formik.handleSubmit()
                return
              }}
            >
              <div data-repeater-list="outer-group" className="outer">
                <div data-repeater-item className="outer">
                  <FormGroup className="mb-4" row>
                    <Label htmlFor="name" className="col-form-label col-lg-2">
                      Name
                    </Label>
                    <Col lg="10">
                      <Input
                        id="name"
                        name="name"
                        type="text"
                        className="form-control"
                        placeholder="Enter Role Name..."
                        value={formik.values.name || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        invalid={!!(formik.touched.name && formik.errors.name)}
                      />
                      {formik.touched.name && formik.errors.name && (
                        <FormFeedback className="fs-6 mt-0" type="invalid">
                          {formik.errors.name}
                        </FormFeedback>
                      )}
                    </Col>
                  </FormGroup>
                </div>
              </div>
              <Row>
                <Col xl="4" lg="4">
                  <Nav tabs className="pt-3">
                    <div
                      className="d-flex  flex-lg-row flex-lg-column gap-1"
                      style={{ width: "100%" }}
                    >
                      {Object.keys(permissionArray).map((key, index) => {
                        return (
                          <NavItem key={key}>
                            <NavLink
                              className={
                                activeTab === `tab${index + 1}`
                                  ? "active bg-primary text-white d-flex justify-content-between"
                                  : " d-flex justify-content-between"
                              }
                              onClick={() => toggleTab(`tab${index + 1}`)}
                            >
                              {key}
                              {Object.keys(permissionArray[key]).filter(
                                (subkey, index) => formik.values[subkey],
                              ).length > 0 && (
                                <Badge
                                  color="primary"
                                  pill
                                  className="d-flex justify-content-center align-items-center rounded-circle"
                                  style={{ width: "20px", height: "20px" }}
                                >
                                  <span style={{ fontSize: "14px" }}>
                                    {
                                      Object.keys(permissionArray[key]).filter(
                                        (subkey, index) =>
                                          formik.values[subkey],
                                      ).length
                                    }
                                  </span>
                                </Badge>
                              )}
                            </NavLink>
                          </NavItem>
                        )
                      })}
                    </div>
                  </Nav>
                </Col>
                <Col xl="6" lg="6">
                  <TabContent activeTab={activeTab}>
                    {Object.keys(permissionArray).map((key, index) => {
                      return (
                        <TabPane key={index} tabId={`tab${index + 1}`}>
                          <FormGroup check>
                            <Label check>
                              <Input
                                style={{ transform: "scale(1.5)" }}
                                type="checkbox"
                                name={`${key}`}
                                checked={formik.values[key]} // Use val as a property accessor
                                onChange={e => allSelect(key, e.target.checked)}
                              />{" "}
                              {"Select All"}
                            </Label>
                          </FormGroup>
                          <div className="pb-3 "></div>

                          {Object.keys(permissionArray[key]).map(
                            (subkey, index) => {
                              return (
                                <FormGroup check key={index} className="my-2">
                                  <Label check className="">
                                    <Input
                                      className="mx-1"
                                      style={{ transform: "scale(1.5)" }}
                                      type="checkbox"
                                      name={`${subkey}`}
                                      checked={formik.values[subkey]} // Use val as a property accessor
                                      disabled={
                                        permissionArray[key][subkey].isRequired
                                          ? true
                                          : false
                                      }
                                      onChange={e => {
                                        if (
                                          !permissionArray[key][subkey]
                                            .isRequired
                                        ) {
                                          oneSelect(
                                            key,
                                            subkey,
                                            e.target.checked,
                                          )
                                        }
                                      }}
                                    />{" "}
                                    <span className="ms-2">
                                      {permissionArray[key][subkey].name}{" "}
                                    </span>
                                  </Label>
                                </FormGroup>
                              )
                            },
                          )}
                        </TabPane>
                      )
                    })}
                  </TabContent>
                </Col>
              </Row>
              <Row className="mb-3">
                <div className="modal-footer">
                  <Col className="text-end">
                    <button
                      type="button"
                      className="btn btn-secondary me-2"
                      onClick={() => toggle()}
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      disabled={formik.isSubmitting}
                      className={`btn btn-success w-sm ${
                        formik.isSubmitting ? "cursor-wait" : "cursor-pointer"
                      }`}
                    >
                      {formik.isSubmitting ? (
                        <div className="d-flex justify-content-center align-items-center">
                          <div
                            className="spinner-border spinner-border-sm me-1"
                            role="status"
                          >
                            <span className="sr-only">Adding...</span>
                          </div>
                          <span>Adding...</span>
                        </div>
                      ) : !IsEdit ? (
                        "Add"
                      ) : (
                        "Update"
                      )}
                    </button>
                  </Col>
                </div>
              </Row>
            </Form>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default AddModel
