import { createSlice } from "@reduxjs/toolkit"
const reportingLocationSlice = createSlice({
  name: "reportingLocation",
  initialState: {
    list: [],
    pageInfo: { limit: 10, page: 1, totalPages: 1, totalResults: 0 },
  },
  reducers: {
    setReportingLocationList: (state, action) => {
      const { reportingLocations, pageInfo } = action.payload
      state.list = reportingLocations
      state.pageInfo = pageInfo
      return
    },
  },
})

export const { setReportingLocationList } = reportingLocationSlice.actions

export default reportingLocationSlice.reducer

export const selectReportingLocationList = state =>
  state.reportingLocation?.list
export const selectReportingLocationTotalRecord = state =>
  state.reportingLocation?.pageInfo
