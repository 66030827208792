import React, { useRef } from "react"
import { useDrag, useDrop } from "react-dnd"
import { ItemTypes } from "./ItemTypes.js"
import LazyImageWithFallback from "../LazyImageWithFallback.js"
import { Col, UncontrolledTooltip } from "reactstrap"
const style = {
  border: "1px dashed gray",
  padding: "0.5rem 0.5rem",
  marginBottom: "1.5rem",
  backgroundColor: "white",
  cursor: "pointer",
  borderRadius: "5px",
}
export const Card = ({ id, ImageObj, index, moveCard, handleDeleteImage }) => {
  const ref = useRef(null)
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))
  return (
    <>
      <Col xxl="2" xl="3" lg="4" md="6" sm="6">
        <div
          className="d-flex justify-content-center position-relative"
          ref={ref}
          style={{ ...style, opacity, zIndex: "111" }}
          data-handler-id={handlerId}
        >
          <div
            className="d-flex justify-content-center position-relative"
            style={{ height: "100px" }}
            id={`Drag${id}`}
          >
            <LazyImageWithFallback
              src={ImageObj.preview ? ImageObj?.preview : ImageObj?.url}
              alt="banner"
              className="rounded bg-light w-100 h-100"
            />
          </div>
          <button
            onClick={e => {
              e.preventDefault()
              handleDeleteImage(index, ImageObj?.key)
            }}
            id={`delete${id}`}
            className="btn btn-danger crose-img-btn"
            type="button"
          >
            <i className="fas fa-times font-16"></i>
          </button>
        </div>
      </Col>
      {!isDragging && (
        <>
          <UncontrolledTooltip placement="top" target={`Drag${id}`}>
            Drag to re-order image
          </UncontrolledTooltip>
          <UncontrolledTooltip placement="top" target={`delete${id}`}>
            Remove image
          </UncontrolledTooltip>
        </>
      )}
    </>
  )
}
