import LazyImageWithFallback from "components/Common/LazyImageWithFallback"
import {
  formatBigNumber,
  formatDate,
  wordTitleCase,
} from "helpers/data-formatters"
import React from "react"
import { Badge } from "reactstrap"

const TitleText = cell => {
  return wordTitleCase(cell.value)
}
const Date = cell => {
  return formatDate(cell.value)
}
const BannerAds = cellProps => {
  const data = cellProps.value
  return (
    <div className="d-flex">
      <div className="me-3">
        <LazyImageWithFallback
          src={data?.image?.url}
          alt=""
          className="avatar-md  rounded"
        />
      </div>
      <div className="flex-grow-1 align-self-center">
        <div className="text-muted">
          <h6>{wordTitleCase(data?.name)}</h6>
        </div>
      </div>
    </div>
  )
}

const NumberFormate = cell => {
  return formatBigNumber(cell.value) || ""
}
const Amount = cell => {
  return <div>£ {formatBigNumber(cell.value)}</div>
}

const Status = cell => {
  return (
    <Badge
      className={`font-size-12 badge-soft-${
        cell.value == "completed" ? "success" : "danger"
      }`}
    >
      {wordTitleCase(cell.value)}
    </Badge>
  )
}
const Type = cell => {
  const data = cell.value
  return (
    <div className="d-flex">
      <div className="flex-grow-1 align-self-center">
        <div className="text-muted">
          <h6 className="font-weight-bold">XX {wordTitleCase(data?.number)}</h6>
          <p className="text-muted mb-1">
            <i className="bx bx-credit-card font-size-18 align-middle text-primary me-1" />
            {data?.type}
          </p>
        </div>
      </div>
    </div>
  )
}

export { Type, Amount, Date, BannerAds, TitleText, NumberFormate, Status }
