import React, { useMemo, useState } from "react"
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import { restrictToVerticalAxis } from "@dnd-kit/modifiers"
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable"
import { useTable } from "react-table"
import { DraggableTableRow } from "./DraggableTableRow"
import { StaticTableRow } from "./StaticTableRow"
import { Table } from "reactstrap"
import LoadingSpinner from "components/Common/LoadingSpinner"
import { useReorderFaqMutation } from "redux/cms/api"
import { showError } from "helpers"
import { useLocation } from "react-router-dom"

export function DragTable({
  isLoading,
  isSuccess,
  refetch,
  columns,
  data = [],
  setData,
}) {
  const [activeId, setActiveId] = useState()
  const { pathname } = useLocation()
  const [Reorder, ReorderAPI] = useReorderFaqMutation()
  const items = useMemo(() => data?.map(({ id }) => id), [data])
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    })
  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {}),
  )

  function handleDragStart(event) {
    setActiveId(event.active.id)
  }

  async function handleDragEnd(event) {
    const { active, over } = event
    if (active.id !== over.id) {
      var changeRow = {}
      if (over.id > active.id) {
        data?.map(val => {
          if (val.id <= over.id && val.id > active.id) {
            changeRow = { ...changeRow, [val._id]: val.id - 1 }
            return
          } else if (val.id == active.id) {
            changeRow = { ...changeRow, [val._id]: over.id }
            return
          } else {
            changeRow = { ...changeRow, [val._id]: val.id }
          }
        })
      } else if (over.id < active.id) {
        data?.map(val => {
          if (val.id >= over.id && val.id < active.id) {
            changeRow = { ...changeRow, [val._id]: val.id + 1 }
            return
          } else if (val.id == active.id) {
            changeRow = { ...changeRow, [val._id]: over.id }
            return
          } else {
            changeRow = { ...changeRow, [val._id]: val.id }
          }
        })
      }
      try {
        const result = await Reorder({
          pathname:
            pathname !== "/pages/settings"
              ? pathname
              : "/pages/advertise-with-us",
          changeRow: changeRow,
        }).unwrap()
        setData(data => {
          const oldIndex = items.indexOf(active.id)
          const newIndex = items.indexOf(over.id)
          refetch()
          return arrayMove(data, oldIndex, newIndex)
        })
      } catch (error) {
        showError(error)
      }
    }

    setActiveId(null)
  }

  function handleDragCancel() {
    setActiveId(null)
  }

  const selectedRow = useMemo(() => {
    if (!activeId) {
      return null
    }
    const row = rows.find(({ original }) => original.id === activeId)
    prepareRow(row)
    return row
  }, [activeId, rows, prepareRow])

  // Render the UI for your table
  return (
    <DndContext
      sensors={sensors}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragCancel={handleDragCancel}
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
    >
      <Table className="table-responsive react-table" {...getTableProps()}>
        <thead className="table-light table-nowrap">
          {headerGroups.map((headerGroup, index) => (
            <tr key={`header${index}`} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => (
                <th key={i} {...column.getHeaderProps()}>
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {isLoading && (
            <tr>
              <td
                // colSpan={visibleColumns.length}
                style={{
                  textAlign: "center",
                }}
              >
                <LoadingSpinner />
              </td>
            </tr>
          )}
          {!rows?.length && !isLoading && (
            <tr>
              <td
                // colSpan={visibleColumns.length}
                style={{
                  textAlign: "center",
                }}
              >
                <p>
                  {" "}
                  Data Not Found{" "}
                  <span
                    style={{
                      fontSize: "100px;",
                    }}
                  >
                    &#128581;
                  </span>
                </p>
              </td>
            </tr>
          )}
          {!isLoading && (
            <SortableContext
              items={items}
              strategy={verticalListSortingStrategy}
            >
              {rows?.map((row, i) => {
                prepareRow(row)
                return <DraggableTableRow key={row.original.id} row={row} />
              })}
            </SortableContext>
          )}
        </tbody>
      </Table>
      <DragOverlay>
        {activeId && (
          <Table
            className="table-responsive react-table"
            style={{ width: "100%" }}
          >
            <tbody>
              <StaticTableRow row={selectedRow} />
            </tbody>
          </Table>
        )}
      </DragOverlay>
    </DndContext>
  )
}
