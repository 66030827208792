import React, { useState } from "react"
import { Col, Card, NavItem, TabContent, TabPane, NavLink } from "reactstrap"
import classnames from "classnames"
import { CmsSection } from "../Common/Section"
import { useGetPagesDataQuery } from "redux/cms/api"
import { convertToTitleCase } from "helpers/util"

export const SectionTabs = ({ tabList }) => {
  const [activeTab, toggleTab] = useState("section1")
  const [activeField, setActiveField] = useState("")
  const { data, isLoading, isSuccess, refetch } = useGetPagesDataQuery("home")
  {
    /* CMS Tabs for managing footer/advert with us and contact us forms */
  }
  return (
    <Col xl={12} lg={8}>
      <Card>
        <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
          {tabList?.map(
            (item, index) =>
              item !== "banner" && (
                <NavItem key={index}>
                  <NavLink
                    to="#"
                    className={classnames({
                      active: activeTab === item,
                    })}
                    onClick={() => {
                      toggleTab(item)
                    }}
                  >
                    {convertToTitleCase(item)}
                  </NavLink>
                </NavItem>
              ),
          )}
        </ul>
        <TabContent className="p-4" activeTab={activeTab}>
          {tabList?.map(
            (item, index) =>
              item !== "banner" && (
                <TabPane key={index} tabId={item}>
                  <CmsSection
                    data={data?.data}
                    activeTab={activeTab}
                    refetch={refetch}
                    activeField={activeField}
                    setActiveField={setActiveField}
                  />
                </TabPane>
              ),
          )}
        </TabContent>
      </Card>
    </Col>
  )
}
