import config from "config"
import { formatBigNumber, wordTitleCase } from "helpers/data-formatters"
import { showFirst20Words } from "helpers/util"
import React from "react"
import { Badge } from "reactstrap"

const TitleText = cell => {
  return showFirst20Words(cell?.value) || ""
}

const Srno = ({ cell }) => {
  return Number(cell) + 1
}
const Added = cell => {
  return wordTitleCase(cell.value)
}

const NumberFormate = cell => {
  return formatBigNumber(cell.value) || ""
}

const Status = cell => {
  return (
    <Badge
      className={`font-size-12 badge-soft-${
        config.REVIEW_PROPERTY_TYPE[cell.value]?.color
      }`}
    >
      {config.REVIEW_PROPERTY_TYPE[cell.value]?.name}
    </Badge>
  )
}
const Type = cell => {
  return (
    <Badge
      className={
        "font-size-12 badge-soft-" +
        (cell?.value == "sale" ? "info" : "primary")
      }
    >
      {cell.value == "sale" ? "Sale" : "Rent"}
    </Badge>
  )
}

export { Type, TitleText, Srno, Added, NumberFormate, Status }
