import React from "react"
import { Card, CardBody, Container } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import List from "./List"
import { Helmet } from "react-helmet"
function PropertyList() {
  // document.title = "Dashboard | Discover Equestrian"
  const BreadcrumbDetails = {
    breadcrumbItem: {
      label: "List",
      link: "/Properties/list",
    },
    paths: [
      {
        label: "Properties",
        link: "/Properties/list",
      },
    ],
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Properties List | Discover Equestrian</title>
      </Helmet>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs breadcrumbDetails={BreadcrumbDetails} />
          <Card>
            <CardBody>
              <List />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PropertyList
