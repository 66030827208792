import { createSlice } from "@reduxjs/toolkit"
const reportSlice = createSlice({
  name: "report",
  initialState: {
    list: [],
    pageInfo: { limit: 10, page: 1, totalPages: 1, totalResults: 0 },
  },
  reducers: {
    setReportList: (state, action) => {
      const { reports, pageInfo } = action.payload
      state.list = reports
      state.pageInfo = pageInfo
      return
    },
  },
})

export const { setReportList } = reportSlice.actions

export default reportSlice.reducer

export const selectReportList = state => state.report?.list
export const selectReportTotalRecord = state => state.report?.pageInfo
