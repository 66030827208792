import React, { useEffect, useRef, useCallback } from "react"
import { useLocation, useParams } from "react-router-dom"
import PropTypes from "prop-types"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import withRouter from "components/Common/withRouter"
import { Link } from "react-router-dom"
import { authentiCateUserPermission } from "helpers"
import {
  selectCurrentUser,
  selectPermissions,
} from "redux/authentication/slice"
import { useSelector } from "react-redux"
import { useReviewPropertyCountQuery } from "redux/review/api"
import { Badge } from "reactstrap"
import { formatBigNumber } from "helpers/data-formatters"

const SidebarContent = props => {
  const ref = useRef()
  const activateParentDropdown = useCallback(item => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }, [])

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement

      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show")
        }

        parent.classList.remove("mm-active")
        const parent2 = parent.parentElement

        if (parent2) {
          parent2.classList.remove("mm-show")

          const parent3 = parent2.parentElement
          if (parent3) {
            parent3.classList.remove("mm-active") // li
            parent3.childNodes[0].classList.remove("mm-active")

            const parent4 = parent3.parentElement // ul
            if (parent4) {
              parent4.classList.remove("mm-show") // ul
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5.classList.remove("mm-show") // li
                parent5.childNodes[0].classList.remove("mm-active") // a tag
              }
            }
          }
        }
      }
    }
  }

  const path = useLocation()
  const { id } = useParams()
  const activeMenu = useCallback(() => {
    const pathName = path.pathname
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    removeActivation(items)

    for (let i = 0; i < items.length; ++i) {
      const sidebarPath = items[i].pathname.split("/")
      const currentPath = pathName.split("/")
      var newPathName = pathName
      if (
        sidebarPath[1] == currentPath[1] &&
        (pathName.includes("edit") ||
          ((currentPath.length == 3 || currentPath.length == 4) && !!id))
      ) {
        if (sidebarPath[1]) newPathName = `/${sidebarPath[1]}/list`
      }
      if (items[i].pathname == newPathName) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [path.pathname, activateParentDropdown])

  useEffect(() => {
    ref.current.recalculate()
  }, [])

  useEffect(() => {
    new MetisMenu("#side-menu")
    activeMenu()
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    activeMenu()
  }, [activeMenu])

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }
  const user = useSelector(selectCurrentUser)
  const userPermision = useSelector(selectPermissions)
  const {
    data: data,
    isLoading,
    isSuccess,
  } = authentiCateUserPermission(["review"], userPermision, user?.role)
    ? useReviewPropertyCountQuery()
    : {}
  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {/* Dashboard */}
            <li>
              <Link to="/dashboard">
                <i className="bx bx-home-circle"></i>
                <span>Dashboard</span>
              </Link>
            </li>
            {/* Properties */}
            {authentiCateUserPermission(
              [
                "listProperty",
                "review",
                "createProperty",
                "updateProperty",
                "archiveProperty",
                "updatePropertyStatus",
              ],
              userPermision,
              user?.role,
            ) && (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-checkbox-square"></i>
                  <span>Properties</span>
                  {data && data.data.underReviewProperties > 0 && (
                    <Badge color="info p-1 ms-3 align"> </Badge>
                  )}
                </Link>
                <ul className="sub-menu">
                  {authentiCateUserPermission(
                    ["createProperty"],
                    userPermision,
                    user?.role,
                  ) && (
                    <li>
                      <Link to="/properties/add">
                        <span>Add</span>
                      </Link>
                    </li>
                  )}
                  {authentiCateUserPermission(
                    ["listProperty"],
                    userPermision,
                    user?.role,
                  ) && (
                    <li>
                      <Link to="/properties/list">
                        <span>List</span>
                      </Link>
                    </li>
                  )}
                  {authentiCateUserPermission(
                    ["review"],
                    userPermision,
                    user?.role,
                  ) && (
                    <li>
                      <Link to="/review-properties/list">
                        <div className="d-flex justify-content-between">
                          <span>Review</span>
                          {data && data.data.underReviewProperties > 0 && (
                            <Badge color="info p-1">
                              {formatBigNumber(data.data.underReviewProperties)}
                            </Badge>
                          )}
                        </div>
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            )}
            {/* advert */}
            {authentiCateUserPermission(
              ["createAdvert", "updateAdvert", "deleteAdvert", "listAdvert"],
              userPermision,
              user?.role,
            ) && (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-briefcase-alt"></i>
                  <span>Businesses & Services</span>
                </Link>
                <ul className="sub-menu">
                  {authentiCateUserPermission(
                    ["createAdvert"],
                    userPermision,
                    user?.role,
                  ) && (
                    <li>
                      <Link to="/businesses&services/add">
                        <span>Add</span>
                      </Link>
                    </li>
                  )}
                  {authentiCateUserPermission(
                    ["listAdvert"],
                    userPermision,
                    user?.role,
                  ) && (
                    <li>
                      <Link to="/businesses&services/list">
                        <span>List</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            )}
            {authentiCateUserPermission(
              ["createBanner", "deleteBanner", "listBanner", "updateBanner"],
              userPermision,
              user?.role,
            ) && (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="mdi mdi-google-ads"></i>
                  <span>Adverts</span>
                </Link>
                <ul className="sub-menu">
                  {authentiCateUserPermission(
                    ["createBanner"],
                    userPermision,
                    user?.role,
                  ) && (
                    <li>
                      <Link to="/adverts/add">
                        <span>Add</span>
                      </Link>
                    </li>
                  )}
                  {authentiCateUserPermission(
                    ["listBanner"],
                    userPermision,
                    user?.role,
                  ) && (
                    <li>
                      <Link to="/adverts/list">
                        <span>List</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            )}
            {/* News & Blogs */}
            {authentiCateUserPermission(
              ["createBlog", "deleteBlog", "listBlog", "updateBlog"],
              userPermision,
              user?.role,
            ) && (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-news"></i>
                  <span>News & Blogs</span>
                </Link>
                <ul className="sub-menu">
                  {authentiCateUserPermission(
                    ["createBlog"],
                    userPermision,
                    user?.role,
                  ) && (
                    <li>
                      <Link to="/news&blogs/add">
                        <span>Add</span>
                      </Link>
                    </li>
                  )}
                  {authentiCateUserPermission(
                    ["listBlog"],
                    userPermision,
                    user?.role,
                  ) && (
                    <li>
                      <Link to="/news&blogs/list">
                        <span>List</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            )}
            {/* users */}
            {authentiCateUserPermission(
              ["createUser", "updateUser", "deleteUser", "banUser", "listUser"],
              userPermision,
              user?.role,
            ) && (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-user-circle"></i>
                  <span>Users</span>
                </Link>
                <ul className="sub-menu">
                  {authentiCateUserPermission(
                    ["createUser"],
                    userPermision,
                    user?.role,
                  ) && (
                    <li>
                      <Link to="/user/add">
                        <span>Add</span>
                      </Link>
                    </li>
                  )}
                  {authentiCateUserPermission(
                    ["listUser"],
                    userPermision,
                    user?.role,
                  ) && (
                    <li>
                      <Link to="/user/list">
                        <span>List</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            )}
            {/* Review*/}
            {/* {authentiCateUserPermission(
              ["review"],
              userPermision,
              user?.role,
            ) && (
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bxs-badge-check"></i>
                    <span>Review</span>
                  </Link>
                  <ul className="sub-menu">
                    {authentiCateUserPermission(
                    ["review"],
                    userPermision,
                    user?.role,
                  ) && (
                    <li>
                      <Link to="/review/adverts/list">
                        <span>Advert</span>
                      </Link>
                    </li>
                  )}

                  </ul>
                </li>
              )} */}
            {/* Review*/}
            {authentiCateUserPermission(
              ["paymentList"],
              userPermision,
              user?.role,
            ) && (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-pound"></i>
                  <span>Payments</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/payment/properties/list">
                      <span>Properties</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/payment/businesses&services/list">
                      <span>Businesses & Services</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/payment/adverts/list">
                      <span>Adverts</span>
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {/* CMS Settings */}
            {authentiCateUserPermission(
              ["updateCMS"],
              userPermision,
              user?.role,
            ) && (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-tone"></i>
                  <span>CMS Setting</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/pages/settings">
                      <span>Web Setting</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/pages/home">
                      <span>Home</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/pages/about-us">
                      <span>About us</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/pages/properties">
                      <span>Properties</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/pages/riding">
                      <span>Riding</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/pages/shopping">
                      <span>Shopping</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/pages/business-service">
                      <span>Business & Services</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/pages/faq">
                      <span>FAQ's</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/pages/news-blogs">
                      <span>News & Blogs</span>
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {/* Role manage */}
            {authentiCateUserPermission(
              ["listCategory", "listPrefix", "manageSettings"],
              userPermision,
              user?.role,
            ) && (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-cog"></i>
                  <span>Setting</span>
                </Link>
                <ul className="sub-menu">
                  {user.role == "admin" && (
                    <li>
                      <Link to="/role/list">
                        <span>Role</span>
                      </Link>
                    </li>
                  )}
                  {authentiCateUserPermission(
                    ["listPrefix"],
                    userPermision,
                    user?.role,
                  ) && (
                    <li>
                      <Link to="/prefix/list">
                        <span>Prefix</span>
                      </Link>
                    </li>
                  )}
                  {authentiCateUserPermission(
                    ["listCategory"],
                    userPermision,
                    user?.role,
                  ) && (
                    <li>
                      <Link to="/categories/list">
                        <span>Categories</span>
                      </Link>
                    </li>
                  )}
                  {authentiCateUserPermission(
                    ["manageSettings"],
                    userPermision,
                    user?.role,
                  ) && (
                    <li>
                      <Link to="/setting/charges">
                        <span>Charges</span>
                      </Link>
                    </li>
                  )}
                  {authentiCateUserPermission(
                    ["reportingLocation"],
                    userPermision,
                    user?.role,
                  ) && (
                    <li>
                      <Link to="/reporting-location/list">
                        <span>Reporting Location</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            )}

            {authentiCateUserPermission(
              ["contacts"],
              userPermision,
              user?.role,
            ) && (
              <li>
                <Link to="/contacts/list">
                  <i className="bx bxs-user-detail"></i>
                  <span>Contact Requests</span>
                </Link>
              </li>
            )}
            {authentiCateUserPermission(["RFA"], userPermision, user?.role) && (
              <li>
                <Link to="/rfa/list">
                  <i className="bx bxs-error"></i>
                  <span>RFA</span>
                </Link>
              </li>
            )}
            {authentiCateUserPermission(
              ["report"],
              userPermision,
              user?.role,
            ) && (
              <li>
                <Link to="/report/list">
                  <i className="bx bxs-flag"></i>
                  <span>Reports</span>
                </Link>
              </li>
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
}

export default withRouter(SidebarContent)
