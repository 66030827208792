import React, { useEffect, useMemo, useState } from "react"

//Import Breadcrumb
import CustomTable from "components/Common/Table/CustomeTable"
import { useDispatch, useSelector } from "react-redux"
import {
  selectPropertyList,
  selectPropertyTotalRecord,
  setPropertiesList,
} from "redux/property/slice"
import {
  SweetAlert,
  authentiCateUserPermission,
  confirmAlert,
  showError,
  showToaster,
} from "helpers"
import {
  LiveStatus,
  NumberFormate,
  TitleText,
  Type,
  Status,
} from "./ColumnFormatters"
import { Link, Navigate, useNavigate } from "react-router-dom"
import { UncontrolledTooltip } from "reactstrap"
import {
  selectCurrentUser,
  selectPermissions,
} from "redux/authentication/slice"
import {
  useApprovePropertyMutation,
  useRejectMessageListMutation,
  useReviewPropertyListQuery,
} from "redux/review/api"
import RejectModel from "./RejectModel"
import * as Yup from "yup"
import { useFormik } from "formik"
import { SelectColumnFilter } from "./Filter"
function List() {
  const data = useSelector(selectPropertyList)
  const pageInfo = useSelector(selectPropertyTotalRecord)
  const [state, setState] = useState({
    search: undefined,
    type: null,
    limit: 10,
    page: 1,
    status: null,
  })
  const [load, setLoad] = useState(false)
  const dispatch = useDispatch()
  const user = useSelector(selectCurrentUser)
  const userPermissions = useSelector(selectPermissions)
  const [approve, approveAPI] = useApprovePropertyMutation()
  const onAprove = async data => {
    try {
      var confirmation
      if (data?.status) {
        const confirmationMessage = `Are you sure want to ${
          data?.status ? "Approve" : "Reject"
        } this property ?`
        confirmation = await confirmAlert(confirmationMessage, "question")
      }

      if (confirmation?.isConfirmed || !data?.status) {
        const result = await approve(data).unwrap()
        setLoad(load => !load)
        SweetAlert(`${result.message}`, "success")
      }
    } catch (error) {
      showError(error)
    }
  }
  const [openModal, setOpenModal] = useState(false)
  const onOpenModel = data => {
    formik.setValues({ ...data, comments: "" })
    setOpenModal(true)
  }
  const toggle = () => {
    if (openModal) {
      setOpenModal(openModal => !openModal)
      formik.resetForm()
      setCommentsData([])
    }
  }
  // Yup validation
  const validationSchema = Yup.object().shape({
    comments: Yup.string().trim().required("Please Add Comment"),
  })
  const [commentsData, setCommentsData] = useState([])
  const [getComments, getCommentsAPI] = useRejectMessageListMutation()
  const onShowComments = async id => {
    try {
      const result = await getComments(id).unwrap()
      setCommentsData(result.data)
      setOpenModal(true)
    } catch (error) {
      showError(error)
      setOpenModal(true)
    }
  }

  // formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      comments: "",
    },
    validationSchema,
    onSubmit: async values => {
      onAprove(values)
      toggle()
    },
  })
  const columns = useMemo(() => [
    {
      Header: "Id",
      accessor: "id",
      // filter: false,
      Cell: cellProps => {
        return <div> {cellProps.row.original.id} </div>
      },
    },
    {
      Header: "Title",
      accessor: "title",
      filter: false,
      filterable: false,
      disableFilters: true,
      disableSortBy: true,
      maxSize: 350,
      Cell: cellProps => {
        return <TitleText {...cellProps} />
      },
    },
    {
      Header: "Listed By",
      accessor: "user.name",
      filterable: false,
      disableFilters: true,
      disableSortBy: true,
      maxSize: 200,
      Cell: cellProps => {
        return <TitleText {...cellProps} />
      },
    },
    {
      Header: "Type",
      accessor: "type",
      filterable: false,
      disableFilters: true,
      disableSortBy: true,
      maxSize: 150,
      Cell: cellProps => {
        return <Type {...cellProps} />
      },
    },
    {
      Header: "Price ( € )",
      accessor: "price",
      filterable: false,
      disableFilters: true,
      disableSortBy: true,
      maxSize: 150,
      Cell: cellProps => {
        return <NumberFormate {...cellProps} />
      },
    },
    {
      Header: "Address",
      accessor: "address",
      filterable: false,
      disableFilters: true,
      disableSortBy: true,
      Cell: cellProps => {
        return <div> {cellProps.value} </div>
      },
    },
    {
      Header: "Area ( Acres )",
      accessor: "area",
      filterable: false,
      disableFilters: true,
      disableSortBy: true,
      Cell: cellProps => {
        return <NumberFormate {...cellProps} />
      },
    },
    {
      Header: "Status",
      accessor: "status",
      // filterable: false,
      Filter: SelectColumnFilter, // Specify the custom filter component
      disableFilters: false, // Enable filtering for the column
      disableSortBy: true,
      maxSize: 200,
      Cell: cellProps => {
        return <Status {...cellProps} />
      },
    },
    {
      Header: "Action",
      accessor: "action",
      disableFilters: true,
      disableSortBy: true,
      maxSize: 150,
      Cell: cellProps => {
        return (
          <div className="d-flex gap-2">
            {cellProps.row.original.status == "underReview" && (
              <>
                <Link
                  // type="button"
                  to={"#"}
                  className=" "
                  onClick={() => {
                    const property = cellProps.row.original
                    onAprove({ propertyId: property.id, status: true })
                    return
                  }}
                >
                  <i
                    className="mdi  mdi-check-all text-success font-size-18"
                    id={`Aprove${cellProps.row.original.id}`}
                  ></i>
                  <UncontrolledTooltip
                    placement="top"
                    target={`Aprove${cellProps.row.original.id}`}
                  >
                    Approve
                  </UncontrolledTooltip>
                </Link>
                <Link
                  // type="Link"
                  to={"#"}
                  className=""
                  onClick={() => {
                    const property = cellProps.row.original
                    onOpenModel({ propertyId: property?.id, status: false })
                    return
                  }}
                >
                  <i
                    className="mdi mdi-close-thick text-danger font-size-18"
                    id={`Rejected${cellProps.row.original.id}`}
                  ></i>
                  <UncontrolledTooltip
                    placement="top"
                    target={`Rejected${cellProps.row.original.id}`}
                  >
                    Reject
                  </UncontrolledTooltip>
                </Link>
                {/* {!cellProps.row.original.isArchived &&
                  authentiCateUserPermission(
                    ["listProperty"],
                    userPermissions,
                    user?.role,
                  ) && ( */}
                <Link
                  to={`/review-properties/${cellProps.row.original.id}`}
                  className="text-info"
                >
                  <i
                    className="mdi mdi-eye font-size-18"
                    id={`view${cellProps.row.original.id}`}
                  />
                  <UncontrolledTooltip
                    placement="top"
                    target={`view${cellProps.row.original.id}`}
                  >
                    View
                  </UncontrolledTooltip>
                </Link>
                {/* )} */}
              </>
            )}
            {cellProps.row.original?.rejection > 0 && (
              <Link
                to={"#"}
                onClick={() => onShowComments(cellProps.row.original.id)}
                className="text-danger"
              >
                <i
                  className="mdi mdi-alert-circle-outline font-size-18"
                  id={`Reject${cellProps.row.original.id}`}
                />
                <UncontrolledTooltip
                  placement="top"
                  target={`Reject${cellProps.row.original.id}`}
                >
                  Rejected Comment
                </UncontrolledTooltip>
              </Link>
            )}
          </div>
        )
      },
    },
  ])

  const list = useReviewPropertyListQuery({ state: state })
  if (list.isSuccess) {
    dispatch(setPropertiesList(list.data.data))
  }

  if (list.isError) {
    showError(list.error)
  }

  useEffect(() => {
    list.refetch()

    return () => {
      dispatch(
        setPropertiesList({
          list: [],
          pageInfo: { limit: 10, page: 1, totalPages: 1, totalResults: 0 },
        }),
      )
    }
  }, [state, load])
  return (
    <React.Fragment>
      <CustomTable
        data={data}
        list={list}
        totalRecords={pageInfo?.totalResults}
        stateAPI={state}
        setStateAPI={setState}
        columns={columns}
      />
      {openModal && (
        <RejectModel
          openModal={openModal}
          toggle={toggle}
          formik={formik}
          data={commentsData}
        />
      )}
    </React.Fragment>
  )
}
export default List
