import React, { useState } from "react"
import { Container } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Helmet } from "react-helmet"
import { FaqForm } from "./Form"
import { useGetPagesDataQuery } from "redux/cms/api"
function Faq() {
  const { data, isLoading, isSuccess, refetch } = useGetPagesDataQuery("faq")
  const BreadcrumbDetails = {
    breadcrumbItem: {
      label: "FAQ's",
      link: "/pages/faq",
    },
    paths: [
      {
        label: "Pages",
        link: "/pages/faq",
      },
    ],
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>FAQ's'sQ | Discover Equestrian</title>
      </Helmet>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs breadcrumbDetails={BreadcrumbDetails} />
          <FaqForm
            data={data}
            isLoading={isLoading}
            isSuccess={isSuccess}
            tab={"FAQs"}
            refetch={refetch}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Faq
