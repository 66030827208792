import React, { useEffect, useState } from "react"
import "./index.scss"
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  Form,
  FormFeedback,
  UncontrolledTooltip,
} from "reactstrap"
import "react-datepicker/dist/react-datepicker.css"
import { useFormik } from "formik"
import * as Yup from "yup"
import { showError, showToaster } from "helpers"
import GoogleAddressField from "components/Common/Location/GoogleLocation"
import SelectMapAddress from "components/Common/Location/SelectMapAddress"
import {
  useAddReportingLocationMutation,
  useGetReportingLocationMutation,
  useUpdateReportingLocationMutation,
} from "redux/reportingList/api"
import { TagsInput } from "react-tag-input-component"
import { useDispatch, useSelector } from "react-redux"
import {
  selectReportingLocationList,
  selectReportingLocationTotalRecord,
  setReportingLocationList,
} from "redux/reportingList/slice"
import LoadingSpinner from "components/Common/LoadingSpinner"

function AddModel({
  openModal,
  setOpenModal,
  setLoadList,
  load,
  id = null,
  setId,
}) {
  const [addReportingLocation, addReportingLocationAPI] =
    useAddReportingLocationMutation()
  // edit page
  const reportingLocationData = useSelector(selectReportingLocationList)
  const reportingLocationPageInfo = useSelector(
    selectReportingLocationTotalRecord,
  )
  const dispatch = useDispatch()
  const IsEdit = !!id
  const [editInfo, setEditInfo] = useState([])
  const [getData, getDataAPI] = useGetReportingLocationMutation()
  const [updateData, updateDataAPI] = useUpdateReportingLocationMutation()
  const [mapModalShow, setMapModalShow] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getData(id).unwrap()
        setEditInfo({ ...result?.data })
      } catch (error) {
        showError(error)
      }
    }
    if (id) {
      fetchData()
    }
  }, [id])
  const validationSchema = Yup.object().shape({
    type: Yup.string().required("Type selection is required"),
    councilName: Yup.string().required("Council Name is required"),
    address: Yup.string()
      .trim()
      .required("Location is required")
      .test("not-same-as-old", "Enter valid Location", function (value) {
        return typeof this.parent.lat == "number"
      }),
    councilEmails: Yup.array()
      .of(Yup.string().email("Invalid Council email"))
      .min(1, "Council Email is required"),
  })
  const editInitialValues = {
    type: editInfo?.type,
    councilName: editInfo?.councilName,
    address: editInfo?.location?.name,
    long: editInfo?.location?.coordinates?.length
      ? editInfo?.location?.coordinates[0]
      : "",
    lat: editInfo?.location?.coordinates?.length
      ? editInfo?.location?.coordinates[1]
      : "",
    additionalInfo: editInfo?.additionalInfo,
    councilEmails:
      editInfo && editInfo?.councilEmails && editInfo?.councilEmails?.length > 2
        ? editInfo?.councilEmails?.split(",")
        : [],
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: IsEdit
      ? editInitialValues
      : {
          type: "",
          councilName: "",
          address: "",
          additionalInfo: "",
          long: "",
          lat: "",
          councilEmails: [],
        },
    validationSchema,
    onSubmit: async values => {
      const apiObj = {
        type: values.type,
        councilName: values.councilName,
        location: {
          name: values.address,
          coordinates: [values?.long, values?.lat],
        },
        additionalInfo:
          values.additionalInfo !== "" ? values?.additionalInfo : undefined,
        councilEmails: `${values?.councilEmails}`,
      }
      if (!IsEdit) {
        try {
          const result = await addReportingLocation(apiObj).unwrap()
          setLoadList(load => !load)
          toggle()

          showToaster("success", `${result.message}`, "Success")
        } catch (error) {
          showError(error)
        }
      } else {
        const updateObj = {
          type: editInfo?.type == values.type ? undefined : values.type,
          councilName:
            editInfo?.councilName == values.councilName
              ? undefined
              : values.councilName,
          location:
            editInfo?.location?.name == values.address
              ? undefined
              : {
                  name: values.address,
                  coordinates: [values?.long, values?.lat],
                },
          additionalInfo:
            editInfo?.additionalInfo == values.additionalInfo &&
            values.additionalInfo == ""
              ? undefined
              : values.additionalInfo,
          councilEmails:
            editInfo?.councilEmails == `${values.councilEmails}`
              ? undefined
              : `${values.councilEmails}`,
        }
        try {
          const result = await updateData({
            value: updateObj,
            id: id,
          }).unwrap()
          const updatedArray = await reportingLocationData.map(item => {
            if (item?._id == result?.data?._id) {
              return result?.data // Replace object with id 2
            } else {
              return item // Keep other objects unchanged
            }
          })
          dispatch(
            setReportingLocationList({
              reportingLocations: updatedArray,
              pageInfo: reportingLocationPageInfo,
            }),
          )
          // setLoadList(load => !load)
          toggle()
          showToaster("success", `${result.message}`, "Success")
        } catch (error) {
          showError(error)
        }
      }
    },
  })
  const toggle = () => {
    if (openModal) {
      setOpenModal(openModal => !openModal)
      setEditInfo([])
      formik.resetForm()
      setId(null)
    }
  }
  const handleInputChange = (fieldName, e) => {
    const sanitizedValue = e.target.value.replace(/\s+/g, " ")
    formik.handleChange(e)
    formik.setFieldValue(fieldName, sanitizedValue)
  }

  return (
    <React.Fragment>
      <Modal
        size="lg"
        isOpen={openModal}
        toggle={toggle}
        backdrop={"static"}
        centered={true}
      >
        <ModalHeader toggle={toggle} tag="h4">
          {!IsEdit ? "Add Reporting Location" : "Update Reporting Location"}
        </ModalHeader>
        <ModalBody>
          {getDataAPI?.isLoading || getDataAPI?.isFetching ? (
            <Col lg="12" className="w-100 d-flex justify-content-center">
              {" "}
              <LoadingSpinner />{" "}
            </Col>
          ) : (
            <Form
              className="outer-repeater"
              onSubmit={e => {
                e.preventDefault()
                formik.handleSubmit()
                // return
              }}
            >
              <div data-repeater-list="outer-group" className="outer">
                <div data-repeater-item className="outer">
                  <FormGroup className="mb-0" row>
                    <Label htmlFor="user" className="col-form-label col-lg-2">
                      Reporting Type
                    </Label>
                    <Col lg="10">
                      <Input
                        type="select"
                        name="type"
                        className="form-control"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.type || ""}
                        invalid={!!(formik.touched.type && formik.errors.type)}
                      >
                        <option value="">Select Reporting Type</option>
                        <option value="accident">Accident</option>
                        <option value="blockage">Blockage</option>
                        <option value="near-miss">Near Miss</option>
                      </Input>
                      {formik.touched.type && formik.errors.type && (
                        <FormFeedback className="fs-6 mt-0" type="invalid">
                          {formik.errors.type}
                        </FormFeedback>
                      )}
                    </Col>
                  </FormGroup>
                  <FormGroup className="mb-0" row>
                    <Label
                      htmlFor="councilName"
                      className="col-form-label col-lg-2"
                    >
                      Council Name
                    </Label>
                    <Col lg="10">
                      <Input
                        id="councilName"
                        name="councilName"
                        type="text"
                        className="form-control"
                        placeholder="Enter council Name"
                        value={formik.values.councilName || ""}
                        onChange={e => {
                          handleInputChange("councilName", e)
                        }}
                        invalid={
                          !!(
                            formik.touched.councilName &&
                            formik.errors.councilName
                          )
                        }
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.councilName &&
                        formik.errors.councilName && (
                          <FormFeedback className="fs-6 mt-0" type="invalid">
                            {formik.errors.councilName}
                          </FormFeedback>
                        )}
                    </Col>
                  </FormGroup>
                  <FormGroup className="mb-0" row>
                    <Label
                      htmlFor="location"
                      className="col-form-label col-lg-2"
                    >
                      Location
                    </Label>
                    <Col lg="10" className="">
                      <div className="auth-pass-inputgroup location-wrapper align-items-start d-flex">
                        <div style={{ width: "92%", display: "inline-block" }}>
                          <GoogleAddressField
                            formik={formik}
                            type="text"
                            className="form-control goo"
                            placeholder="Find Location"
                            id="address"
                            name="address"
                            invalid={
                              !!(
                                formik.touched.address && formik.errors.address
                              )
                            }
                          />
                        </div>
                        <button
                          style={{ width: "8%", display: "inline-block" }}
                          onClick={() => setMapModalShow(true)}
                          className="btn btn-light "
                          type="button"
                          id="pin"
                        >
                          <i
                            className={`bx bx-map text-danger font-size-16`}
                          ></i>
                          <UncontrolledTooltip placement="top" target={`pin`}>
                            Pin on map
                          </UncontrolledTooltip>
                        </button>
                      </div>
                    </Col>
                    <SelectMapAddress
                      mapModalShow={mapModalShow}
                      setMapModalShow={setMapModalShow}
                      formik={formik}
                    />
                  </FormGroup>
                  <FormGroup className="mb-0" row>
                    <Label className="col-form-label col-lg-2">
                      Addition Info
                    </Label>
                    <Col lg="10">
                      <Input
                        id=" additionalInfo"
                        name=" additionalInfo"
                        type="textarea"
                        className="form-control"
                        placeholder="Enter Addition Info Here"
                        value={formik.values.additionalInfo}
                        onChange={e => {
                          handleInputChange("additionalInfo", e)
                        }}
                        onBlur={formik.handleBlur}
                        invalid={
                          !!(
                            formik.touched.additionalInfo &&
                            formik.errors.additionalInfo
                          )
                        }
                      />
                      {formik.touched.additionalInfo &&
                        formik.errors.additionalInfo && (
                          <FormFeedback className="fs-6 mt-0" type="invalid">
                            {formik.errors.additionalInfo}
                          </FormFeedback>
                        )}
                    </Col>
                  </FormGroup>
                  <FormGroup className="mb-0" row>
                    <Label className="col-form-label col-lg-2 pt-4">
                      Council Email
                    </Label>
                    <Col lg="10">
                      <em className="text-info">
                        Press enter to add multiple Council Email
                      </em>
                      <TagsInput
                        value={formik.values.councilEmails}
                        onChange={data => {
                          formik.handleChange({
                            target: {
                              name: "councilEmails",
                              value: data,
                            },
                          })
                        }}
                        onBlur={formik.handleBlur}
                        name="councilEmails"
                        placeHolder="Enter Council Email"
                        invalid={
                          !!(
                            formik.touched.councilEmails &&
                            formik.errors.councilEmails
                          )
                        }
                        beforeAddValidate={email => {
                          const isEmailValid =
                            /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,3}$/

                          if (!isEmailValid.test(email)) {
                            showToaster(
                              "error",
                              "Enter valid Council Email",
                              "Wrong",
                            )
                          }

                          return isEmailValid.test(email)
                        }}
                      />
                      {formik.touched.councilEmails &&
                        formik.errors.councilEmails && (
                          <div className="text-danger fs-6 mt-0">
                            {formik.errors.councilEmails}
                          </div>
                        )}
                    </Col>
                  </FormGroup>
                </div>
              </div>
              <Row className="mb-3">
                <div className="modal-footer">
                  <Col className="text-end">
                    <button
                      type="button"
                      className="btn btn-secondary me-2"
                      onClick={() => toggle()}
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      disabled={
                        formik.isSubmitting ||
                        (IsEdit && formik.initialValues == formik.values)
                      }
                      className={`btn btn-success w-sm ${
                        formik.isSubmitting ? "cursor-wait" : "cursor-pointer"
                      }`}
                    >
                      {formik.isSubmitting ? (
                        <div className="d-flex justify-content-center align-items-center">
                          <div
                            className="spinner-border spinner-border-sm me-1"
                            role="status"
                          >
                            <span className="sr-only">Adding...</span>
                          </div>
                          <span>Adding...</span>
                        </div>
                      ) : !IsEdit ? (
                        <>
                          {/* <i className="bx bx-plus font-size-16 align-middle me-2"></i>{" "} */}
                          Add
                        </>
                      ) : (
                        <>
                          {/* <i className="bx bx-up-arrow-alt font-size-16 align-middle me-2"></i>{" "} */}
                          Update
                        </>
                      )}
                    </button>
                  </Col>
                </div>
              </Row>
            </Form>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default AddModel
