import update from "immutability-helper"
import React, { useCallback, useEffect, useState } from "react"
import { Card } from "./Card.js"
export const DragImage = ({
  Images,
  setImages,
  imageSequence,
  setImageSequence,
  handleDeleteImage,
}) => {
  useEffect(() => {
    if (Images.length != imageSequence.length) {
      setImageSequence(
        Images.map((value, index) => {
          return { ImageObj: value, id: index }
        }),
      )
    }
  }, [Images])
  useEffect(() => {
    setImages(
      imageSequence.map((value, index) => {
        return { ...value.ImageObj }
      }),
    )
  }, [imageSequence])
  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setImageSequence(prevCards =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      }),
    )
  }, [])

  const renderCard = useCallback(
    (card, index) => {
      return (
        <Card
          key={card.id}
          index={index}
          id={card.id}
          ImageObj={card.ImageObj}
          moveCard={moveCard}
          handleDeleteImage={handleDeleteImage}
        />
      )
    },
    [Images],
  )
  return (
    <>
      <div className="row properties-img-wrapper">
        {imageSequence.map((card, i) => renderCard(card, i))}
      </div>
    </>
  )
}
