import { showError } from "helpers"
import React, { useEffect, useState } from "react"
import { Input } from "reactstrap"
import { useCategoryListQuery } from "redux/category/api"
export const SelectColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column
  // category option data list
  const [categoryOption, setcategoryOption] = useState([])
  const categoryData = useCategoryListQuery()
  useEffect(() => {
    if (categoryData.isSuccess) {
      setcategoryOption(categoryData?.data?.data?.categories)
    }
    if (categoryData.isError) {
      showError(categoryData.error)
    }
    return () => {
      setcategoryOption([])
    }
  }, [categoryData])
  return (
    <div>
      <Input
        type="select"
        className="form-control"
        value={filterValue}
        onChange={e => {
          setFilter(e.target.value || undefined)
        }}
      >
        <option value="">All</option>
        {categoryOption?.map((val, index) => {
          return (
            <option key={index} value={`${val?.id}`}>
              {val?.name}
            </option>
          )
        })}
      </Input>
    </div>
  )
}
export const SelectColumnFilterStatus = ({ column }) => {
  const { filterValue, setFilter } = column
  return (
    <select
      id="custom-select"
      className="form-select"
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value="">All</option>
      <option value="draft">Draft</option>
      <option value="live">Live</option>
    </select>
  )
}
