import React from "react"
import { Helmet } from "react-helmet"
import { Container } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import List from "./List"
function RFAList() {
  const BreadcrumbDetails = {
    breadcrumbItem: {
      label: "List",
      link: "/rfa/list",
    },
    paths: [
      {
        label: "RFA",
        link: "/rfa/list",
      },
    ],
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>RFA List | Discover Equestrian</title>
      </Helmet>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs breadcrumbDetails={BreadcrumbDetails} />

          <List />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default RFAList
