import React, { useEffect, useMemo, useState } from "react"

//Import Breadcrumb
import CustomTable from "components/Common/Table/CustomeTable"
import { useDispatch, useSelector } from "react-redux"
import {
  selectPropertyList,
  selectPropertyTotalRecord,
  setPropertiesList,
} from "redux/property/slice"
import {
  useArchivePropertyMutation,
  useDeletePropertyMutation,
  usePropertyListQuery,
} from "redux/property/api"
import {
  SweetAlert,
  authentiCateUserPermission,
  confirmAlert,
  showError,
  showToaster,
} from "helpers"
import {
  DateFormate,
  LiveStatus,
  NumberFormate,
  TitleText,
  Type,
} from "./ColumnFormatters"
import { SelectColumnFilter } from "./Filter"
import { Link, Navigate, useNavigate } from "react-router-dom"
import { UncontrolledTooltip } from "reactstrap"
import {
  selectCurrentUser,
  selectPermissions,
} from "redux/authentication/slice"
function List({ userId = null }) {
  const data = useSelector(selectPropertyList)
  const pageInfo = useSelector(selectPropertyTotalRecord)
  const [state, setState] = useState({
    search: undefined,
    type: null,
    limit: 10,
    page: 1,
    status: null,
  })
  const [load, setLoad] = useState(false)
  const [archiveProperty, archivePropertyAPI] = useArchivePropertyMutation()
  const [deleteProperty, deletePropertyAPI] = useDeletePropertyMutation()
  const dispatch = useDispatch()
  const user = useSelector(selectCurrentUser)
  const userPermissions = useSelector(selectPermissions)
  const onArchive = async (id, IsArchived) => {
    try {
      const confirmationMessage = `Are you sure want to ${
        IsArchived ? "revert" : "archive"
      } this property ?`
      const confirmation = await confirmAlert(confirmationMessage)
      if (confirmation.isConfirmed) {
        const result = await archiveProperty({
          id: id,
          isArchived: !IsArchived,
        }).unwrap()
        setLoad(load => !load)
        SweetAlert(`${result.message}`, "success")
      }
    } catch (error) {
      showError(error)
    }
  }
  const onDelete = async (id, userId) => {
    try {
      const confirmationMessage = `Are you sure want to delete this property ?`
      const confirmation = await confirmAlert(confirmationMessage)
      if (confirmation.isConfirmed) {
        const result = await deleteProperty({ id, userId }).unwrap()
        setLoad(load => !load)
        SweetAlert(`${result.message}`, "success")
      }
    } catch (error) {
      showError(error)
    }
  }
  const columns = useMemo(() => [
    {
      Header: "Id",
      accessor: "id",
      // filter: false,
      Cell: cellProps => {
        return <div> {cellProps.row.original.id} </div>
      },
    },
    {
      Header: "Title",
      accessor: "title",
      filter: false,
      filterable: false,
      disableFilters: true,
      disableSortBy: true,
      maxSize: 300,
      Cell: cellProps => {
        return <TitleText {...cellProps} />
      },
    },
    {
      Header: "Listed By",
      accessor: "user.name",
      filterable: false,
      disableFilters: true,
      disableSortBy: true,
      Cell: cellProps => {
        return <TitleText {...cellProps} />
      },
    },
    {
      Header: "Type",
      accessor: "type",
      filterable: false,
      disableFilters: true,
      disableSortBy: true,
      maxSize: 100,
      Cell: cellProps => {
        return <Type {...cellProps} />
      },
    },
    {
      Header: "Price ( € )",
      accessor: "price",
      filterable: false,
      disableFilters: true,
      disableSortBy: true,
      Cell: cellProps => {
        return <NumberFormate {...cellProps} />
      },
    },
    {
      Header: "Created At",
      accessor: "createdAt",
      filterable: false,
      disableFilters: true,
      maxSize: 150,
      disableSortBy: true,
      Cell: cellProps => {
        return <DateFormate {...cellProps} />
      },
    },
    {
      Header: "Address",
      accessor: "address",
      filterable: false,
      disableFilters: true,
      maxSize: 300,
      disableSortBy: true,
      Cell: cellProps => {
        return <div> {cellProps.value || "-"} </div>
      },
    },
    {
      Header: "Area ( Acres )",
      accessor: "area",
      filterable: false,
      disableFilters: true,
      disableSortBy: true,
      Cell: cellProps => {
        return <NumberFormate {...cellProps} />
      },
    },
    {
      Header: "Status",
      accessor: "isLive",
      filter: "equals", // Set the filter property to specify filter type
      Filter: SelectColumnFilter, // Specify the custom filter component
      disableFilters: false, // Enable filtering for the column
      disableSortBy: true,
      maxSize: 200,
      Cell: cellProps => {
        return <LiveStatus {...cellProps} />
      },
    },
    {
      Header: "Action",
      accessor: "action",
      disableFilters: true,
      disableSortBy: true,
      Cell: cellProps => {
        return (
          <>
            {cellProps.row.original.isDeleted ? (
              "-"
            ) : (
              <div className="d-flex gap-2">
                {!cellProps.row.original.isArchived &&
                  !cellProps.row.original.isDeleted &&
                  authentiCateUserPermission(
                    ["updateProperty"],
                    userPermissions,
                    user?.role,
                  ) && (
                    <Link
                      to={
                        userId
                          ? `/user/property/edit/${cellProps.row.original.id}`
                          : `/properties/edit/${cellProps.row.original.id}`
                      }
                      className="text-success"
                    >
                      <i
                        className="mdi mdi-lead-pencil font-size-18"
                        id={`edit${cellProps.row.original.id}`}
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target={`edit${cellProps.row.original.id}`}
                      >
                        Edit
                      </UncontrolledTooltip>
                    </Link>
                  )}
                {!!authentiCateUserPermission(
                  ["listProperty"],
                  userPermissions,
                  user?.role,
                ) &&
                  !(cellProps?.row?.original?.status == "draft") &&
                  !cellProps?.row?.original?.isDeleted && (
                    <Link
                      to={
                        userId
                          ? `/user/property/${cellProps.row.original.id}`
                          : `/properties/${cellProps.row.original.id}`
                      }
                      className="text-info"
                    >
                      <i
                        className="mdi mdi-eye font-size-18"
                        id={`view${cellProps.row.original.id}`}
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target={`view${cellProps.row.original.id}`}
                      >
                        View
                      </UncontrolledTooltip>
                    </Link>
                  )}
                {authentiCateUserPermission(
                  ["archiveProperty"],
                  userPermissions,
                  user?.role,
                ) &&
                  !cellProps.row.original.isDeleted &&
                  !(cellProps?.row?.original?.status == "draft") && (
                    <Link
                      to="#"
                      className="text-danger"
                      onClick={() => {
                        const Property = cellProps.row.original
                        onArchive(Property.id, Property.isArchived)
                      }}
                    >
                      {cellProps.row.original.isArchived ? (
                        <>
                          {" "}
                          <i
                            className="bx bx-revision font-size-18 pt-1 text-dark"
                            id={`revert${cellProps.row.original.id}`}
                          />
                          <UncontrolledTooltip
                            placement="top"
                            target={`revert${cellProps.row.original.id}`}
                          >
                            Un-Archived
                          </UncontrolledTooltip>
                        </>
                      ) : (
                        <>
                          {" "}
                          <i
                            className="mdi mdi-archive-arrow-down font-size-18"
                            id={`archive${cellProps.row.original.id}`}
                          />
                          <UncontrolledTooltip
                            placement="top"
                            target={`archive${cellProps.row.original.id}`}
                          >
                            Archive
                          </UncontrolledTooltip>
                        </>
                      )}
                    </Link>
                  )}
                {!cellProps?.row?.original?.isDeleted &&
                  authentiCateUserPermission(
                    ["deleteProperty"],
                    userPermissions,
                    user?.role,
                  ) && (
                    <Link
                      to="#"
                      className="text-danger"
                      onClick={() => {
                        const Property = cellProps.row.original
                        onDelete(Property.id, Property?.user?._id)
                      }}
                    >
                      <i
                        className="mdi mdi-delete font-size-18 pt-1 text-danger"
                        id={`revert${cellProps.row.original.id}`}
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target={`revert${cellProps.row.original.id}`}
                      >
                        Delete
                      </UncontrolledTooltip>
                    </Link>
                  )}
              </div>
            )}
          </>
        )
      },
    },
  ])
  const list = usePropertyListQuery({ state: state, userId: userId })
  if (list.isSuccess) {
    if (list.data.data.properties.length == 0 && state.page != 1) {
      setState({ ...state, page: 1 })
    }
    dispatch(setPropertiesList(list.data.data))
  }

  if (list.isError) {
    showError(list.error)
  }
  useEffect(() => {
    list.refetch()
    return () => {
      dispatch(
        setPropertiesList({
          list: [],
          pageInfo: { limit: 10, page: 1, totalPages: 1, totalResults: 0 },
        }),
      )
    }
  }, [state, load])
  return (
    <React.Fragment>
      <CustomTable
        data={data}
        list={list}
        totalRecords={pageInfo?.totalResults}
        stateAPI={state}
        setStateAPI={setState}
        columns={columns}
        userId={userId}
      />
    </React.Fragment>
  )
}
export default List
