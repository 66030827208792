import config from "config"
import { formatBigNumber, wordTitleCase } from "helpers/data-formatters"
import { showFirst20Words } from "helpers/util"
import React from "react"
import { Badge } from "reactstrap"

const TitleText = cell => {
  return showFirst20Words(cell.value)
}

const NumberFormate = cell => {
  return formatBigNumber(cell.value) || ""
}

const LiveStatus = cell => {
  return !cell.row.original.isArchived ? (
    <Badge
      className={"font-size-12 badge-soft-" + (cell.value ? "success" : "dark")}
    >
      {cell.value ? "Live" : "Draft"}
    </Badge>
  ) : (
    <Badge className={"font-size-12 badge-soft-" + "info"}>Archived</Badge>
  )
}
const Type = cell => {
  return cell.value.length > 0 ? (
    cell.value
      ?.map(item => item.name)
      ?.map((value, index) => {
        return index < 10 ? (
          <>
            <Badge
              className={"font-size-12 badge-soft-dark text-primary"}
              key={index}
            >
              {value}{" "}
            </Badge>{" "}
          </>
        ) : index == 10 ? (
          <Badge
            className={"font-size-12 badge-soft-dark text-black"}
            key={index}
          >
            {cell.value.length - 10 + 1}
            {"+"}
          </Badge>
        ) : (
          <></>
        )
      })
  ) : (
    <span> - </span>
  )
}

export { Type, TitleText, NumberFormate, LiveStatus }
