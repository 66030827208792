import { createSlice } from "@reduxjs/toolkit"
const categorySlice = createSlice({
  name: "category",
  initialState: {
    list: [],
    pageInfo: { limit: 10, page: 1, totalPages: 1, totalResults: 0 },
  },
  reducers: {
    setCategoryList: (state, action) => {
      const { categories, pageInfo } = action.payload
      state.list = categories
      state.pageInfo = pageInfo
      return
    },
  },
})

export const { setCategoryList } = categorySlice.actions

export default categorySlice.reducer

export const selectCategoryList = state => state.category?.list
export const selectCategoryPageInfo = state => state.category?.pageInfo
