import React, { useState } from "react"
import { Col, Card, NavItem, TabContent, TabPane, NavLink } from "reactstrap"
import classnames from "classnames"
import { FaqTable } from "pages/CMS/FAQ/FaqTable"
import { useGetPagesDataQuery } from "redux/cms/api"
import FaqModal from "pages/CMS/FAQ/Modal"
import { AdvertiseBanner } from "./AdvertiseBanner"

export const AdvertSetting = () => {
  const { data, isLoading, isSuccess, refetch } =
    useGetPagesDataQuery("advertise-with-us")
  // let tabs = ["Banner", "FAQ"] // remove advert with us faq tab front side not show
  let tabs = ["Banner"]
  const [activeTab, toggleTab] = useState(tabs[0])
  const [faqId, setFaqId] = useState("") //state for managing add and edit modal by faqId
  const [modal_center, setmodal_center] = useState(false)

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_center(id) {
    setmodal_center(!modal_center)
    if (id) {
      setFaqId(id) // only for edit faq data
    } else {
      setFaqId("")
    }
    removeBodyCss()
  }

  {
    /* CMS Tabs for managing footer/advert with us and contact us forms */
  }
  return (
    <>
      {activeTab === "FAQ" && (
        <FaqModal
          modal_center={modal_center}
          tog_center={tog_center}
          setmodal_center={setmodal_center}
          faqId={faqId}
          activeTab={"faqSection"}
          listRefetch={refetch}
        />
      )}
      <Col xl={12} lg={8}>
        <Card>
          <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
            {tabs?.map((item, index) => (
              <NavItem key={index}>
                <NavLink
                  to="#"
                  className={classnames({
                    active: activeTab === item,
                  })}
                  onClick={() => {
                    toggleTab(item)
                  }}
                >
                  {item}
                </NavLink>
              </NavItem>
            ))}
          </ul>

          <TabContent className="p-4" activeTab={activeTab}>
            {tabs?.map((item, index) => (
              <TabPane key={index} tabId={item}>
                {item === "Banner" && <AdvertiseBanner />}
                {/* {item === 'Advertise With Us' && <AdvertSetting />} */}
                {item === "FAQ" && (
                  <FaqTable
                    data={data?.data}
                    isLoading={isLoading}
                    isSuccess={isSuccess}
                    refetch={refetch}
                    tog_center={tog_center}
                    modal_center={modal_center}
                  />
                )}
              </TabPane>
            ))}
          </TabContent>
        </Card>
      </Col>
    </>
  )
}
