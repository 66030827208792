import React, { useEffect, useMemo, useState } from "react"
import CustomTable from "components/Common/Table/CustomeTable"
import { useDispatch, useSelector } from "react-redux"
import { SweetAlert, confirmAlert, showError } from "helpers"
import { Card, CardBody, UncontrolledTooltip } from "reactstrap"
import { Adderss, Date, TitleText, Type } from "./ColumnFormatters"
import { SelectColumnFilter } from "./Filter"
import {
  selectReportingLocationList,
  selectReportingLocationTotalRecord,
  setReportingLocationList,
} from "redux/reportingList/slice"
import {
  useDeleteReportingLocationMutation,
  useReportingListQuery,
} from "redux/reportingList/api"
import { Link } from "react-router-dom"
import EmailViewModel from "./EmailViewModel"
function List({ setLoad, load, onAddReportingLocation }) {
  const data = useSelector(selectReportingLocationList)
  const pageInfo = useSelector(selectReportingLocationTotalRecord)
  const [state, setState] = useState({
    search: undefined,
    type: null,
    limit: 10,
    page: 1,
    status: null,
  })
  const dispatch = useDispatch()
  const [deleteReportingLocation, deleteReportingLocationAPI] =
    useDeleteReportingLocationMutation()
  const onDelete = async id => {
    try {
      const confirmationMessage = `You want to delete this Reporting location!`
      const confirmation = await confirmAlert(
        confirmationMessage,
        "warning",
        "Yes, delete it!",
      )
      if (confirmation.isConfirmed) {
        const result = await deleteReportingLocation(id).unwrap()
        setLoad(load => !load)
        SweetAlert(`${result.message}`, "success")
      }
    } catch (error) {
      showError(error)
    }
  }
  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        // filter: false,
        Cell: cellProps => {
          return <div> {cellProps.row.original.id} </div>
        },
      },
      {
        Header: "Council Name",
        accessor: "councilName",
        // filter: false,
        filterable: false,
        disableFilters: true,
        disableSortBy: true,
        maxSize: 300,
        Cell: cellProps => {
          return cellProps.value ? <TitleText {...cellProps} /> : "-"
        },
      },
      {
        Header: "Create Date",
        accessor: "createdAt",
        filterable: false,
        disableFilters: true,
        disableSortBy: true,
        maxSize: 200,
        Cell: cellProps => {
          return <Date {...cellProps} />
        },
      },
      {
        Header: "Type",
        accessor: "type",
        filter: "equals", // Set the filter property to specify filter type
        Filter: SelectColumnFilter, // Specify the custom filter component
        disableFilters: false, // Enable filtering for the column
        disableSortBy: true,
        maxSize: 200,
        Cell: cellProps => {
          return <Type {...cellProps} />
        },
      },
      {
        Header: "Location",
        accessor: "location",
        // filter: false,
        filterable: false,
        disableFilters: true,
        disableSortBy: true,
        maxSize: 300,
        Cell: cellProps => {
          return <Adderss {...cellProps} />
        },
      },
      {
        Header: "Additional Info",
        accessor: "additionalInfo",
        // filter: false,
        filterable: false,
        disableFilters: true,
        disableSortBy: true,
        maxSize: 300,
        Cell: cellProps => {
          return cellProps.value ? <TitleText {...cellProps} /> : "-"
        },
      },
      {
        Header: "Location",
        accessor: "location.name",
        // filter: false,
        filterable: false,
        disableFilters: true,
        disableSortBy: true,
        // maxSize: 500,
        Cell: cellProps => {
          return <div> {cellProps.value} </div>
        },
      },
      {
        Header: "Action",
        accessor: "",
        // filter: false,
        filterable: false,
        disableFilters: true,
        disableSortBy: true,
        maxSize: 100,
        Cell: cellProps => {
          return (
            <>
              <div className="d-flex gap-2">
                <Link
                  to={"/#"}
                  onClick={e => {
                    e.preventDefault()
                    onAddReportingLocation(cellProps.row.original._id)
                  }}
                  className="text-success"
                >
                  <i
                    className="mdi mdi-lead-pencil font-size-18"
                    id={`edit${cellProps.row.original._id}`}
                  />
                  <UncontrolledTooltip
                    placement="top"
                    target={`edit${cellProps.row.original._id}`}
                  >
                    Edit
                  </UncontrolledTooltip>
                </Link>
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    const data = cellProps.row.original
                    onDelete(data?._id)
                  }}
                >
                  {" "}
                  <i
                    className="mdi mdi-delete font-size-18"
                    id={`delete${cellProps.row.original._id}`}
                  />
                  <UncontrolledTooltip
                    placement="top"
                    target={`delete${cellProps.row.original._id}`}
                  >
                    Delete
                  </UncontrolledTooltip>
                </Link>
                {cellProps?.row?.original?.councilEmails && (
                  <Link
                    to={"/#"}
                    onClick={e => {
                      e.preventDefault()
                      if (cellProps?.row?.original?.councilEmails) {
                        const councilEmails =
                          cellProps?.row?.original?.councilEmails // Assuming councilEmails is a string of comma-separated email addresses
                        const councilEmailsArray = councilEmails?.split(",") // Split the string into an array using commas as the separator
                        onshowMessage(councilEmailsArray)
                      }
                    }}
                    className="text-info"
                  >
                    <i
                      className="mdi mdi-email font-size-18"
                      id={`CouncilEmail${cellProps.row.original._id}`}
                    />
                    <UncontrolledTooltip
                      placement="top"
                      target={`CouncilEmail${cellProps.row.original._id}`}
                    >
                      Council Email
                    </UncontrolledTooltip>
                  </Link>
                )}
              </div>
            </>
          )
        },
      },
    ],
    [data],
  )

  const list = useReportingListQuery({ state: state })
  useEffect(() => {
    if (list.isSuccess) {
      dispatch(setReportingLocationList(list.data.data))
    }

    if (list.isError) {
      showError(list.error)
    }
  }, [list])
  useEffect(() => {
    list.refetch()

    return () => {
      dispatch(
        setReportingLocationList({
          list: [],
          pageInfo: { limit: 10, page: 1, totalPages: 1, totalResults: 0 },
        }),
      )
    }
  }, [state, load])
  const [openEmailModal, setOpenEmailModal] = useState(false)
  const [emailData, setEmailData] = useState(false)
  const onshowMessage = emailData => {
    if (emailData) {
      setEmailData(emailData)
    }
    setOpenEmailModal(true)
  }
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CustomTable
            data={data}
            list={list}
            totalRecords={pageInfo?.totalResults}
            stateAPI={state}
            setStateAPI={setState}
            columns={columns}
            hideColumns={["location.name"]}
            createButton={{
              visible: true,
              lable: "Add Reporting",
              onClick: onAddReportingLocation,
            }}
          />
        </CardBody>
      </Card>
      {openEmailModal && (
        <EmailViewModel
          openModal={openEmailModal}
          setOpenModal={setOpenEmailModal}
          emailData={emailData}
          setEmailData={setEmailData}
        />
      )}
    </React.Fragment>
  )
}
export default List
