import { formatBigNumber, wordTitleCase } from "helpers/data-formatters"
import { showFirst20Words } from "helpers/util"
import React from "react"
import { Badge } from "reactstrap"
const TitleText = cell => {
  return cell?.value ? showFirst20Words(cell?.value) : ""
}

const NumberFormate = cell => {
  return formatBigNumber(cell.value) || ""
}

const Type = cell => {
  return (
    <Badge
      className={
        "font-size-12 badge-soft-" +
        (cell.value == "videoLink" ? "info" : "dark")
      }
    >
      {cell.value == "videoLink" ? "Video" : "Image"}
    </Badge>
  )
}
const Tag = cell => {
  return cell.value.length > 0 ? (
    cell.value.map((value, index) => {
      return index < 10 ? (
        <>
          <Badge
            className={"font-size-12 badge-soft-dark text-primary"}
            key={index}
          >
            {value}{" "}
          </Badge>{" "}
        </>
      ) : index == 10 ? (
        <Badge
          className={"font-size-12 badge-soft-dark text-black"}
          key={index}
        >
          {cell.value.length - 10 + 1}
          {"+"}
        </Badge>
      ) : (
        <></>
      )
    })
  ) : (
    <span> - </span>
  )
}
const Description = cell => {
  return cell?.value ? (
    <div dangerouslySetInnerHTML={{ __html: cell?.value }} />
  ) : (
    ""
  )
}
export { TitleText, NumberFormate, Type, Tag, Description }
